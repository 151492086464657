import React from "react";
import {Image, Navbar} from "react-bootstrap";
import Logo from "../../../images/bati_plus.png";

class SidebarMainNavbar extends React.Component {
  render() {
    return (
      <Navbar bg="light"
              className="main-navbar border-bottom"
      >
        <Navbar.Brand
          className="m-0 mx-auto"
          href="#"
        >
          <Image
            id="main-logo"
            className="d-inline-block align-top"
            width={150}
            height={150}
            src={Logo}
            alt="Bati +"
          />
        </Navbar.Brand>
      </Navbar>
    );
  }
}


export default SidebarMainNavbar;
