import {Badge, Button, ButtonToolbar, Col, Dropdown, Form, FormControl, Row} from "react-bootstrap";
import * as React from "react";
import Select from "react-select";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {formatEur, NUMBER_FORMAT_EUR} from "../../utils/format";
import filterOptions from "../../utils/SelectSearch";
import {Attribution, ATTRIBUTION_SANS_OBJET, AttributionStatus, Offre} from "./Attribution";
import {User} from "../user-profile-lite/User";
import {NumericFormat} from "react-number-format";
import {AllUsers, userOrdering} from "../../utils/AllUsers";
import * as immutable from "immutable";
import {
  faCalendarAlt,
  faCheck,
  faEnvelope,
  faFileContract,
  faPen,
  faPlus,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Corps} from "../user-profile-lite/Corps";


type AttributionListItemProps = {
  corps: Corps;
  attribution: Attribution;
  update: (a: Attribution) => void;
  onPrepareMail: () => void;
  onDelete: () => void;
  onCreateEvent: () => void;
  onGenerate: (selected: Offre) => void;
  itvOptions: AllUsers;
}

type AttributionListItemState = {
  addItv: boolean;
  edit: boolean;
  editOffres: immutable.Map<number, string>;
}


export class AttributionListItem extends React.Component<AttributionListItemProps, AttributionListItemState> {
  state: AttributionListItemState = {
    addItv: false,
    edit: false,
    editOffres: immutable.Map()
  }

  private static readonly StatusOptionValues =
    AttributionStatus.entrySeq()
      .map(([value, label]) => ({value, label}))
      .toArray();

  private removeOffre(attribution: Attribution, intervenantId: number) {
    this.props.update({
      ...attribution,
      offres: attribution.offres.remove(intervenantId)
    });
  }

  private handleEdit(): void {
    this.setState({
      edit: true,
      editOffres: this.props.attribution.offres.map(o => o.offre || '')
    })
  }

  private updateDetails(e: React.FormEvent<HTMLFormElement>, a: Attribution) {
    e.preventDefault();
    const data = new FormData(e.currentTarget).get("detail");
    const detail = (data && typeof data === "string") ? data : "";
    this.props.update({
        ...a,
        detail,
        offres: a.offres.map((old, id) => ({
          ...old,
          offre: this.state.editOffres.get(id)
        }))
      }
    );
    this.setState({edit: false});
  }


  private selectOffre(o: Offre) {
    this.props.update({...this.props.attribution, selected: o.intervenantId})
  }


  private static userOrderingLotFirst(lot: number): (user1: User, user2: User) => number {
    return (user1: User, user2: User) => {
      if (user1.corps.includes(lot)) {
        if (user2.corps.includes(lot)) {
          return userOrdering(user1, user2);
        } else {
          return -1;
        }
      } else {
        if (user2.corps.includes(lot)) {
          return 1;
        } else {
          return userOrdering(user1, user2);
        }
      }
    }
  }

  private addItv(a: Attribution, u: User) {
    this.setState({addItv: false});
    return this.props.update({
      ...a,
      offres: a.offres.set(u.userId, {intervenantId: u.userId})
    })
  }


  render() {
    const {
      corps, attribution, update,
      onPrepareMail, onDelete, onCreateEvent,
      onGenerate, itvOptions
    } = this.props;

    const selected = attribution.selected === undefined ? undefined : attribution.offres.get(attribution.selected);

    return <div className="mx-0 my-1 p-1 px-3 rounded"
                style={{
                  backgroundColor: attribution.statut === ATTRIBUTION_SANS_OBJET ? undefined : corps.couleur.toCSS(),
                  border: attribution.statut === ATTRIBUTION_SANS_OBJET ? "1px solid" : undefined,
                }}
    >
      <Row>
        <Col
          sm={6}
          className="align-middle my-auto"
          style={{
            color: attribution.statut === ATTRIBUTION_SANS_OBJET ? undefined : corps.couleur.textColor().toCSS(),
            fontSize: "1.2em"
          }}>
          {corps.corps}

          {attribution.eventId &&
              <a style={{color: corps.couleur.textColor().toCSS()}} href={`/planning/${attribution.eventId}`}>
                  &nbsp;<FontAwesomeIcon icon={faCalendarAlt}/>
              </a>
          }
        </Col>
        <Col sm={5} className={"px-0 my-auto"}
             onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          <Select
            value={AttributionListItem.StatusOptionValues[attribution.statut]}
            options={AttributionListItem.StatusOptionValues}
            onChange={(event) => update(
              {
                ...attribution,
                statut: event?.value || 0
              }
            )}
          />
        </Col>

        <Col sm={1}>
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              className="border-0 text-center btn-circle"
              id="dropdown"
              variant="light"
              title=""/>
            <Dropdown.Menu>

              <Dropdown.Item
                as="button"
                onClick={() => this.handleEdit()}>
                <FontAwesomeIcon icon={faPen}/> Modifier offres et détails
              </Dropdown.Item>
              {

                <Dropdown.Item
                  as="button"
                  onClick={() => this.setState({addItv: true})}
                >
                  <FontAwesomeIcon icon={faPlus}/> Ajouter consultation
                </Dropdown.Item>
              }
              <Dropdown.Item
                as="button"
                onClick={() => onPrepareMail()}
              >
                <FontAwesomeIcon icon={faEnvelope}/> Contacter prestataires
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                as="button"
                onClick={() => onDelete()}>
                <FontAwesomeIcon icon={faTrash}/> Supprimer
              </Dropdown.Item>
              {!attribution.eventId &&
                  <Dropdown.Item
                      as="button"
                      onClick={() => onCreateEvent()}
                  >
                      <FontAwesomeIcon icon={faCalendarAlt}/> Créer tâche
                  </Dropdown.Item>
              }
              <Dropdown.Item
                as="button"
                disabled={!selected}
                onClick={() => onGenerate(selected!)}
              >
                <FontAwesomeIcon icon={faFileContract}/> Générer contrat
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>


      {attribution.offres.entrySeq()
        .map(([i, o]) =>
          <Row key={i}>
            <Col sm={12}
                 style={{
                   color: corps.couleur.textColor().toCSSAlpha(
                     attribution.selected && attribution.selected !== o.intervenantId ? 0.5 : 1)
                 }}
                 className={"p-2 my-auto form-inline"}>
              <UserBadge>{itvOptions.get(o.intervenantId)!}</UserBadge>
              {
                o.contacted && <>&nbsp;<FontAwesomeIcon icon={faEnvelope}/></>
              }
              {
                this.state.edit ?
                  <>
                    <NumericFormat
                      {...NUMBER_FORMAT_EUR}
                      className="mx-2"
                      value={this.state.editOffres.get(o.intervenantId)}
                      onValueChange={({value}) => this.setState({
                        editOffres: this.state.editOffres.set(o.intervenantId, value)
                      })}
                    />
                    <Button size="sm"
                            className="p-0 border-0 text-center btn-circle"
                            variant="danger"
                            onClick={() => this.removeOffre(attribution, o.intervenantId)}>
                      <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                  </> : (
                    o.offre &&
                    <> &nbsp;: {formatEur(Number(o.offre))}
                        &nbsp;
                        <Button size="sm"
                                className="p-0 border-0 text-center btn-circle text-success"
                                variant={attribution.selected === o.intervenantId ? "light" : "link"}
                                onClick={() => this.selectOffre(o)}>
                            <FontAwesomeIcon icon={faCheck}/>
                        </Button>
                    </>
                  )
              }
            </Col>
          </Row>
        )}

      {this.props.attribution.contrat &&
          <Row><Col sm={"12"}>
              <a href="/projet/documents"> <Badge bg={"success"}>
                  <FontAwesomeIcon icon={faFileContract}/> Contrat généré
              </Badge>
              </a>
          </Col></Row>
      }

      {this.state.addItv &&
          <Row>
              <Col sm={12}>
                  <Select
                      isSearchable
                      styles={{
                        menu: (provided) => ({...provided, width: '20em'})
                      }}
                      filterOption={filterOptions}
                      options={
                        itvOptions
                          .userSelect(
                            i => !attribution.offres.has(i.userId),
                            AttributionListItem.userOrderingLotFirst(attribution.lot)
                          )
                      }
                      onChange={(event: any) => this.addItv(attribution, event.value)}
                  />
              </Col>
          </Row>
      }

      <Row>
        <Col sm={12}
             style={{color: corps.couleur.textColor().toCSS()}}
             className="small">
          {this.state.edit ?
            <Form className="mt-1"
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.updateDetails(e, attribution)}
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}>
              <FormControl name="detail" as="textarea" defaultValue={attribution.detail}/>
              <ButtonToolbar className="mt-1">
                <Button className="ms-auto" type="submit">OK</Button>
                <Button className="ms-1" variant="secondary"
                        onClick={() => this.setState({edit: false})}
                >
                  Annuler
                </Button>
              </ButtonToolbar>
            </Form> :
            attribution.detail
          }
        </Col>
      </Row>
    </div>;
  }
}
