import {ReactNode} from "react";
import PropTypes from "prop-types";
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

export interface SidebarItem {
  before?: JSX.Element;
  to: string | URL;
  title: ReactNode;
  after?: JSX.Element;
  notif?: JSX.Element;
  indent?: number;
}

const SidebarNavItem = ({item}: { item: SidebarItem }) => (
  <Nav.Item>
    {/*<Nav.Link>*/}
    <Link to={item.to} className={"nav-link d-inline-block"}>
      <ul
        className="d-inline-block fa-ul align-middle">
        <li className="align-middle d-inline-block" style={{marginLeft: 20*(item.indent||0) + "px"}}>
          {item.before}
          {item.title && <>{item.title}</>}
          {item.notif && <>{item.notif}</>}
        </li>
      </ul>
    </Link>
    {/*</Nav.Link>*/}
    <div className="d-block">{item.after}</div>
  </Nav.Item>
);

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
