import {Card, Form, InputGroup} from "react-bootstrap";
import {Message} from "./Message";
import {UserBadge} from "../user-profile-lite/UserBadge";
import React from "react";
import {formatDate, DefaultDateTimeFormat} from "../../utils/format";
import classNames from "classnames";
import * as immutable from "immutable";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type MessageListProps = {
  messages: immutable.Seq.Indexed<Message>;
  onSelect: (m: Message) => void;
  showNotif?: boolean;
  onShowNotifChange: (sn?: boolean) => void;
}

type MessageListState = {
  search: string;
}

export class MessageList extends React.Component<MessageListProps, MessageListState> {
  state: MessageListState = {
    search: '',
  };

  render() {
    return (
      <Card className="mb-3 shadow">
        <Card.Header>
          <Form
            className="form-inline justify-content-between"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
          >
            <InputGroup className="my-1">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch}/>
              </InputGroup.Text>
              <Form.Control
                id="search"
                className="search"
                placeholder="Search for something..."
                value={this.state.search}
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) =>
                    this.setState({search: e.currentTarget.value})
                }
              />
            </InputGroup>
            {/*<div className="form-inline">*/}
            {/*  <label className="mx-2">Afficher les notifications</label>*/}
            {/*  <Checkbox*/}
            {/*    checked={this.props.showNotif === true}*/}
            {/*    indeterminate={this.props.showNotif === undefined}*/}
            {/*    onChange={() => this.props.onShowNotifChange(threeWayBoolRotate(this.props.showNotif))}/>*/}
            {/*</div>*/}
          </Form>
        </Card.Header>
        <Card.Body className="p-0">
          <table className="table table-hover mb-0 p-0" style={{borderCollapse: "collapse"}}>
            <thead className="bg-light">
            <tr>
              <th className="border-0"/>
              <th className="border-0">De</th>
              <th className="border-0">À</th>
              <th className="border-0">Sujet</th>
              <th className="border-0">Date</th>
            </tr>
            </thead>
            <tbody>
            {this.props.messages
              .filter(m => this.search(m))
              .map((m: Message) =>
                <tr key={m.messageId}
                    onClick={() => this.props.onSelect(m)}
                    className={classNames(m.unread ? "fw-bolder" : "fw-normal bg-light")}
                >
                  <td className="text-center">
                    {m.attachedBlobs.length + m.attachedDocs.length > 0 &&
                    <i className="fas fa-paperclip"/>
                    }
                  </td>
                  <td>
                    <UserBadge>{m.sender}</UserBadge>
                  </td>
                  <td>
                    {m.recipient.map((r, i) =>
                      <UserBadge key={i}>{r}</UserBadge>
                    )}
                  </td>
                  <td>{m.subject}</td>
                  <td>{formatDate(m.timestamp, DefaultDateTimeFormat)}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    );
  }

  private search(m: Message) {
    const motif = this.state.search.toLocaleLowerCase();
    return m.subject?.toLocaleLowerCase().includes(motif) ||
      m.recipient.find(r => UserBadge.link(r).toLocaleLowerCase().includes(motif)) ||
      m.content?.toLocaleLowerCase().includes(motif);
  }
}