import immutable, {Seq} from "immutable";
import {LogoEntreprise, Projet} from "./Projet";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {ReactNode, useState} from "react";
import {useAuth} from "../../utils/auth";


const ville = new RegExp("[0-9]{5}(.*)$");

export function ProjetParResponsable(props: { projets: Seq.Indexed<Projet>, children: (p: Projet) => ReactNode, colSpan: number }) {
  const credentials = useAuth();
  const [display, setDisplay] = useState(immutable.Set.of(credentials.user.userId))

  return <>
    {props.projets.groupBy(p => p.responsable.userId).valueSeq().map(projs => {
      const responsable = projs.first()!.responsable;
      return <>
        <tr>
          <th colSpan={props.colSpan + 2}>
            {display.has(responsable.userId) ?
              <Button className="py-0" size="lg" variant="link"
                      onClick={() => setDisplay(display.remove(responsable.userId))}>
                <FontAwesomeIcon icon={faAngleUp}/>
              </Button> :
              <Button className="py-0" size="lg" variant="link"
                      onClick={() => setDisplay(display.add(responsable.userId))}>
                <FontAwesomeIcon icon={faAngleDown}/>
              </Button>
            }
            <UserBadge>{responsable}</UserBadge>
          </th>
        </tr>
        {display.has(responsable.userId) && projs.map(p =>
          <tr key={p.projetId}>
            <td className={"position-absolute bg-white overflow-hidden border-bottom-0 border-top"}
                style={{width: "300px", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
              {p.entreprise &&
                  <><LogoEntreprise>{p.entreprise}</LogoEntreprise>&nbsp;</>
              }

              {p.client &&
                  <><UserBadge prenom={false}>{p.client}</UserBadge>&nbsp;</>
              }
            </td>
            <td className="overflow-hidden border-bottom-0 border-top"
                style={{paddingLeft: "300px", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "400px"}}>
              {(p.adresse.match(ville) ?? ["", p.adresse])[1]}
            </td>
            {props.children(p)}
          </tr>
        )}
      </>;
    })}
  </>;
}