import {Badge, Card, Table} from "react-bootstrap";
import {Projet} from "./Projet";
import immutable, {Collection, Seq} from "immutable";
import {
  allAttributions,
  Attribution, ATTRIBUTION_SANS_OBJET,
  AttributionStatus,
  AttributionWithSelectedUser
} from "../attributions/Attribution";
import {useAsync} from "react-async-hook";
import {Corps} from "../user-profile-lite/Corps";
import {WaitForAsync} from "../../utils/Loading";
import {ProjetParResponsable} from "./ProjetParResponsable";

type AttributionsTableProps = {
  projets: Seq.Indexed<Projet>,
  selectFinished: boolean,
}

enum AF { Att, Four}

const colonnes: [number, AF][] = [
  [1, AF.Att], // Gros œuvre,
  [2, AF.Att], // Charpente
  [9, AF.Att], // Bardage
  [3, AF.Att], // Pose charpente
  [4, AF.Att], // Couverture
  // [5, AF.Att], // Conduit de fumée
  [7, AF.Att], // Gouttière
  [8, AF.Att], // Enduit
  // [10, AF.Att], // Menuiseries extérieures fourniture
  // [14, AF.Att], // Menuiseries intérieures fourniture
  [11, AF.Att], // Menuiseries ext pose
  [12, AF.Att], // Porte garage
  [15, AF.Att], // Menuiseries int pose
  [18, AF.Att], // Plâtrerie
  [19, AF.Att], // Plomberie
  [20, AF.Att], // Électricité
  [22, AF.Att], // Carrelage
  // [13, AF.Att], // Garde corps
  [16, AF.Att], // Escalier bois
  [17, AF.Att], // Escalier béton
  // [24, AF.Att], // VRD

//  [20, AF.Four]
];

export function AttributionsTable(props: AttributionsTableProps) {

  const corps = useAsync(Corps.load, []);
  const attributions = useAsync(allAttributions, [props.selectFinished]);

  return <Card>
    <Card.Body>
      <WaitForAsync async={corps}>{corps =>
        <Table responsive className={"overflow-auto"}>

          <thead>
          <tr>
            <th className={"position-absolute"}/>
            <th style={{paddingLeft: "300px"}}/>
            {colonnes.map(([id, af], i) =>
              <th key={i} className={"small text-center"}>
                {af === AF.Att ? corps.get(id)?.corps : id}
              </th>
            )}
          </tr>
          </thead>
          <WaitForAsync async={attributions}>{attributions =>
            <tbody className={"overflow-y h-100"}>
            <ProjetParResponsable projets={props.projets} colSpan={colonnes.length}>{p =>
              <>
                {colonnes.map(([lot, af], i) => {
                  const atts: immutable.Map<number, Seq.Indexed<AttributionWithSelectedUser>> = attributions.get(p.projetId, immutable.Map());
                  const attsLot = atts.get(lot, Seq.Indexed<AttributionWithSelectedUser>()).valueSeq();

                  return <td key={i} className={"text-center"}>
                    <a href={"projet/attributions?projetId=" + p.projetId}><StatutAtt atts={attsLot}/></a>
                  </td>
                })}
              </>}</ProjetParResponsable>
            </tbody>
          }</WaitForAsync>
        </Table>
      }</WaitForAsync>
    </Card.Body>
  </Card>;
}

function StatutAtt(props: { atts: Seq.Indexed<AttributionWithSelectedUser> }) {
  if (props.atts.isEmpty()) {
    return <Badge bg="danger">N/D</Badge>;
  } else if (props.atts.find(a => !!a.selectedUser)) {
    console.log(props.atts);

    return <>{props.atts.map(a =>
      <Badge
        bg={a.contrat ? "success" : "warning"}
        key={a.attributionId}>{a.selectedUser?.societe ?? a.selectedUser?.nom}</Badge>)
    }</>;
  } else {
    return <>{props.atts.map(a =>
      <Badge key={a.attributionId}
             bg={a.statut === ATTRIBUTION_SANS_OBJET ? "secondary" : "info"}>
        {statut(a.statut)}
      </Badge>)}
    </>
      ;
  }

}

function statut(s: number) {
  switch (s) {
    case 0 :
      return "À consulter";
    case 1:
      return "Lancé";
    case 2:
      return "Traité";
    case 3:
      return "S/O"
  }
}
