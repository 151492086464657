import React from "react";
import {Button, Dropdown} from "react-bootstrap";
import {WithLoginProps} from "../../../utils/auth";
import {Loadable, LOADING, waitFor} from "../../../utils/Loading";
import {filterStatus} from "../../../utils/FetchError";
import immutable from "immutable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faCheck} from "@fortawesome/free-solid-svg-icons";

interface Notification {
  notificationId: number;
  userId: number;
  // projet: Projet;
  notification: string;
  notiftime: Date;
  icon: string;
  uri: string;
}

type NotificationsState = {
  notifications: Loadable<immutable.Map<number, Notification>>;
};

const backend = process.env.REACT_APP_BACKEND_SERVER;

function goto(uri?: string) {
  if (uri) window.location.href = uri;
}

export default class Notifications extends React.Component<WithLoginProps, NotificationsState> {

  state: NotificationsState = {
    notifications: LOADING,
  };

  componentDidMount(): void {
    fetch(`${backend}/notifications`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((notifications: Notification[]) => this.setState({
        notifications: immutable.Map(notifications.map(
          (n) => [n.notificationId, n]
        ))
      }))
      .catch((error) => this.setState({notifications: error}));
  }

  render() {
    return waitFor(this.state.notifications, (notifications: immutable.Map<number, Notification>) =>
      <Dropdown className="notifications"
                drop={"down"}>
        <Dropdown.Toggle
          className="nav-link-icon my-auto"
          variant={"link"}
          id={"dropdown-notifications"}
        >
          <span className="fa-layers fa-fw mx-2">
              <FontAwesomeIcon icon={faBell} size={"2x"} transform={"left-2"}
                               className={
                                 notifications.isEmpty() ? "text-dark" : "text-danger"
                               }/>
              <span className="fa-layers-text text-white fw-bold" data-fa-transform="shrink-10">
                  {notifications.size}
              </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="border"
        >
          <Dropdown.Item className="notification__all text-center"
                         onClick={() => this.clearNotifications()}>
            Nettoyer les notifications
          </Dropdown.Item>
          {notifications.valueSeq().map((n, i) =>
            <Dropdown.Item key={i}>
              <div className="notification__icon-wrapper" onClick={() => goto(n.uri)}>
                <div className="notification__icon"
                     dangerouslySetInnerHTML={{__html: n.icon}}/>
              </div>
              <div className="notification__content" onClick={() => goto(n.uri)}>
                <p dangerouslySetInnerHTML={{__html: n.notification}}/>
              </div>
              <Button variant="light" className="btn-circle text-center p-1"
                      onClick={() => this.clearNotification(notifications, n)}>
                <FontAwesomeIcon icon={faCheck}/>
              </Button>
            </Dropdown.Item>
          )}

        </Dropdown.Menu>
      </Dropdown>
    );
  }

  private clearNotifications() {
    fetch(`${backend}/notifications/clear`, {
      credentials: 'include',
      method: 'DELETE',
      headers: this.props.credentials.headers(),
    })
      .then(filterStatus)
      .then(() => this.setState({notifications: immutable.Map()}))
      .catch(e => this.setState({notifications: e}));
  }

  private clearNotification(notifications: immutable.Map<number, Notification>,
                            n: Notification
  ) {
    fetch(`${backend}/notifications/${n.notificationId}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: this.props.credentials.headers(),
    })
      .then(filterStatus)
      .then(() => this.setState({notifications: notifications.remove(n.notificationId)}))
      .catch(e => this.setState({notifications: e}));
  }
}
