import {DossierFacturation} from "./DossierFacturation";
import {Projet} from "../projets/Projet";
import {ABDocument, DocumentActions} from "../documents/Document";
import {Seq} from "immutable";
import Select from "react-select";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import React, {Fragment, ReactNode} from "react";
import {Badge, Button, ButtonToolbar, Card, Col, Modal} from "react-bootstrap";
import {formatDate} from "../../utils/format";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {DocumentCard} from "../documents/DocumentCard";
import {
  faAngleDown,
  faAngleRight, faDownload,
  faExclamationTriangle,
  faFolder, faMehBlank, faMinusCircle, faPlus,
  faSmile,
  faTimesCircle,
  faTrash,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WithLoginProps} from "../../utils/auth";

type DocumentOptionType = { value: ABDocument, label: ReactNode };


type DossiersFacturationCardProps = WithLoginProps & {
  dossier: DossierFacturation;
  projet: Projet | number;
  actions: (doc: ABDocument) => DocumentActions;
  addAttestations: () => Promise<void>;
  addDocument: (d: ABDocument) => Promise<void>;
  removeDocument: (d: ABDocument) => Promise<void>;
  documents: Seq.Indexed<ABDocument>;
  onDelete: (d: DossierFacturation) => void;
  onValidate?: (d: DossierFacturation) => void;
  upload: (reason: string) => ((dos: DossierFacturation) => void) | undefined;
};


type DossiersFacturationCardState = {
  submitStatusAttestations: SubmitStatus;
  submitStatusAdd: SubmitStatus;
  openAddDocument: boolean;
  documentToAdd?: DocumentOptionType;
  open?: number;
}


const DeleteButton: React.FunctionComponent<{
  onDelete: (dos: DossierFacturation) => void,
  dos: DossierFacturation
}> = ({onDelete, dos}) =>
  <Button onClick={() => onDelete(dos)}
          disabled={dos.cloture !== undefined}
          className="btn-light px-1 btn-circle">
    <FontAwesomeIcon icon={faTrash} size="lg"/>
  </Button>;

enum StatutDossier {
  cloture, incomplet, complet
}

export class DossierFacturationCard extends React.Component<DossiersFacturationCardProps, DossiersFacturationCardState> {
  state: DossiersFacturationCardState = {
    submitStatusAttestations: SubmitStatus.enabled,
    submitStatusAdd: SubmitStatus.enabled,
    openAddDocument: false,
  }


  render() {
    const {dossier, projet, onDelete} = this.props;

    const status = dossier.cloture ? StatutDossier.cloture :
      dossier.attestations.manquants.length + dossier.attestations.invalides.length === 0 ? StatutDossier.complet :
        StatutDossier.incomplet

    return <Col lg="4" md="6" sm="12" className="mb-4">
      <Card className="card-small card-post card-post--1 shadow rounded p-2"
            style={{
              background: '#f3e8de',
            }}>

        {status === StatutDossier.cloture &&
        <div className="d-flex flex-column card-img-overlay fw-bolder align-items-center"
             style={{fontSize: "2em"}}>
            <div className="w-75 rotated-overlay text-center my-auto">
                Clôturé le {formatDate(dossier.cloture!)}
            </div>
          {this.props.onValidate &&
          <Button
              className="mt-auto ms-auto"
              variant={"secondary"}
              onClick={() => this.props.onValidate!(dossier)}
          >Annuler</Button>
          }
        </div>
        }

        <Card.Header className="bg-transparent">
          {status === StatutDossier.cloture ?
            <h6>
              <FontAwesomeIcon icon={faFolder} size="2x"/>
            </h6> :
            status === StatutDossier.complet ?
              <h6 className="text-info">
                <span className="fa-layers fa-fw me-2">
                <FontAwesomeIcon icon={faFolder} size="2x" transform="left-3"/>
                <FontAwesomeIcon icon={faSmile} inverse/>
                </span> Complet
              </h6> :
              <h6 className="text-danger">
                <span className="fa-layers fa-fw me-2">
                <FontAwesomeIcon icon={faFolder} size="2x" transform="left-3"/>
                <FontAwesomeIcon icon={faExclamationTriangle} inverse/>
                </span> Incomplet
              </h6>
          }

          <div>
            {typeof projet === 'number' ? `Projet clôturé #${projet}` : <>
              Projet {projet.title} {projet.client ?
              <UserBadge civilite prenom={false}>{projet.client}</UserBadge> :
              <>Client inconnu</>
            }
            </>
            }
          </div>
        </Card.Header>
        <Card.Body>
          {dossier.cloture ?
            dossier.attestations.allDocs().map((v, i) =>
              <DocItem
                key={i}
                actions={this.props.actions(v)}
                credentials={this.props.credentials}
                document={v}
                isOpen={this.state.open === v.documentId}
                open={(d) => this.open(d)}
                reasons={[]}/>
            )


            : <>
              {dossier.attestations.valides.length > 0 && <>
                  <h6 className="text-success"><FontAwesomeIcon icon={faSmile}/> Documents valides :</h6>
                {dossier.attestations.valides.map((v, i) =>
                  <DocItem
                    credentials={this.props.credentials}
                    key={i}
                    actions={this.props.actions(v)}
                    document={v}
                    isOpen={this.state.open === v.documentId}
                    open={(d) => this.open(d)}
                    removeDocument={(d) => this.props.removeDocument(d)}
                    reasons={[]}/>
                )}
              </>
              }


              {dossier.attestations.invalides.length > 0 && <>
                  <h6 className="text-danger mt-3"><FontAwesomeIcon icon={faTimesCircle}/> Documents invalides :</h6>
                {dossier.attestations.invalides.map(({document, reasons}, i) =>
                  <DocItem
                    credentials={this.props.credentials}
                    key={i}
                    actions={this.props.actions(document)}
                    document={document}
                    isOpen={this.state.open === document.documentId}
                    open={(d) => this.open(d)}
                    removeDocument={(d) => this.props.removeDocument(d)}
                    reasons={reasons}
                  />
                )}
              </>}

              {dossier.attestations.manquants.length > 0 && <>
                  <h6 className="mt-3"><FontAwesomeIcon icon={faMehBlank}/> Documents manquants :</h6>

                {dossier.attestations.manquants.map((m, i) =>
                  <div key={i} className="d-flex justify-content-between fw-normal">
                    <div><FontAwesomeIcon icon={faAngleRight}/> {m}</div>
                    {this.props.upload(m) &&
                    <Button className="text-center btn-circle"
                            variant="success" size="sm"
                            onClick={() => this.props.upload(m)!(dossier)}
                    ><FontAwesomeIcon icon={faUpload}/></Button>
                    }
                  </div>
                )}

              </>}


              {status === StatutDossier.incomplet &&
              <SubmitButton
                  className="m-1"
                  submitstatus={this.state.submitStatusAttestations}
                  onClick={() => this.addAttestations()}
              >Ajouter attestations en cours</SubmitButton>
              }


              <ButtonToolbar className="mt-1 bg-transparent">
                <div className="ms-auto"/>

                {this.props.onValidate &&
                <Button
                    variant="light"
                    onClick={() => this.props.onValidate!(dossier)}
                    className="p-1 btn-pill">
                    Clôturer
                </Button>
                }

                <Button variant="light"
                        onClick={() => this.setState({openAddDocument: true})}
                        className="p-1 btn-circle">
                  <FontAwesomeIcon icon={faPlus} size="lg"/>
                </Button>


                <DeleteButton onDelete={onDelete} dos={dossier}/>

                <Button variant="success" className="p-1 btn-circle"
                        onClick={() => window.location.href = dossier.download()}>
                  <FontAwesomeIcon icon={faDownload}/>
                </Button>
              </ButtonToolbar>

            </>}
        </Card.Body>

      </Card>


      <Modal show={this.state.openAddDocument} onHide={() => this.setState({openAddDocument: false})}>
        <Modal.Header>Ajouter un document existant</Modal.Header>
        <Modal.Body>
          <Select
            isSearchable
            value={this.state.documentToAdd}
            options={this.props.documents
              .filter(d => !dossier.attestations.allAttestations.has(d.documentId))
              .filter(d => d.projetId === undefined ||
                (typeof this.props.projet === 'number' ?
                  this.props.projet === d.projetId :
                  this.props.projet.projetId === d.projetId)
              )
              .map(d => ({
                value: d,
                label: <>{d.title} {d.concerne && <>&ndash; {UserBadge.link(d.concerne)}</>}</>
              }))
              .toArray()}
            onChange={(elt) => this.addDocument(elt)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({openAddDocument: false})}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>


    </Col>
  }

  private addAttestations() {
    this.setState({submitStatusAttestations: SubmitStatus.working});
    this.props.addAttestations()
      .then(() => this.setState({submitStatusAttestations: SubmitStatus.disabled}));
  }

  private addDocument(dot?: DocumentOptionType | null) {
    if (dot) {
      this.setState({
        openAddDocument: false,
        submitStatusAdd: SubmitStatus.working
      });
      this.props.addDocument(dot.value)
        .then(() => this.setState({submitStatusAdd: SubmitStatus.enabled}));
    }
    // this.props.addDocument(dossier)
  }

  private open(v: ABDocument) {
    this.setState({open: this.state.open === v.documentId ? undefined : v.documentId});
  }

}

type DocItemProps = WithLoginProps & {
  document: ABDocument,
  reasons: string[],
  isOpen: boolean,
  open: (doc: ABDocument) => void,
  removeDocument?: (doc: ABDocument) => void,
  actions: DocumentActions,
}
const DocItem: React.FunctionComponent<DocItemProps> =
  ({document, reasons, isOpen, open, credentials, removeDocument, actions}) =>
    <Fragment>
      <div className="d-flex justify-content-between fw-normal mb-0">
        <Button
          size="lg"
          className="p-1 m-0 text-start"
          variant="link"
          onClick={() => open(document)}>
          <FontAwesomeIcon icon={
            isOpen ? faAngleDown: faAngleRight
          }/> {document.title}
          {document.telecharge.find(u => credentials.is(u.user)) &&
          <Badge bg="success" className="mt-0 ms-3">Téléchargé</Badge>
          }
          {reasons.map((r, j) =>
            <Badge className="ms-1 text-wrap" key={j} bg="danger">{r}</Badge>
          )}
        </Button>
        {removeDocument &&
        <Button
            size="sm"
            variant="link"
            className={"p-1 mb-0"}
            onClick={() => removeDocument!(document)}
        ><FontAwesomeIcon icon={faMinusCircle} size="lg" /></Button>
        }
      </div>

      {isOpen &&
      <DocumentCard
          actions={actions}
          doc={document}
          badges={document.badges()}
          defaultOpen={true}
      />
      }
    </Fragment>;

