import {Button, Card, Col, Container, Row} from "react-bootstrap";
import UsersList from "../components/user-profile-lite/UsersList";
import {WaitForAsync} from "../utils/Loading";
import immutable from "immutable";
import UserCard from "../components/user-profile-lite/UserCard";
import {Planning} from "../components/planning/Planning";
import { useAuth,} from "../utils/auth";
import {WithProjectProps} from "../utils/SelectedProject";
import {User} from "../components/user-profile-lite/User";
import {Task} from "../components/planning/Task";
import * as queryString from "query-string";
import Notify from "../components/planning/Notify";
import {formatISO} from "date-fns";
import 'react-day-picker/lib/style.css';
import {DateFilter, Filter} from "../utils/DateFilter";
import {AllUsers} from "../utils/AllUsers";
import {faComment, faExclamation, faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EventEdit} from "../components/planning/EventCard";
import {Corps} from "../components/user-profile-lite/Corps";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAsync} from "react-async-hook";

export type IntervenantsProps = WithProjectProps;

export function Intervenants(props: IntervenantsProps) {

  const credentials = useAuth();
  const [filter, setFilter] = useState<Filter>({});
  const [editMode, setEditMode] = useState<EventEdit>(EventEdit.none);
  const [selectedItv, setSelectedItv] = useState<number>(credentials.user.userId);
  const [events, setEvents] = useState<immutable.Map<number, Task>>(immutable.Map());
  const [editedEvent, setEditedEvent] = useState<Task | undefined>();
  const [notify, setNotify] = useState<User[] | undefined>();
  const corps = useAsync(Corps.load, []);
  const allItvs = useAsync<AllUsers | null>(AllUsers.loadItv, [credentials]);
  const loadedUser = useAsync(User.load, [selectedItv]);

  const {eventId} = useParams();

  useEffect(() => {
    if (eventId) {
      setEditedEvent(events?.get(Number(eventId)))
    }
  }, [])

  useEffect(() => {
    if (loadedUser.result) {
      Task.loadAllUser(loadedUser.result).then(Planning.eventMap).then(setEvents);
    } else {
      setEvents(immutable.Map());
    }
  }, [loadedUser.result])


  const backend = process.env.REACT_APP_BACKEND_SERVER;


  return (
    <Container fluid className="main-content-container px-4 py-4">
      <div>
        <WaitForAsync async={allItvs}>
          {allItvs => allItvs &&
              <Row>
                  <Col md="4">
                      <WaitForAsync async={corps}>{corps =>
                        <UsersList
                          title="Intervenants"
                          currentUserId={selectedItv}
                          users={allItvs}
                          onClick={(i: number) => setSelectedItv(i)}
                          corps={corps}
                        />
                      }</WaitForAsync>
                  </Col>
                  <Col md="8">
                      <Card className="card-small mb-4">
                          <WaitForAsync async={loadedUser}>
                            {(u?: User) => u &&
                                <WaitForAsync async={corps}>
                                  {corps => <UserCard
                                    user={u}
                                    corps={corps}
                                  />
                                  }
                                </WaitForAsync>
                            }</WaitForAsync>
                      </Card>
                  </Col>
              </Row>
          }
        </WaitForAsync>


        <Row><Col className="text-end">
          {credentials.isAdmin() && <WaitForAsync async={loadedUser}>{user =>
            <Button className="btn-warning text-center btn-circle mb-2 ms-auto btn-xl"
                    onClick={() => setNotify(user && [user])}
            >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faComment} size="2x" transform={"left-3"}/>
                    <FontAwesomeIcon icon={faExclamation} inverse/>
                  </span>
            </Button>
          }</WaitForAsync>}
          <Button
            className="text-center btn-success btn-circle m-3 btn-xl"
            onClick={() =>
              window.location.href =
                `${backend}/events/printablePlanning?${queryString.stringify({
                  resource: selectedItv,
                  startDate: filter.from && formatISO(filter.from, {representation: "date"}),
                  endDate: filter.to && formatISO(filter.to, {representation: "date"}),
                })}`}>
            <FontAwesomeIcon icon={faPrint}/>
          </Button>
        </Col></Row>


        <Row className={"mb-2"}>
          <Col>
            <DateFilter
              label="Période :"
              filter={filter}
              onChange={(filter) => setFilter(filter)}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <WaitForAsync async={allItvs}>
              {(allItvs) =>
                <Planning
                  events={events}
                  startDate={filter.from}
                  endDate={filter.to}
                  showProjet
                  editedEvent={editedEvent}
                  editEvent={(editedEvent) => setEditedEvent(editedEvent)}
                  credentials={credentials}
                  addAlert={() => null}
                  updateEvents={(events) => setEvents(events)}
                  allItvs={allItvs === null ? undefined : allItvs}
                  editMode={editMode}
                  setEditMode={(editMode) => setEditMode(editMode)}
                />
              }
            </WaitForAsync>
          </Col>
        </Row>

        {notify &&
            <Notify
                credentials={credentials}
                intervenants={notify.map(u => [u])}
                onHide={() => setNotify(undefined)}
            />
        }
      </div>

    </Container>
  );

}