import DocumentCardBody, {DocBadge} from "../documents/DocumentCardBody";
import {Button, Card, Col} from "react-bootstrap";
import classNames from "classnames";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {formatDate} from "../../utils/format";
import {DocumentToolBar} from "../documents/DocumentToolBar";
import React from "react";
import {ABDocument, DocumentActions} from "../documents/Document";
import {Projet} from "../projets/Projet";

type ContratCardProps = {
  da: ABDocument,
  reasons: string[],
  enCoursValide: boolean,
  valide: boolean,
  projet?: Projet,
  cloture?: (contrat: ABDocument) => void,
  newDossier?: (contrat: ABDocument) => void,
  actions: DocumentActions,
}
type ContratCardState = {
  open: boolean,
  hover: boolean,
}

export class ContratCard extends React.Component<ContratCardProps, ContratCardState> {

  state: ContratCardState = {
    open: false,
    hover: false,
  }

  render(): JSX.Element {
    const {da, reasons, enCoursValide, valide, projet} = this.props;

    const badges: DocBadge[] = da.badges();

    if (reasons.length > 0) {
      for (const r of reasons) {
        badges.push({variant: "danger", content: r});
      }
    } else if (enCoursValide) {
      badges.push({variant: "info", content: "Dossier en cours"});
    } else if (valide) {
      badges.push({variant: "success", content: "Dossier validé"});
    }


    return <Col lg="4" md="6" sm="12" className="mb-4">
      <Card className="card-small card-post card-post--1"
            onMouseEnter={() => this.setState({hover: true})}
            onMouseLeave={() => this.setState({hover: false})}>

        <Card.Header className={classNames({"bg-danger": da.projetId === undefined})}>
          {projet ?
            <>Projet {projet.title || "clôturé"}{" "}
              {projet.client ?
                <UserBadge prenom={false} civilite={true}>{projet.client}</UserBadge> :
                <>Client inconnu</>
              }</> :
            "Pas de projet associé"
          }
        </Card.Header>

        <DocumentCardBody
          disabled={da.administratif?.cloture !== undefined}
          doc={da}
          badges={badges}
          open={this.state.open}
          onOpen={() => this.setState({open: true})}
          showVisibility={false}
        />

        {da.administratif?.cloture &&
        <div className="d-flex flex-column card-img-overlay fw-bolder align-items-center"
             style={{fontSize: "2em"}}>
            <div className="rotated-overlay text-center my-auto">
                Clôturé le {formatDate(da.administratif.cloture)}
            </div>
          {this.props.cloture &&
          <Button
              className="mt-auto ms-auto"
              variant={"secondary"}
              onClick={() => this.props.cloture!(da)}
          >Annuler</Button>
          }
        </div>
        }

        {this.props.newDossier && this.state.open &&
        <Button
            variant="link"
            disabled={!da.projetId}
            onClick={() => this.props.newDossier!(da)}>
            Créer un dossier de facturation pour ce contrat
        </Button>
        }

        {!da.administratif?.cloture &&
        <DocumentToolBar
            actions={this.props.actions}
            doc={da}
            open={this.state.open}
            toggleOpen={() => this.setState({open: !this.state.open})}
            hover={this.state.hover}
        >
          {this.props.cloture &&
          <Button
              variant="light"
              className="p-1 btn-pill"
              onClick={() => this.props.cloture!(da)}>Clôturer
          </Button>
          }
        </DocumentToolBar>
        }

      </Card>
    </Col>;
  }
}