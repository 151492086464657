import React, {Fragment, useState} from "react";
import {Button, Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {Credentials, useAuth} from "../utils/auth";
import {findPrivateKey, PublicKey, RSAKeys} from "../utils/Signature";
import {WaitForAsync} from "../utils/Loading";
import {filterStatus} from "../utils/FetchError";
import queryString from "query-string";
import Alerts, {AlertData} from "../utils/Alerts";
import classNames from "classnames";
import {SubmitButton, SubmitStatus} from "../utils/LoadingTS";
import {nl2br} from "../utils/Nl2Br";
import {Parapheur} from "../components/documents/Parapheur";
import {faKey} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAsync} from "react-async-hook";


const backend = process.env.REACT_APP_BACKEND_SERVER;


export function delCertificate(credentials: Credentials, certificateId: number): Promise<Response> {
  return fetch(`${backend}/certificate/${certificateId}`, {
    credentials: "include",
    method: 'DELETE',
    headers: credentials.headers(),
  })
    .then(filterStatus);
}

export function deleteKey(credentials: Credentials, keyId: number): Promise<Response> {
  return fetch(`${backend}/key/${keyId}`, {
    credentials: "include",
    method: 'DELETE',
    headers: credentials.headers()
  })
    .then(filterStatus)
}

function newKP(password: string): Promise<Response> {
  const backend = process.env.REACT_APP_BACKEND_SERVER;
  return fetch(`${backend}/key/new?${queryString.stringify({password})}`,
    {credentials: 'include'})
    .then(filterStatus)
}

function userKeys(credentials: Credentials) {
  return credentials.user.keys();
}

export function KeysView() {

  const credentials = useAuth();
  const [newKPPassword, setNewKPPassword] = useState('');
  const [csrPassword, setCSRPassword] = useState('');
  console.log(credentials);
  console.log(credentials.user);
  const keys = useAsync(userKeys, [credentials]);
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.disabled);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [csr, setCSR] = useState<string | undefined>();
  const [confirmDelete, setConfirmDelete] = useState<number | undefined>();

  const [confirmNewKey, setConfirmNewKey] = useState(false);
  const [understood, setUnderstood] = useState(false);

  const [error, setError] = useState<string | undefined>();


  const handleDeleteKey = (keyId: number) => {
    return deleteKey(credentials, keyId)
      .then(() => {
        keys.execute(credentials);
        setConfirmDelete(undefined);
      })
  }

  const handleDelCertificate = (certificateId: number) => {
    return delCertificate(credentials, certificateId)
      .then(() => keys.execute(credentials));
  }

  const handleCSR = (key: PublicKey) => {
    setCSR("Veuillez patienter...");
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(`${backend}/key/${key.keyId}/csr?${queryString.stringify({password: csrPassword})}`)
      .then(filterStatus)
      .then(r => r.text())
      .then(csr => setCSR(csr))
      .catch(e => e.text().then((csr: string) => setCSR(csr)));

  }

  const handleImport = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    data.append("user", credentials.user.toString());

    setSubmitStatus(SubmitStatus.working);

    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(`${backend}/key/import`, {
      credentials: 'include',
      method: 'POST',
      headers: credentials.headers(),
      body: data
    })
      .then(filterStatus)
      .then(() => {
        setSubmitStatus(SubmitStatus.disabled);
        keys.execute(credentials);
        const importform = document.getElementById("import-form");
        if (importform) (importform as HTMLFormElement).reset();
      })
      .catch((e) => {
        console.log(e);
        e.text().then((t: string) => {
          setSubmitStatus(SubmitStatus.disabled);
          setAlerts(Alerts.add(
            {theme: "danger", text: <>{t}</>},
            (alerts) => setAlerts(alerts),
            alerts
          ))
        });
      })

  }


  const handleNewKP = () => {
    newKP(newKPPassword)
      .then(() => {
        setConfirmNewKey(false);
        keys.execute(credentials);
      })
      .catch(e => e.text().then((error: string) => setError(error)));
  }


  return (
    <Fragment>
      <Alerts alerts={alerts}
              update={(newAlerts) => setAlerts(newAlerts)}/>
      <Container fluid className="main-content-container px-4 py-4">
        <Parapheur credentials={credentials}/>

        <Card>
          <Card.Header><h2>Clés de signature</h2></Card.Header>
          <WaitForAsync async={keys}>{keys => {
            const privateKey = findPrivateKey(keys);
            return <Card.Body>
              <Row>
                <Col md={12}>
                  <RSAKeys
                    onDelCertificate={keyId => handleDelCertificate(keyId)}
                    onDelete={keyId => setConfirmDelete(keyId)}>
                    {keys}
                  </RSAKeys>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button className="m-1"
                          disabled={privateKey !== undefined}
                          onClick={() => setConfirmNewKey(true)}
                  ><FontAwesomeIcon icon={faKey}/> Générer une nouvelle paire de clés</Button>
                  <div className={classNames("text-muted small", {
                    "d-none": privateKey === undefined
                  })}>Nous ne gérons qu’une clé privée simultanément. Détruisez votre clé privée actuelle avant de pouvoir en générer une nouvelle.
                  </div>
                </Col>
              </Row>

              <Row>

                <Col md={12}>
                  <Button variant="link"
                          onClick={() => setShowAdvanced(!showAdvanced)}>
                    Fonctions avancées
                  </Button>

                  <div className={classNames({"d-none": !showAdvanced})}>
                    <Form
                      id={"import-form"}
                      onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleImport(e)}
                    >
                      <label>
                        Importer une clé publique ou un certificat
                      </label>
                      <Form.Control
                        as="textarea"
                        name="rsaData"
                        className={"mb-1"}
                        onChange={() => setSubmitStatus(SubmitStatus.enabled)}
                        placeholder="Données PEM"
                      />
                      <SubmitButton
                        size="sm" submitstatus={submitStatus}>
                        <i className="fas fa-upload"/>
                      </SubmitButton>
                    </Form>

                    <label>Demander un certificat externe</label>
                    {csr ?
                      <div className="border-1 text-monospace small">
                        {nl2br(csr)}
                      </div> :
                      privateKey &&
                        <Form onSubmit={() => handleCSR(privateKey)}>
                            <Form.Control
                                className={"me-1"}
                                type="password"
                                value={csrPassword}
                                onChange={(e) => setCSRPassword(e.currentTarget.value)}
                                placeholder="Mot de passe"
                            />
                            <Button
                                type="submit"
                                disabled={csrPassword.length === 0}
                                size={"sm"}><i className={"fas fa-certificate"}/> </Button>
                        </Form>

                    }
                  </div>
                </Col>
              </Row>


            </Card.Body>
          }}
          </WaitForAsync>
        </Card>

      </Container>
      <Modal show={confirmDelete !== undefined}
             onHide={() => setConfirmDelete(undefined)}>
        <Modal.Body>
          <p>Vous êtes sur le point de supprimer la clé privée associée à la clé
            #{confirmDelete}.</p>

          <p>Cela ne supprimera pas la clé publique et vos signatures et certificats passés seront
            toujours valides.</p>

          <p>Vous devrez générer une nouvelle clé privée pour pouvoir à nouveau
            signer des documents.
          </p>

          <Button variant="danger"
                  className="me-1"
                  onClick={() => confirmDelete && handleDeleteKey(confirmDelete)}>
            <i className={"fas fa-trash"}/> Confirmer
          </Button>
          <Button variant="secondary"
                  onClick={() => setConfirmDelete(undefined)}>
            Annuler
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmNewKey}
        onHide={() => setConfirmNewKey(false)}>
        <Modal.Body>
          <p>
            Vous n’avez pas de clé privée gérée par Bati+. Vous pouvez générer une nouvelle
            paire de clé afin de permettre à Bati+ de générer des signatures pour des documents,
            ou générer vous-même une signature et l’importer.
          </p>

          <p>Si vous choisissez de laisser Bati+ gérer votre clé privée, celle-ci sera
            chiffrée à l’aide de votre mot de passe, et vous seul pourrez en faire usage.
            <span className="text-danger"> Si vous perdez votre mot de passe ou soupçonnez
                                que celui-ci a été compromis,
                            vous devrez changer de mot de passe au plus vite, détruire votre clé privée et
                            en générer une nouvelle. Informez-en immédiatement les administrateurs de
                            Bati+.</span></p>

          <p>
            Dans tous les cas, pensez à importer également les éventuels certificats associés
            à vos clés, ou demander à un administrateur du site de faire certifier vos clés.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Form>
            <Form.Check className="m-1" type="checkbox" label={"J'ai bien compris"}
                        onChange={() => setUnderstood(!understood)}
            />
            <Form.Control className="m-1" type={"password"}
                          value={newKPPassword}
                          placeholder="Mot de passe"
                          isInvalid={error !== undefined}
                          onChange={(e) => setNewKPPassword(e.currentTarget.value)}
            />
            <Form.Control.Feedback type={"invalid"}>{error}</Form.Control.Feedback>
            <Button
              className={"m-1"}
              disabled={!understood}
              onClick={() => handleNewKP()}>
              <i className="fas fa-key"/> Générer une nouvelle paire de clés
            </Button>
            <Button className="m-1"
                    variant="secondary" onClick={() => setConfirmNewKey(false)}>
              Annuler
            </Button>
          </Form>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );


}