import * as React from "react";
import {useCookies} from "react-cookie";
import {parse} from 'query-string';
import {Projet} from "../components/projets/Projet";
import {useAsync, UseAsyncReturn} from "react-async-hook";
import {createContext, useContext, useEffect, useState} from "react";
import {ABLoading} from "./Loading";
import FetchError from "./FetchError";


export const projectContext = createContext<[(Projet | undefined), ((projet: Projet) => void)]>([undefined, () => 0]);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideProject(props: { children: React.ReactNode }) {
  const [project, deleteProject] = useProjectPriv();

  if (project.loading) {
    return <ABLoading/>;
  }
  if (project.error) {
    console.log(project.error);
    return <projectContext.Provider value={[undefined, () => 0]}>{props.children}</projectContext.Provider>;
  }

  return <projectContext.Provider value={[project.result, deleteProject]}>{props.children}</projectContext.Provider>;

}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useProject: () => [(Projet | undefined), ((projet: Projet) => void)] = () => {
  const project = useContext(projectContext);
  return project;
};

export type WithProjectProps = {
  projet?: Projet;
  onDeleteProjet?: (p: Projet) => void;
}

function useProjectPriv(): [UseAsyncReturn<undefined | Projet>, (p: Projet) => void] {
  const [cookies, setCookies, removeCookie] = useCookies(["projetId"]);
  const get = parse(window.location.search);
  let fromGET: number | undefined;
  if (typeof (get.projetId) === 'string') {
    fromGET = Number(get.projetId);
  } else if (Array.isArray(get.projetId)) {
    fromGET = Number(get.projetId[0]);
  }
  const fromCookie = cookies.projetId;

  if (fromGET && fromCookie !== fromGET) {
    setCookies("projetId", fromGET, {sameSite: "strict", path: "/"});
  }

  // let updateAccess = true;
  // let defaultProjetId: number | undefined;
  // if (fromCookie) {
  //   defaultProjetId = fromCookie;
  //   updateAccess = false;
  // } else {
  //   Projet.loadAll(false)
  //     .then(projets => {
  //       if (projets.size === 1) {
  //         defaultProjetId = projets.first(undefined)?.projetId;
  //       }
  //     });
  // }

  const [projetId, setProjetId] = useState<number | undefined>(fromGET || fromCookie);


  useEffect(() => {
      if (projetId === undefined) {
        Projet.loadAll(false)
          .then(projets => {
            if (projets.size === 1) {
              const projetId = projets.first()!.projetId
              setCookies("projetId", projetId)
              setProjetId(projetId)
            }
          })
          .catch((e: FetchError) => undefined)
      }
    },
    []
  )

  const loadedProjet = useAsync<Projet | undefined>(() =>
      projetId === undefined ?
        new Promise(r => r(undefined)) :
        Projet.load(projetId, true)
          .catch((e: FetchError) => {
            setProjetId(undefined);
            return undefined;
          }),
    [projetId]
  );

  const deleteProjet = (projet: Projet) => {
    if (
      typeof loadedProjet.result === 'object' &&
      (loadedProjet.result as Projet).projetId === projet.projetId) {

      removeCookie("projetId");
      setProjetId(undefined);
    }
  }

  return [loadedProjet, deleteProjet];

}
