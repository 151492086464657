import {CLItem} from "./CLItem";
import {ABDocument, DocumentVisibility} from "../documents/Document";
import {Either, Maybe} from "monet";
import {mapRight, SubmitError} from "../../utils/FetchError";
import {Credentials} from "../../utils/auth";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import React from "react";
import {Button, ButtonToolbar, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faCheck, faImages, faPaperclip, faSlash, faUpload} from "@fortawesome/free-solid-svg-icons";
import {DocumentCard} from "../documents/DocumentCard";
import {DocEditor} from "../documents/DocEditor";
import * as immutable from "immutable";
import {Gallery} from "../documents/Gallery";
import {Projet} from "../projets/Projet";
import {nl2p} from "../../utils/Nl2Br";

type CheckListProposeProps = {
  projet: Projet;
  edit: CLItem;
  onSign: (doc: ABDocument) => void;
  onSubmit: (updated: CLItem, submit: boolean) => Promise<Either<SubmitError, CLItem>>;
  credentials: Credentials;
  onHide: () => void;
};
type CheckListProposeState = {
  doneButtonStatus: SubmitStatus;
  proposition?: string;
  upload?: ABDocument;
  gallery: boolean;
  camera?: string,
};

export class CheckListPropose extends React.Component<CheckListProposeProps, CheckListProposeState> {
  state: CheckListProposeState = {
    doneButtonStatus: SubmitStatus.enabled,
    gallery: false,
  }

  render(): JSX.Element {
    const {edit} = this.props;
    return <><Form onSubmit={(e) => e.preventDefault()}>
      <Modal.Body>

        <div className="text-danger">
          {nl2p(edit.warning)}
        </div>

        <Form.Group className="mb-3">
          <Form.Label>
            <FontAwesomeIcon icon={faAlignLeft}/> Commentaire
          </Form.Label>
          <Form.Control
            as="textarea"
            value={this.state.proposition || edit.proposition || ''}
            onChange={(e) =>
              this.setState({proposition: e.currentTarget.value})}/>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label><FontAwesomeIcon icon={faPaperclip}/> Document joint</Form.Label>
          {edit.document && (typeof edit.document === 'number' ? `Document ${edit.document} non visible` :
              <DocumentCard
                className="mb-3"
                doc={edit.document}
                actions={{
                  onSign: () => typeof edit.document === 'object' && this.props.onSign(edit.document),
                }}
              />
          )}
          <ButtonToolbar>
            <Button variant="info"
                    onClick={() => this.setState({gallery: !this.state.gallery})}>
              <FontAwesomeIcon icon={faImages}/> Joindre un document
            </Button>
            <Button className="ms-3" variant="info"
                    onClick={() => this.setState({
                      upload:
                        ABDocument.new(
                          this.props.credentials,
                          {
                            title: edit.name,
                            projetId: edit.projet,
                            categorie: edit.categorie,
                            visibility: DocumentVisibility.client,
                          }
                        ),
                    })}>
              <FontAwesomeIcon icon={faUpload}/> Ajouter un document
            </Button>
            <Button
              className="ms-3" variant="info"
              onClick={() => this.props.onSubmit(edit.updated({document: undefined}), false)}
              disabled={edit.document === undefined}>
              <span className="fa-stack fa-1x">
                <FontAwesomeIcon icon={faPaperclip} className="fa-stack-1x"/>
                <FontAwesomeIcon icon={faSlash} className="fa-stack-1x"/>
              </span>
              Détacher le document
            </Button>
          </ButtonToolbar>
          <Gallery
            className="mt-3"
            open={this.state.gallery}
            projet={this.props.projet}
            exclude={typeof this.props.edit.document === 'object' ? [this.props.edit.document] : []}
            onPick={(document) =>
              this.props.onSubmit(edit.updated({document}), false)
                .then(() => this.setState({gallery: false}))
            }
            categories={Maybe.fromUndefined(edit.categorie).toArray()}
          />
          <Form.Text>{edit.docWarning()}</Form.Text>
        </Form.Group>


      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ms-1"
          variant="secondary"
          onClick={() => this.handleDone(false)}>
          Mettre en attente
        </Button>
        <SubmitButton
          submitstatus={this.state.doneButtonStatus}
          disabled={!this.state.proposition?.trim().length && !edit.proposition?.trim().length && edit.document === undefined}
          onClick={() => this.handleDone(true)}
          icon={<FontAwesomeIcon icon={faCheck}/>}
        >
          Marquer comme fait
        </SubmitButton>
      </Modal.Footer>
    </Form>
      {this.state.upload &&
      <DocEditor
          credentials={this.props.credentials}
          onSubmit={(submitted, saved) =>
            this.handleSubmitDoc(saved)}
          update={(document) =>
            this.setState({upload: document})}
          edit={this.state.upload}
          cats={this.state.upload.categorie === undefined ? undefined : immutable.Set.of(this.state.upload.categorie)}
          setCameraState={(camera) => this.setState({camera})}
          camera={this.state.camera}
      />
      }
    </>
  }


  private handleDone(submit: boolean): void {
    let updated = this.props.edit;
    if (this.state.proposition) updated = updated.updated({proposition: this.state.proposition});

    if (submit || this.state.proposition) {
      this.setState({doneButtonStatus: SubmitStatus.working});
      this.props.onSubmit(updated, submit)
        .then(() => {
          this.setState({doneButtonStatus: SubmitStatus.enabled});
          this.props.onHide();
        });
    } else {
      this.props.onHide();
    }
  }


  private handleSubmitDoc(saved: Either<SubmitError, ABDocument[]>) {
    this.setState({doneButtonStatus: SubmitStatus.working});
    return mapRight(saved, docs =>
      this.props.onSubmit(this.props.edit.updated({document: docs[0]}), false)
        .then(() => {
          this.setState({
              upload: undefined,
              doneButtonStatus: SubmitStatus.enabled
            }
          );
          return docs;
        })
    );
  }
}