import React, {Component} from "react";
import {Badge, Button, ButtonToolbar, Card, Col, Image, Row} from "react-bootstrap";
import * as queryString from "query-string";
import {Task} from "./Task";
import {faDownload, faMapMarker, faSearchPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {encodeURIForShow} from "../../views/ShowImage";

let id = 0;

type ReservePlansProps = {
  res: Task[];
  onClickPoint: (r: Task) => void;
  onClickImage?: (event: React.MouseEvent, tag: number, id: string) => void;
};
type ReservePlansState = {
  editor?: number;
  hovered?: number;
};

export default class ReservePlans extends Component<ReservePlansProps, ReservePlansState> {
  state: ReservePlansState = {};

  private id = id++;

  private toggleHover(hovered?: number) {
    this.setState({hovered});
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.forceUpdate());
  }

  render() {
    const {res, onClickPoint, onClickImage} = this.props;
    const backend = process.env.REACT_APP_BACKEND_SERVER;

    const plans: Task[][] = [];
    for (const r of res) {
      const planId = r.plan;
      if (planId) {
        if (!plans[planId]) plans[planId] = [];
        plans[planId].push(r);
      }
    }


    return (plans.length > 0 &&
        <Card className="mb-3">
            <Card.Body>
                <Row>
                  {
                    plans.map((allReserves, i) => {

                      const planFile = `${backend}/documents/${i}/preview`;

                      const pdf = document.getElementById(`plan_${this.id}_${i}`);
                      const pdfRect = pdf?.getBoundingClientRect();

                      return (
                        <Col
                          lg={6}
                          className="col-centered"
                          key={i}>
                          <div className="position-relative m-0 p-0">
                            <Image onClick={(event) =>
                              onClickImage?.(event, i, `plan_${this.id}_${i}`)}
                                   className="mb-2" fluid src={planFile}
                                   id={`plan_${this.id}_${i}`}
                                   onLoad={() => this.forceUpdate()}/>

                            {
                              pdfRect && allReserves.map((r) => r.position &&
                                  <ReservePoint
                                      onMouseEnter={() => this.toggleHover(r.eventId)}
                                      onMouseLeave={() => this.toggleHover()}
                                      hovered={this.state.hovered === r.eventId}
                                      onClick={() => onClickPoint(r)}
                                      key={r.eventId}
                                      reserve={r}
                                      position={r.position}
                                      pdfRect={pdfRect}/>
                              )
                            }
                          </div>

                          <ButtonToolbar className="d-block position-relative w-100 text-end"
                                         style={{top: "-70px", left: "-20px"}}>
                            <Button
                              onClick={() => window.location.href =
                                `${backend}/events/plan/${i}?${queryString.stringify({e: allReserves.map(r => r.eventId)})}`}
                              className="btn-circle m-1"
                            >
                              <FontAwesomeIcon icon={faDownload}/>
                            </Button>
                            <Button
                              onClick={() => window.location.href =
                                "/show/" + encodeURIForShow(
                                    `events/plan/${i}?${queryString.stringify({
                                      e: allReserves.map(r => r.eventId),
                                      preview: true
                                    })}`
                                )
                              }
                              className="btn-circle m-1"
                            >
                              <FontAwesomeIcon icon={faSearchPlus}/>
                            </Button>

                          </ButtonToolbar>

                        </Col>

                      );
                    })}
                </Row>

            </Card.Body>
        </Card>);

  }
}

type ReservePointProps = {
  reserve: Task;
  pdfRect: DOMRect;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  hovered: boolean;
  position: { x: number, y: number };
}

export const ReservePoint = ({
                               reserve,
                               position,
                               pdfRect,
                               onClick,
                               onMouseEnter,
                               onMouseLeave,
                               hovered
                             }: ReservePointProps) => {

  const offset = navigator.userAgent.includes("Safari") ?
    {x: -8, y: -26} :
    {x: -8, y: -26};

  const x = position.x * pdfRect.width + offset.x;
  const y = position.y * pdfRect.height + offset.y ;
  // console.log(reserve.tag, pdfRect, position, x, y)
  return (
    <div className="position-absolute" style={{top: y, left: x}}
         onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
         onClick={onClick}
    >
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faMapMarker} color={reserve.color.toHex()}
                                 size="2x" transform="left-1"
                />
                <span
                  style={{
                    color: reserve.color.textCSS(),
                    marginTop: "-3px"
                  }}
                  className="fa-layers-text">
                    {reserve.tag}
                </span>
            </span>

      {hovered &&
      <Badge className="badge-outline-primary"
             bg="none"
             style={{
               borderColor: reserve.color.toCSS(),
               color: reserve.color.toCSS(),
               background: reserve.color.textCSS()
             }}>
        {reserve.title}
      </Badge>
      }

    </div>
  );
};