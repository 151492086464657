import React, {useState} from "react";
import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import {Loadable, LOADING, waitFor} from "../utils/Loading";
import {filterStatus} from "../utils/FetchError";
import {useParams} from "react-router-dom";
import * as queryString from "query-string";
import {Variant} from "react-bootstrap/types";
import {User} from "../components/user-profile-lite/User";


type MatchParams = {
  userId?: string;
  key?: string;
}

type RecoveryProps = {
  first: boolean;
};

interface ZxcvbnStrength {
  score: number;
  appreciation: string;
  theme: Variant;
  warning: string;
  suggestions: string[];
}

Recovery.defaultProps = {first: false};

let delayTimer: number | undefined = undefined;

export function Recovery(props: RecoveryProps) {
  const [zxcvbn, setZxcvbn] = useState<Loadable<ZxcvbnStrength> | undefined>();
  const [sent, setSent] = useState<Loadable<boolean> | undefined>();
  const [sentPwd, setSentPwd] = useState<Loadable<boolean> | undefined>();

  const {userId, key} = useParams<MatchParams>();

  const sendEmail = (form: React.FormEvent<HTMLFormElement>) => {
    form.preventDefault();

    const email = form.currentTarget['email'].value;

    setSent(LOADING);

    User.sendRecoveryCode(email)
      .then(() => setSent(true))
      .catch(e => setSent(e));
  }


  const sendPasswd = (form: React.FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    const backend = process.env.REACT_APP_BACKEND_SERVER;

    const passwd = form.currentTarget['password'].value;

    setSentPwd(LOADING);

    if (userId) {
      fetch(`${backend}/newPassword/${encodeURIComponent(userId)}?${queryString.stringify({key, passwd})}`)
        .then(filterStatus)
        .then(() => setSentPwd(true))
        .catch(e => setSentPwd(e));
    }
  }


  const updateZxcvbn = (value: string) => {
    delayTimer && clearTimeout(delayTimer);
    setZxcvbn(LOADING);
    delayTimer = window.setTimeout(() => {
      const backend = process.env.REACT_APP_BACKEND_SERVER;
      fetch(`${backend}/zxcvbn/${encodeURIComponent(value)}`)
        .then(filterStatus)
        .then(r => r.json())
        .then(zxcvbn => setZxcvbn(zxcvbn));
    }, 1000);
  }

  let content;
  if (sentPwd) {
    content = waitFor(sentPwd, () => <>Mot de passe modifié. Vous pouvez vous <a
      href="/">connecter</a>.</>)
  } else if (userId) {
    content = waitFor(sentPwd, sentPwd =>
      <Form onSubmit={(form: React.FormEvent<HTMLFormElement>) => sendPasswd(form)}>
        <Row className="my-1">
          <label>Entrez votre nouveau mot de passe :</label>
          <Form.Control name="password" type="password"
                        onChange={(e) => updateZxcvbn(e.currentTarget.value)}
          />
        </Row>
        <Row>
          {
            zxcvbn &&
              <Col className="p-1">
                {waitFor(zxcvbn, (zxcvbn: ZxcvbnStrength) => <>
                    <Alert className="text-center" variant={zxcvbn.theme}>
                      Ce mot de passe est {zxcvbn?.appreciation}.<br/>
                      {zxcvbn.warning}
                    </Alert>

                    <ul>
                      {zxcvbn.suggestions.map((s, i) =>
                        <li key={i}>{s}</li>
                      )}
                    </ul>
                  </>
                )}
              </Col>

          }
        </Row>
        <Row className="my-1">
          <Col className="text-center">
            {waitFor(zxcvbn, zxcvbn =>
              <Button type="submit"
                      disabled={
                        zxcvbn === undefined ||
                        zxcvbn.score < 2
                      }>
                Ok
              </Button>
            )}
          </Col>
        </Row>
      </Form>);
  } else {
    content = waitFor(sent, sent => {
      if (sent) {
        return "Consultez vos emails";
      } else {
        return (
          <Form onSubmit={(form: React.FormEvent<HTMLFormElement>) => sendEmail(form)}>
            <Row className="my-1">
              {props.first && <label>Pour pouvoir vous connecter à Bati+, un administrateur doit
                  préalablement avoir créé votre compte en utilisant l’adresse e-mail que vous lui avez
                  fournie. Cependant, c’est à vous d’initialiser le mot de passe.</label>}
              <label>
                Entrez votre e-mail pour recevoir un lien vous
                permettant {props.first ? "d'initialiser" : "de réinitialiser"} votre
                mot de passe :
              </label>
              <Form.Control name="email" type="email"/>
            </Row>
            <Row className="my-1">
              <Col className="text-center">
                <Button type="submit">Ok</Button>
              </Col>
            </Row>
          </Form>);
      }
    });
  }


  return (
    <Col sm={6} className="mx-auto my-5">
      <Card>
        <Card.Body>
          {content}
        </Card.Body>
      </Card>
    </Col>
  );

}
