import {JSONProjet, Projet} from "../projets/Projet";
import {ABDocument, JSONDocument} from "../documents/Document";
import {JSONUser, User} from "../user-profile-lite/User";
import {formatISO, parseISO} from "date-fns";
import {RGB_COLORS, RGBColor} from "../../utils/colors";
import * as queryString from "query-string";
import {filterStatus} from "../../utils/FetchError";
import {Credentials} from "../../utils/auth";
import {Suggestions} from "../../utils/Suggestions";

interface ITask {
  eventId: number;
  tag?: number;
  project: Projet;
  projetId: number;
  title: string;
  affectation?: User;
  photos: ABDocument[];

  description: string;
  color: RGBColor;

  startDay?: Date;
  echeance?: Date;
  effectue?: Date;
  cloture?: Date;

  plan?: number;
  position?: { x: number, y: number };

  visibleClient: boolean;
}

export interface JSONTask {
  eventId: number,
  tag?: number,
  project: JSONProjet,
  projetId: number,
  title: string,
  affectation?: number | JSONUser,
  photos?: JSONDocument[],
  description: string,
  color: string,
  startDay?: string,
  echeance?: string,
  effectue?: string,
  cloture?: string,
  plan?: number,
  position?: { x: number, y: number },
  visibleClient: boolean,
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export class Task implements ITask {

  eventId: number;
  tag?: number;
  project: Projet;
  projetId: number;
  title: string;
  affectation?: User;
  photos: ABDocument[];

  description: string;
  color: RGBColor;

  startDay?: Date;
  echeance?: Date;
  effectue?: Date;
  cloture?: Date;

  plan?: number;
  position?: { x: number, y: number };
  visibleClient: boolean;

  constructor({
                eventId,
                tag,
                project,
                projetId,
                title,
                affectation,
                photos,
                description,
                color,
                startDay,
                echeance,
                effectue,
                cloture,
                plan,
                position,
                visibleClient
              }: ITask) {
    this.eventId = eventId;
    this.tag = tag;
    this.project = project;
    this.projetId = projetId;
    this.title = title;
    this.affectation = affectation;
    this.photos = photos;
    this.description = description;
    this.color = color;
    this.startDay = startDay;
    this.echeance = echeance;
    this.effectue = effectue;
    this.cloture = cloture;
    this.plan = plan;
    this.position = position;
    this.visibleClient = visibleClient;
  }

  updated(changes: Partial<ITask>): Task {
    return new Task({...this, ...changes});
  }

  static parse(e: JSONTask): Task {
    return new Task({
      ...e,
      project: Projet.parse(e.project),
      startDay: e.startDay ? parseISO(e.startDay) : undefined,
      echeance: e.echeance ? parseISO(e.echeance) : undefined,
      cloture: e.cloture ? parseISO(e.cloture) : undefined,
      effectue: e.effectue ? parseISO(e.effectue) : undefined,
      color: RGBColor.fromHex(e.color),
      photos: (e.photos || []).map(ABDocument.parse),
      affectation: typeof e.affectation === 'object' ? User.parse(e.affectation) : undefined,
    });
  }

  static loadAllProjet(projet: Projet, showCloture: boolean): Promise<Task[]> {
    return fetch(
      `${backend}/projet/${projet.projetId}/events?${
        queryString.stringify({showCloture})}`,
      {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((tasks: JSONTask[]) => tasks.map(t => Task.parse({...t, project: t.project || projet})));
  }

  static loadAllUser(user: User): Promise<Task[]> {
    return fetch(`${backend}/events/of/${user.userId}`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((tasks: JSONTask[]) => tasks.map(Task.parse));
  }

  static suggestions(): Promise<Suggestions> {
    return fetch(`${backend}/events/titres`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then(s => new Suggestions(s))
  }

  static new(projet: Projet, plan?: number, position?: { x: number, y: number }): Task {
    return new Task({
      eventId: 0,
      title: "",
      description: "",
      photos: [],
      color: RGB_COLORS[0],
      projetId: projet.projetId,
      project: projet,
      plan,
      position,
      visibleClient: false,
    });
  }

  effectuer(credentials: Credentials): Promise<Task> {
    return fetch(`${backend}/events/effectuer/${this.eventId}`,
      {
        credentials: 'include',
        method: 'PUT',
        headers: credentials.headers()
      })
      .then(filterStatus)
      .then(response => response.json())
      .then(Task.parse);
  }

  addPhoto(credentials: Credentials, doc: ABDocument[]): Promise<Task> {
    return fetch(`${backend}/events/${this.eventId}/newDocs`,
      {
        credentials: 'include',
        method: 'PUT',
        headers: credentials.headers({"Content-Type": "application/json"}),
        body: JSON.stringify(doc.map(d => d.documentId)),
      })
      .then(filterStatus)
      .then(response => response.json())
      .then(Task.parse);
  }

  save(credentials: Credentials): Promise<Task> {
    const sentEvent = this.toFormData();
    return fetch(`${backend}/projet/saveEvent`, {
      credentials: 'include',
      method: 'POST',
      headers: credentials.headers(),
      body: sentEvent
    })
      .then(filterStatus)
      .then(response => response.json())
      .then(Task.parse);
  }

  delete(credentials: Credentials): Promise<Response> {
    return fetch(`${backend}/events/${this.eventId}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: credentials.headers()
    })
      .then(filterStatus);
  }


  private toFormData(f: FormData = new FormData()): FormData {
    f.append("eventId", this.eventId.toString());
    if (this.startDay) {
      f.append("startDay", formatISO(this.startDay, {representation: "date"}));
    }
    if (this.echeance) {
      f.append("echeance", formatISO(this.echeance, {representation: "date"}));
    }
    if (this.effectue) {
      f.append("effectue", formatISO(this.effectue, {representation: "date"}));
    }
    if (this.cloture) {
      f.append("cloture", formatISO(this.cloture, {representation: "date"}));
    }
    f.append("title", this.title);
    f.append("projetId", this.projetId.toString());
    f.append("description", this.description);
    f.append("color", this.color.toHex());
    f.append("visibleClient", this.visibleClient.toString());

    if (this.affectation) {
      f.append("affectation", this.affectation.userId.toString());
    }

    for (const p of this.photos) {
      f.append("photos[]", p.documentId.toString());
    }

    if (this.plan) {
      f.append("plan", this.plan.toString());
    }

    if (this.position) {
      f.append("position[]", this.position.x.toString());
      f.append("position[]", this.position.y.toString());
    }

    return f;
  }
}


export interface WeekTask {
  task: Task;
  weekStart: Date;
  weekEnd: Date;
  openLb: boolean;
  openUb: boolean;
  lb: Date;
  ub: Date;
  over: boolean;
  isAlert: boolean;
}