import * as immutable from "immutable";
import {Seq} from "immutable";
import {DossierFacturation} from "./DossierFacturation";
import {Projet} from "../projets/Projet";
import {ABDocument, DocumentActions} from "../documents/Document";
import React, {Fragment} from "react";
import {Col, Row} from "react-bootstrap";
import {DossierFacturationCard} from "./DossierFacturationCard";
import {User} from "../user-profile-lite/User";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {WithLoginProps} from "../../utils/auth";

type DossiersFacturationProps = WithLoginProps & {
  dossiers: immutable.Map<User, Seq.Indexed<DossierFacturation>>;
  projets: immutable.Map<number, Projet>;
  actions: (doc: ABDocument) => DocumentActions;
  updateDossier: (d: DossierFacturation) => void;
  docAdmin: Seq.Indexed<ABDocument>;
  onDelete: (dos: DossierFacturation, concerne: User) => void;
  onValidate?: (dos: DossierFacturation) => void;
  upload: (m: string) => ((dos: DossierFacturation, concerne: User) => void) | undefined;
}

export class DossiersFacturationUI extends React.Component<DossiersFacturationProps> {
  render() {
    if (this.props.dossiers.isEmpty()) {
      return <Row><Col>Pas de dossier</Col></Row>
    } else {
      return this.props.dossiers.entrySeq().map(([u, dossiers], i) =>
        <Fragment key={i}>
          <Row><Col><h6><UserBadge>{u}</UserBadge></h6></Col></Row>
          <Row>
            {dossiers.map((d, j) =>
              <DossierFacturationCard
                key={j}
                credentials={this.props.credentials}
                actions={this.props.actions}
                dossier={d}
                projet={this.props.projets.get(d.projetId) || d.projetId}
                addAttestations={() => this.addAttestations(d)}
                addDocument={(doc) => this.addDocument(doc, d)}
                removeDocument={(doc) => this.removeDocument(doc, d)}
                onDelete={(dos) => this.deleteDossier(dos, u)}
                documents={this.props.docAdmin}
                onValidate={this.props.onValidate}
                upload={(m) => {
                  const uploadFunction = this.props.upload(m);
                  return uploadFunction && ((d) => uploadFunction(d, u));
                }}
              />
            )}
          </Row>
        </Fragment>);
    }
  }

  private addAttestations(d: DossierFacturation): Promise<void> {
    return d.addAllAttestations(this.props.credentials).then(this.props.updateDossier);
  }

  private addDocument(doc: ABDocument, d: DossierFacturation): Promise<void> {
    return d.addDocument(this.props.credentials, doc).then(this.props.updateDossier);
  }

  private removeDocument(doc: ABDocument, d: DossierFacturation): Promise<void> {
    return d.removeDocument(this.props.credentials, doc).then(this.props.updateDossier);
  }

  private deleteDossier(dos: DossierFacturation, concerne: User) {
    return dos.delete(this.props.credentials).then(() => this.props.onDelete(dos, concerne));
  }
}
