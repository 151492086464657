import {GRANTS, useAuth,} from "../utils/auth";
import {useProject} from "../utils/SelectedProject";
import {WaitForAsync} from "../utils/Loading";
import {Critere, Notation} from "../components/notations/Notation";
import {Card, Container, Table} from "react-bootstrap";
import Rating from "react-rating";
import {faStar as fasStar} from '@fortawesome/free-solid-svg-icons'
import {faStar as farStar} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parseISO} from "date-fns";
import {formatDate} from "../utils/format";
import {useAsync} from "react-async-hook";

const RatingProps = {
  emptySymbol: <FontAwesomeIcon icon={farStar} color="#ffb400"/>,
  fullSymbol: <FontAwesomeIcon icon={fasStar} color="#ffb400"/>,
}

export const formatMoyenne = new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 1, maximumFractionDigits: 1});


export function NotationView() {
  const credentials = useAuth();
  const [projet] = useProject();

  const current = useAsync(async () => {
      if (projet) {
        return await Notation.loadCurrent(projet);
      } else {
        return {notes: [], moyenne: NaN};
      }
    }, [projet]
  );

  const history = useAsync(async () => {
    if (projet && credentials.in(GRANTS.resp)) {
      return await Notation.loadHistory(projet);
    } else {
      return [];
    }
  }, [projet, credentials]);

  const handleChange = (c: Critere, v: number) => {
    if (projet) {
      Notation.noter(credentials, projet, c, v)
        .then(() => {
          current.execute();
          history.execute();
        });
    }
  }

  return <WaitForAsync async={current}>{state =>
    <Container fluid className="main-content-container px-4 py-4">
      <Card className="w-50 mb-3">
        <Card.Header><h4>Selon les critères suivants, êtes-vous satisfaits de votre chantier ?</h4></Card.Header>
        <Card.Body>

          <Table>

            <tbody>
            {state.notes.map((n, i) =>
              <tr key={i}>
                <td>{n.critere.nomCritere}</td>
                <td className="text-center" style={{minWidth: "8em"}}>
                  <Rating
                    readonly={credentials.user.userId !== projet?.client?.userId}
                    initialRating={n.note}
                    onChange={v => handleChange(n.critere, v)}
                    {...RatingProps}
                  />
                </td>
                <td className="text-center">
                  {n.date && `au ${formatDate(parseISO(n.date))}`}
                </td>
              </tr>
            )}
            </tbody>
            <tfoot>
            <tr>
              <th>Moyenne</th>
              <td className="text-center">
                <Rating
                  initialRating={state.moyenne}
                  fractions={2}
                  readonly
                  {...RatingProps}
                />
              </td>
              <td/>
            </tr>
            </tfoot>
          </Table>

        </Card.Body>
      </Card>

      <WaitForAsync async={history}>{history => history &&
          <Card>
              <Card.Header><h4>Historique</h4></Card.Header>
              <Card.Body>
                  <Table striped borderless>
                      <thead>
                      <tr>
                          <th className="text-center">Au</th>
                        {state.notes.map((n, i) =>
                          <th key={i} className="text-end">{n.critere.nomCritere}</th>
                        )}
                          <th className="text-end">Moyenne</th>
                      </tr>
                      </thead>
                      <tbody>
                      {history.map((h, i) =>
                        <tr key={i}>
                          <td className="text-end">{formatDate(h.date)}</td>
                          {state.notes.map(n => n.critere.critereId).map((c, j) =>
                            <td className="text-end" key={j}>
                              {h.notes.get(c)}
                            </td>
                          )}
                          <td className="text-end">{formatMoyenne.format(h.moyenne)}</td>
                        </tr>
                      )}
                      </tbody>
                  </Table>
              </Card.Body>
          </Card>
      }</WaitForAsync>
    </Container>
  }</WaitForAsync>;

}