import React from "react";
import classNames from "classnames";
import {Variant} from "react-bootstrap/types";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type NotifProps = { theme: Variant, showZero: boolean, tooltip?: string, icon: IconProp, children?: React.ReactNode };

export class Notif extends React.Component<NotifProps, { show: boolean }> {
  state = {show: false};

  static defaultProps = {
    theme: "primary",
    showZero: false,
    icon: faCircle,
  }

  static textColor(theme: Variant) {
    switch (theme) {
      case "danger":
      case "success":
      case "info":
        return "white";
      default:
        return "black";
    }
  }

  render() {
    if (!this.props.showZero && (this.props.children === undefined || this.props.children === 0)) {
      return null;
    } else {
      return (<>
          <div className="d-inline-block fa-layers fa-fw fa-lg"
               onMouseEnter={() => this.setState({show: true})}
               onMouseLeave={() => this.setState({show: false})}
               onClick={() => this.setState({show: !this.state.show})}
          >
            <FontAwesomeIcon icon={this.props.icon} className={`text-${this.props.theme}`}
                             />
            <span className={classNames("fa-layers-text", `text-${Notif.textColor(this.props.theme)}`)}
                  style={{fontSize: ".5em", fontWeight: "bold"}}
            >
              {this.props.children}
            </span>
          </div>
          {this.props.tooltip &&
          <div className={classNames("tooltip", {show: this.state.show})}>
              <div className="tooltip-inner">
                {this.props.tooltip}
              </div>
          </div>
          }
        </>

      );
    }
  }
}
