import React from "react";
import {Button, Form, ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import {User} from "../user-profile-lite/User";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import classNames from "classnames";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDate} from "../../utils/format";
import {Credentials} from "../../utils/auth";
import {Projet} from "../projets/Projet";
import {filterStatus} from "../../utils/FetchError";

type NotifyProps = {
  onHide: () => void;
  intervenants: [User, Date?][];
  removeItv?: (u: User) => void;
  credentials: Credentials;
  projet?: Projet;
}

type NotifyState = {
  submitStatus: SubmitStatus;
  includePDF: boolean;
}

export default class Notify extends React.Component<NotifyProps, NotifyState> {
  state = {
    submitStatus: SubmitStatus.enabled,
    includePDF: false,
  };

  private notify() {
    this.setState({submitStatus: SubmitStatus.working});
    const json = {
      projet: this.props.projet?.projetId,
      includePDF: this.state.includePDF,
      intervenants: this.props.intervenants.map(i => i[0].userId)
    };
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const headers = this.props.credentials.headers();
    headers.append("Content-Type", "application/json");

    return fetch(`${backend}/events/notify`, {
      credentials: "include",
      method: 'POST',
      headers: headers,
      body: JSON.stringify(json),
    })
      .then(filterStatus)
      .then(() => this.props.onHide())
      .finally(() => this.setState({submitStatus: SubmitStatus.enabled}))
  }

  render() {
    return (

      <Modal show onHide={this.props.onHide}>
        <Modal.Body>
          Vous allez envoyer un message à :
          <ListGroup>
            {
              this.props.intervenants.map(([u, mod], i) =>
                <ListGroupItem key={i}>
                  <Button
                    size="sm"
                    className={
                      classNames("btn-circle", {"d-none": !this.props.removeItv})
                    }
                    onClick={() => this.props.removeItv?.(u)}>
                    <FontAwesomeIcon icon={faMinus}/>
                  </Button>
                  <UserBadge>{u}</UserBadge>
                  {mod && <span className="small"> &ndash; planning modifié le {formatDate(mod)}</span>}
                </ListGroupItem>)
            }

          </ListGroup>
          <Form.Check
            checked={this.state.includePDF}
            onChange={() => this.setState({includePDF: !this.state.includePDF})}
            label="Attacher le planning PDF"/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Annuler</Button>
          <SubmitButton variant="warning"
                        onClick={() => this.notify()}
                        submitstatus={this.state.submitStatus}
          >
            OK
          </SubmitButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
