import DayPickerInput from "react-day-picker/DayPickerInput";
import {DAY_PICKER_INPUT_PROPS, DAY_PICKER_PROPS} from "./format";
import React from "react";
import Checkbox from "react-three-state-checkbox";
import {Form} from "react-bootstrap";
import {isAfter, isBefore, startOfDay} from "date-fns";
import {threeWayBoolRotate} from "./miscellaneous";

type DateFilterProps = {
  label?: string;
  filter: Filter,
  onChange: (f: Filter) => void,
  handleNulls: boolean,
}

export type Filter = {
  nulls?: boolean,
  from?: Date,
  to?: Date,
}

export class DateFilter extends React.Component<DateFilterProps> {
  static defaultProps = {
    handleNulls: false,
  }

  static filterDate({from, to, nulls}: Filter, d?: Date): boolean {
    if (d) {
      if (nulls === false) {
        return false;
      } else if (from && isBefore(d, startOfDay(from))) {
        return false;
      } else {
        return !to || !isAfter(d, startOfDay(to));
      }
    } else {
      return !nulls;
    }
  }

  render() {
    const {from, to, nulls} = this.props.filter;

    return <Form>
      {this.props.label && <label>{this.props.label}</label>}

      <DayPickerInput
        {...DAY_PICKER_INPUT_PROPS}
        inputProps={{className: 'form-control form-control-sm p-1', size: 7, readOnly: nulls === false}}
        value={from}
        dayPickerProps={{
          disabledDays: [
            to && {after: to}
          ],
          selectedDays: [
            from && (to ? {from, to} : from),
          ],
          ...DAY_PICKER_PROPS
        }}
        onDayChange={(from) => this.handleChange({from})}
      />&ndash;<DayPickerInput
      {...DAY_PICKER_INPUT_PROPS}
      inputProps={{className: 'form-control form-control-sm p-1', size: 7, readOnly: nulls === false}}
      value={to}
      onDayChange={(to) => this.handleChange({to})}
      dayPickerProps={{
        disabledDays: [
          from && {before: from}
        ],
        selectedDays: [
          to && (from ? {from, to} : to),
        ],
        ...DAY_PICKER_PROPS
      }}

    />

      {this.props.handleNulls &&
      <Checkbox
          className="p-1"
          checked={nulls === true}
          indeterminate={nulls === undefined}
          onChange={() => this.handleChange({nulls: threeWayBoolRotate(nulls)})}
      />
      }
    </Form>;

  }

  private handleChange(change: Partial<Filter>) {
    return this.props.onChange({...this.props.filter, ...change});
  }
}