import {Component} from "react";
import {Button, ButtonToolbar, Card, Col, Container} from "react-bootstrap";
import {DocEditor} from "./DocEditor";
import {ABDocument, DocumentVisibility} from "./Document";
import {Credentials, GRANTS} from "../../utils/auth";
import {Projet} from "../projets/Projet";
import {FACING_MODES} from "react-html5-camera-photo";
import {Sketch} from "./Sketch";
import immutable, {Seq} from "immutable";
import Folders from "../../utils/Folders";
import {ZipUpload} from "./ZipUpload";
import SignForm from "./SignForm";
import {formatDate, DefaultDateTimeFormatSeconds} from "../../utils/format";
import {Either} from "monet";
import {SubmitError} from "../../utils/FetchError";
import {faCamera, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DocumentCard} from "./DocumentCard";


type DocumentsProps = {
  documents: ABDocument[];
  onDelete: (doc: ABDocument) => Promise<void>;
  credentials: Credentials;
  projet: Projet;
  updateAlerts: (res: Either<{ [p: string]: string }, ABDocument[]>) => void;
  updateDocs: (docs: ABDocument[]) => void;
};

type DocumentsState = {
  editDoc?: ABDocument;
  camera?: string;
  sketchDoc?: ABDocument;
  refreshAnnotations: immutable.Map<number, number>;
  zipUpload: boolean;
  signDoc?: ABDocument;
};

export default class Documents extends Component<DocumentsProps, DocumentsState> {

  state: DocumentsState = {
    refreshAnnotations: immutable.Map(this.props.documents.map(d => [d.documentId, new Date().getTime()])),
    zipUpload: false,
  };


  render(): JSX.Element {
    let docs = immutable.OrderedMap(
      Seq(this.props.documents)
        .groupBy(d => d.categorie)
        .map(col => col.toIndexedSeq())
    );

    const {credentials} = this.props;

    const mustSign = Seq(this.props.documents.filter(d => d.mustSign.length > 0));
    docs = docs.set("À signer", mustSign)

    return (


      <Container fluid className="main-content-container p-4">

        {docs.size === 0 &&
        <Card className="card-small">
            <Card.Body>Pas de documents sur ce projet</Card.Body>
        </Card>}
        <Folders
          data={docs}
          display={(cat) =>
            cat.map((doc) =>
              <Col lg="4" md="6" sm="12" className="mb-4" key={doc.documentId}>
                <DocumentCard
                  actions={{
                    onDelete: this.props.credentials.isAdmin() ? (() => this.props.onDelete(doc)) : undefined,
                    onEdit: doc.editable(this.props.credentials) ? (() => this.setState({editDoc: doc})) : undefined,
                    onSketch: doc.editable(this.props.credentials) ? (d) => this.setState({sketchDoc: d}) : undefined,
                    onArchive: doc.editable(this.props.credentials) ? (d) => this.handleArchive(d) : undefined,
                    onSign: (d) => this.setState({signDoc: d}),
                  }}
                  doc={doc}
                  badges={doc.mustSignBadges()}
                  refreshAnnotation={this.state.refreshAnnotations.get(doc.documentId)}
                  defaultOpen={false}
                  showVisibility={this.props.credentials.in(GRANTS.resp)}
                />
              </Col>
            )
          }
        />
        <div className="button-placeholder"/>
        <ButtonToolbar className="button-fixed">

          <Button className="text-center btn-success btn-circle ms-auto m-2 btn-xl"
                  onClick={() => this.setState({
                    editDoc: ABDocument.new(credentials, {
                      projetId: this.props.projet.projetId,
                      title: formatDate(new Date(), DefaultDateTimeFormatSeconds),
                      categorie: "Photos",
                      visibility: DocumentVisibility.public,
                    }),
                    camera: FACING_MODES.ENVIRONMENT,
                  })}>
            <FontAwesomeIcon icon={faCamera}/>
          </Button>

          <Button className="text-center btn-success btn-circle m-2 btn-xl"
                  onClick={() => this.setState({
                    editDoc: ABDocument.new(credentials, {
                      projetId: this.props.projet.projetId,
                      visibility: DocumentVisibility.public,
                    }),
                    camera: undefined
                  })}>
            <FontAwesomeIcon icon={faUpload}/>
          </Button>

          <Button className="text-center btn-success btn-circle m-2 btn-xl"
                  style={{lineHeight: ".6em"}}
                  onClick={() => this.setState({zipUpload: true})}>
            <FontAwesomeIcon icon={faUpload}/><br/><small>.zip</small>
          </Button>

        </ButtonToolbar>

        {this.state.editDoc &&
        <DocEditor
            credentials={this.props.credentials}
            edit={this.state.editDoc}
            update={(doc) => this.setState({editDoc: doc})}
            onSubmit={(submitted, saved) => this.handleSubmitted(saved)}
            camera={this.state.camera}
            setCameraState={(camera) => this.setState({camera})}
        />
        }

        {this.state.sketchDoc &&
        <Sketch doc={this.state.sketchDoc}
                onHide={(id) => this.setState({
                  refreshAnnotations: this.state.refreshAnnotations.set(id, new Date().getTime()),
                  sketchDoc: undefined
                })}
                credentials={this.props.credentials}/>
        }

        {this.state.zipUpload &&
        <ZipUpload
            projet={this.props.projet}
            credentials={this.props.credentials}
            onSubmit={(saved) => this.handleSubmitted(saved)}
            onHide={() => this.setState({zipUpload: false})}
        />
        }

        {this.state.signDoc &&
        <SignForm
            doc={this.state.signDoc}
            credentials={this.props.credentials}
            onUpdate={(signDoc) => {
              this.props.updateDocs([signDoc]);
              this.setState({signDoc});
            }}
            onHide={() => this.setState({signDoc: undefined})}
        />
        }


      </Container>
    );
  }

  private handleSubmitted(res: Either<SubmitError, ABDocument[]>): Promise<Either<SubmitError, ABDocument[]>> {
    this.props.updateAlerts(res);
    res.forEach(r => this.props.updateDocs(r));
    return new Promise((resolve) => resolve(res));
  }

  private handleArchive(doc: ABDocument) {
    doc.archive(this.props.credentials)
      .then(updated => this.props.updateDocs([updated]));
  }


}