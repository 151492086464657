import {Button, Card, Form, ListGroup, Modal} from "react-bootstrap";
import {ChoixMateriauxItem} from "./ChoixMateriauxItem";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPen, faSave, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {ChoixMateriaux, ChoixMateriauxOption} from "./ChoixMateriaux";
import classNames from "classnames";

type ChoixMateriauxCardProps = {
  cm: ChoixMateriaux,
  onSave?: (cm: ChoixMateriaux) => Promise<unknown>,
  onDelete?: (cm: ChoixMateriaux) => void,
  editable: boolean;
};

type ChoixMateriauxCardState = {
  submitStatus: SubmitStatus;
  confirm: boolean;
  edit?: ChoixMateriaux;
};

export class ChoixMateriauxCard extends React.Component<ChoixMateriauxCardProps, ChoixMateriauxCardState> {
  state: ChoixMateriauxCardState = {
    submitStatus: SubmitStatus.disabled,
    confirm: false,
  }

  private handleEdit() {
    if (this.state.edit === undefined) {
      this.setState({edit: this.props.cm});
    }
  }

  render() {
    const {cm} = this.props;
    return <Card
      className={classNames("choix-materiaux my-2", cm.valide && "valide")}>
      <Form className="mt-2" onSubmit={e => this.handleSubmit(e)}>
        <Card.Header>
          {this.state.edit ?
            <Form.Control
              size={"lg"}
              value={this.state.edit.titre}
              onChange={(e) => this.handleChange({titre: e.currentTarget.value})}
            />
            :
            <h2>
              {cm.valide && <FontAwesomeIcon icon={faCheck} className={"me-2"}/>}
              {cm.titre}
            </h2>
          }
        </Card.Header>
        <Card.Body>
          <div dangerouslySetInnerHTML={{__html: cm.description.html}}/>
          <ListGroup horizontal={"lg"} className="ms-0 mb-3 fa-ul">
            {cm.options.map((o, i) =>
              <ChoixMateriauxItem
                key={i}
                active={cm.noOption === o.noOption}
                onClick={() => this.handleClick(cm, o)}
              >
                {o.description}
              </ChoixMateriauxItem>
            )}
          </ListGroup>

          <Form.Control
            as="textarea"
            placeholder="Votre choix..."
            value={this.state.edit ? this.state.edit.reponseLibre : this.props.cm.reponseLibre}
            disabled={!this.props.editable}
            onClick={() => this.handleEdit()}
            onChange={(e) =>
              this.handleChange({reponseLibre: e.currentTarget.value})
            }
          />
        </Card.Body>
        <Card.Footer>
          {this.state.edit ? <>
              <Button
                variant="danger"
                onClick={() => this.setState({edit: undefined})}
              >
                <FontAwesomeIcon icon={faTimes}/> Annuler
              </Button>
              <SubmitButton
                className="ms-2"
                submitstatus={this.state.submitStatus}
                icon={<FontAwesomeIcon icon={faSave}/>}
              >
                Enregistrer
              </SubmitButton>
            </> :
            <>
              {this.props.onDelete &&
                  <Button
                      variant="danger"
                      onClick={() => this.props.onDelete!(cm)}
                  >
                      <FontAwesomeIcon icon={faTrash}/> Supprimer
                  </Button>
              }
              {this.props.editable &&
                  <Button className={"ms-2"}
                          onClick={() => this.handleEdit()}>
                      <FontAwesomeIcon icon={faPen}/> Modifier
                  </Button>
              }
              {this.props.onSave && (
                (cm.valide ?
                    <Button
                      className="ms-2"
                      variant={"warning"}
                      onClick={() => this.handleValidate(cm, false)}
                    >
                      <FontAwesomeIcon icon={faTimes}/> Réouvrir
                    </Button>
                    :
                    <Button
                      className="ms-2"
                      variant={"success"}
                      disabled={cm.options.length === 0 ? cm.reponseLibre.length === 0 : cm.noOption === undefined}
                      onClick={() => this.setState({confirm: true})}
                    >
                      <FontAwesomeIcon icon={faCheck}/> Valider
                    </Button>
                )
              )}
            </>
          }

        </Card.Footer>
      </Form>
      <Modal show={this.state.confirm}
             onHide={() => this.setState({confirm: false})}>
        <Modal.Body>
          <p className="text-danger">Attention, en cliquant sur le bouton « valider » ci-dessous, vous confirmez
            la commande du produit sélectionné.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="ms-2"
                  variant={"secondary"}
                  type={"reset"}
                  onClick={() => this.setState({confirm: false})}
          >
            <FontAwesomeIcon icon={faTimes}/> Annuler
          </Button>
          <Button
            className="ms-2"
            variant={"success"}
            onClick={() => this.handleValidate(cm, true)}
          >
            <FontAwesomeIcon icon={faCheck}/> Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>

  }

  handleValidate(cm: ChoixMateriaux, valide: boolean) {
    this.setState({confirm: false});
    this.save({...cm, valide});
  }

  private save(toSave: ChoixMateriaux) {
    if (this.props.onSave) {
      this.setState({
        submitStatus: SubmitStatus.working,
      });
      this.props.onSave(toSave)
        .then(() => this.setState({edit: undefined, submitStatus: SubmitStatus.disabled}));
    }
  }

  handleClick(cm: ChoixMateriaux, o: ChoixMateriauxOption): void {
    if (this.props.editable) {
      this.save({...cm, noOption: o.noOption});
    }
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.state.edit) {
      this.save(this.state.edit);
    }
  }

  private handleChange(change: Partial<ChoixMateriaux>) {
    this.setState({
      submitStatus: SubmitStatus.enabled,
      edit: {...this.state.edit!, ...change}
    });
    // if (this.props.onChange) {
    //   this.setState({submitStatus: SubmitStatus.enabled});
    //   this.props.onChange({...this.props.cm, ...change});
    // }
  }
}