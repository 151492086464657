import {JSONUser, User} from "../user-profile-lite/User";
import {filterStatus, filterStatusEither, SubmitError} from "../../utils/FetchError";
import {formatISO, parseISO} from "date-fns";
import {Credentials} from "../../utils/auth";
import {Either} from "monet";
import {Entreprise, JSONProjet, Projet} from "./Projet";
import querystring from "query-string";
import {Filter} from "../../utils/DateFilter";


export interface ProjetCommercial {
  projetId: number,
  commercial?: User,
  prixMaison?: number,
  depotPermis?: Date,
  acceptationPermis?: Date,
  mbPrevisionnelle?: number,
  mbContreEtude?: number,
  mbDefinitive?: number,
  prixDefinitif?: number,
  notes: string,
  dateVente?: Date,
  locked: boolean,
}

interface JSONProjetCommercial {
  projetId: number,
  commercial?: JSONUser,
  depotPermis?: string,
  acceptationPermis?: string,
  prixMaison?: number,
  mbPrevisionnelle?: number,
  mbContreEtude?: number,
  mbDefinitive?: number,
  prixDefinitif?: number,
  notes: string,
  dateVente?: string,
  locked: boolean,
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export const newProjetCommercial: ProjetCommercial = {projetId: 0, notes: '', locked: false};

export function loadProjetCommercial(projetId: number): Promise<ProjetCommercial> {
  return fetch(`${backend}/projet/commercial/${projetId}`, {credentials: "include"})
    .then(filterStatus)
    .then(r => r.json())
    .then(parseProjetCommercial);
}

export function loadDonneesCommerciales(open: Filter, selectFinished: boolean, entreprise?: Entreprise): Promise<{
  projet: Projet,
  projetCommercial: ProjetCommercial
}[]> {
  return fetch(`${backend}/projets/commercial?${querystring.stringify({
    openFrom: open.from && formatISO(open.from, {representation: "date"}),
    openTo: open.to && formatISO(open.to, {representation: "date"}),
    nulls: open.nulls,
    selectFinished,
    entreprise: entreprise?.entrepriseId
  })}`, {credentials: "include"})
    .then(filterStatus)
    .then(response => response.json())
    .then((rs: (JSONProjet & JSONProjetCommercial)[]) => rs.map(r => {
      const projetCommercial = parseProjetCommercial(r)
      const projet = Projet.parse(r)
      return {projet, projetCommercial};
    }));

}

function parseProjetCommercial(pc: JSONProjetCommercial): ProjetCommercial {
  return {
    ...pc,
    commercial: pc.commercial && User.parse(pc.commercial),
    depotPermis: pc.depotPermis ? parseISO(pc.depotPermis) : undefined,
    acceptationPermis: pc.acceptationPermis ? parseISO(pc.acceptationPermis) : undefined,
    dateVente: pc.dateVente ? parseISO(pc.dateVente) : undefined,
  };
}

export type ProjetTuple = {
  projet: Projet,
  pc?: ProjetCommercial,
}

export function saveProjetCommercial(pc: ProjetCommercial, credentials: Credentials): Promise<Either<SubmitError, ProjetCommercial>> {
  const headers = credentials.headers();
  headers.append("Content-Type", "application/json");

  const data = {
    ...pc,
    commercial: pc.commercial?.userId,
    depotPermis: pc.depotPermis && formatISO(pc.depotPermis, {representation: 'date'}),
    acceptationPermis: pc.acceptationPermis && formatISO(pc.acceptationPermis, {representation: 'date'}),
    dateVente: pc.dateVente && formatISO(pc.dateVente, {representation: 'date'}),
  };

  return fetch(`${backend}/projet/saveCommercial`,
    {
      credentials: "include",
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(r => filterStatusEither<JSONProjetCommercial>(r))
    .then(r => r.map(parseProjetCommercial));
}