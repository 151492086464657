import * as React from "react";
import {Button, ListGroup, ListGroupItem} from "react-bootstrap";
import {JSONUser, User} from "../components/user-profile-lite/User";
import {nl2br} from "./Nl2Br";
import {UserBadge} from "../components/user-profile-lite/UserBadge";
import classNames from "classnames";
import {formatDate, DefaultDateTimeFormat} from "./format";
import {parseISO} from "date-fns";
import {faCertificate, faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export interface PublicKey {
  keyId: number,
  timestamp: Date,
  publicKey: string,
  hasPrivate: boolean,
  certificates: CertificateTree[]
}

export interface Certificate {
  certificateId: number,
  certificate: string,
  notBefore: string,
  notAfter: string,
  issuer: string,
  issuerCertificate?: number,
  subject: string,
  keyId: number,
  fullIssuer?: User,
}

export interface Signature {
  documentId: number,
  keyId: number,
  timestamp: Date,
  signature: string,
  signer: User,
  certificates: CertificateTree[],
}

export interface JSONSignature {
  documentId: number,
  keyId: number,
  timestamp: string,
  signature: string,
  signer: JSONUser,
  certificates: CertificateTree[],
}

export function loadSignature(json: JSONSignature): Signature {
  return {
    ...json,
    signer: User.parse(json.signer),
    timestamp: parseISO(json.timestamp)
  };
}

type CertificateTree = Certificate & {
  certificates: CertificateTree[],
}


type RSAKeysProps = {
  children: PublicKey[],
  onDelete?: (keyId: number) => void,
  onDelCertificate?: (keyId: number) => void,
}

type RSAKeysState = {
  password: string;
  viewCertificate?: number;
}

type CertifTreeProps = {
  onDelCertificate?: (certificateId: number) => void,
  certificate: CertificateTree
}

export function findPrivateKey(keys: PublicKey[]): PublicKey | undefined {
  return keys.find(k => k.hasPrivate);
}


export class CertifTree extends React.Component<CertifTreeProps, { open: boolean }> {
  state = {open: false};

  render() {
    const {certificate} = this.props;

    return <>
      <Button variant="link"
              className="text-start"
              onClick={() => this.setState({
                open: !this.state.open
              })}>
        <FontAwesomeIcon icon={faCertificate} className="text-success"/> {certificate.issuerCertificate === certificate.certificateId ? "Certificat auto-signé" : <>
        Certifiée
        par {certificate.fullIssuer ? <UserBadge>{certificate.fullIssuer}</UserBadge> : certificate.issuer}

      </>
      }
      </Button>
      <blockquote
        className={classNames(
          "border p-3",
          {"d-none": !this.state.open})
        }>


        <div className="text-monospace small">
          -----BEGIN CERTIFICATE-----<br/>
          {nl2br(certificate.certificate)}
          -----END CERTIFICATE-----<br/>
          Certificat valable du {formatDate(parseISO(certificate.notBefore))} au {
          formatDate(parseISO(certificate.notAfter))} {this.props.onDelCertificate &&
        <Button variant="danger"
                className="m-1 p-1" size="sm"
                onClick={() => this.props.onDelCertificate?.(certificate.certificateId)}>
            <FontAwesomeIcon icon={faTrash}/> Détruire le certificat
        </Button>
        }

        </div>


        {certificate.certificates.map((c, i) =>
          <CertifTree
            certificate={c}
            key={i}/>
        )}

      </blockquote>
    </>;
  }
}


export class RSAKeys extends React.Component<RSAKeysProps, RSAKeysState> {
  state: RSAKeysState = {
    password: ''
  };

  render() {
    const {children, onDelete, onDelCertificate} = this.props;
    return <ListGroup>
      {children.map((s, i) =>
        <ListGroupItem key={i} className="p-3">
          <h6>Clé #{s.keyId}, obtenue
            le {formatDate(s.timestamp, DefaultDateTimeFormat)}
            {s.hasPrivate && <>,
                clé privée gérée
              {onDelete &&
              <Button className="m-1 p-1" size="sm" variant="danger" onClick={() => onDelete?.(s.keyId)}>
                  <FontAwesomeIcon icon={faTrash}/> Détruire la clé privée
              </Button>}
            </>}
          </h6>
          <div className="border-1 text-monospace small" style={{textOverflow: "clip", overflowX: "scroll"}}>
            -----BEGIN PUBLIC KEY-----<br/>
            {nl2br(s.publicKey)}
            -----END PUBLIC KEY-----<br/>
          </div>
          {s.certificates.map((c, i) =>
            <CertifTree
              certificate={c}
              key={i}
              onDelCertificate={onDelCertificate}/>
          )}

        </ListGroupItem>
      )}
    </ListGroup>;
  }

}
