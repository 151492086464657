import {Component, Fragment} from "react";
import {Button, ButtonToolbar, Col, Container, Image, Modal, Row} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import processString from "react-process-string";
import {Message} from "./Message";
import {formatDate, DefaultDateTimeFormat} from "../../utils/format";
import {faCalendarAlt, faEyeSlash, faPaperclip, faReply} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


type MessageCardProps = {
  message: Message;
  onHide: () => void;
  onCreateEvent?: (m: Message) => void;
  onMarkAsUnread: (m: Message) => void;
}

export class MessageCard extends Component<MessageCardProps> {
  selectMessage(message?: Message): void {
    this.setState({
      selectedMessage: message
    });
  }

  static messageHTML(message: string): JSX.Element {
    const config = [
      {
        regex: /(http|https):\/\/(\S+)(.*?)( |,|$|\.|\n)/gim,
        fn: (key: number, result: string[]) => <Fragment key={key}>
          <a
            href={`${result[1]}://${result[2]}${result[4]}`}>{result[2]}{result[4]}</a>{result[5]}
        </Fragment>
      },
      {
        regex: /(\S+)\.([a-z]{2,}?)(.*?)( |,|$|\.)/gim,
        fn: (key: number, result: string[]) => <Fragment key={key}>
          <a
            href={`http://${result[1]}.${result[2]}${result[3]}`}>{result[1]}.{result[2]}{result[3]}</a>{result[4]}
        </Fragment>
      },
      {
        regex: /(.*)\n(.*)/gim,
        fn: (key: number, result: string[]) => <Fragment key={key}>
          {result[1]} <br/> {result[2]}
        </Fragment>
      }
    ];


    return processString(config)(message);
  }

  render() {
    const {message, onHide, onCreateEvent} = this.props;
    const backend = process.env.REACT_APP_BACKEND_SERVER;

    return (
      <Modal show
             size={"lg"}
             onHide={onHide}>
        <Modal.Header>

          <Container>
            <Row><h4>{message.subject}</h4></Row>
            <Row className="my-1">
              <Col className="fw-bold text-lg-right text-md-left" lg={2}>De&nbsp;:&nbsp;</Col>
              <Col lg={10}>
                <UserBadge>{message.sender}</UserBadge>
              </Col>
            </Row>
            <Row className="my-1">
              <Col className="fw-bold text-lg-right text-md-left" lg={2}>À&nbsp;:&nbsp;</Col>
              <Col lg={10}>
                {message.recipient.map((r, i) => <UserBadge key={i}>{r}</UserBadge>)}
              </Col>
            </Row>
            <Row className="my-1">
              <Col className="fw-bold text-lg-right text-md-left" lg={2}>Date&nbsp;:&nbsp;</Col>
              <Col lg={10}>{formatDate(message.timestamp, DefaultDateTimeFormat)}</Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Body>
          {MessageCard.messageHTML(message.content!)}
          <p>{message.signature()}</p>
        </Modal.Body>

        <Modal.Footer className="mb-0 pb-0">
          {message.attachedDocs.length + message.attachedBlobs.length > 0 &&
          <>
              <FontAwesomeIcon icon={faPaperclip}/>
            {message.attachedBlobs.map((b, i) =>
              <>[Document indisponible] <span key={i} className="m-1 fw-bold">{b}</span></>)
            }
            {message.attachedDocs.map(d =>
              <a className="m-1 fw-bold" key={d.documentId}
                 href={`${backend}/documents/${d.documentId}/download`}>
                <Image width={100} title={d.title} alt={d.title}
                       src={`${backend}/documents/${d.documentId}/preview`}/>
              </a>
            )}
          </>
          }
        </Modal.Footer>
        <Modal.Footer className="border-0 mt-0 pt-0">

          <ButtonToolbar>
            {onCreateEvent &&
            <Button className="m-1"
                    onClick={() => onCreateEvent(message)}
            >
                <FontAwesomeIcon icon={faCalendarAlt}/> Créer une tâche
            </Button>
            }

            <Button className="m-1"
                    onClick={() => this.props.onMarkAsUnread(message)}>
              <FontAwesomeIcon icon={faEyeSlash}/> Marquer comme non-lu
            </Button>

            <Button className="m-1"
                    onClick={() => this.reply(message)}>
              <FontAwesomeIcon icon={faReply}/> Répondre
            </Button>

            <Button className="m-1"
                    variant="secondary"
                    onClick={() => this.props.onHide()}
            >
              Fermer
            </Button>
          </ButtonToolbar>
        </Modal.Footer>

      </Modal>
    );
  }

  reply(message: Message) {
    window.location.href = Message.prepare({
      recipient: [message.sender.userId],
      content:
        `En réponse au message de ${message.sender.pn()} du ${formatDate(message.timestamp, DefaultDateTimeFormat)} :\n` +
        message.content.split("\n").map(l => "> " + l).join("\n"),
      subject: "Re: " + message.subject,
      attachments: [],
    });
  }
}