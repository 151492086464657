import React, {Fragment, ReactNode} from "react";
import {Badge, Button, Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {ABDocument} from "./Document";
import {filesize} from "filesize";
import * as queryString from "query-string";
import {formatDate, DefaultDateTimeFormat, FILESIZE_FORMAT} from "../../utils/format";
import {Variant} from "react-bootstrap/types";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSignature} from "@fortawesome/free-solid-svg-icons";
import {encodeURIForShow} from "../../views/ShowImage";


type DocumentCardProps = {
  doc: ABDocument;
  refreshAnnotation?: number;
  badges: DocBadge[],
  disabled: boolean,
  open: boolean,
  onOpen: () => void,
  showVisibility: boolean,
};

type DocumentCardState = {
  showDownload: boolean;
}

export type DocBadge = {
  variant: Variant;
  content: ReactNode;
}


export default class DocumentCardBody extends React.Component<DocumentCardProps> {
  state: DocumentCardState = {
    showDownload: false,
  }

  static defaultProps = {
    badges: [],
    disabled: false,
  }

  render(): JSX.Element {
    const {doc, refreshAnnotation} = this.props;
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    // const original = `${backend}/documents/${doc.documentId}/download`;
    const image = `documents/${doc.documentId}/image`;
    const preview = `${backend}/documents/${doc.documentId}/preview?${refreshAnnotation ? refreshAnnotation : ""}`;

    const iconColor = doc.mimeColor();

    const signPDF = `${backend}/document/${this.props.doc.documentId}/signaturesPDF`;

    const user = doc.concerne ?? doc.posteur;

    const visibility = ABDocument.visibilityIcon(doc.visibility);

    if (this.props.open) {
      return <>
        <a href={`/show/${encodeURIForShow(image)}`}>
          <div className="card-post__image">
            <img className={classNames("card-img", {"opacity-3": this.props.disabled})}
                 style={{height: "10.3125rem", objectFit: "cover", objectPosition: "50% 30%"}}
                 src={preview}
                 alt={""}/>
            <div className="card-post__category">
              <Badge
                pill
                bg="none"
                style={{
                  backgroundColor: iconColor.toCSS(),
                  color: iconColor.textColor().toCSS(),
                }}
              >
                {doc.mimeName.substring(0, 40)}
              </Badge>
              {this.props.showVisibility && <><br/>
                  <Badge pill
                         bg="none"
                         style={{
                           backgroundColor: visibility.color.toCSS(),
                           color: visibility.color.textColor().toCSS()
                         }}
                  >
                    {visibility.toString} {visibility.icon}
                  </Badge>
              </>}
            </div>
            <div className="card-post__author d-flex">
              <div
                className="card-post__author-avatar card-post__author-avatar--small"
                style={{backgroundImage: `url('${UserBadge.gravatar(user)}')`}}
              />
              <Badge className="author-badge ms-2 mt-2 badge-outline-primary text-wrap"
                     bg="none"
                     style={{
                       color: user.favourite.textColor().toCSS(),
                       borderColor: user.favourite.textColor().toCSS(),
                       backgroundColor: user.favourite.toCSS()
                     }}>
                {UserBadge.link(user)}
              </Badge>
            </div>
          </div>
        </a>
        <Card.Body className={"pb-0"}>

          <h5><a href={`/show/${encodeURIForShow(image)}`} className="text-fiord-blue">
            {this.props.doc.title} </a></h5>

          <div
            className="text-muted small"><>
            {doc.posteur && doc.posteur.userId !== user.userId &&
                <div>Mis en ligne par {UserBadge.link(doc.posteur)}</div>
            }
            {this.props.doc.size && filesize(this.props.doc.size, FILESIZE_FORMAT)} − {
            formatDate(this.props.doc.timestamp, DefaultDateTimeFormat)}
          </></div>
          {this.props.doc.commentaire &&
              <div className="fw-normal mt-1 py-3 border border-info">
                {this.props.doc.commentaire}
              </div>
          }
          <a href={signPDF} className="border-top border-light">
            <ListGroup>
              {doc.signatures.map((s, i) =>
                <ListGroupItem className="p-1 bg-transparent" key={i}>
                  <FontAwesomeIcon icon={faSignature}/> <UserBadge>{s.signer}</UserBadge></ListGroupItem>
              )}
            </ListGroup>
          </a>

          <Button className="px-1 py-0 m-0"
                  disabled={doc.telecharge.length === 0}
                  onClick={() => this.setState({showDownload: !this.state.showDownload})}
                  variant={"link"}
          >
            <FontAwesomeIcon icon={faDownload}/> Téléchargé {doc.telecharge.length} fois
          </Button>

          {this.state.showDownload &&
              <ListGroup>
                {doc.telecharge.map((t, i) =>
                  <ListGroupItem className="p-1 small" key={i}>
                    <UserBadge>{t.user}</UserBadge> : {formatDate(t.date)}
                  </ListGroupItem>
                )}
              </ListGroup>
          }

          <div className="d-block">
            {this.props.badges.map((b, i) =>
              <Fragment key={i}>
                <Badge className="text-wrap text-start" bg={b.variant}>{b.content}</Badge>{' '}
              </Fragment>)}
          </div>

        </Card.Body>
      </>;
    } else {
      return <>
        <div className="card-post__image" style={{minHeight: "3rem"}}>
          <img className={classNames("card-img", {"opacity-3": this.props.disabled})}
               style={{height: "3rem", objectFit: "cover", objectPosition: "50% 30%"}}
               src={preview}
               alt={""}/>
          <div className="card-post__category"
               style={{top: "2px"}}>
            <Badge className="border"
                   bg={"none"}
                   style={{
                     background: iconColor.textColor().toCSS(),
                     color: iconColor.toCSS(), borderColor: iconColor.toCSS(),
                     borderWidth: "1px"
                   }}
            >
              <FontAwesomeIcon icon={doc.mimeIcon()} size={"2x"}/>
            </Badge>
          </div>
          <div className="card-post__author d-flex">
            <div
              className="card-post__author-avatar card-post__author-avatar--small"
              style={{backgroundImage: `url('${UserBadge.gravatar(user)}')`}}
            />
            <Badge className="author-badge ms-2 mt-2 badge-outline-primary text-wrap"
                   bg="none"
                   style={{
                     color: user.favourite.textColor().toCSS(),
                     borderColor: user.favourite.textColor().toCSS(),
                     backgroundColor: user.favourite.toCSS()
                   }}>
              {UserBadge.link(user)}
            </Badge>
          </div>
        </div>
        <Card.Body className="mt-3 mx-1 mb-0 p-0">
          <Button variant="link"
                  style={{fontSize: "large"}}
                  onClick={this.props.onOpen}
                  className="text-fiord-blue text-start fw-bold">
            {this.props.doc.title} </Button>
        </Card.Body>
      </>;
    }
  }
}
