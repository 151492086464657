import {GRANTS, GrantsStrings} from "../../utils/auth";
import {filterStatus} from "../../utils/FetchError";
import immutable from "immutable";
import {UserBadge} from "./UserBadge";
import {RGBColor} from "../../utils/colors";
import {PhoneNumber} from "libphonenumber-js";
import {parsePhoneNumberFR} from "../../utils/miscellaneous";
import {parseISO} from "date-fns";
import {PublicKey} from "../../utils/Signature";
import {Corps} from "./Corps";

interface IUser {
  userId: number;
  codePostal: string;
  ville: string;
  adresse: string;
  telephone?: PhoneNumber;
  nom: string;
  prenom: string;
  grants: GRANTS;
  societe?: string;
  email: string;
  logo?: number;
  favourite: RGBColor;
  corps: number[];
  civilite: string;
  titre?: string;
}

export interface JSONUser {
  codePostal: string;
  ville: string;
  adresse: string;
  telephone: string;
  nom: string;
  prenom: string;
  userId: number;
  grants: GrantsStrings;
  societe?: string;
  email: string;
  logo?: number;
  favourite: string;
  corps: number[];
  civilite: string;
  titre?: string;
}

const backend = process.env.REACT_APP_BACKEND_SERVER;


export class User implements IUser {

  readonly userId: number;
  readonly codePostal: string;
  readonly ville: string;
  readonly adresse: string;
  readonly telephone?: PhoneNumber;
  readonly nom: string;
  readonly prenom: string;
  readonly grants: GRANTS;
  readonly societe?: string;
  readonly email: string;
  readonly logo?: number;
  readonly favourite: RGBColor;
  readonly corps: number[];
  readonly civilite: string;
  readonly titre?: string;


  constructor({
                userId,
                codePostal,
                ville,
                adresse,
                telephone,
                nom,
                prenom,
                grants,
                societe,
                email,
                logo,
                favourite,
                corps,
                civilite,
                titre
              }: IUser) {
    this.userId = userId;
    this.codePostal = codePostal;
    this.ville = ville;
    this.adresse = adresse;
    this.telephone = telephone;
    this.nom = nom;
    this.prenom = prenom;
    this.grants = grants;
    this.societe = societe;
    this.email = email;
    this.logo = logo;
    this.favourite = favourite;
    this.corps = corps;
    this.civilite = civilite;
    this.titre = titre;
  }


  static new(data: Partial<User> = {}): User {

    return new User({
      userId: 0,
      nom: '',
      prenom: '',
      email: '',
      grants: GRANTS.std,
      adresse: '',
      ville: '',
      codePostal: '',
      favourite: new RGBColor(0, 0x7b, 0xff),
      corps: [],
      telephone: undefined,
      titre: undefined,
      logo: undefined,
      civilite: 'M.',
      ...data,
    });
  }

  static parse(u: JSONUser): User {
    return new User({
        ...u,
        telephone: parsePhoneNumberFR(u.telephone),
        grants: GRANTS[u.grants],
        // logo: u.logo ? parseISO(u.logo) : undefined,
        favourite: RGBColor.fromHex(u.favourite),
      }
    );
  }

  static load(userId: number): Promise<User> {
    return fetch(`${backend}/loadUser/${userId}`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((data: JSONUser) => User.parse(data));
  }

  updated(newData: Partial<IUser>): User {
    return new User(
      {
        ...this,
        ...newData
      }
    );
  }

  myCorps(corps: immutable.Map<number, Corps>): Corps[] {
    const r: Corps[] = [];
    for (const c of this.corps) {
      const cc = corps.get(c);
      if (cc) r.push(cc);
    }
    return r;
  }

  oneLineAddress(): string {
    const r = [];
    if (this.adresse.length > 0) {
      r.push(this.adresse);
    }
    const ville = [];
    if (this.codePostal.length > 0) {
      ville.push(this.codePostal);
    }
    if (this.ville.length > 0) {
      ville.push(this.ville);
    }
    if (ville.length > 0) {
      r.push(ville.join(" "))
    }
    return r.join(" -- ");
  }

  static sortByCorps(corps: immutable.Map<number, Corps>): (user1: User, user2: User) => number {
    return (user1, user2) => {
      const c1 = user1.myCorps(corps).map(c => c.corps);
      const c2 = user2.myCorps(corps).map(c => c.corps);

      if (c2.length === 0 && c1.length > 0) return -1;
      else if (c1.length === 0 && c2.length > 0) return 1;
      else if (c1 < c2) {
        return -1;
      } else if (c1 > c2) {
        return 1;
      } else {
        const u1 = UserBadge.link(user1);
        const u2 = UserBadge.link(user2);
        return u1.localeCompare(u2);
      }
    }
  }

  connexions(): Promise<{ ip: string, time: Date }[]> {
    return fetch(`${backend}/connexions/${this.userId}`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then(connexions => connexions.map((t: { time: string, ip: string }) =>
        ({ip: t.ip, time: parseISO(t.time)})
      ));
  }

  keys(): Promise<PublicKey[]> {
    return fetch(`${backend}/user/${this.userId}/keys`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then(keys => keys.map((t: { keyId: number, timestamp: string, publicKey: string }) =>
        ({...t, timestamp: parseISO(t.timestamp)})
      ));
  }

  static civiliteOption(civilite: string): { value: string, label: string } {
    return {value: civilite, label: civilite};
  }

  static civilites(): { value: string; label: string }[] {
    return ['M.', 'Mme.'].map(User.civiliteOption)
  }

  civiliteOption(): { value: string; label: string } {
    return User.civiliteOption(this.civilite);
  }

  cn(): string {
    return `${this.civilite} ${this.nom}`;
  }

  pn(): string {
    return `${this.prenom} ${this.nom}`;
  }

  static sendRecoveryCode(email: string): Promise<Response> {
    return fetch(`${backend}/recover/${encodeURIComponent(email)}`,
      {credentials: 'include'}
    )
      .then(filterStatus)
  }

  hasRecoveryCode(): Promise<boolean> {
    return fetch(`${backend}/user/${this.userId}/hasRecoveryCode`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
  }
}

