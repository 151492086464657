import React from "react";
import {Container, Row} from "react-bootstrap";

type EmptyLayoutProps = {
    children: React.ReactNode;
}

export default class EmptyLayout extends React.Component<EmptyLayoutProps> {

    render(): JSX.Element {
        const {children} = this.props;

        return (
            <Container className="h-100">
                <Row className="h-100">
                    {children}
                </Row>
            </Container>
        );
    }
}
