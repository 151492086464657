import React, {Component} from "react";
import {
  Button,
  ButtonToolbar,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Image,
  Modal,
  OverlayTrigger,
  Popover,
  Row
} from "react-bootstrap";
import {FACING_MODES} from "react-html5-camera-photo";
import ReservePlans from "./ReservePlans";
import {Task} from "./Task";
import {Projet} from "../projets/Projet";
import {Credentials, GRANTS} from "../../utils/auth";
import {ABDocument, DocumentVisibility} from "../documents/Document";
import immutable from "immutable";
import * as querystring from "query-string";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import {Either, Maybe} from "monet";
import {SubmitError} from "../../utils/FetchError";
import {
  faAlignLeft,
  faBell,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarTimes,
  faCamera,
  faCaretDown,
  faCheck,
  faCircle,
  faDownload,
  faEnvelope,
  faEye,
  faHardHat,
  faImages,
  faMapMarked,
  faPaintBrush,
  faPen,
  faPhone,
  faTimes,
  faTimesCircle,
  faTrash,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {formatDate, DefaultDateFormatDayName, DefaultDateTimeFormatSeconds} from "../../utils/format";
import {DocEditor} from "../documents/DocEditor";
import {ErrorData, Gallery} from "../documents/Gallery";
import {onDefined, undefinedToArray} from "../../utils/miscellaneous";
import {Message} from "../messages/Message";
import {addDays, min, startOfISOWeek} from "date-fns";
import {EditableDate} from "./EditableDate";
import 'react-day-picker/lib/style.css';
import filterOptions from "../../utils/SelectSearch";
import Select from "react-select";
import {COLORS, RGBColor} from "../../utils/colors";
import {CirclePicker} from "react-color";
import classNames from "classnames";
import {Suggestions} from "../../utils/Suggestions";
import Autosuggest from "react-autosuggest";
import {Loadable} from "../../utils/Loading";
import {EventPlanEditor} from "./EventPlanEditor";
import {User} from "../user-profile-lite/User";
import {encodeURIForShow} from "../../views/ShowImage";


type EventProps = {
  onDelete: (event: Task) => Promise<void>;
  event: Task;
  onEffectuer: (t: Task) => void;
  project?: Projet;
  credentials: Credentials;
  onNewPhoto: (submitted: Either<SubmitError, ABDocument[]>, event: Task) =>
    Promise<Either<SubmitError, ABDocument[]>>;
  annotations: immutable.Map<number, number>;
  onSketch: (doc: ABDocument) => void;
  // onComment: (t: Task, c: string) => Promise<Either<ErrorData, Task>>;
  onHide: () => void;
  onSubmit: (t: Task) => Promise<Either<ErrorData, Task>>;
  onRevert: () => void;
  users?: AllUsers;
  plans: Loadable<ABDocument[]>;
  edit: EventEdit;
  setEdit: (mode: EventEdit) => void;
};

type EventState = {
  hovered: boolean;
  camera?: string;
  editDoc?: ABDocument;
  editError?: { [key: string]: string };
  suggestions: string[];
  suggestionsBase?: Suggestions;
  title: string;
  description: string;
  affectation?: User;
};

let editorId = 0;

export enum EventEdit {
  none, title, description, plan, photos
}

export default class EventCard extends Component<EventProps, EventState> {

  state: EventState = {
    hovered: false,
    suggestionsBase: new Suggestions(),
    suggestions: [],
    title: '',
    description: '',
  };

  id: number = editorId++;

  componentDidMount(): void {
    window.addEventListener('resize', () => this.forceUpdate());

    if (this.props.credentials.in(GRANTS.resp)) {
      Task.suggestions()
        .then(suggestionsBase => this.setState({suggestionsBase}))
        .catch(error => this.setState({suggestionsBase: error}));
    }
  }

  private static prepareMailItv(e: React.MouseEvent, task: Task, projet?: Projet) {
    e.stopPropagation();
    window.location.href = Message.prepare(
      {
        recipient: undefinedToArray(task.affectation?.userId),
        subject: (task.project || projet)?.title + " " + task.title,
        content: `${task.description}\n${window.location.origin}/intervenants/${task.eventId}`,
        attachments: [],
      },
      projet ?? task.project
    );
  }


  private switchMode(mode: EventEdit, change: Partial<Task> = {}): Promise<Either<ErrorData, Task>> {
    switch (this.props.edit) {
      case EventEdit.title:
        change.title = this.state.title;
        break;
      case EventEdit.description:
        change.description = this.state.description;
        break;
    }
    if (Object.entries(change).length === 0) {
      this.props.setEdit(mode);
      return new Promise(r => r(Either.right(this.props.event)));
    } else {
      return this.props.onSubmit(this.props.event.updated(change))
        .then((r) => {
          r.cata(
            editError => this.setState({editError}),
            () => this.props.setEdit(mode)
          );
          return r;
        });
    }
  }

  private handleUpdate(change: Partial<Task>) {
    return this.switchMode(this.props.edit, change);
  }

  render(): JSX.Element {
    const {event, onEffectuer} = this.props;

    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const photos = event.photos.map(p =>
      ({
        doc: p,
        preview: `${backend}/documents/${p.documentId}/preview?${this.props.annotations.get(p.documentId)}`,
        picture: `/show/${encodeURIForShow(`documents/${p.documentId}/image`)}?${querystring.stringify({
          back: `/planning/${event.eventId}`
        })}`,
        original: `${backend}/documents/${p.documentId}/download`,
        alt: p.title
      })
    );


    const project = event.project || this.props.project;

    return (
      <>
        <Modal show onHide={() => this.handleHide()} centered size="lg">
          <Form onSubmit={(e) => {
            e.preventDefault();
            this.switchMode(EventEdit.none)
          }}>
            <Modal.Header style={{backgroundColor: event.color.toCSS()}}>
              {this.props.edit === EventEdit.title ?
                <>
                  <OverlayTrigger
                    trigger={["click", "focus"]}
                    placement="right"
                    overlay={
                      <Popover id={`ColorPicker${this.id}`}>
                        <Popover.Body>
                          <CirclePicker
                            circleSize={20}
                            color={event.color}
                            colors={COLORS}
                            onChangeComplete={(c) =>
                              this.switchMode(EventEdit.none, {color: RGBColor.fromColorResult(c)})
                            }
                          />
                        </Popover.Body>
                      </Popover>
                      // this.colorPicker(
                      //   event.color,
                      //   (c) => this.switchMode(EventEdit.none, {color: RGBColor.fromColorResult(c)})
                      // )
                    }
                    delay={{show: 200, hide: 400}}>
                    <Button className="border-light bg-transparent me-2" style={{color: event.color.textCSS()}}>
                      <FontAwesomeIcon icon={faCircle} className={"me-2"}/>
                      <FontAwesomeIcon icon={faCaretDown}/>
                    </Button>
                  </OverlayTrigger>
                  <Autosuggest
                    suggestions={this.state.suggestions}
                    inputProps={{
                      className: classNames("w-100 form-control-lg fw-bold",
                        {"is-invalid": this.state.editError?.title !== undefined}),
                      onChange: (e, {newValue}) => this.setState({title: newValue}),
                      value: this.state.title
                    }}
                    onSuggestionsFetchRequested={({value}) =>
                      this.setState({
                        suggestions: this.state.suggestionsBase?.updateSuggestions(value) || []
                      })
                    }
                    onSuggestionsClearRequested={() =>
                      this.setState({suggestions: this.state.suggestionsBase?.clear() || []})
                    }
                    getSuggestionValue={(s) => Suggestions.getSuggestionValue(s)}
                    renderSuggestion={(s) => Suggestions.renderSuggestion(s)}
                  />


                  <FormControl.Feedback
                    className={classNames("px-2 w-auto", {"d-flex": this.state.editError?.title !== undefined})}
                    type="invalid">{this.state.editError?.title}</FormControl.Feedback>
                </>

                : <>
                  <h5 style={{color: event.color.textCSS()}}>{event.title}</h5>
                  {this.props.credentials.in(GRANTS.resp) &&
                      <Button
                          onClick={() => {
                            this.setState({title: this.props.event.title});
                            this.switchMode(EventEdit.title);
                          }}>
                          <FontAwesomeIcon icon={faPen}/>
                      </Button>
                  }
                </>
              }


            </Modal.Header>

            <Modal.Body>
              {this.ownEvent() &&
                  <Form.Group>
                    {this.props.edit === EventEdit.plan ?
                      <EventPlanEditor
                        credentials={this.props.credentials}
                        event={this.props.event}
                        plans={this.props.plans}
                        saveEvent={(c) => this.handleUpdate(c)}
                        onHide={() => this.props.setEdit(EventEdit.none)}
                      /> : <>
                        <Form.Label><FontAwesomeIcon icon={faMapMarked}/> Plans
                          {this.props.credentials.in(GRANTS.resp) &&
                              <Button className="m-1 py-1"
                                      onClick={() => this.switchMode(EventEdit.plan)}>
                                  <FontAwesomeIcon icon={faPen}/>
                              </Button>
                          }
                        </Form.Label>
                        <ReservePlans res={[event]}
                                      onClickImage={() => null}
                                      onClickPoint={() => null}/>
                      </>
                    }
                  </Form.Group>
              }
              <Form.Group>
                <Form.Label><FontAwesomeIcon icon={faCamera}/> Photos
                  {this.props.credentials.in(GRANTS.resp) &&
                      <Button
                          className={classNames("m-1 py-1", {"btn-outline-primary": this.props.edit === EventEdit.photos})}
                          onClick={() =>
                            this.switchMode(this.props.edit === EventEdit.photos ? EventEdit.none : EventEdit.photos)
                          }
                      >
                          <FontAwesomeIcon icon={faImages}/> Galerie
                      </Button>
                  }

                  {this.ownEvent() &&
                      <Button
                          className="py-1 m-1"
                          onClick={() => this.handleCamera()}
                      >
                          <FontAwesomeIcon icon={faCamera}/>
                      </Button>
                  }

                </Form.Label>
                <Gallery
                  open={this.props.edit === EventEdit.photos}
                  projet={this.props.event.project}
                  exclude={this.props.event.photos}
                  annotations={this.props.annotations}
                  onPick={(doc) =>
                    this.handleUpdate({photos: this.props.event.photos.concat(doc)})
                  }
                  categories={['Photos']}
                />
                <Row>
                  {photos.map(({picture, preview, original, alt, doc}, i) =>
                    <Col sm={12} lg={6} key={i} className="text-center mb-3">
                      <a href={picture}><Image fluid src={preview} alt={alt}/></a>

                      <div className="w-100 text-end">
                        <Button href={original}
                                className="btn-circle btn-pill text-center p-1 position-relative m-1"
                                style={{top: "-50px", left: "-20px"}}
                        ><FontAwesomeIcon icon={faDownload}/></Button>
                        {this.props.credentials.in(GRANTS.resp) &&
                            <Button
                                onClick={() => this.switchMode(EventEdit.none, {
                                  photos: event.photos.filter(p => p.documentId !== doc.documentId)
                                })}
                                style={{top: "-50px", left: "-20px"}}
                                className="btn-circle btn-pill text-center position-relative p-1 m-1"
                            >
                                <FontAwesomeIcon icon={faTimes}/>
                            </Button>
                        }
                        {doc.editable(this.props.credentials) &&
                            <Button onClick={() => this.props.onSketch(doc)}
                                    className="btn-circle btn-pill text-center p-1 position-relative m-1"
                                    style={{top: "-50px", left: "-20px"}}
                            ><FontAwesomeIcon icon={faPaintBrush}/></Button>
                        }
                      </div>
                    </Col>
                  )}
                </Row>

              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label> <FontAwesomeIcon icon={faAlignLeft}/> Description
                  {this.ownEvent() && this.props.edit !== EventEdit.description &&
                      <Button
                          className={classNames("m-1 py-1")}
                          onClick={() => {
                            this.setState({description: this.props.event.description});
                            this.switchMode(EventEdit.description);
                          }}
                      >
                          <FontAwesomeIcon icon={faPen}/>
                      </Button>
                  }
                </Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={this.props.edit !== EventEdit.description}
                  value={this.props.edit === EventEdit.description ? this.state.description : event.description}
                  onChange={e => this.setState({description: e.currentTarget.value})}
                />
              </Form.Group>

              {project?.client &&
                  <Form.Group className="mb-3">
                      <Form.Label><FontAwesomeIcon icon={faWrench}/> Projet</Form.Label>
                      <p>
                        {project.title} | <UserBadge civilite prenom={false}>{project.client}</UserBadge>
                        {project.client.telephone && <>
                          {' '}| <FontAwesomeIcon icon={faPhone}/>&nbsp;<a
                            href={project.client.telephone.getURI()}>{project.client.telephone.format("IDD", {fromCountry: 'FR'})}</a>
                        </>
                        }
                      </p>
                  </Form.Group>
              }

              <Form.Group className="mb-3">
                <Form.Label><FontAwesomeIcon icon={faHardHat}/> Affectation</Form.Label>
                <p>
                  {this.props.users ?
                    <Select
                      value={userSelect(event.affectation)}
                      name="resources"
                      isSearchable
                      isClearable
                      isDisabled={!this.props.credentials.in(GRANTS.resp)}
                      filterOption={filterOptions}
                      options={this.props.users.userSelect()}
                      onChange={s =>
                        this.handleUpdate(
                          {affectation: s?.value, color: s?.value.favourite ?? event.color})
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                    /> :
                    event.affectation ? <UserBadge>{event.affectation}</UserBadge> : "Non affecté"
                  }
                </p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label><FontAwesomeIcon icon={faCalendarPlus}/> Démarrage prévu</Form.Label>
                <EditableDate
                  editable={this.isAdmin()}
                  onChange={(day) =>
                    this.handleUpdate({startDay: day})
                      .then(r => r.leftMap(error => error.startDay))
                  }
                  onWeekClick={(days: Date[]) => this.handleWeekClick(days)}
                  modifiers={{
                    start: event.startDay,
                    end: event.echeance,
                  }}
                  disabled={Maybe.fromUndefined(onDefined([
                    this.props.event.echeance,
                    this.props.event.effectue,
                    this.props.event.cloture
                  ], min))
                    .cata(() => [], after => [{after}])
                  }
                  selectedDays={EventCard.eventRange(this.props.event)}
                  date={event.startDay}
                  placeholder="Non planifié"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><FontAwesomeIcon icon={faBell}/> Échéance</Form.Label>
                <EditableDate
                  editable={this.isAdmin()}
                  onChange={(day) =>
                    this.handleUpdate({echeance: day})
                      .then(r => r.leftMap(error => error.echeance))
                  }
                  onWeekClick={(days: Date[]) => this.handleWeekClick(days)}
                  date={this.props.event.echeance}
                  modifiers={{
                    start: event.startDay,
                    end: event.echeance,
                  }}
                  disabled={Maybe.fromUndefined(this.props.event.startDay).cata(() => [], before => [{before}])}
                  selectedDays={EventCard.eventRange(this.props.event)}
                  placeholder="Non planifiée"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><FontAwesomeIcon icon={faCalendarCheck}/> Effectué </Form.Label>
                <div>
                  {this.props.event.effectue ?
                    <>{formatDate(this.props.event.effectue, DefaultDateFormatDayName)}
                      {this.isAdmin() &&
                          <Button variant={"link"}
                                  onClick={() => this.handleUpdate({effectue: undefined})}>
                              <FontAwesomeIcon icon={faTimesCircle}/>
                          </Button>
                      }
                    </> :
                    this.ownEvent() ?
                      <Button
                        size="lg"
                        variant="link"
                        onClick={() => onEffectuer(event)}>
                        <FontAwesomeIcon icon={faCheck}/> Marquer la tâche comme effectuée
                      </Button> :
                      "À faire"
                  }
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label><FontAwesomeIcon icon={faCalendarTimes}/> Clôture</Form.Label>
                <div>
                  {this.props.event.cloture ? <>
                      {formatDate(this.props.event.cloture, DefaultDateFormatDayName)}
                      <Button variant={"link"}
                              disabled={!this.isAdmin()}
                              onClick={() => this.handleUpdate({cloture: undefined})}>
                        <i className="fas fa-times-circle"/>
                      </Button>
                    </> :
                    this.isAdmin() ?
                      <Button
                        size="lg"
                        variant="link"
                        onClick={() => this.handleUpdate({
                          startDay: event.startDay ?? new Date(),
                          cloture: new Date()
                        })}>
                        <FontAwesomeIcon icon={faCheck}/> Clôturer la tâche
                      </Button> :
                      "Non clôturée"
                  }
                </div>
              </Form.Group>
              {this.props.credentials.in(GRANTS.resp) &&
                  <Form.Group className="mb-3">
                      <Form.Check>
                          <Form.Check.Input
                              onClick={() => this.handleUpdate({visibleClient: !this.props.event.visibleClient})}
                              checked={this.props.event.visibleClient}/>{' '}
                          <Form.Check.Label>
                              <FontAwesomeIcon icon={faEye}/> Visible client
                          </Form.Check.Label>
                      </Form.Check>
                  </Form.Group>
              }

            </Modal.Body>


            <Modal.Footer>
              <ButtonToolbar>
                {this.isAdmin() &&
                    <>
                      {event.affectation &&
                          <Button onClick={(e: React.MouseEvent) => EventCard.prepareMailItv(e, event, project)}
                                  className="py-1"
                                  variant="light"
                          >
                              <FontAwesomeIcon icon={faEnvelope}/> {UserBadge.link(event.affectation)}
                          </Button>
                      }
                    </>
                }
                {this.isAdmin() &&
                    <DropdownButton className="py-1 m-1"
                                    id="dropdownDelete"
                                    variant="secondary"
                                    title={<FontAwesomeIcon icon={faTrash}/>}>
                        <Dropdown.Item
                            as="button"
                            onClick={(e) => this.handleDelete(e, this.props.onDelete!, event)}>
                            Supprimer la tâche
                        </Dropdown.Item>
                    </DropdownButton>
                }


                {this.props.edit === EventEdit.title || this.props.edit === EventEdit.description ?
                  <>
                    <Button type="reset" className="my-2 mx-1 py-1"
                            onClick={() => {
                              this.props.onRevert();
                              this.props.setEdit(EventEdit.none)
                            }}
                            variant="secondary"
                    >
                      <FontAwesomeIcon icon={faTimes}/> Annuler
                    </Button>
                    <Button
                      className="my-2 mx-1 py-1"
                      type="submit"
                    >
                      <FontAwesomeIcon icon={faCheck}/> Ok
                    </Button>
                  </> :
                  <Button className="m-2 py-1"
                          onClick={() => this.handleHide()}>Fermer</Button>
                }
              </ButtonToolbar>
            </Modal.Footer>
          </Form>
        </Modal>
        {
          this.state.editDoc && (
            <DocEditor
              credentials={this.props.credentials}
              onSubmit={(data, submitted) =>
                this.props.onNewPhoto(submitted, event)}
              edit={this.state.editDoc}
              update={(doc) => this.setState({editDoc: doc})}
              camera={this.state.camera}
              setCameraState={(camera) => this.setState({camera})}
            />
          )
        }

      </>
    )
      ;
  }

  private static eventRange(e: Task) {
    return [
      e.startDay,
      e.echeance,
      (e.startDay &&
        e.echeance && {
          from: e.startDay,
          to: e.echeance
        })
    ];
  }

  private handleWeekClick(days: Date[]) {
    const from = startOfISOWeek(days[0]);
    const to = addDays(from, 4);
    return this.switchMode(EventEdit.none, {startDay: from, echeance: to})
      .then(r => r.leftMap(e => e.startDay + e.echeance));
  }

  private ownEvent() {
    return this.isAdmin() || this.props.credentials.is(this.props.event.affectation);
  }

  private isAdmin() {
    return this.props.credentials.in(GRANTS.resp);
  }

  private handleDelete(e: React.MouseEvent<HTMLElement>, onDelete: ((event: Task) => Promise<void>), event: Task) {
    e.preventDefault();
    onDelete(event).then(() => this.handleHide());
  }


  private handleHide() {
    this.props.setEdit(EventEdit.none);
    this.props.onHide();
  }

  private handleCamera() {
    this.setState({
      editDoc: ABDocument.new(this.props.credentials,
        {
          projetId: this.props.event.projetId,
          title: formatDate(new Date(), DefaultDateTimeFormatSeconds),
          visibility: DocumentVisibility.prestataire,
          concerne: this.props.event.affectation,
          categorie: "Photos"
        }
      ),
      camera: FACING_MODES.ENVIRONMENT,
    });
  }
}
