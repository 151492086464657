import {JSONUser, User} from "../user-profile-lite/User";
import {filterStatus, filterStatusEither, SubmitError} from "../../utils/FetchError";
import {formatISO, parseISO} from "date-fns";
import {Credentials} from "../../utils/auth";
import {Either} from "monet";
import {Entreprise, JSONProjet, Projet} from "./Projet";
import querystring from "query-string";
import {Filter} from "../../utils/DateFilter";
import immutable from "immutable";


export interface ProjetRecap {
  projetId: number,
  miseAuPointClient?: Date,
  demarragePrevu?: Date,
  promisPar?: number,
}

interface JSONProjetRecap {
  projetId: number,
  miseAuPointClient?: string,
  demarragePrevu?: string,
  promisPar?: number,
}


const backend = process.env.REACT_APP_BACKEND_SERVER;

export function newProjetRecap(projetId: number): ProjetRecap {
  return {projetId};
}

export function loadDonneesRecap(selectFinished: boolean): Promise<immutable.Map<number, ProjetRecap>> {
  return fetch(`${backend}/projets/recap?${querystring.stringify({
    selectFinished,
  })}`, {credentials: "include"})
    .then(filterStatus)
    .then(response => response.json())
    .then((rs: JSONProjetRecap[]) =>
      immutable.Map(rs.map(r => [r.projetId, parseProjetRecap(r)]))
    );
}

function parseProjetRecap(pc: JSONProjetRecap): ProjetRecap {
  return {
    ...pc,
    miseAuPointClient: pc.miseAuPointClient ? parseISO(pc.miseAuPointClient) : undefined,
    demarragePrevu: pc.demarragePrevu ? parseISO(pc.demarragePrevu) : undefined,
  };
}

export function saveProjetRecap(pc: ProjetRecap, credentials: Credentials): Promise<Either<SubmitError, ProjetRecap>> {
  const headers = credentials.headers();
  headers.append("Content-Type", "application/json");

  const data: JSONProjetRecap = {
    ...pc,
    miseAuPointClient: pc.miseAuPointClient && formatISO(pc.miseAuPointClient, {representation: 'date'}),
    demarragePrevu: pc.demarragePrevu && formatISO(pc.demarragePrevu, {representation: 'date'}),
  };

  return fetch(`${backend}/projet/saveRecap`,
    {
      credentials: "include",
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(r => filterStatusEither<JSONProjetRecap>(r))
    .then(r => r.map(parseProjetRecap));
}