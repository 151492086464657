import {Col, Container, Row} from "react-bootstrap";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import {Outlet} from "react-router-dom";
import { useProject } from "../utils/SelectedProject";

type DefaultLayoutProps = {
  version: string;
}

export function DefaultLayout(props: DefaultLayoutProps) {
  const {version} = props;
  const [projet] = useProject();
  return (

    <Container fluid>
      <Row>

        <MainSidebar projet={projet} version={version}/>

        <Col
          className="main-content p-0"
          xl={{span: 10, offset: 2}}
          md={{span: 9, offset: 3}}
          sm="12"
        >
          <MainNavbar projet={projet}/>
          <Outlet/>
          {/*{!noFooter && <MainFooter/>}*/}
        </Col>
      </Row>
    </Container>

  );
}

