import {fr} from "date-fns/locale";
import {format, parse} from "date-fns";
import {DateUtils, DayPickerInputProps, DayPickerProps} from "react-day-picker";
import {NumericFormatProps} from "react-number-format";
import {ReactNode} from "react";
import {
  faDraftingCompass,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {RGBColor} from "./colors";

export const DefaultDateFormat = (date: Date) => (date.getDate() === 1 ? "do" : "d") + " MMM yyyy";

export const DefaultDateTimeFormat = (date: Date) => DefaultDateFormat(date) + " HH:mm";
export const DefaultDateTimeFormatSeconds = (date: Date) => DefaultDateTimeFormat(date) + ":ss";
export const DefaultDateFormatDayName = (date: Date) => "eee " + DefaultDateFormat(date);

export const InputDateFormat = 'd/M/y';

export function formatDate(date: Date, formatStr: (date: Date) => string = DefaultDateFormat): string {
  return format(date, formatStr(date), {
    locale: fr
  });
}

export function parseDate(str: string, format: string = InputDateFormat, locale = fr): Date | undefined {
  const parsed = parse(str, format, new Date(), {locale});
  if (DateUtils.isDate(parsed)) {
    return parsed;
  } else {
    return undefined;
  }
}

export const FILESIZE_FORMAT = {
  locale: "fr",
  symbols: {
    B: "o",
    KB: "Kio",
    MB: "Mio",
    GB: "Gio"
  }
};

export const MONTHS = [
  "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
];
export const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundì',
  'Mardì',
  'Mercredì',
  'Jeudi',
  'Vendredi',
  'Samedi',
];
export const WEEKDAYS_SHORT = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
export const DAY_PICKER_PROPS: DayPickerProps = {
  months: MONTHS,
  weekdaysShort: WEEKDAYS_SHORT,
  weekdaysLong: WEEKDAYS_LONG,
  firstDayOfWeek: 1,
};
export const DAY_PICKER_INPUT_PROPS: DayPickerInputProps = {
  format: InputDateFormat,
  formatDate: (date: Date, fmt: string) => formatDate(date, () => fmt),
  parseDate: (str: string, format: string) => parseDate(str, format),
  placeholder: 'J/M/A',
  clickUnselectsDay: true,
  dayPickerProps: DAY_PICKER_PROPS,
};

export const NUMBER_FORMAT_FR: NumericFormatProps = {
  valueIsNumericString: true,
  decimalSeparator: ',',
  thousandSeparator: ' ',
  fixedDecimalScale: true,
}
export const NUMBER_FORMAT_EUR = {
  ...NUMBER_FORMAT_FR,
  suffix: " €",
  decimalScale: 2,
};
export const NUMBER_FORMAT_PERC = {
  ...NUMBER_FORMAT_FR,
  suffix: " %"
}
const NumberFormatEUR = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR'
});

const NumberFormatPERC = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  maximumFractionDigits: 3,
});


const NumberFormatDec = new Intl.NumberFormat('fr-FR');
export const formatEur = NumberFormatEUR.format;
export const formatDec = NumberFormatDec.format;
export const formatPerc = NumberFormatPERC.format;

export function formatItv(min: number, max: number, unite = '', format: (n: number) => string = formatDec): ReactNode {
  const maxDefined = isFinite(max);
  if (min > 0) {
    if (maxDefined) {
      if (min === max) {
        return <>{format(min!)}&nbsp;{unite}</>;
      } else {
        return <>{format(min!)} à {format(max!)}&nbsp;{unite}</>;
      }
    } else {
      return <>au moins {format(min!)}&nbsp;{unite}</>;
    }
  } else if (max === 0) {
    return <>&ndash;</>;
  } else if (maxDefined) {
    return <>au plus {format(max!)}&nbsp;{unite}</>;
  } else {
    return "non estimé";
  }
}

const iconClasses: { [key: string]: IconDefinition } = {
  // Media
  "image": faFileImage,
  "audio": faFileAudio,
  "video": faFileVideo,
  // Documents
  "application/pdf": faFilePdf,
  "application/msword": faFileWord,
  "application/vnd.ms-word": faFileWord,
  "application/vnd.oasis.opendocument.text": faFileWord,
  "application/vnd.openxmlformats-officedocument.wordprocessingml":
  faFileWord,
  "application/vnd.ms-excel": faFileExcel,
  "application/vnd.openxmlformats-officedocument.spreadsheetml":
  faFileExcel,
  "application/vnd.oasis.opendocument.spreadsheet": faFileExcel,
  "application/vnd.ms-powerpoint": faFilePowerpoint,
  "application/vnd.openxmlformats-officedocument.presentationml":
  faFilePowerpoint,
  "application/vnd.oasis.opendocument.presentation": faFilePowerpoint,
  "text/plain": faFileAlt,
  "text/html": faFileCode,
  "application/json": faFileCode,
  // Archives
  "application/gzip": faFileArchive,
  "application/zip": faFileArchive,

  // AutoCAD
  "image/vnd.dwg": faDraftingCompass,
  "model/vnd.dwf": faDraftingCompass,

};

export function getFontAwesomeIconFromMIME(mimeType: string): IconDefinition {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml

  const candidate = Object.entries(iconClasses).find(([k]) =>
    mimeType.startsWith(k)
  )

  if (candidate === undefined) {
    return faFile;
  } else {
    return candidate[1];
  }

}

const colorClasses: { [key: string]: RGBColor } = {
  // Media
  "image": RGBColor.fromHex("#03a9f4"),
  "audio": RGBColor.fromHex("#00aef0"),
  "video": RGBColor.fromHex("#0094ff"),
  // Documents
  "application/pdf": RGBColor.fromHex("#f42c33"),
  "application/msword": RGBColor.fromHex("#115abe"),
  "application/vnd.ms-word": RGBColor.fromHex("#115abe"),
  "application/vnd.oasis.opendocument.text": RGBColor.fromHex("#115abe"),
  "application/vnd.openxmlformats-officedocument.wordprocessingml":
    RGBColor.fromHex("#115abe"),
  "application/vnd.ms-excel": RGBColor.fromHex("#1f7246"),
  "application/vnd.openxmlformats-officedocument.spreadsheetml":
    RGBColor.fromHex("#1f7246"),
  "application/vnd.oasis.opendocument.spreadsheet": RGBColor.fromHex("#1f7246"),
  "application/vnd.ms-powerpoint": RGBColor.fromHex("#d04424"),
  "application/vnd.openxmlformats-officedocument.presentationml":
    RGBColor.fromHex("#d04424"),
  "application/vnd.oasis.opendocument.presentation": RGBColor.fromHex("#d04424"),
  "text/plain": RGBColor.fromHex("#333333"),
  "text/html": RGBColor.fromHex("#f16529"),
  "application/json": RGBColor.fromHex("#333333"),
  // Archives
  "application/gzip": RGBColor.fromHex("#f6e871"),
  "application/zip": RGBColor.fromHex("#f6e871"),

  // Autocad
  "image/vnd.dwg": RGBColor.fromHex("#f2cf0f"),
  "model/vnd.dwf": RGBColor.fromHex("#f2cf0f")
};

export function getColorFromMIME(mimeType: string): RGBColor {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml

  const candidate = Object.entries(colorClasses).find(([k]) =>
    mimeType.startsWith(k)
  )

  if (candidate === undefined) {
    return RGBColor.BLACK;
  } else {
    return candidate[1];
  }

}