import React, {Fragment} from "react";
import Errors from "../../views/Errors";
import {Card} from "react-bootstrap";
import {ErrorData} from "../documents/Gallery";
import {mapRight, SubmitError} from "../../utils/FetchError";
import {Loadable, LOADING, waitFor} from "../../utils/Loading";
import * as Immutable from "immutable";
import EventCard, {EventEdit} from "./EventCard";
import ReservePlans from "./ReservePlans";
import {Task} from "./Task";
import {Projet} from "../projets/Projet";
import {Credentials} from "../../utils/auth";
import {ABDocument} from "../documents/Document";
import {IAlert} from "../../utils/Alerts";
import {Sketch} from "../documents/Sketch";
import {AllUsers, userSelectCorps} from "../../utils/AllUsers";
import {Either} from "monet";
import {User} from "../user-profile-lite/User";
import {Timetable} from "./Timetable";
import { Corps } from "../user-profile-lite/Corps";

type PlanningProps = {
  projet?: Projet;
  // open?: number;
  events: Immutable.Map<number, Task>;
  credentials: Credentials;
  updateEvents: (e: Immutable.Map<number, Task>) => void;
  addAlert: (alert: IAlert) => void;
  showProjet: boolean;
  handleClickPlan?: (event: React.MouseEvent, plan: number, dom: string) => void;
  allItvs?: AllUsers;
  startDate?: Date;
  endDate?: Date;
  editedEvent?: Task;
  editEvent: (t?: Task) => void;
  editMode: EventEdit;
  setEditMode: (mode: EventEdit) => void;
};

type PlanningState = {
  updateError?: string;
  plans: Loadable<ABDocument[]>;
  annotations: Immutable.Map<number, number>;
  sketch?: ABDocument;
  allItvs?: AllUsers;
};


export class Planning extends React.Component<PlanningProps, PlanningState> {

  static defaultProps = {
    editable: false,
  };

  state: PlanningState = {
    plans: LOADING,
    annotations: Immutable.Map(),
  };

  static group<K>(map: Map<K, any>, indices: K[]): Map<K, any> {
    let a = map;
    for (const i of indices) {
      if (!a.has(i)) a.set(i, new Map());
      a = a.get(i);
    }
    return a;
  }

  static eventMap(events: Task[]): Immutable.Map<number, Task> {
    return Immutable.Map(events.map(e => [
      e.eventId,
      e
    ]));
  }


  componentDidMount(): void {
    if (this.props.projet) this.refreshPlans(this.props.projet);

    if (this.props.allItvs) {
      Corps.load()
        .then(corps =>
          this.setState({
            allItvs: this.props.allItvs?.withOptions(userSelectCorps(corps))
              .withOrdering(User.sortByCorps(corps))
          })
        )
        .catch(e => this.setState({allItvs: e}));
    }

    // if (this.props.open !== undefined) {
    //   this.setState({selectedEvent: this.props.events.get(this.props.open)});
    // }
  }

  private refreshPlans(projet: Projet) {
    this.setState({plans: LOADING});
    ABDocument.loadProjet(projet.projetId, false, ["Plans PDF"])
      .then(plans => this.setState({plans}))
      .catch((error) => this.setState({plans: error}));
  }

  render(): JSX.Element {
    const {events, projet} = this.props;

    return (
      <Fragment>
        <ReservePlans onClickPoint={(event) => this.props.editEvent(event)}
                      onClickImage={(event: React.MouseEvent, plan: number, dom: string) =>
                        this.props.handleClickPlan?.(event, plan, dom)}
                      res={[...events.values()]}/>

        {waitFor(this.state.allItvs, allItvs => <>
            {this.props.editedEvent &&
            <EventCard project={projet}
                       credentials={this.props.credentials}
                       event={this.props.editedEvent}
                       onSubmit={(task) => this.saveEvent(task)}
                       onEffectuer={(event) => this.handleEffectuer(event)}
                       onDelete={(event) => this.deleteEvent(event)}
                       onNewPhoto={(saved: Either<SubmitError, ABDocument[]>, event: Task) =>
                         this.handleNewPhoto(event, saved)}
                       annotations={this.state.annotations}
                       onSketch={(doc) => this.setState({sketch: doc})}
                       users={allItvs}
                       onRevert={() => this.props.editEvent(
                         this.props.editedEvent?.eventId ?
                           this.props.events.get(this.props.editedEvent?.eventId) :
                           undefined
                       )}
                       plans={this.state.plans}
                       onHide={() => this.props.editEvent(undefined)}
                       edit={this.props.editMode}
                       setEdit={this.props.setEditMode}
            />
            }

            {/*{projetEditable(this.props.credentials, this.props.projet) &&*/}
            {/*this.props.editedEvent &&*/}
            {/*<Gallery update={this.props.editEvent}*/}
            {/*             event={this.props.editedEvent}*/}
            {/*             projet={this.props.projet}*/}
            {/*             plans={this.state.plans}*/}
            {/*             saveEvent={(event) => this.saveEvent(event)}*/}
            {/*             credentials={this.props.credentials}*/}
            {/*             annotations={this.state.annotations}*/}
            {/*             onSketch={(doc) => this.setState({sketch: doc})}*/}
            {/*/>*/}
            {/*}*/}
          </>
        )}
        {this.state.sketch &&
        <Sketch
            credentials={this.props.credentials}
            doc={this.state.sketch}
            onHide={(id) => this.setState({
              annotations: this.state.annotations.set(id, new Date().getTime()),
              sketch: undefined
            })}/>
        }

        <Card className="mb-3">
          <Card.Body>{
            this.state.updateError ?
              <Errors>{this.state.updateError}</Errors> :
              <Timetable
                credentials={this.props.credentials}
                events={events}
                onClick={selectedEvent => this.props.editEvent(selectedEvent)}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                showProjet={this.props.showProjet}
              />
          }</Card.Body>
        </Card>

      </Fragment>
    );
  }

  private handleEffectuer(event: Task) {
    event.effectuer(this.props.credentials)
      .then(updated => {
        this.props.updateEvents(
          this.props.events
            .remove(event.eventId)
            .set(updated.eventId, updated)
        );
        this.props.editEvent(updated);
      })
      .catch(error => this.props.updateEvents(error));
  }


  private async handleNewPhoto(event: Task, saved: Either<SubmitError, ABDocument[]>):
    Promise<Either<SubmitError, ABDocument[]>> {

    return mapRight(saved, doc =>
        event.addPhoto(this.props.credentials, doc)
          .then(updated => {
            //const updated = event.updated({photos: json.photos});
            this.props.updateEvents(
              this.props.events
                .remove(event.eventId)
                .set(updated.eventId, updated)
            );
            this.props.editEvent(updated);
            return doc;
          })
      // .catch((error: FetchError) => {
      //   this.props.updateEvents(error);
      //   return doc;
      // })
    );
  }

  private saveEvent(event: Task): Promise<Either<ErrorData, Task>> {
    return event.save(this.props.credentials)
      .then((updated: Task) => {
        this.props.updateEvents(
          this.props.events
            .remove(event.eventId)
            .set(updated.eventId, updated)
        );
        this.props.editEvent(updated);
        this.props.addAlert({
          theme: "success",
          text: <>Modifications de {event.title} enregistrées</>
        });
        return Either.right<ErrorData, Task>(updated);
      })
      .catch(e => {
        if (e.status === 400) {
          return e.json().then((errors: ErrorData) => Either.left(errors));
        } else {
          this.props.updateEvents(e);
          throw e;
        }
      });

  }


  private deleteEvent(event: Task): Promise<void> {
    return event.delete(this.props.credentials)
      .then(() => {
        this.props.updateEvents(this.props.events.delete(event.eventId));
        this.props.addAlert({
          theme: "warning",
          text: <>{event.title} a été supprimé</>
        })
      })
      .catch(updateError => this.setState({updateError}));
  }


}

