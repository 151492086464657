import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import {CLItem} from "./CLItem";
import {ABDocument} from "../documents/Document";
import React from "react";
import {Button, Form, FormControl, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faCheck, faCheckDouble, faPaperclip, faQuestion, faTimes} from "@fortawesome/free-solid-svg-icons";
import {DocumentCard} from "../documents/DocumentCard";
import {nl2p} from "../../utils/Nl2Br";

type CheckListValidateState = {
  validateButtonStatus: SubmitStatus;
  validation?: boolean;
  justification: string;
}

type CheckListValidateProps = {
  edit: CLItem;
  onSign: (doc: ABDocument) => void;
  onHide: () => void;
  validate: (item: CLItem, validation: boolean, justification?: string) => Promise<void>;
}

export class CheckListValidate extends React.Component<CheckListValidateProps, CheckListValidateState> {
  state: CheckListValidateState = {
    validateButtonStatus: SubmitStatus.enabled,
    justification: '',
  }

  render(): JSX.Element {
    const {edit} = this.props;
    return (
      <Form onSubmit={(e) => this.handleValidate(e)}>
        <Modal.Body>
          <div className="mb-3 bg-muted p-2 border border-dark">
            <div className="text-danger">
              {nl2p(edit.warning)}
            </div>
            <Form.Group className="mb-3">
              <Form.Label>
                <FontAwesomeIcon icon={faAlignLeft}/> Commentaire
              </Form.Label>
              <Form.Control
                as="textarea"
                className="bg-transparent"
                value={edit.proposition}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label><FontAwesomeIcon icon={faPaperclip}/> Document joint</Form.Label>
              {edit.document ? (typeof edit.document === 'number' ? `Document ${edit.document} non visible` :
                    <DocumentCard
                      className="mb-3"
                      doc={edit.document}
                      actions={{
                        onSign: () => typeof edit.document === 'object' && this.props.onSign(edit.document),
                      }}
                    />
                ) :
                <div className={"text-danger"}>Pas de document joint</div>
              }
              <Form.Text className={"text-danger"}>{edit.docWarning()}</Form.Text>
            </Form.Group>
          </div>
          <Form.Group className="mb-3">
            <Form.Label><FontAwesomeIcon icon={faQuestion}/> Décision</Form.Label>
            <Form.Check>
              <Form.Check.Input
                type={"radio"}
                onClick={() => this.setState({validation: true})}
                checked={this.state.validation === true}
              />{" "}
              <Form.Check.Label className={"text-success"}>
                <FontAwesomeIcon icon={faCheckDouble}/> Valider
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Input
                type={"radio"}
                onClick={() => this.setState({validation: false})}
                checked={this.state.validation === false}/>{" "}
              <Form.Check.Label className={"text-danger"}>
                <FontAwesomeIcon icon={faTimes}/> Refuser
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Form.Group className={"mb-3"}>
            <Form.Label>Justification du refus</Form.Label>
            <FormControl
              as="textarea"
              disabled={this.state.validation !== false}
              placeholder={"Expliquez les raisons du refus"}
              value={this.state.justification}
              onChange={(e) => this.setState({
                justification: e.currentTarget.value
              })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="ms-1"
            type="reset"
            variant="secondary"
            onClick={() => this.props.onHide()}>
            Fermer
          </Button>
          <SubmitButton
            submitstatus={this.state.validateButtonStatus}
            disabled={this.state.validation === undefined ||
            (!this.state.validation && !this.state.justification?.trim().length)}
            icon={<FontAwesomeIcon icon={faCheck}/>}
          >
            Ok
          </SubmitButton>
        </Modal.Footer>
      </Form>
    );
  }

  private handleValidate(e: React.FormEvent) {
    e.preventDefault();
    if (this.state.validation !== undefined) {
      this.setState({validateButtonStatus: SubmitStatus.working});
      this.props.validate(this.props.edit, this.state.validation, this.state.justification)
        .then(() => this.setState({validateButtonStatus: SubmitStatus.enabled}));
    }
  }

}