import {Projet} from "../projets/Projet";
import * as immutable from "immutable";
import {filterStatus} from "../../utils/FetchError";
import {Credentials} from "../../utils/auth";
import {CLItem} from "../checklist/CLItem";

interface IOptionChantier {
  optionId: number,
  projetId: number,
  name: string,
  category: string,
  price: number,
  unite: string,
  eval: number,
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export class OptionChantier implements IOptionChantier {
  optionId: number;
  projetId: number;
  name: string;
  category: string;
  price: number;
  unite: string;
  eval: number;

  constructor(oc: IOptionChantier) {
    this.optionId = oc.optionId;
    this.projetId = oc.projetId;
    this.name = oc.name;
    this.category = oc.category;
    this.price = oc.price;
    this.unite = oc.unite;
    this.eval = oc.eval;
  }

  static list(projet: Projet): Promise<immutable.OrderedMap<string, immutable.Map<number, OptionChantier>>> {
    return fetch(`${backend}/projet/${projet.projetId}/options`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((r: { category: string, options: IOptionChantier[] }[]) =>
        immutable.OrderedMap(
          r.map(({category, options}) =>
            [category, immutable.Map(options.map(o => [o.optionId, OptionChantier.parse(o)]))]
          )
        )
      );
  }


  estimate(): number {
    return this.price * this.eval;
  }

  static parse(json: any) {
    return new OptionChantier({
      ...json,
      selected: json.selected === undefined ? null : json.selected
    });
  }

  save(credentials: Credentials): Promise<OptionChantier> {
    const body = JSON.stringify(this);
    return fetch(`${backend}/option`, {
      credentials: 'include',
      method: 'PUT',
      headers: credentials.headers({"Content-Type": "application/json"}),
      body
    })
      .then(filterStatus)
      .then(r => r.json())
      .then(o => this.updated(o));
  }


  updated(changes: Partial<IOptionChantier>) {
    return new OptionChantier({...this, ...changes});
  }

  static checkListItem(projet: Projet): Promise<CLItem> {
    return fetch(`${backend}/projet/${projet.projetId}/optionCheckList`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then(CLItem.parse)

  }

  static validate(credentials: Credentials, projet: Projet): Promise<CLItem> {
    return fetch(
      `${backend}/projet/${projet.projetId}/validateOptions`,
      {
        credentials: 'include',
        method: 'PUT',
        headers: credentials.headers()
      })
      .then(filterStatus)
      .then(r => r.json())
      .then(CLItem.parse)
  }
}