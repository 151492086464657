import React, {Fragment} from "react";
import {RGBColor} from "../../utils/colors";
import {UserBadge} from "../user-profile-lite/UserBadge";
import classnames from "classnames";
import {Task, WeekTask} from "./Task";
import {ABDocument} from "../documents/Document";
import {Credentials, GRANTS} from "../../utils/auth";
import {addDays, addHours, differenceInDays, isAfter, isBefore, isSameDay} from "date-fns";
import {formatDate} from "../../utils/format";
import {faCamera, faCheck, faEye, faMapMarker} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type WeekProps = {
  plannedWeek: number;
  plannedYear: number;
  plan: WeekTask[];
  selectEvent: (e: Task) => void;
  hoverEvent: (e?: number) => void;
  hoveredEvent?: number;
  credentials: Credentials;
  showProjet: boolean;
};

type WeekState = Record<string, never>;

export class Week extends React.Component<WeekProps, WeekState> {

  static OVER = new RGBColor(255, 24, 73);
  static ALERT = new RGBColor(255, 210, 0);
  static CLOTURE = new RGBColor(233, 236, 239);

  private static getDateOfISOWeek(w: number, y: number): Date {
    //if (w && y) {
    const simple = new Date(y, 0, 1 + (w - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - dow + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - dow);
    return ISOweekStart;
    //}
  }

  render() {
    const {plannedWeek, plannedYear, plan, selectEvent, hoverEvent, hoveredEvent, showProjet} = this.props;

    const sow = Week.getDateOfISOWeek(plannedWeek, plannedYear);
    const eow = addDays(sow, 5);

    const days: string[] = [];
    for (let day = sow; isBefore(day, eow); day = addDays(day, 1)) {
      days.push(formatDate(day, date => date.getDate() === 1 ? "EEE do MMM" : "EEE d MMM"));
    }

    return (
      <table className="w-100" style={{borderCollapse: "separate", borderSpacing: 0}}>
        <thead>
        <tr>{days.map(d => <th key={d}>{d}</th>)}</tr>
        </thead>
        <tbody>
        {plan.sort((e1: WeekTask, e2: WeekTask) => e1.task.eventId - e2.task.eventId).map((e: WeekTask) => {
          const eventId = e.task.eventId;
          const before = differenceInDays(e.weekStart, sow);
          const length = 1 + differenceInDays(e.weekEnd, e.weekStart);

          const endsLeft = !e.openLb && isSameDay(e.lb, e.weekStart);
          const endsRight = !e.openUb && isSameDay(e.ub, e.weekEnd);

          const isHovered = hoveredEvent === eventId;

          const background = [];
          let border: undefined | RGBColor = undefined;

          if (e.over) {
            border = Week.OVER;
          } else if (e.isAlert) {
            border = Week.ALERT;
          } else if (e.task.cloture) {
            background.push(`repeating-linear-gradient(45deg,
                            transparent,
                            transparent 10px,
                            ${Week.CLOTURE.hoverable(isHovered).toCSS()} 10px,
                            ${Week.CLOTURE.hoverable(isHovered).toCSS()} 20px)`);
          }


          const duration = differenceInDays(e.ub, e.lb);
          const start = differenceInDays(e.weekStart, e.lb) / duration;
          const end = differenceInDays(e.weekEnd, e.lb) / duration;

          let percents = [0, 1];

          let gradient: RGBColor[];
          const color = e.task.color;
          if (e.openLb) {
            if (e.openUb) {
              const halfWay = addHours(e.lb, duration * 12);
              if (isBefore(e.weekEnd, halfWay)) {
                gradient = [
                  RGBColor.WHITE.mean(color, 2 * start).hoverable(isHovered),
                  RGBColor.WHITE.mean(color, 2 * end).hoverable(isHovered)
                ];
              } else if (isAfter(e.weekStart, halfWay)) {
                gradient = [
                  color.mean(RGBColor.WHITE, 2 * start - 1).hoverable(isHovered),
                  color.mean(RGBColor.WHITE, 2 * end - 1).hoverable(isHovered)
                ];
              } else {
                gradient = [
                  RGBColor.WHITE.mean(color, 2 * start).hoverable(isHovered),
                  color.hoverable(isHovered),
                  color.mean(RGBColor.WHITE, 2 * end - 1).hoverable(isHovered)
                ];
                percents = [0, start + end, 1];
              }
            } else {
              gradient = [
                RGBColor.WHITE.mean(color, start).hoverable(isHovered),
                RGBColor.WHITE.mean(color, end).hoverable(isHovered)
              ];
            }
          } else if (e.openUb) {
            gradient = [
              color.mean(RGBColor.WHITE, start).hoverable(isHovered),
              color.mean(RGBColor.WHITE, end).hoverable(isHovered)
            ];
          } else {
            gradient = [
              color.hoverable(isHovered),
              color.hoverable(isHovered)
            ];
          }


          background.push(`linear-gradient(90deg, ${gradient.map((c, i) => `${c.toCSS()} ${percents[i] * 100}%`).join(", ")})`);

          let meanBgColor: RGBColor = gradient[0].mean(gradient[1], .3);
          if (e.task.cloture) {
            meanBgColor = meanBgColor.mean(Week.CLOTURE.hoverable(isHovered), .5);
          }

          const textColor = meanBgColor.textColor().toCSS();

          return (
            <tr key={eventId} id={isSameDay(e.lb, e.weekStart) ? `e${eventId}` : undefined}>
              {before > 0 && <td colSpan={before}/>}
              {length > 0 &&
              <td onMouseEnter={() => hoverEvent(eventId)}
                  onMouseLeave={() => hoverEvent()}
                  onClick={() => selectEvent(e.task)}

                  className={classnames("p-2",
                    {
                      "plan-rounded-left": endsLeft,
                      "plan-rounded-right": endsRight
                    }
                  )}
                  colSpan={length} style={{
                background: background.join(", "),
                color: textColor,
                border: border ? `5px solid ${border.toCSS()}` : '1 px solid #FFFFFF',
              }}>
                  <Desc event={e.task} meanBgColor={meanBgColor}
                        showItv={!this.props.credentials.is(e.task.affectation)}
                        showProjet={showProjet} showEyes={this.props.credentials.in(GRANTS.resp)}/>
              </td>
              }

            </tr>);
        })
        }
        </tbody>
      </table>);
  }


}


type DescProps = {
  event: Task;
  meanBgColor: RGBColor;
  showItv: boolean;
  showProjet: boolean;
  showEyes: boolean;
}

type DescState = { [key: string]: never }

export class Desc extends React.Component<DescProps, DescState> {

  render(): JSX.Element {
    const {event, meanBgColor, showProjet, showEyes} = this.props;

    return (<>
      {event.tag &&
      <span className="fa-layers fa-fw mx-2">
                    <FontAwesomeIcon icon={faMapMarker} size={"2x"} transform={"left-1"}/>
                    <span className="fa-layers-text" data-fa-transform="shrink-10 down-2" style={{
                      color: meanBgColor.toCSS(),
                    }}>
                        {event.tag}
                    </span>
                </span>
      }
      {event.title}
      {
        showProjet &&
        <> | {event.project!.title}</>
      }

      {event.affectation && <> |
          <UserBadge key={event.affectation.userId}>{event.affectation}</UserBadge>
      </>
      }
      {event.photos.map((p: ABDocument) =>
        <Fragment key={p.documentId}> | <FontAwesomeIcon icon={faCamera} size={"lg"}/></Fragment>)
      }

      {showEyes && event.visibleClient && <> | <FontAwesomeIcon icon={faEye} size={"lg"}/></>}

      {event.effectue && <> | <FontAwesomeIcon
          icon={faCheck} size={"lg"} className="text-success-light"/> le {formatDate(event.effectue)} </>}
    </>);
  }
}
