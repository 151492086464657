import {Card, Container, Form} from "react-bootstrap";
import {WaitForAsync} from "../utils/Loading";
import {fetchEntreprises, Projet} from "../components/projets/Projet";
import {useState} from "react";
import {useAsync} from "react-async-hook";
import {DateFilter, Filter} from "../utils/DateFilter";
import {set} from "date-fns";
import {Ventes} from "../components/stats/Ventes";
import {PlanDeCharge} from "../components/stats/PlanDeCharge";
import {fetchFacturations} from "../components/facturation/Facturation";
import {VolumeFacturation} from "../components/stats/VolumeFacturation";
import {ProjetForm} from "../components/projets/ProjetForm";
import {
  loadDonneesCommerciales,
  loadProjetCommercial,
  ProjetCommercial,
  ProjetTuple,
  saveProjetCommercial
} from "../components/projets/ProjetCommercial";
import {AllUsers} from "../utils/AllUsers";
import {useAuth} from "../utils/auth";
import {flatMapRight, SubmitError} from "../utils/FetchError";
import {Either} from "monet";
import { Marges } from "../components/stats/Marges";

export function StatsView() {
  const entreprises = useAsync(() => fetchEntreprises().then(ets => ets.filter(e => e.entrepriseId !== 4)), []);
  const [editProjet, setEditProjet] = useState<Projet | undefined>();
  const [editProjetCommercial, setEditProjetCommercial] = useState<ProjetCommercial | undefined>();

  const [filter, setFilter] = useState<Filter>({
    from: set(new Date(), {month: 0, date: 1}),
    to: set(new Date(), {month: 11, date: 31}),
    nulls: true,
  });

  const projets = useAsync(() => loadDonneesCommerciales(filter, true, undefined), [filter])

  const facturations = useAsync(() => fetchFacturations(true), []);


  const userList = useAsync(AllUsers.loadUsers, []);

  const credentials = useAuth();

  const saveProjet = (projet: Projet, pc?: ProjetCommercial) =>
    projet.saveProjet(credentials)
      .then(response => {
        if (pc) {
          return flatMapRight(response, projet =>
            saveProjetCommercial({...pc, projetId: projet.projetId}, credentials)
          )
            .then(savedPcEither => savedPcEither.map(savedPC => ({projet, pc: savedPC})));
        } else {
          return new Promise<Either<SubmitError, ProjetTuple>>(resolve =>
            resolve(response.map(projet => ({projet, pc: undefined})))
          );
        }
      })
      .then(save => {

        save.forEach(({projet, pc}: ProjetTuple) => {
          facturations.execute();
          // Triggers reload ventes
          projets.execute();
        });

        return save;
      });

  const onEditProjet = (projet: Projet) => {
    setEditProjet(projet);
    loadProjetCommercial(projet.projetId)
      .then(pc => setEditProjetCommercial(pc))
  }


  return <Container fluid className="main-content-container p-4">
    <Card className={"mb-3"}>
      <Card.Body>
        <Form.Group className="mb-3">
          <Form.Label>Période :</Form.Label>
          <DateFilter
            onChange={setFilter}
            filter={filter}
            handleNulls
          />
        </Form.Group>
      </Card.Body>
    </Card>
    <WaitForAsync async={entreprises}>{entreprises => <>
      <WaitForAsync async={projets}>{projets =>
        <Ventes
          entreprises={entreprises}
          projets={projets}
          onEditProjet={(projet) => onEditProjet(projet)}
        />
      }</WaitForAsync>
      <WaitForAsync async={facturations}>{facturations => <>
        <PlanDeCharge
          entreprises={entreprises}
          facturations={facturations}
          onEditProjet={(projet) => onEditProjet(projet)}
        />
        <VolumeFacturation
          filter={filter}
          entreprises={entreprises}
          facturations={facturations}
        />
      </>}</WaitForAsync>
      <WaitForAsync async={projets}>{projets =>
        <Marges
          entreprises={entreprises}
          projets={projets}
          onEditProjet={(projet) => onEditProjet(projet)}
        />
      }</WaitForAsync>
    </>}</WaitForAsync>

    {editProjet && <WaitForAsync async={userList}>{userList =>
      <ProjetForm updateCurrentProjet={(editProjet?: Projet) => setEditProjet(editProjet)}
                  updateCurrentProjetCommercial={(editProjetCommercial?: ProjetCommercial) =>
                    setEditProjetCommercial(editProjetCommercial)}
                  projetCommercial={editProjetCommercial}
                  projet={editProjet}
                  credentials={credentials}
                  save={(projet: Projet, pc?: ProjetCommercial) => saveProjet(projet, pc)}
                  users={userList}/>
    }</WaitForAsync>}
  </Container>;
}