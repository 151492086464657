import {Entreprise, LogoEntreprise, Projet} from "../projets/Projet";
import {FacturationProjet, fetchStades} from "../facturation/Facturation";
import immutable, {Seq} from "immutable";
import {Button, Card, Collapse, Table} from "react-bootstrap";
import {DateFilter, Filter} from "../../utils/DateFilter";
import {VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryLine, VictoryTheme} from "victory";
import {useAsync} from "react-async-hook";
import {WaitForAsync} from "../../utils/Loading";
import {addMonths, addYears, isAfter, isBefore, startOfMonth} from "date-fns";
import {formatDate, formatEur} from "../../utils/format";
import {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

type VolumeFacturationProps = {
  entreprises: Entreprise[],
  facturations: FacturationProjet[],
  filter: Filter,
}

export function VolumeFacturation({entreprises, facturations, filter}: VolumeFacturationProps) {

  const [detail, setDetail] = useState(false);

  const stadeBefore = useAsync(async () =>
      fetchStades().then(stades => {
        let taux = 0;
        const before: [number, number][] = [];
        for (const s of stades) {
          before.push([s.taux, taux]);
          taux = s.taux;
        }
        return immutable.Map(before);
      })
    , [])

  return <Card>
    <Card.Header>Volume facturation</Card.Header>
    <Card.Body>
      <WaitForAsync async={stadeBefore}>{stadeBefore =>
        <Table>
          <thead>
          <tr>
            <th>
              <Button className="py-0" size="lg" variant="link" onClick={() => setDetail(!detail)}>
                <FontAwesomeIcon icon={detail ? faAngleUp : faAngleDown}/>
              </Button>
            </th>
            {entreprises.map(e => <th key={e.entrepriseId}>{e.nom}&nbsp;
              <LogoEntreprise>{e}</LogoEntreprise></th>)}
          </tr>
          <tr>
            <td/>
            {entreprises.map(e => {
              const total = Seq.Indexed(facturations)
                .filter(f => f.projet.entreprise?.entrepriseId === e.entrepriseId)
                .flatMap(p =>
                  p.facturations.valueSeq()
                    .filter(s =>
                      s.validDate && DateFilter.filterDate(filter, s.validDate)
                    )
                    .map(s => (p.prix ?? 0) * (s.taux - stadeBefore.get(s.taux, s.taux)))
                )
                .reduce((a, v) => a + v, 0);

              return <td key={e.entrepriseId}>Total = {formatEur(total)}</td>;
            })}
          </tr>
          </thead>

          <Collapse in={detail}>
            <tbody>

            <tr>
              <td/>
              {entreprises.map(e => {
                const fact = Seq.Indexed(facturations)
                  .filter(f => f.projet.entreprise?.entrepriseId === e.entrepriseId)
                  .flatMap(p =>
                    p.facturations.valueSeq()
                      .flatMap(s =>
                        s.validDate ? Seq.Indexed.of({
                            validDate: s.validDate,
                            facture: (p.prix ?? 0) * (s.taux - stadeBefore.get(s.taux, s.taux))
                          }) :
                          Seq.Indexed.of<{ validDate: Date, facture: number }>()
                      )
                  )


                let monthly = fact
                  .groupBy(f => startOfMonth(f.validDate))
                  .map(stades =>
                    stades.reduce((a, v) => a + v.facture, 0)
                  )

                const from = monthly.keySeq().min();
                const to = monthly.keySeq().max();
                if (from && to) {
                  for (let i = from; isBefore(i, to); i = addMonths(i, 1)) {
                    if (!monthly.has(i)) {
                      monthly = monthly.concat(immutable.Map([[i, 0]]));
                    }
                  }
                }

                const monthlyY1 = monthly
                  .filter((value, month) => DateFilter.filterDate(filter, month))

                const monthlyY0 = monthly
                  .mapKeys(month => addYears(month, 1))
                  .filter((value, month) => DateFilter.filterDate(filter, month))

                return <td key={e.entrepriseId}>
                  {/*{monthlyY1.sortBy((volume, month) => month).map((volume, month) => `${month}: ${volume}`).toString()}*/}
                  {/*{months}*/}
                  {!monthlyY1.isEmpty() &&
                      <VictoryChart
                          theme={VictoryTheme.material}
                          domainPadding={20}
                          height={250}
                      >
                          <VictoryAxis
                              tickValues={monthlyY0.concat(monthlyY1).keySeq().toArray()}
                              tickFormat={(x) => formatDate(x, () => "MMM yy")}
                              style={{tickLabels: {fontSize: 6, angle: -90}}}
                          />
                          <VictoryAxis
                              dependentAxis

                            // tickFormat specifies how ticks should be displayed
                              tickFormat={(y) => (`${y / 1000}k€`)}
                              style={{tickLabels: {fontSize: 8}}}
                          />

                          <VictoryGroup offset={8}>

                              <VictoryBar
                                  data={monthlyY1.toArray().map(([k, v]) => ({month: k, volume: v}))}
                                  barWidth={8}
                                  x={"month"}
                                  y={"volume"}
                              />
                              <VictoryBar
                                  data={monthlyY0.toArray().map(([k, v]) => ({month: k, volume: v}))}
                                  barWidth={8}
                                  x={"month"}
                                  y={"volume"}
                              />
                          </VictoryGroup>

                          <VictoryLegend
                              data={[
                                {name: "Volume année N-1", symbol: {fill: "gold"}},
                                {name: "Volume année N", symbol: {fill: "tomato"}},
                              ]}
                          />
                      </VictoryChart>
                  }

                  {!monthlyY1.isEmpty() &&
                      <VictoryChart
                          theme={VictoryTheme.material}
                          domainPadding={20}
                          height={250}
                      >
                          <VictoryAxis
                              tickValues={monthlyY0.concat(monthlyY1).keySeq().toArray()}
                              tickFormat={(x) => formatDate(x, () => "MMM yy")}
                              style={{tickLabels: {fontSize: 6, angle: -90}}}
                          />
                          <VictoryAxis
                              dependentAxis
                            // tickFormat specifies how ticks should be displayed
                              tickFormat={(y) => (`${y / 1000}k€`)}
                              style={{tickLabels: {fontSize: 8}}}
                          />
                          <VictoryGroup>

                              <VictoryLine
                                  data={monthlyY1.toArray().map(([k, v]) => ({month: k, volume: v}))}
                                  x={"month"}
                                  y={"volume"}
                              />
                              <VictoryLine
                                  data={monthlyY0.toArray().map(([k, v]) => ({month: k, volume: v}))}
                                  x={"month"}
                                  y={"volume"}
                              />

                          </VictoryGroup>

                      </VictoryChart>
                  }
                </td>

              })}
            </tr>
            </tbody>
          </Collapse>
        </Table>
      }</WaitForAsync>
    </Card.Body>
  </Card>;
}
