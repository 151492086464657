import React from "react";
import {JSONUser, User} from "../user-profile-lite/User";
import {Credentials} from "../../utils/auth";
import immutable from "immutable";
import {add, differenceInDays, format, formatISO, parseISO} from "date-fns";
import {Image} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {filterStatus, filterStatusEither, SubmitError} from "../../utils/FetchError";
import * as queryString from "query-string";
import {Either, Maybe} from "monet";
import {faExclamationCircle, faExclamationTriangle, faListAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface Entreprise {
  entrepriseId: number,
  nom: string,
  adresse: string,
  rcs: string,
  logo: string,
}

export const BadgeEntreprise: React.FunctionComponent<{ children: Entreprise }> = (props) => <>
  <LogoEntreprise>{props.children}</LogoEntreprise> {props.children.nom}
</>

export const LogoEntreprise: React.FunctionComponent<{ children: Entreprise }> =
  (props) =>
    <Image rounded
           src={`${process.env.REACT_APP_BACKEND_SERVER}/assets/images/${props.children.logo}`}
    />

const backend = process.env.REACT_APP_BACKEND_SERVER;

interface IProjet {
  deletable: boolean;
  projetId: number;
  termine?: Date;
  adresse: string;
  title: string;
  client?: User;
  description: string;
  responsable: User;
  ouverture?: Date;
  reception?: Date;
  entreprise?: Entreprise;
  dureePrevue?: number;
  signature?: Date;
}

export interface JSONProjet {
  deletable: boolean;
  projetId: number;
  termine?: string;
  adresse: string;
  title: string;
  client: JSONUser;
  description: string;
  responsable: JSONUser;
  ouverture?: string;
  reception?: string;
  entreprise?: Entreprise;
  dureePrevue?: number;
  signature?: string;
}

export class Projet implements IProjet {
  deletable: boolean;
  projetId: number;
  termine?: Date;
  adresse: string;
  title: string;
  client?: User;
  description: string;
  responsable: User;
  ouverture?: Date;
  reception?: Date;
  entreprise?: Entreprise;
  dureePrevue?: number;
  signature?: Date;

  constructor({
                deletable,
                projetId,
                termine,
                adresse,
                title,
                client,
                description,
                responsable,
                ouverture,
                reception,
                entreprise,
                dureePrevue,
                signature,
              }: IProjet) {
    this.deletable = deletable;
    this.projetId = projetId;
    this.termine = termine;
    this.adresse = adresse;
    this.title = title;
    this.client = client;
    this.description = description;
    this.responsable = responsable;
    this.ouverture = ouverture;
    this.reception = reception;
    this.entreprise = entreprise;
    this.dureePrevue = dureePrevue;
    this.signature = signature;
  }

  updated(change: Partial<Projet>) {
    return new Projet({...this, ...change});
  }

  static new(responsable: User): Projet {
    return new Projet({
      projetId: 0,
      adresse: '',
      title: '',
      description: '',
      deletable: true,
      responsable,
    });
  }


  end(): Maybe<Date> {
    return this.ouverture && this.dureePrevue ? Maybe.of(add(this.ouverture, {months: this.dureePrevue})) : Maybe.none();
  }

  static loadAll(selectFinished = false): Promise<immutable.Map<number, Projet>> {
    return fetch(
      `${backend}/projets?${queryString.stringify({selectFinished})}`,
      {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((rawProjets: JSONProjet[]) =>
        immutable.Map(
          rawProjets.map(p => [p.projetId, Projet.parse(p)])
        )
      );
  }

  static load(projetId: number, updateAccess = false) {
    return fetch(`${backend}/projet/${projetId}?${queryString.stringify({updateAccess})}`,
      {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then(Projet.parse);
  }

  static parse(projet: JSONProjet): Projet {
    return new Projet({
      ...projet,
      termine: projet.termine ? parseISO(projet.termine) : undefined,
      ouverture: projet.ouverture ? parseISO(projet.ouverture) : undefined,
      reception: projet.reception ? parseISO(projet.reception) : undefined,
      signature: projet.signature ? parseISO(projet.signature) : undefined,
      client: User.parse(projet.client),
      responsable: User.parse(projet.responsable),
    });
  }


  saveProjet(credentials: Credentials): Promise<Either<SubmitError, Projet>> {
    const sentProjet = this.toFormData();

    return fetch(`${backend}/projet/save`, {
      credentials: 'include',
      method: 'POST',
      headers: credentials.headers(),
      body: sentProjet
    })
      .then(r => filterStatusEither<JSONProjet>(r))
      .then(r => r.map(Projet.parse))
  }


  private toFormData(formData = new FormData()): FormData {
    formData.append("responsable", this.responsable.userId.toString());

    formData.append("projetId", this.projetId.toString());

    if (this.client)
      formData.append("client", this.client.userId.toString());

    if (this.termine)
      formData.append("termine", formatISO(this.termine, {representation: "date"}));

    if (this.entreprise)
      formData.append("entreprise", this.entreprise.entrepriseId.toString());


    if (this.signature)
      formData.append("signature", formatISO(this.signature, {representation: "date"}));


    if (this.ouverture)
      formData.append("ouverture", formatISO(this.ouverture, {representation: "date"}));

    if (this.reception)
      formData.append("reception", formatISO(this.reception, {representation: "date"}));

    formData.append("thisId", this.projetId.toString());
    formData.append("adresse", this.adresse);
    formData.append("title", this.title);
    formData.append("description", this.description);
    if (this.dureePrevue) {
      formData.append("dureePrevue", this.dureePrevue.toString());
    }


    return formData;
  }

  static loadStats(selectFinished: boolean, projetId?: number): Promise<immutable.Map<number, ProjetStats>> {
    return fetch(`${backend}/projets/stats?${queryString.stringify({
      projetId, selectFinished
    })}`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then(Projet.parseStats);
  }

  static parseStats(stats: { [key: string]: any }): immutable.Map<number, ProjetStats> {
    return immutable.Map(
      Object.entries(stats)
        .map(([projetId, stats]) => {

            const attStats = stats.attStats && immutable.Map(
              Object.entries(stats.attStats)
                .map(([k, v]) => [Number(k), Number(v)])
            );

            const fournStats = stats.fournStats && immutable.Map(
              Object.entries(stats.fournStats)
                .map(([k, v]) => [Number(k), Number(v)])
            );

            const checklist = new CLStats(stats.checklist);

            return [
              Number(projetId),
              {
                ...stats,
                eventsStats: immutable.Map(stats.eventsStats),
                attStats,
                fournStats,
                delaysMsgClient: stats.msgClient?.nbDelays,
                lastMsgClient: stats.msgClient?.last && parseISO(stats.msgClient.last),
                checklist
              }
            ];
          }
        )
    );
  }

  static latest(): Promise<Projet[]> {
    return fetch(`${backend}/projet/latest`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((r: JSONProjet[]) => r.map(Projet.parse));
  }

  recentlyUpdated(): Promise<[User, Date?][]> {
    return fetch(`${backend}/projet/${this.projetId}/recentlyUpdated`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((r: [JSONUser, string?][]) =>
        r.map(([u, t]) => [User.parse(u), t ? parseISO(t) : undefined])
      );
  }
}

export class CLStats {
  private readonly actionRequired: number;
  private readonly remaining: number;
  private readonly submitted: number;
  private readonly waitingSubmission?: Date;

  constructor(json: {
    actionRequired: number,
    remaining: number,
    submitted: number,
    waitingSubmission: string | null
  }) {
    this.actionRequired = json.actionRequired;
    this.remaining = json.remaining;
    this.submitted = json.submitted;
    this.waitingSubmission = json.waitingSubmission === null ? undefined : parseISO(json.waitingSubmission);
  }

  isWaiting(): boolean {
    return !!this.waitingSubmission && differenceInDays(new Date(), this.waitingSubmission) >= 2;
  }

  notif(): JSX.Element {
    if (this.submitted === 0) {
      return <FontAwesomeIcon icon={faListAlt} size="lg" className="text-danger"/>;
    } else if (this.isWaiting()) {
      return <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-warning"/>;
    } else if (this.actionRequired > 0) {
      return (
        <span className="fa-layers fa-fw fa-lg">
            <FontAwesomeIcon icon={faExclamationCircle} className="text-warning"/>
            <span className="fa-layers-counter bg-danger text-white"
                  style={{fontSize: "1.5em"}}>{this.actionRequired}</span>
          </span>
      );
    } else if (this.remaining > 0) {
      return (
        <span className="fa-layers fa-fw fa-lg">
            <FontAwesomeIcon icon={faListAlt} className="text-warning"/>
            <span className="fa-layers-counter bg-danger text-white"
                  style={{fontSize: "1.5em"}}>{this.remaining}</span>
          </span>
      );
    } else {
      return <FontAwesomeIcon icon={faListAlt} size="lg" className="text-success"/>;
    }
  }


}

export interface ProjetStats {
  satisfaction?: number;
  unreadStats: number;
  msgStats?: number;
  //questionsStats: immutable.Map<string, number>;
  eventsStats: immutable.Map<string, number>,
  attStats?: immutable.Map<number, number>,
  fournStats?: immutable.Map<number, number>,
  docStats: number;
  delaysMsgClient?: number,
  lastMsgClient?: Date,
  checklist?: CLStats,
}

export function totalStats(stats: immutable.Map<any, number>): number {
  let total = 0;
  for (const v of stats.values()) {
    total += v;
  }
  return total;
}

type OptionType = { value: Projet, label: JSX.Element, searchable: string };

export function projetSelect(p: Projet): OptionType ;
export function projetSelect(p: Projet | undefined): OptionType | undefined ;
export function projetSelect(p: Projet | undefined): OptionType | undefined {
  return p &&
    {
      value: p,
      label: <>{p.title} {p.client && <UserBadge civilite prenom={false}>{p.client}</UserBadge>}</>,
      searchable: p.title.toLocaleLowerCase() + (p.client && UserBadge.link(p.client).toLocaleLowerCase())
    };
}


export function fetchEntreprises(): Promise<Entreprise[]> {
  return fetch(`${backend}/entreprises`, {credentials: 'include'})
    .then(filterStatus)
    .then(response => response.json())
}