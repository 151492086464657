import React, {ReactNode} from "react";
import Immutable from "immutable";
import {Alert} from "react-bootstrap"

type AlertsProps = {
  alerts?: AlertData;
  update: (alerts: AlertData) => void
}

export interface AlertData {
  id: number;
  alerts: Immutable.Map<number, IAlert>;
}

export interface IAlert {
  theme: string;
  text: JSX.Element;
}

export default class Alerts extends React.Component<AlertsProps> {

  static add(alert: IAlert, setAlert: (a: AlertData) => void, componentAlerts?: AlertData): AlertData {
    const id = componentAlerts?.id || 0;
    const alerts: Immutable.Map<number, IAlert> = componentAlerts?.alerts || Immutable.Map();
    setTimeout(() =>
      setAlert(Alerts.dismiss(id, componentAlerts)), 10000);
    return {id: id + 1, alerts: alerts.set(id, alert)};
  }

  static dismiss(dismiss: number, alertsData?: AlertData): AlertData {
    const {id, alerts} = alertsData || {id: 0, alerts: Immutable.Map()};
    return {id, alerts: alerts.delete(dismiss)};
  }

  render(): ReactNode {
    const alerts = (this.props.alerts && this.props.alerts.alerts.toArray()) || [];
    return alerts.map(([id, {theme, text}]) =>
      <Alert className={`sticky-top alert-${theme}`}
             key={id}
             dismissible={true}
             onClose={() => this.props.update(Alerts.dismiss(id, this.props.alerts))}
      >{text}
      </Alert>
    );
  }

}