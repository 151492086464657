import * as React from "react";
import {Col} from "react-bootstrap";
import immutable from "immutable";
import {Attribution, Offre} from "./Attribution";
import {Corps} from "../user-profile-lite/Corps";
import {Projet} from "../projets/Projet";
import {AllUsers} from "../../utils/AllUsers";
import {GenerateContract} from "./GenerateContrat";
import {WithLoginProps} from "../../utils/auth";
import {AttributionListItem} from "./AttributionListItem";
import {Message} from "../messages/Message";
import {undefinedToArray} from "../../utils/miscellaneous";

type AttributionsListProps = WithLoginProps & {
  attributions: immutable.Map<number, Attribution>;
  corps: immutable.Map<number, Corps>;
  itvOptions: AllUsers;
  update: (attribution: Attribution) => Promise<unknown>;
  addLot: (lot: Corps) => void;
  delete: (attribution: Attribution) => Promise<unknown>;
  createEvent: (a: Attribution, ac: Corps) => Promise<unknown>;
  projet: Projet;
}

type AttributionsListState = {
  contract?: [Attribution, Offre];
}


export class AttributionsList extends React.Component<AttributionsListProps, AttributionsListState> {
  state: AttributionsListState = {};


  render() {
    const {attributions, corps, itvOptions} = this.props;
    if (attributions.isEmpty()) {
      return "Aucun lot à attribuer"
    } else {
      return <>
        {attributions.valueSeq()
          .sortBy(a => [a.lot, a.attributionId])
          .map(a => {
            const ac = corps.get(a.lot)!;

            return (
              <Col xl={6} sm={12} key={a.attributionId}>
                <AttributionListItem
                  corps={ac}
                  attribution={a}
                  update={(updated) => this.props.update(updated)}
                  onCreateEvent={() => this.props.createEvent(a, ac)}
                  onDelete={() => this.props.delete(a)}
                  onPrepareMail={() => this.prepareMail(a, ac)}
                  onGenerate={(selected) => this.setState({contract: [a, selected]})}
                  itvOptions={itvOptions}
                />
              </Col>

            );


          })}
        {this.state.contract &&
            <GenerateContract
                credentials={this.props.credentials}
                entreprise={this.props.projet.entreprise}
                attribution={this.state.contract[0]}
                offre={this.state.contract[1]}
                onHide={() => this.setState({contract: undefined})}
                corps={corps}
                itvOptions={itvOptions}
            />
        }
      </>
    }
  }

  private prepareMail(a: Attribution, c: Corps) {
    window.location.href = Message.prepare({
        recipient: a.offres.valueSeq()
          .flatMap(o => undefinedToArray(this.props.itvOptions.get(o.intervenantId)?.userId))
          .toArray(),
        subject: "Appel d'offre lot " + c.corps + " projet " + this.props.projet.title,
        content: a.detail,
        attachments: [],
      },
      this.props.projet);
  }

}