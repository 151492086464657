import {ABDocument, DocumentActions, DocumentReferences} from "./Document";
import {Loadable, waitFor} from "../../utils/Loading";
import React from "react";
import {Button, ButtonToolbar, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faCaretDown,
  faCaretUp,
  faDownload,
  faEdit,
  faEnvelope,
  faFileSignature,
  faPaintBrush,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {Message} from "../messages/Message";

type DocumentToolBarProps = {
  doc: ABDocument;
  actions: DocumentActions;
  toggleOpen: () => void;
  open: boolean;
  hover: boolean;
  children?: React.ReactNode;
};

type DocumentToolBarState = {
  references?: Loadable<DocumentReferences>;
}


export class DocumentToolBar extends React.Component<DocumentToolBarProps, DocumentToolBarState> {
  state: DocumentToolBarState = {};

  componentDidUpdate(prevProps: Readonly<DocumentToolBarProps>, prevState: Readonly<DocumentToolBarState>, snapshot?: any) {
    if (prevProps.doc !== this.props.doc) {
      this.setState({references: undefined});
    }
  }

  render() {
    const {doc, actions: {onEdit, onSketch, onSign, onDelete, onArchive}} = this.props;
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const original = `${backend}/documents/${doc.documentId}/download`;

    return waitFor(this.state.references, references => <>
        {references?.hasReferences() &&
        <Card.Body className="m-1 p-0">
            <h6 className="text-danger">Ce document ne peut pas être supprimé car il est référencé :</h6>
            <ul>
              {references.tasks.map(t => `Tâche ${t.title}`)
                .concat(references.signatures.map(s => `Signature de ${UserBadge.link(s.signer)}`))
                .concat(references.checkLists.map(i => `Item checklist ${i.name}`))
                .concat(references.dossiers.map(d => `Dossier facturation #${d.dossierId}`))
                .map((r, i) => <li key={i}>{r}</li>)
              }
            </ul>
        </Card.Body>
        }
        <ButtonToolbar
          className={classNames("mt-1 bg-transparent", !this.props.hover && "hidden")}>
          <div className="ms-auto"/>

          <Button onClick={() => this.props.toggleOpen()}
                  className="py-1 btn-light  px-1 btn-circle"
          >
            <FontAwesomeIcon icon={this.props.open ? faCaretUp : faCaretDown}/>
          </Button>
          <Button onClick={() => window.location.href = original}
                  className="py-1 btn-light  px-1 btn-circle"
          >
            <FontAwesomeIcon icon={faDownload} size={"lg"}/>
          </Button>
          {this.props.children}
          <Button
            onClick={() => window.location.href = Message.prepare({
              subject: doc.title,
              attachments: [doc.documentId]
            })}
            className="btn-light px-1 btn-circle"
          >
            <FontAwesomeIcon icon={faEnvelope} size={"lg"}/>
          </Button>

          <OverlayTrigger
            overlay={(doc.signatures.length > 0 || doc.mustSign.length > 0) ?
              <Tooltip id="tooltip-sign">
                {doc.signatures.length > 0 &&
                <p>Signé par {doc.signatures.map(s => UserBadge.link(s.signer)).join(", ")}</p>}
                {doc.mustSign.length > 0 && <p>Signatures
                    manquantes : {doc.mustSign.map(u => UserBadge.link(u)).join(", ")}</p>}
              </Tooltip> :
              <div/>
            }
          >
            <Button
              onClick={() => onSign(doc)}
              variant={doc.mustSign.length > 0 ? "outline-warning" : doc.signatures.length > 0 ? "outline-success" : "light"}
              className="mb-1 p-1 p-1 btn-circle"
            >
              <FontAwesomeIcon icon={faFileSignature} size={"lg"}/>
            </Button>
          </OverlayTrigger>


          {onSketch &&
          <Button
              onClick={() => onSketch(doc)}
              className="py-1 btn-light  px-1 btn-circle"
          >
              <FontAwesomeIcon icon={faPaintBrush} size={"lg"}/>
          </Button>
          }
          {onEdit &&
          <Button
              onClick={() => onEdit(doc)}
              className="py-1 btn-light  px-1 btn-circle"
          >
              <FontAwesomeIcon icon={faEdit} size={"lg"}/>
          </Button>
          }
          {onDelete &&
          <Button onClick={() => this.handleDelete()}
                  disabled={references?.hasReferences()}
                  className="btn-light  px-1 btn-circle">
              <FontAwesomeIcon icon={faTrash} size={"lg"}/>
          </Button>
          }
          {onArchive &&
          <Button onClick={() => onArchive(doc)}
                  className="btn-light  px-1 btn-circle">
              <FontAwesomeIcon icon={faArchive} size={"lg"}/>
          </Button>
          }
        </ButtonToolbar>
      </>
    );

  }

  private handleDelete() {
    const doc = this.props.doc;
    const onDelete = this.props.actions.onDelete!;
    doc.references().then(references => {
      if (references.hasReferences()) {
        this.setState({references});
      } else {
        onDelete(doc)
      }
    });
  }
}