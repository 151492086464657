import React from "react";
import {ListGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ChoixMateriauxItemProps = {
  active: boolean;
  children: { md: string, html: string };
  onClick?: () => void;
}

type ChoixMateriauxItemState = Record<string, never>

export class ChoixMateriauxItem extends React.Component<ChoixMateriauxItemProps, ChoixMateriauxItemState> {
  state: ChoixMateriauxItemState = {}

  render() {
    return <ListGroup.Item
      active={this.props.active}
      disabled={this.props.onClick === undefined}
      onClick={() => this.props.onClick && this.props.onClick()}
    >
      <FontAwesomeIcon className="align-middle"
                       listItem
                       style={{left: "0px", top: "22px"}}
                       icon={["far", this.props.active ? "dot-circle" : "circle"]}
      />
      <div dangerouslySetInnerHTML={{__html: this.props.children.html}}/>
    </ListGroup.Item>;
  }
}