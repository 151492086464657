import immutable, {Seq} from "immutable";
import {Credentials} from "../../utils/auth";
import {filterStatus} from "../../utils/FetchError";
import queryString from "query-string";
import {User} from "../user-profile-lite/User";

export interface Attribution {
  attributionId: number;
  projetId: number;
  lot: number;
  detail: string;
  statut: number;
  eventId?: number;
  selected?: number;
  offres: immutable.Map<number, Offre>;
  contrat?: number;
}

export interface AttributionOffreArray {
  attributionId: number;
  projetId: number;
  lot: number;
  detail: string;
  statut: number;
  eventId?: number;
  selected?: number;
  offres?: Offre[];
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export function attributionOffreArrayToMap(a: AttributionOffreArray, contacted: immutable.Set<number>): Attribution {
  return {
    ...a,
    offres: immutable.Map((a.offres || []).map(o =>
      [
        o.intervenantId,
        {...o, contacted: contacted.has(o.intervenantId)}
      ]
    ))
  };
}

export function attributionMapToArray(a: Attribution): AttributionOffreArray {
  return {...a, offres: a.offres.valueSeq().toArray()}
}

export interface Offre {
  intervenantId: number;
  offre?: string;
  contacted?: boolean;
}

export function deleteAttribution(credentials: Credentials, a: Attribution): Promise<Response> {
  return fetch(`${backend}/attributions/${a.attributionId}`, {
    credentials: "include",
    method: 'DELETE',
    headers: credentials.headers(),
  })
    .then(filterStatus)
}

export const AttributionStatus = immutable.Map([
  [0, "À consulter"],
  [1, "Consultation lancée"],
  [2, "Traité"],
  [3, "Sans objet"],
]);

export const ATTRIBUTION_SANS_OBJET = 3;

export type AttributionWithSelectedUser = Attribution & {
  selectedUser?: User
}

export function allAttributions(selectFinished: boolean): Promise<immutable.Map<number, immutable.Map<number, Seq.Indexed<AttributionWithSelectedUser>>>> {
  return fetch(`${backend}/projets/attributions?${queryString.stringify({selectFinished})}`, {credentials: "include"})
    .then(filterStatus)
    .then(r => r.json())
    .then((r: { [key: string]: AttributionWithSelectedUser[] }) => immutable.Map(
      Object.entries(r)
        .map(([projetId, attributions]) =>
          [Number(projetId), Seq.Indexed(attributions).groupBy(a => a.lot).map(c => c.valueSeq())]
        )
    ));
}