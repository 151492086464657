import {LastProjects} from "./components/layout/MainNavbar/LastProjects";
import {Projet, ProjetStats} from "./components/projets/Projet";
import {Credentials, GRANTS} from "./utils/auth";
import {SidebarItem} from "./components/layout/MainSidebar/SidebarNavItem";
import {Notif} from "./utils/Notif";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt, faChartLine,
  faCheckSquare,
  faFileAlt,
  faFileInvoice,
  faFileInvoiceDollar,
  faHandshake,
  faHardHat,
  faHistory,
  faKey,
  faMailBulk,
  faShoppingCart,
  faStar,
  faTable,
  faTasks,
  faUsers,
  faWarehouse,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {Constants, Dispatcher} from "./flux";
import {UserBadge} from "./components/user-profile-lite/UserBadge";

export function sidebarNavItems(credentials: Credentials, projet?: Projet, stats?: ProjetStats) {

  const common: SidebarItem[] = [];

  if (credentials.in(GRANTS.itv)) {
    common.push({
      title: "Planning prestataire",
      before: <FontAwesomeIcon listItem icon={faHardHat}/>,
      to: "/intervenants"
    });
  }

  if (credentials.in(GRANTS.itv, GRANTS.resp)) {
    common.push({
      title: "Contrats et factures",
      before: <FontAwesomeIcon listItem icon={faFileInvoiceDollar}/>,
      to: "/contrats-factures"
    });
  }

  if (credentials.in(GRANTS.itv) && credentials.grants() !== GRANTS.admin) {
    common.push({
      title: "Mes attestations",
      before: <FontAwesomeIcon listItem icon={faFileInvoice}/>,
      to: "/mon-administratif"
    });
  }

  common.push({
    title: "Parapheur",
    before: <FontAwesomeIcon listItem icon={faKey}/>,
    to: "/keys"
  })

  if (credentials.in(GRANTS.resp)) {
    common.push({
      title: "Utilisateurs",
      before: <FontAwesomeIcon listItem icon={faUsers}/>,
      to: "/user-profile-lite",
    });
  }

  if (credentials.in(GRANTS.resp)) {
    common.push({
      title: "Facturation",
      before: <FontAwesomeIcon listItem icon={faFileInvoiceDollar}/>,
      to: "/facturation"
    });
  }

  if (credentials.in(GRANTS.admin)) {
    common.push({
      title: "Stats",
      before: <FontAwesomeIcon listItem icon={faChartLine}/>,
      to: "/stats"
    });
  }

  // if (credentials.in(GRANTS.resp)) {
  //   common.push({
  //     title: "Récap",
  //     before: <FontAwesomeIcon listItem icon={faTable}/>,
  //     to: "/recap"
  //   });
  // }

  common.push({
    title: <>
      Projets
      <Button variant={"link py-0"} onClick={(e) => {
        e.preventDefault();
        Dispatcher.dispatch({
          actionType: Constants.TOGGLE_HISTORY
        });
      }}>
        <FontAwesomeIcon icon={faHistory}/>
      </Button>
      <p className={"mb-0"}>
        {projet ? <>{projet.title}<span className="d-none d-md-inline"> −&nbsp;
          <UserBadge civilite prenom={false}>{projet.client!}</UserBadge></span></> : "Pas de projet sélectionné"}
      </p>
    </>,
    to: "/",
    before: <FontAwesomeIcon listItem icon={faWrench}/>,
    after: <LastProjects projet={projet}/>
  });

  if (projet) {
    common.push({
      title: "Documents",
      before: <FontAwesomeIcon listItem icon={faFileAlt}/>,
      to: "/projet/documents",
      notif: stats && <Notif theme="info">{stats.docStats}</Notif>,
      indent: 1
    });

    common.push({
      title: "Messages",
      before: <FontAwesomeIcon listItem icon={faMailBulk}/>,
      to: "/projet/messages",
      notif: stats && <Notif theme="danger">{stats.unreadStats}</Notif>,
      indent: 1
    });

    common.push({
      title: "Planning projet",
      to: "/projet/planning",
      before: <FontAwesomeIcon listItem icon={faCalendarAlt}/>,
      notif: stats && <>
          <Notif theme="danger">
            {stats.eventsStats.get("late")}
          </Notif>
          <Notif theme="warning">
            {stats.eventsStats.get("open")}
          </Notif>
      </>,
      indent: 1
    });

    if (credentials.in(GRANTS.std, GRANTS.resp)) {
      common.push({
        title: "Ma satisfaction",
        to: "/projet/notation",
        before: <FontAwesomeIcon listItem icon={faStar}/>,
        indent: 1
      });
    }

    if (credentials.isAdmin()) {
      common.push({
        title: "Attributions",
        before: <FontAwesomeIcon listItem icon={faHandshake}/>,
        to: "/projet/attributions",
        notif: stats?.attStats && <>
            <Notif theme="danger">
              {stats.attStats.get(0)}
            </Notif>
            <Notif theme="warning">
              {stats.attStats.get(1)}
            </Notif>
        </>,
        indent: 1
      });
      common.push({
        title: "Fournitures",
        before: <FontAwesomeIcon listItem icon={faShoppingCart}/>,
        to: "/projet/fournitures",
        notif: stats?.fournStats && <>
            <Notif theme="danger">
              {stats.fournStats.get(0)}
            </Notif>
            <Notif theme="warning">
              {stats.fournStats.get(1)}
            </Notif>
        </>,
        indent: 1
      });
    }
    if (credentials.in(GRANTS.std, GRANTS.resp)) {
      common.push({
        title: "Checklist",
        before: <FontAwesomeIcon listItem icon={faTasks}/>,
        to: "/projet/checklist",
        notif: stats?.checklist && stats.checklist.notif(),
        indent: 1
      });
      common.push({
        title: "Options",
        before: <FontAwesomeIcon listItem icon={faCheckSquare}/>,
        to: "/projet/options",
        // notif: stats &&
        //     <Notif theme={ProjetsList.clTheme(stats.checklist.status)} tooltip="À faire">
        //       {stats.checklist.todo}
        //     </Notif>
        indent: 1
      });

      common.push({
        title: "Choix matériaux",
        before: <FontAwesomeIcon listItem icon={faWarehouse}/>,
        to: "/projet/choix-materiaux",
        indent: 1
      });

    }

  }

  return common;
}
