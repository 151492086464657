import React, {ReactNode} from "react";
import {Col, ListGroup} from "react-bootstrap";
import {CLItem} from "./CLItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";

type CheckListItemProps = {
  item: CLItem,
  onClick: () => void,
}

export class CheckListItem extends React.Component<CheckListItemProps> {
  render(): ReactNode {
    const {item} = this.props;
    return (
      <ListGroup.Item action={item.validate === undefined}
                      variant={item.variant()}
                      onClick={this.props.onClick}
                      className={"d-flex rounded mb-1 text-semibold align-middle"}>
        <Col xs="auto" className="align-middle text-center" style={{minWidth: "40px"}}>
          <FontAwesomeIcon  {...item.bullet()} />
        </Col>
        <Col xs="auto">
          {item.name}
        </Col>
        <Col xs="auto" className="ms-auto text-end">
          {item.statusString()}{' '}
          {item.document && <FontAwesomeIcon icon={faPaperclip} />}
        </Col>
      </ListGroup.Item>
    );
  }

}