import {Button, ButtonToolbar, Card, Container, Row} from "react-bootstrap";
import {Loadable, LOADING, waitFor, WaitForAsync} from "../utils/Loading";
import {filterStatus} from "../utils/FetchError";
import immutable, {Seq} from "immutable";
import {
  deleteFourniture,
  Fourniture,
  loadFournitures,
  loadMateriaux,
  Materiau
} from "../components/fournitures/Fourniture";
import {useProject} from "../utils/SelectedProject";
import {useAuth} from "../utils/auth";
import FournituresList from "../components/fournitures/FournituresList";
import FournituresAdd from "../components/fournitures/FournituresAdd";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {formatISO} from "date-fns";


export function FournituresView() {
  const [fournitures, setFournitures] = useState<Loadable<immutable.Map<number, Fourniture>>>(LOADING);
  const allMateriaux = useAsync(loadMateriaux, []);
  const [addedMateriaux, setAddedMateriaux] = useState<immutable.Map<number, Materiau> | undefined>();
  const credentials = useAuth();
  const [projet] = useProject();

  useEffect(() => {
    if (projet) {
      loadFournitures(projet)
        .then(fournitures => {
          const map = immutable.Map(fournitures.map(a => [
            a.fournitureId,
            a
          ]));
          setFournitures(map)
        })
        .catch((error) => setFournitures(error));
    }

  }, [projet]);


  const addFournitures = (addFs: immutable.Seq.Indexed<Materiau>) => {
    const data = new FormData();
    if (projet)
      data.append("projetId", projet.projetId.toString());

    for (const a of addFs) {
      data.append("materiaux[]", a.materiauId.toString());
    }

    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(`${backend}/fournitures/add`, {
      credentials: "include",
      method: 'POST',
      headers: credentials.headers(),
      body: data
    })
      .then(filterStatus)
      .then(response => response.json())
      .then((fs: Fourniture[]) => {
          if (immutable.isImmutable(fournitures)) {
            setFournitures(fournitures.merge(
              fs.map(a => [a.fournitureId, a])
            ));
          }
        }
      )
  }


  const delFourniture = (fournitures: immutable.Map<number, Fourniture>, fourniture: Fourniture) => {
    return deleteFourniture(credentials, fourniture)
      .then(() =>
        setFournitures(fournitures.remove(fourniture.fournitureId))
      );
  }

  const update = (fournitures: immutable.Map<number, Fourniture>, fourniture: Fourniture) => {
    const data = JSON.stringify({
      ...fourniture,
      commande: fourniture.commande && formatISO(fourniture.commande, {representation: "date"})
    });
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const headers = credentials.headers();
    headers.append("Content-Type", "application/json");

    return fetch(`${backend}/fournitures/update`, {
      credentials: "include",
      method: 'POST',
      headers: headers,
      body: data
    })
      .then(filterStatus)
      .then(() => {
          setFournitures(fournitures.set(fourniture.fournitureId, fourniture));
        }
      )
      .catch((error) => setFournitures(error));
  }


  return (
    <Container fluid className="main-content-container px-4 py-4">
      {addedMateriaux &&
          <FournituresAdd
              materiaux={addedMateriaux.valueSeq()}
              addFournitures={(materiaux) => addFournitures(materiaux)}
              removeMateriau={(materiau) =>
                addedMateriaux &&
                setAddedMateriaux(addedMateriaux.remove(materiau.materiauId))
              }
              onHide={() => setAddedMateriaux(undefined)}
          />
      }

      <Card>
        <Card.Body>
          <Row>
            {
              waitFor(fournitures, (fournitures: immutable.Map<number, Fourniture>) =>
                <WaitForAsync async={allMateriaux}>{materiaux =>
                  <FournituresList
                    fournitures={fournitures}
                    materiaux={materiaux}
                    update={(a: Fourniture) => update(fournitures, a)}
                    addMateriau={(m: Materiau) => addFournitures(Seq([m]))}
                    delete={(a: Fourniture) => delFourniture(fournitures, a)}
                  />
                }</WaitForAsync>
              )
            }
          </Row>
        </Card.Body>
      </Card>
      <div className="button-placeholder"/>
      <WaitForAsync async={allMateriaux}>{allMateriaux =>
        <ButtonToolbar className="button-fixed">
          <Button className="text-center btn-success btn-circle ms-auto m-2 btn-xl"
                  style={{lineHeight: ".6em"}}
                  onClick={() => setAddedMateriaux(allMateriaux)}>
            <FontAwesomeIcon icon={faCartPlus}/>
          </Button>
        </ButtonToolbar>
      }</WaitForAsync>
    </Container>);


}