import {Fragment, useState} from "react";
import {Card, Container} from "react-bootstrap";
import Alerts, {AlertData} from "../utils/Alerts";
import Documents from "../components/documents/Documents";
import {WaitForAsync} from "../utils/Loading";
import {ABDocument} from "../components/documents/Document";
import {useAuth} from "../utils/auth";
import immutable from "immutable";
import {useProject} from "../utils/SelectedProject";
import {Either} from "monet";
import {useAsync} from "react-async-hook";


export function DocumentsView() {
  const [projet] = useProject();
  const credentials = useAuth();
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const documents = useAsync<immutable.Map<number, ABDocument>>(async () => {
    if (projet) {
      return ABDocument.loadProjet(projet.projetId, true)
        .then((documents) =>
          immutable.Map(documents.map(d => [d.documentId, d]))
        )
    } else {
      return new Promise(r => r(immutable.Map()))
    }
  }, [projet]);


  const handleDelete = (document: ABDocument) =>
    document.delete(credentials)
      .then(() => documents.execute(projet))
      .then(() => {return});

  const updateAlerts = (res: Either<{ [p: string]: string }, ABDocument[]>) => {
    if (res.isLeft()) {
      setAlerts(Alerts.add(
        {theme: "danger", text: <>Vérifiez vos entrées</>},
        (alerts) => setAlerts(alerts),
        alerts
      ));
    }

    res.forEach(r =>
      setAlerts(Alerts.add(
          {
            theme: "success",
            text: <>Nouveau(x) fichier(s) {r.map(d => d.toString()).join(" ")} enregistré(s)</>
          },
          (alerts) => setAlerts(alerts),
          alerts
        )
      )
    )
  }


  let chat;
  if (projet) {
    chat = <WaitForAsync async={documents}>{(docs: immutable.Map<number, ABDocument>) =>
      <Documents
        onDelete={(doc) => handleDelete(doc)}
        updateAlerts={(res) => updateAlerts(res)}
        updateDocs={(d) => documents.execute(projet)}
        documents={docs.valueSeq().toArray()}
        projet={projet}
        credentials={credentials}
      />
    }</WaitForAsync>;
  } else {
    chat = <Card><Card.Body>Sélectionnez un projet</Card.Body></Card>;
  }

  return (
    <Fragment>
      <Alerts
        alerts={alerts}
        update={(newAlerts) => setAlerts(newAlerts)}/>
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {chat}

      </Container>
    </Fragment>
  );


}

