import React, {createContext, useContext} from "react";
import FetchError, {filterStatus} from "./FetchError";
import {User} from "../components/user-profile-lite/User";
import {Seq} from "immutable";
import {useAsync} from 'react-async-hook';
import {ABLoading} from "./Loading";
import EmptyLayout from "../layouts/EmptyLayout";
import Errors from "../views/Errors";
import querystring from "query-string";

export const authContext = createContext<Credentials | Error>(new Error("Default value"));

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth(props: { children: React.ReactNode }) {
  const auth = useAsync(fetchCredentials, []);
  if (auth.loading) {
    return <ABLoading/>;
  }
  if (auth.error) {
    return <authContext.Provider value={auth.error}>{props.children}</authContext.Provider>;
  }
  if (auth.result) {
    return <authContext.Provider value={auth.result}>{props.children}</authContext.Provider>;
  }
  return null;
}

export function isAuth(credentials: Credentials | Error): credentials is Credentials {
  return credentials instanceof Credentials;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth: () => Credentials = () => {
  const credentials = useContext(authContext);
  if (isAuth(credentials)) {
    return credentials;
  } else {
    throw credentials;
  }
};


const backend = process.env.REACT_APP_BACKEND_SERVER;
const fetchCredentials = () => fetch(`${backend}/logged`, {credentials: 'include'})
  .then(response => filterStatus(response, [202]))
  .then(response => response.json())
  .then(({csrfToken, comptable, ...user}) =>
    new Credentials(csrfToken, User.parse(user), comptable)
  )
// Provider hook that creates auth object and handles state
// function useProvideAuth(): UseAsyncReturn<Credentials, never[]> {
//   const asyncCredentials =
//
//   // Return the user object and auth methods
//   return asyncCredentials;
// }

export type WithLoginProps = {
  credentials: Credentials
}

//
// export default function useLogin(WrappedComponent: React.ComponentType<WithLoginProps>) {
//   const [credentials, setCredentials] = useState<Credentials | undefined>();
//
//   const fetched = useAsync(fetchCredentials)
//
//   useEffect(() => {
//
//     const backend = process.env.REACT_APP_BACKEND_SERVER;
//
//     fetch(`${backend}/logged`, {credentials: 'include'})
//       .then(response => filterStatus(response, [202]))
//       .then(response => response.json())
//       .then(({csrfToken, comptable, ...user}) =>
//         setCredentials(new Credentials(csrfToken, User.parse(user), comptable))
//       )
//       .catch(errorResponse => setCredentials(errorResponse));
//   }, []);
//
//
//   return waitFor(
//     credentials,
//     (o: Credentials) => <WrappedComponent {...this.props} credentials={o}/>,
//     (o: Error) => {
//       if (o instanceof FetchError && o.status === 401) {
//         window.location.href = "/login?" + querystring.stringify({
//           fromPath: window.location.pathname,
//           fromSearch: window.location.search
//         });
//       }
//       return <Errors>{o}</Errors>;
//     }
//   );
//
//
// }

export enum GRANTS {
  std = 1,
  itv = 2,
  resp = 3,
  admin = 4,
}

export type GrantsStrings = keyof typeof GRANTS;

export function grantsToString(grants: GRANTS): GrantsStrings {
  switch (grants) {
    case GRANTS.std:
      return 'std';
    case GRANTS.itv:
      return 'itv';
    case GRANTS.resp:
      return 'resp';
    case GRANTS.admin:
      return 'admin';
  }
}

export class Credentials {
  csrfToken: string;
  user: User;
  comptable: boolean;

  constructor(csrfToken: string, user: User, comptable: boolean) {
    this.csrfToken = csrfToken;
    this.user = user;
    this.comptable = comptable;
  }

  grants(): GRANTS {
    return this.user.grants;
  }


  headers(additional: { [key: string]: string } = {}): Headers {
    const headers = new Headers({"Csrf-Token": this.csrfToken});
    Seq.Keyed(additional).forEach((v, k) =>
      headers.append(k, v)
    );
    return headers;

  }

  isAdmin(): boolean {
    return this.grants() === GRANTS.admin;
  }

  in(...grants: GRANTS[]): boolean {
    return this.isAdmin() || grants.find(g => g === this.grants()) !== undefined;
  }

  is(user?: User): boolean {
    return user?.userId === this.user.userId;
  }
}

