import {Entreprise, Projet} from "../projets/Projet";
import {useAsync} from "react-async-hook";
import {FacturationProjet, fetchFacturations, fetchStades, validFacturations} from "./Facturation";
import immutable from "immutable";
import {filterStatus} from "../../utils/FetchError";
import {WaitForAsync} from "../../utils/Loading";
import {Button, Table} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {Maybe} from "monet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faPlus} from "@fortawesome/free-solid-svg-icons";
import {formatDate, formatEur} from "../../utils/format";

const backend = process.env.REACT_APP_BACKEND_SERVER;

export function FacturationTable(props: { showCloture: boolean, entreprise?: Entreprise, refresh?: number }) {

  const stades = useAsync(fetchStades, []);
  const asyncFacturations = useAsync<FacturationProjet[]>(
    async () => {
      if (props.entreprise) {
        return await fetchFacturations(props.showCloture, props.entreprise)
          .then(fs => fs.sort((a, b) => validFacturations(a).count() - validFacturations(b).count()));
      } else {
        return [];
      }
    },
    [props.showCloture, props.entreprise, props.refresh],
    {setLoading: state => ({...state, loading: true})}
  );

  const addFacturation = (facturation: FacturationProjet) => {
    fetch(`${backend}/facturation/add/${facturation.projet.projetId}`, {credentials: "include"})
      .then(filterStatus)
      .then(() => asyncFacturations.execute(props.showCloture, props.entreprise));
  }


  const delFacturation = (projet: Projet, stadeId: number) => {
    fetch(`${backend}/facturation/del/${projet.projetId}/${stadeId}`, {credentials: "include"})
      .then(filterStatus)
      .then(() => asyncFacturations.execute(props.showCloture, props.entreprise));
  }

  return <WaitForAsync async={stades}>{stades =>
    <Table>
      <thead>
      <tr>
        <th>Projet</th>
        {stades.map((s, i) =>
          <th key={i} className="text-center">
            {s.nom} − {s.taux.toLocaleString("fr", {style: 'percent'})}
          </th>
        )}
        <th/>
      </tr>
      </thead>
      <tbody>
      <WaitForAsync async={asyncFacturations}>{(facturations) =>
        facturations.map((f, i) =>
          <tr key={i}>
            <td>{f.projet.title}<br/>
              {f.projet.client && <UserBadge civilite prenom={false}>{f.projet.client}</UserBadge>}<br/>
              {f.prix && formatEur(f.prix)}
            </td>
            {stades.map((s, j) =>
              <td key={j} className="text-center">
                {f.facturations.has(s.stadeId) &&
                    <Button
                        variant={"link"}
                        onClick={() => delFacturation(f.projet, s.stadeId)}
                        disabled={f.facturations.get(s.stadeId)?.validDate !== undefined}>
                      {Maybe.fromUndefined(f.facturations.get(s.stadeId)?.validDate)
                        .cata(
                          () => <FontAwesomeIcon icon={faCheck} className={"text-primary"}/>,
                          validDate => {
                            return <>
                              <FontAwesomeIcon icon={faCheckCircle} size="lg" className="text-success"/><br/>
                              {formatDate(validDate).replace(" ", "\u00a0")}
                            </>
                          }
                        )
                      }
                    </Button>
                }
              </td>
            )}
            <td>
              <Button size="sm" className="btn-circle m-2 text-center p-0"
                      disabled={f.facturations.size >= stades.length}
                      onClick={() => addFacturation(f)}>
                <FontAwesomeIcon icon={faPlus}/>
              </Button>
            </td>
          </tr>
        )
      }</WaitForAsync>
      </tbody>
    </Table>
  }</WaitForAsync>
}