import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";
import immutable, {Seq} from "immutable";
import {faFolder, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type FoldersProps<T> = {
  data: immutable.Map<string, Seq.Indexed<T>>;
  display: (content: Seq.Indexed<T>) => any;
};
type FoldersState = {
  open?: string;
}

export default class Folders<T> extends React.Component<FoldersProps<T>, FoldersState> {
  state: FoldersState = {};

  render() {
    return [...this.props.data.entries()]
      .sort(
        ([cat1, _1], [cat2, _2]) => cat1.localeCompare(cat2)
      )
      .map(([catName, content]) =>
        <React.Fragment key={catName}>
          <Row>
            <Col>
              <Button
                className="fw-bold"
                size="lg"
                variant="link"
                onClick={() => this.setState({
                  open: this.state.open === catName ? undefined : catName
                })}>
                <FontAwesomeIcon
                  size="lg"
                  icon={this.state.open === catName ?
                    faFolderOpen : faFolder
                  }/>&nbsp;
                {catName} ({content.size})
              </Button>
            </Col>
          </Row>
          {this.state.open === catName &&
          <Row>{this.props.display(content)}</Row>
          }
        </React.Fragment>
      );

  }
}