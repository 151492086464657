import React, {ReactNode} from "react";
import Errors from "../views/Errors";
import {useAsync, UseAsyncOptions, UseAsyncReturn} from "react-async-hook";

export class ABLoading extends React.Component {
  render() {
    return (
      <div className="d-flex">
        <i style={{display: "block", fontSize: "xx-large"}} className="mx-auto my-auto fas fa-spinner fa-spin"/>
      </div>
    );
  }
}


type LOADINGT = 9847109450130466;
export const LOADING: LOADINGT = 9847109450130466;

export type Loadable<T> = LOADINGT | Error | T

export function waitFor<T>(
  object: Loadable<T>,
  onLoaded: (r: T) => ReactNode,
  onError: (e: Error) => ReactNode = (o) => <Errors>{o}</Errors>
): ReactNode {
  if (object instanceof Error) {
    return onError(object);
  } else if (object === LOADING) {
    return <ABLoading/>
  } else {
    return onLoaded(object);
  }
}

export function WaitFor<R, Args extends any[]>(props: {
  asyncFunction: (...args: Args) => Promise<R>, params: Args, options?: UseAsyncOptions<R>,
  children: (r: R) => JSX.Element
}) {
  const async = useAsync(props.asyncFunction, props.params)
  return <WaitForAsync async={async}>
    {props.children}
  </WaitForAsync>;
}

export function WaitForAsync<R, Args extends any[]>(props: { async: UseAsyncReturn<R, Args>, children: (r: R) => ReactNode }) {
  return <>
    {props.async.loading && <ABLoading/>}
    {props.async.error && <Errors>{props.async.error}</Errors>}
    {props.async.result !== undefined && props.children(props.async.result)}
  </>;
}
