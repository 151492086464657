import React from "react";
import {Card, ListGroup, ListGroupItem,} from "react-bootstrap";
import {User} from "./User";
import {UserBadge} from "./UserBadge";
import {Loadable, waitFor} from "../../utils/Loading";
import immutable from "immutable";
import {faEnvelope, faHardHat, faHome, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Corps, CorpsBadge} from "./Corps";

type UserCardProps = {
  user: User;
  // publicKeys: Loadable<PublicKey[]>;
  corps: Loadable<immutable.Map<number, Corps>>;
  onDelete?: (keyId: number) => void;
}

export default class UserCard extends React.Component<UserCardProps> {
  render(): JSX.Element {
    const {user} = this.props;
    const link = UserBadge.link(user, true, true);
    return (
      <>
        <Card.Header className="border-bottom text-center">
          <div className="mb-3 mx-auto">
            {UserBadge.image(user, 110)}
          </div>
          <h6 className="m-0">{link}</h6>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroupItem className="p-3"><FontAwesomeIcon icon={faEnvelope}/>&nbsp;<a
            href={`mailto:${encodeURIComponent(user.email)}`}
          >
            {user.email}</a>
          </ListGroupItem>
          {user.telephone &&
          <ListGroupItem className="p-3"><FontAwesomeIcon icon={faPhone}/>&nbsp;<a
              href={user.telephone.getURI()}>{user.telephone.format("IDD", {fromCountry: 'FR'})}</a>
          </ListGroupItem>
          }
          <ListGroupItem className="p-3 d-flex">
            <div><FontAwesomeIcon icon={faHome}/>&nbsp;</div>
            <div>
              {user.adresse && <> {user.adresse} <br/></>}
              {user.codePostal} {user.ville}
            </div>
          </ListGroupItem>
          {
            user.corps && waitFor(this.props.corps, (corps: immutable.Map<number, Corps>) =>
              <ListGroupItem className="p-3 d-flex">
                <div><FontAwesomeIcon icon={faHardHat}/>&nbsp;</div>
                <div>
                  {
                    user.corps.map((c, i) =>
                      <CorpsBadge key={i}>{corps.get(c)}</CorpsBadge>
                    )
                  }
                </div>
              </ListGroupItem>
            )
          }
          {/*{waitFor(this.props.publicKeys, publicKeys => publicKeys.length > 0 &&*/}
          {/*<ListGroupItem className="p-3">*/}
          {/*    <div><i className="fas fa-key"/> Clés publiques</div>*/}

          {/*    <RSAKeys>*/}
          {/*      {publicKeys}*/}
          {/*    </RSAKeys>*/}

          {/*</ListGroupItem>*/}
          {/*)}*/}
        </ListGroup>
      </>
    );


  }
}