import {ABDocument, JSONDocument} from "../documents/Document";
import {parseISO} from "date-fns";
import {Projet} from "../projets/Projet";
import * as immutable from "immutable";
import {filterStatus} from "../../utils/FetchError";
import {formatDate} from "../../utils/format";
import {
  faCheck,
  faCheckDouble,
  faCircle,
  faEnvelope,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {Credentials} from "../../utils/auth";
import {Fragment, ReactNode} from "react";
import {Variant} from "react-bootstrap/types";
import queryString from "query-string";

interface ICheckListItem {
  item: number,
  name: string,
  categorie?: string,
  projet: number,
  document?: ABDocument | number,
  submitted?: Date,
  validate?: Date,
  status: ClStatus,
  actionRequired: boolean,
  warning: string,
  justification?: string,
  proposition?: string,
  contraignant: string,
}

export enum ClStatus {
  PENDING, SUBMITTED, VALIDATED
}

export type JSONCheckListItem = {
  item: number,
  name: string,
  categorie?: string,
  projet: number,
  document?: JSONDocument | number,
  submitted?: string,
  validate?: string,
  actionRequired: boolean,
  warning: string,
  justification?: string,
  proposition?: string,
  contraignant: string,
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export type GeneralItem = {
  item: number;
  name: string;
  contraignant: string;
}

export class CLItem implements ICheckListItem {
  readonly item: number;
  readonly name: string;
  readonly categorie?: string;
  readonly projet: number;
  readonly document?: ABDocument | number;
  readonly submitted?: Date;
  readonly validate?: Date;
  readonly status: ClStatus;
  readonly actionRequired: boolean;
  readonly warning: string;
  readonly justification?: string;
  readonly proposition?: string;
  readonly contraignant: string;

  constructor(from: ICheckListItem) {
    this.item = from.item;
    this.name = from.name;
    this.categorie = from.categorie;
    this.projet = from.projet;
    this.document = from.document;
    this.submitted = from.submitted;
    this.validate = from.validate;
    this.status = from.status;
    this.actionRequired = from.actionRequired;
    this.warning = from.warning;
    this.justification = from.justification;
    this.proposition = from.proposition;
    this.contraignant = from.contraignant;
  }

  static parse(data: JSONCheckListItem): CLItem {
    return new CLItem({
      ...data,
      document: data.document && (typeof data.document === 'number' ? data.document : ABDocument.parse(data.document)),
      submitted: data.submitted ? parseISO(data.submitted) : undefined,
      validate: data.validate ? parseISO(data.validate) : undefined,
      status: data.validate ? ClStatus.VALIDATED : data.submitted ? ClStatus.SUBMITTED : ClStatus.PENDING,
    });
  }

  static load(projet: Projet): Promise<immutable.OrderedMap<number, CLItem>> {
    return fetch(`${backend}/projet/${projet.projetId}/checkList`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((r: JSONCheckListItem[]) =>
        immutable.OrderedMap(r.map(CLItem.parse).map(it => [it.item, it]))
      );
  }

  static loadAll(selectFinished: boolean): Promise<immutable.Map<number, immutable.Map<number, CLItem>>> {
    return fetch(`${backend}/projets/checkLists?${queryString.stringify({selectFinished})}`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((r: { [key: string]: CLItem[] }) => immutable.Map(
        Object.entries(r).map(([projetId, checkList]) =>
          [Number(projetId), immutable.Map(checkList.map(cl => [cl.item, cl]))]
        )
      ));
  }

  docWarning(): JSX.Element {
    if (typeof this.document === "number") {
      return <>Le document joint est introuvable ou inaccessible.</>;
    } else if (this.categorie && this.categorie !== this.document?.categorie) {
      return <>Un document de catégorie <span className="text-semibold">{this.categorie}</span> est attendu</>;
    } else {
      return <Fragment/>;
    }
  }


  doValidate(credentials: Credentials, validate?: boolean, justification?: string):
    Promise<CLItem> {
    const body = JSON.stringify({
      projetId: this.projet,
      item: this.item,
      validate,
      justification,
    });
    return fetch(
      `${backend}/projet/checkListItem/validate`,
      {
        credentials: 'include',
        method: 'POST',
        headers: credentials.headers({"Content-Type": "application/json"}),
        body,
      }
    )
      .then(filterStatus)
      .then(r => r.json())
      .then(CLItem.parse)
  }

  updated(changes: Partial<CLItem>): CLItem {
    return new CLItem({...this, ...changes});
  }

  submit(credentials: Credentials, submit: boolean): Promise<CLItem> {
    const body = new FormData();
    body.set("projetId", this.projet.toString());
    body.set("item", this.item.toString());
    switch (typeof this.document) {
      case "object":
        body.set("document", this.document.documentId.toString());
        break;
      case "number":
        body.set("document", this.document.toString());
        break;
      default:
        console.log(this.document);
    }
    body.set("submit", submit.toString())
    body.set("proposition", this.proposition || '');

    return fetch(
      `${backend}/projet/checkListItem/submit`,
      {
        credentials: 'include',
        method: 'POST',
        headers: credentials.headers(),
        body,
      }
    )
      .then(filterStatus)
      .then(r => r.json())
      .then(CLItem.parse)
  }


  statusString(): ReactNode {
    switch (this.status) {
      case ClStatus.VALIDATED:
        return `Validé le ${formatDate(this.validate!)}`;
      case ClStatus.SUBMITTED:
        if (this.actionRequired) {
          return `Fait le ${formatDate(this.submitted!)} − À valider`;
        } else {
          return `Fait le ${formatDate(this.submitted!)} − En attente de validation`;
        }
      case ClStatus.PENDING:
        if (this.justification) {
          return <Fragment>Refusé <FontAwesomeIcon icon={faEnvelope}/>, à faire</Fragment>;
        } else if (this.actionRequired) {
          return 'À faire';
        } else {
          return 'En attente';
        }
    }
  }

  static allItems(): Promise<GeneralItem[]> {
    return fetch(`${backend}/checkListItems`)
      .then(filterStatus)
      .then(r => r.json());
  }

  bullet(): FontAwesomeIconProps {
    if (this.actionRequired) {
      return {icon: faExclamationCircle};
    } else switch (this.status) {
      case ClStatus.VALIDATED:
        return {icon: faCheckDouble};
      case ClStatus.SUBMITTED:
        return {icon: faCheck};
      case ClStatus.PENDING:
        return {icon: faCircle, size: "xs"};
    }
  }

  variant(): Variant {
    if (this.actionRequired) {
      return "warning";
    } else switch (this.status) {
      case ClStatus.VALIDATED:
        return "success";
      case ClStatus.SUBMITTED:
        return "info";
      case ClStatus.PENDING:
        return "secondary";
    }
  }
}