import React from "react";
import {Card, Col} from "react-bootstrap";
import {Loadable, LOADING, waitFor} from "../utils/Loading";
import {filterStatus} from "../utils/FetchError";
import {withCookies} from "react-cookie";
import {Link} from "react-router-dom";

type LogoutState = {
    logout: Loadable<boolean>;
}

type LogoutProps = Record<string, never>;

class Logout extends React.Component<LogoutProps, LogoutState> {

    state = {logout: LOADING};

    componentDidMount() {
        const backend = process.env.REACT_APP_BACKEND_SERVER;

        fetch(`${backend}/logout`, {credentials: 'include'})
            .then(filterStatus)
            .then(() => this.setState({logout: true}))
            .catch((e) => this.setState({logout: e}));
    }

    render() {
        return (
            <Col sm={6} className="mx-auto my-5">
                <Card>
                    <Card.Header>
                        {waitFor(this.state.logout, () => "Déconnexion réussie")}
                    </Card.Header>
                    <Card.Body>
                        <Link to="..">Retour à la page de connexion</Link>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}

export default withCookies(Logout);