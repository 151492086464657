import Select from "react-select";
import classNames from "classnames";
import {ABDocument, DocumentVisibility} from "./Document";
import {Loadable, LOADING, waitFor} from "../../utils/Loading";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import filterOptions from "../../utils/SelectSearch";
import React from "react";
import immutable from "immutable";
import {GRANTS, WithLoginProps} from "../../utils/auth";
import {Badge, Form, FormControl} from "react-bootstrap";
import {User} from "../user-profile-lite/User";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface VisibilityOptionValue {
  value: DocumentVisibility,
  label: JSX.Element
}

type VisibilitySelectProps = WithLoginProps & {
  visibility: DocumentVisibility,
  concerne?: User,
  isInvalid: boolean,
  onChange: (visibility: DocumentVisibility, concerne?: User) => void,
  projetId?: number,
  defaultConcerne: User,
  errors?: { visibility?: JSX.Element, concerne?: JSX.Element },
};

type VisibilityState = {
  users: Loadable<AllUsers>;
}

export class VisibilitySelect extends React.Component<VisibilitySelectProps> {
  state: VisibilityState = {
    users: LOADING,
  }


  refreshAllUsers(projetId?: number) {
    this.setState({users: LOADING});
    AllUsers.loadUsers({projet: projetId})
      .then(users => this.setState({users}))
      .catch(error => this.setState({users: error}));
  }

  componentDidMount() {
    this.refreshAllUsers(this.props.projetId);
  }

  componentDidUpdate(prevProps: Readonly<VisibilitySelectProps>, prevState: Readonly<Record<string, never>>) {
    if (prevProps.projetId !== this.props.projetId) {
      this.refreshAllUsers(this.props.projetId);
    }
  }


  visibilityOptions() {
    const visibilities = [DocumentVisibility.public];
    switch (this.props.credentials.grants()) {
      case GRANTS.resp:
      case GRANTS.admin:
        visibilities.push(DocumentVisibility.interne);
        visibilities.push(DocumentVisibility.prestataire);
        visibilities.push(DocumentVisibility.client);
        break;
      case GRANTS.itv:
        visibilities.push(DocumentVisibility.prestataire);
        break;
      case GRANTS.std:
        visibilities.push(DocumentVisibility.client);
        break;
    }

    return visibilities.map((value) => {
      const {icon, color} = ABDocument.visibilityIcon(value);
      return {
        value,
        label:
          <Badge pill bg={"none"} style={{backgroundColor: color.toCSS(), color: color.textColor().toCSS()}}>
            {icon} {DocumentVisibility[value].toLocaleUpperCase()}
          </Badge>
      };
    });
  }


  handleVisibilityChange(selected: VisibilityOptionValue | null) {
    //if (instanceOfVisibilityOption(selected)) {
    if (selected) {
      let concerne: User | undefined = this.props.concerne;
      if (selected.value === DocumentVisibility.prestataire) {
        concerne = concerne ?? this.props.defaultConcerne;
      }
      this.props.onChange(selected.value, concerne);
    }
  }

  render() {

    const optionMap = immutable.Map<DocumentVisibility, VisibilityOptionValue>(
      this.visibilityOptions().map(o => [o.value, o])
    );

    return <>
      <Form.Group className="mb-3">
        <Form.Label>
          <FontAwesomeIcon icon={faEye}/> Visibilité
        </Form.Label>
        <Select
          className={classNames(
            {"is-invalid": this.props.isInvalid}
          )}
          value={optionMap.get(this.props.visibility)}
          onChange={(selected) => this.handleVisibilityChange(selected)}
          options={[...optionMap.valueSeq()]}/>
        <FormControl.Feedback type="invalid">{this.props.errors?.visibility}</FormControl.Feedback>

      </Form.Group>
      {this.props.visibility === DocumentVisibility.prestataire && waitFor(this.state.users, (users: AllUsers) =>
        <Form.Group className="mb-3">
          <Form.Label>
            <FontAwesomeIcon icon={faEye}/> Prestataire concerné
          </Form.Label>
          <Select
            placeholder={"Sélectionner un prestataire"}
            className={classNames({"is-invalid": this.props.isInvalid})}
            value={userSelect(this.props.concerne)}
            options={users.userSelect()}
            isSearchable
            isClearable
            filterOption={filterOptions}
            onChange={selected =>
              this.props.onChange(this.props.visibility, selected?.value)
            }/>
          <FormControl.Feedback type="invalid">{this.props.errors?.concerne}</FormControl.Feedback>

        </Form.Group>
      )}
    </>;
  }
}