import {Badge, Card, Table} from "react-bootstrap";
import {Projet} from "./Projet";
import immutable, {Collection, Seq} from "immutable";
import {
  allAttributions,
  Attribution, ATTRIBUTION_SANS_OBJET,
  AttributionStatus,
  AttributionWithSelectedUser
} from "../attributions/Attribution";
import {useAsync} from "react-async-hook";
import {Corps} from "../user-profile-lite/Corps";
import {WaitForAsync} from "../../utils/Loading";
import {ProjetParResponsable} from "./ProjetParResponsable";
import {Fourniture, FournitureStatus, loadAllFournituresFor, loadMateriaux} from "../fournitures/Fourniture";
import { formatDate } from "../../utils/format";

type FournituresTableProps = {
  projets: Seq.Indexed<Projet>,
  selectFinished: boolean,
}


const colonnes: number[] = [
  29, // ½ linteau
  20, // sous-face
  7, // Menuiseries ext
  8, // Menuiseries int
  11, // Carrelage
//  [20, AF.Four]
];

export function FournituresTable(props: FournituresTableProps) {

  const materiaux = useAsync(loadMateriaux, []);
  const fournitures = useAsync(loadAllFournituresFor, [props.selectFinished]);

  return <Card>
    <Card.Body>
      <WaitForAsync async={materiaux}>{materiaux =>
        <Table responsive className={"overflow-auto"}>

          <thead>
          <tr>
            <th className={"position-absolute"}/>
            <th style={{paddingLeft: "300px"}}/>
            {colonnes.map((id, i) =>
              <th key={i} className={"small text-center"}>
                {materiaux.get(id)?.materiau ?? id}
              </th>
            )}
          </tr>
          </thead>
          <WaitForAsync async={fournitures}>{fournitures =>
            <tbody className={"overflow-y h-100"}>
            <ProjetParResponsable projets={props.projets} colSpan={colonnes.length}>{p =>
              <>
                {colonnes.map((mat, i) => {
                  const fourns:  immutable.Map<number, Seq.Indexed<Fourniture>> = fournitures.get(p.projetId, immutable.Map());
                  const fournsMat = fourns.get(mat, Seq.Indexed<Fourniture>());

                  return <td key={i} className={"text-center"}>
                    <a href={"projet/fournitures?projetId=" + p.projetId}><StatutMat mat={fournsMat}/></a>
                  </td>
                })}
              </>}</ProjetParResponsable>
            </tbody>
          }</WaitForAsync>
        </Table>
      }</WaitForAsync>
    </Card.Body>
  </Card>;
}

function StatutMat(props: { mat: Seq.Indexed<Fourniture> }) {
  if (props.mat.isEmpty()) {
    return <Badge bg="danger">N/D</Badge>;
  } else if (props.mat.find(a => a.statut === 2)) {
    return <>{props.mat.map(a =>
      <Badge
        bg={"success"}
        key={a.fournitureId}>Acheté {a.fournisseur} {a.commande && `le ${formatDate(a.commande)}`}</Badge>)
    }</>;
  } else {
    return <>{props.mat.map(a =>
      <Badge key={a.fournitureId}
             bg={a.statut === ATTRIBUTION_SANS_OBJET ? "secondary" : "info"}>
        {FournitureStatus.get(a.statut)}
      </Badge>)}
    </>
      ;
  }

}
