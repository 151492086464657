import {Loadable, LOADING, waitFor} from "../../../utils/Loading";
import React from "react";
import {Projet} from "../../projets/Projet";
import {UserBadge} from "../../user-profile-lite/UserBadge";
import {Store} from "../../../flux";
import classNames from "classnames";

type LastProjectProps = {
  projet?: Projet;
};
type LastProjectState = {
  latest: Loadable<Projet[]>;
  open: boolean;
};

export class LastProjects extends React.Component<LastProjectProps, LastProjectState> {

  state: LastProjectState = {
    latest: LOADING,
    open: false,
  };

  componentDidMount() {
    Store.addChangeListener(this.onChange);
    Projet.latest()
      .then(latest => this.setState({latest}))
      .catch(error => this.setState({latest: error}))
  }

  constructor(props: LastProjectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      open: Store.getHistoryState()
    });
  }

  render() {
    return (<div
      className={classNames(
        "history",
        this.state.open ? "open" : "d-none",
      )}>
      {waitFor(
        this.state.latest,
        o =>
          // style={{position: "fixed", left: "7rem"}}
          <ul className="list-unstyled pl-2">
            {
              o.filter(proj => !this.props.projet || proj.projetId !== this.props.projet.projetId)
                .map(proj =>
                  <li key={proj.projetId}>
                    <a href={`?projetId=${proj.projetId}`}>{proj.title}</a> − <UserBadge civilite prenom={false}>{proj.client!}</UserBadge>
                  </li>
                )
            }
          </ul>
      )}

    </div>);
  }
}