import {Fragment} from "react";

export function nl2br(text: string): JSX.Element {
    return <>
        {
            text.split("\n").map((l, i) =>
                <Fragment key={i}>{l}<br/></Fragment>)
        }
    </>;
}

export function nl2p(text: string): JSX.Element {
    return <>
        {
            text.split("\n").map((l, i) =>
                <p key={i}>{l}</p>)
        }
    </>;
}