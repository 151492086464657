import {Either} from "monet";

export default class FetchError extends Error {

  response: Response;
  status: number;
  statusText: string;

  constructor(response: Response) {
    super();
    this.response = response;
    this.status = response.status;
    this.statusText = response.statusText;
  }

  text(): Promise<string> {
    return this.response.text();
  }

  json(): Promise<SubmitError> {
    return this.response.json();
  }
}

export function filterStatus(response: Response, status = [200]): Response {
  if (status.includes(response.status)) {
    return response;
  } else {
    throw new FetchError(response);
  }
}

export function filterStatusEither<A>(response: Response): Promise<Either<SubmitError, A>> {
  switch (response.status) {
    case 200:
      return response.json()
        .then((json: A) => Either.right(json));

    case 400:
      return response.json()
        .then((json: SubmitError) => Either.left(json));

    default:
      throw new FetchError(response);
  }
}

export function mapRight<A, B, C>(p: Either<A, B>, mapper: (o: B) => Promise<C>): Promise<Either<A, C>> {
  return flatMapRight(p, o => mapper(o).then(d => Either.right(d)));
}

export function flatMapRight<A, B, C>(p: Either<A, B>, mapper: (o: B) => Promise<Either<A, C>>): Promise<Either<A, C>> {
  return p.cata(
    l => new Promise(r => r(Either.left(l))),
    r => mapper(r)
  );
}

export function lefts<A>(eithers: Either<A, unknown>[]): A[] {
  const lefts: A[] = [];
  for (const e of eithers) {
    e.forEachLeft(l => lefts.push(l));
  }
  return lefts;
}

export function rights<B>(eithers: Either<unknown, B>[]): B[] {
  const rights: B[] = [];
  for (const e of eithers) {
    e.forEach(r => rights.push(r));
  }
  return rights;
}

export function mergeErrors(errors: SubmitError[]): SubmitError {
  const error: SubmitError = {};
  for (const l of errors) {
    Object.assign(error, l);
  }
  return error;
}

export function mergeEithers<A>(eithers: Either<SubmitError, A>[]): Either<SubmitError, A[]> {
  const ls = lefts(eithers);
  if (ls.length === 0) {
    return Either.right(rights(eithers));
  } else {
    return Either.left(mergeErrors(ls));
  }
}


export type SubmitError = { [key: string]: string }
