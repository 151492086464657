import {JSONUser, User} from "../user-profile-lite/User";
import {JSONSignature, loadSignature, Signature} from "../../utils/Signature";
import {formatISO, isPast, parseISO} from "date-fns";
import {Credentials, GRANTS} from "../../utils/auth";
import {RGBColor} from "../../utils/colors";
import {formatDate, getColorFromMIME, getFontAwesomeIconFromMIME} from "../../utils/format";
import {DocBadge} from "./DocumentCardBody";
import queryString from "query-string";
import {filterStatus, filterStatusEither} from "../../utils/FetchError";
import * as immutable from "immutable";
import {Projet} from "../projets/Projet";
import {b64toBlob} from "../../utils/miscellaneous";
import {Either} from "monet";
import {JSONTask, Task} from "../planning/Task";
import {CLItem, JSONCheckListItem} from "../checklist/CLItem";
import {faFile, faHardHat, faHouseUser, faIndustry, faUsers, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum DocumentVisibility {
  interne, prestataire, public, client
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export class DocumentReferences {
  readonly dossiers: { dossierId: number, projetId: number }[];
  readonly tasks: Task[];
  readonly signatures: Signature[];
  readonly checkLists: CLItem[];

  constructor(dossiers: { dossierId: number, projetId: number }[], tasks: Task[], signatures: Signature[], checkLists: CLItem[]) {
    this.dossiers = dossiers;
    this.tasks = tasks;
    this.signatures = signatures;
    this.checkLists = checkLists;
  }

  hasReferences() {
    return this.dossiers.length > 0 ||
      this.tasks.length > 0 ||
      this.signatures.length > 0 ||
      this.checkLists.length > 0;
  }
}

interface DocumentReferencesJSON {
  dossiers: any[],
  tasks: JSONTask[],
  questions: any[],
  signatures: JSONSignature[],
  checkLists: JSONCheckListItem[],
}

interface IABDocument {
  documentId: number;
  projetId?: number;
  title: string;
  contentType?: string;
  mimeName: string;
  size?: number;
  timestamp: Date;
  categorie: string;
  file?: File[] | string;
  digest: string;
  posteur: User;
  concerne?: User;
  visibility: DocumentVisibility;
  signatures: Signature[];
  commentaire: string;
  administratif?: AdministratifData;
  telecharge: { user: User, date: Date }[];
  mustSign: User[];
}

export interface JSONDocument {
  documentId: number;
  projetId?: number;
  title: string;
  contentType?: string;
  mimeName: string;
  size?: number;
  timestamp: string;
  categorie: string;
  file?: File[] | string;
  digest: string;
  posteur: number | JSONUser;
  concerne?: JSONUser;
  visibility: keyof typeof DocumentVisibility;
  signatures?: JSONSignature[];
  commentaire: string;
  telecharge?: (JSONUser & { date: string })[];
  mustSign?: JSONUser[];
}

export class ABDocument implements IABDocument {
  documentId: number;
  projetId?: number;
  title: string;
  contentType?: string;
  size?: number;
  timestamp: Date;
  categorie: string;
  file?: File[] | string;
  digest: string;
  posteur: User;
  concerne?: User;
  visibility: DocumentVisibility;
  signatures: Signature[];
  commentaire: string;
  administratif?: AdministratifData;
  telecharge: { user: User, date: Date }[];
  mustSign: User[];
  mimeName: string;

  static new({user}: Credentials, data: Partial<IABDocument>): ABDocument {
    return new ABDocument({
      documentId: 0,
      title: '',
      contentType: '',
      timestamp: new Date(),
      categorie: '',
      signatures: [],
      digest: '',
      commentaire: '',
      telecharge: [],
      posteur: user,
      visibility: ABDocument.defaultVisibility(user, data.concerne),
      mustSign: [],
      mimeName: "non reconnu",
      ...data
    });
  }


  private constructor(data: IABDocument) {
    this.documentId = data.documentId;
    this.projetId = data.projetId;
    this.title = data.title;
    this.contentType = data.contentType;
    this.size = data.size;
    this.timestamp = data.timestamp;
    this.categorie = data.categorie;
    this.file = data.file;
    this.digest = data.digest;
    this.posteur = data.posteur;
    this.concerne = data.concerne;
    this.visibility = data.visibility;
    this.signatures = data.signatures;
    this.commentaire = data.commentaire;
    this.administratif = data.administratif;
    this.telecharge = data.telecharge;
    this.mustSign = data.mustSign;
    this.mimeName = data.mimeName;
  }

  updated(newData: Partial<IABDocument>) {
    return new ABDocument({...this, ...newData});
  }

  static visibilityIcon(visibility: DocumentVisibility): {
    icon: JSX.Element,
    color: RGBColor,
    toString: string,
  } {
    let icon: IconDefinition;
    let color: RGBColor;
    let toString;
    switch (visibility) {
      case DocumentVisibility.client:
        icon = faHouseUser;
        color = new RGBColor(0x17, 0xc6, 0x71);
        toString = "Client";
        break;
      case DocumentVisibility.prestataire:
        icon = faHardHat;
        color = new RGBColor(0xff, 0xb4, 0x00);
        toString = "Prestataire"
        break;
      case DocumentVisibility.interne:
        icon = faIndustry;
        color = new RGBColor(0x22, 0x22, 0x22);
        toString = "Interne";
        break;
      case DocumentVisibility.public:
        icon = faUsers;
        color = RGBColor.WHITE;
        toString = "Public"
        break;
      default:
        throw new Error("Visibility is " + visibility);
    }

    return {
      icon: <FontAwesomeIcon color={color.textColor().toHex()} icon={icon} />,
      color,
      toString,
    };
  }


  editable(credentials: Credentials): boolean {
    return credentials.in(GRANTS.resp) || credentials.is(this.posteur);
  }

  static defaultVisibility(posteur: User, concerne?: User): DocumentVisibility {
    switch (posteur.grants) {
      case GRANTS.resp:
      case GRANTS.admin:
        return concerne ? DocumentVisibility.prestataire : DocumentVisibility.interne;
      case GRANTS.itv:
        return concerne ? DocumentVisibility.prestataire : DocumentVisibility.public;
      case GRANTS.std:
        return DocumentVisibility.client;

    }
  }

  // getConcerne(): User {
  //   return this.concerne ?? this.posteur;
  // }

  static parse(doc: JSONDocument & JSONAdministratifData): ABDocument {
    return new ABDocument({
      ...doc,
      timestamp: parseISO(doc.timestamp),
      signatures: doc.signatures?.map(loadSignature) ?? [],
      administratif: ABDocument.parseAdministratif(doc),
      visibility: DocumentVisibility[doc.visibility],
      posteur: (typeof doc.posteur === 'number') ? User.new({userId: doc.posteur}) : User.parse(doc.posteur),
      concerne: doc.concerne && User.parse(doc.concerne),
      telecharge: doc.telecharge?.map((t) =>
        ({date: parseISO(t.date), user: User.parse(t)})
      ) ?? [],
      mustSign: doc.mustSign?.map(User.parse) || [],
    });
  }

  static parseAdministratif(data: JSONAdministratifData): AdministratifData {
    return {
      echeance: data.echeance ? parseISO(data.echeance) : undefined,
      cloture: data.cloture ? parseISO(data.cloture) : undefined,
    };
  }

  static searchDocumentAdministratif(filter: { projet?: Projet, user?: User, categorie?: string, showCloture?: boolean }): Promise<immutable.Map<number, ABDocument>> {
    return fetch(
      `${backend}/documentAdministratif/search?${queryString.stringify({
        projetId: filter.projet?.projetId,
        userId: filter.user?.userId,
        categorie: filter.categorie,
        showCloture: filter.showCloture,
      })}`,
      {credentials: 'include'}
    )
      .then(filterStatus)
      .then(response => response.json())
      .then((docs: (JSONDocument & JSONAdministratifData)[]) =>
        immutable.Map(
          docs.map(d => [d.documentId, ABDocument.parse(d)])
        )
      );
  }

  private postAdministratif(administratif?: AdministratifData): FormData {
    const data = new FormData();
    data.append("documentId", this.documentId.toString());
    if (administratif?.echeance)
      data.append("echeance", formatISO(administratif.echeance, {representation: "date"}));
    if (administratif?.cloture)
      data.append("cloture", formatISO(administratif.cloture, {representation: "date"}));
    return data;
  }

  cloture(): Promise<ABDocument> {
    return fetch(
      `${backend}/documentAdministratif/cloture/${this.documentId}`,
      {credentials: 'include'}
    )
      .then(filterStatus)
      .then(response => response.json())
      .then((doc: any) =>
        this.updated({administratif: ABDocument.parseAdministratif(doc)}));
  }

  badges(): DocBadge[] {
    const badges = [];
    const echeance = this.administratif?.echeance;
    if (echeance) {
      badges.push({
        variant: echeance && isPast(echeance) ? "danger" : "info",
        content: <>Échéance : {formatDate(echeance)}</>
      });
    }
    return badges;
  }

  saveAdministratif(creds: Credentials, data?: AdministratifData): Promise<Either<{ [key: string]: string }, ABDocument>> {
    return fetch(
      `${backend}/documentAdministratif/save`, {
        credentials: 'include',
        method: 'POST',
        headers: creds.headers(),
        body: this.postAdministratif(data),
      }
    )
      .then(r => filterStatusEither<JSONAdministratifData>(r))
      .then(response =>
        response.map(admin => this.updated({
          administratif: ABDocument.parseAdministratif(admin)
        }))
      );
  }

  archive(credentials: Credentials): Promise<ABDocument> {
    return fetch(
      `${backend}/document/${this.documentId}/archive`, {
        credentials: 'include',
        method: 'PUT',
        headers: credentials.headers()
      }
    )
      .then(filterStatus)
      .then(r => r.json())
      .then(ABDocument.parse);
  }

  static allCats(projetId?: number): Promise<string[]> {
    return fetch(`${backend}/documents/allCats?${queryString.stringify({projetId})}`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
  }

  delete(credentials: Credentials): Promise<Response> {
    return fetch(`${backend}/documents/${this.documentId}`,
      {credentials: 'include', method: 'DELETE', headers: credentials.headers()}
    )
      .then(filterStatus)
  }

  private static async downscale(file: Blob, downscale: boolean): Promise<Blob> {
    if (!downscale)
      return new Promise(resolve => resolve(file));
    const img = document.createElement("img");
    img.src = await new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => resolve(e.target.result);
      reader.onerror = reject
      reader.readAsDataURL(file);
    });
    await new Promise((resolve, reject) => {
      img.onerror = reject
      img.onload = resolve
    })
    const canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    if (ctx) ctx.drawImage(img, 0, 0);
    const MAX_WIDTH = 500;
    const MAX_HEIGHT = 500;
    let width = img.naturalWidth;
    let height = img.naturalHeight;
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
    canvas.width = width;
    canvas.height = height;
    ctx = canvas.getContext("2d");
    if (ctx) ctx.drawImage(img, 0, 0, width, height);
    return await new Promise<Blob>((resolve: any) => {
      canvas.toBlob(resolve, 'image/jpeg', 0.95);
    });
  }


  toString() {
    return `${this.documentId}. ${this.title} (${this.contentType})`
  }

  prepareFormData(target: HTMLFormElement, downscale: boolean): Promise<FormData> {
    const data = new FormData(target);

    if (this.projetId) {
      data.append("projetId", this.projetId.toString());
    }

    data.append("commentaire", this.commentaire);
    if (this.concerne) {
      data.set("concerne", this.concerne.userId.toString());
    }

    data.append("visibility", DocumentVisibility[this.visibility]);

    if (this.documentId !== 0) {
      data.append("docId", this.documentId.toString());
    }

    let prepare: Promise<any>;
    if (Array.isArray(this.file)) {
      prepare = Promise.all(
        this.file.map(file =>
          ABDocument.downscale(file, downscale)
            .then(f => data.append("doc[]", f, file.name))
        )
      )
        .catch(() => {
          throw new Error("Impossible de réduire l'image")
        });
    } else if (typeof this.file === 'string') {
      const base64ImageContent = this.file.replace(/^data:image\/(png|jpeg);base64,/, "");
      prepare = ABDocument.downscale(b64toBlob(base64ImageContent, "image/jpeg"), downscale)
        .then(b =>
          data.append("doc[]", b, `${this.title}.jpg`)
        )
        .catch(() => {
          throw new Error("Impossible de réduire l'image")
        });
    } else {
      // Edited document: no file provided
      prepare = Promise.resolve();
    }
    return prepare.then(() => data);
  }

  private static submit(url: string, data: FormData, credentials: Credentials): Promise<Either<{ [key: string]: string }, ABDocument[]>> {
    return fetch(url, {
      credentials: "include",
      method: 'POST',
      headers: credentials.headers(),
      body: data
    })
      .then(r => filterStatusEither<JSONDocument[]>(r))
      .then(response => response.map(docs => docs.map(ABDocument.parse)));
  }

  static submitZip(data: FormData, credentials: Credentials): Promise<Either<{ [key: string]: string }, ABDocument[]>> {
    return ABDocument.submit(`${backend}/documents/newZip`, data, credentials);
  }

  static submitDoc(data: FormData, credentials: Credentials): Promise<Either<{ [key: string]: string }, ABDocument[]>> {
    return ABDocument.submit(`${backend}/documents/save`, data, credentials);
  }

  static loadProjet(projetId: number, withArchives = false, categories: string[] = []): Promise<ABDocument[]> {
    const qs = {withArchives, categories};
    return fetch(
      `${backend}/projet/${projetId}/documents?${queryString.stringify(qs)}`,
      {credentials: 'include'}
    )
      .then(filterStatus)
      .then(response => response.json())
      .then((docs: JSONDocument[]) => docs.map(ABDocument.parse));
  }

  static mustSign(): Promise<ABDocument[]> {
    return fetch(
      `${backend}/mustSign`,
      {credentials: 'include'}
    )
      .then(filterStatus)
      .then(response => response.json())
      .then((docs: JSONDocument[]) => docs.map(ABDocument.parse));
  }

  static load(documentId: number): Promise<ABDocument> {
    return fetch(
      `${backend}/documents/${documentId}`,
      {credentials: 'include'}
    )
      .then(filterStatus)
      .then(response => response.json())
      .then((docs: JSONDocument) => ABDocument.parse(docs));
  }

  mustSignBadges(): DocBadge[] {
    const nbs = this.mustSign.length;
    if (nbs > 0) {
      return [{variant: "warning", content: <>{nbs} {nbs > 1 ? "signatures" : "signature"} en attente</>}];
    } else {
      return [];
    }
  }

  references(): Promise<DocumentReferences> {
    return fetch(
      `${backend}/documents/${this.documentId}/references`,
      {credentials: 'include'}
    )
      .then(filterStatus)
      .then(r => r.json())
      .then((r: DocumentReferencesJSON) => new DocumentReferences(
        r.dossiers,
        r.tasks.map(Task.parse),
        r.signatures.map(loadSignature),
        r.checkLists.map(CLItem.parse),
      ));
  }

  mimeColor(): RGBColor {
    return this.contentType === undefined ? RGBColor.BLACK : getColorFromMIME(this.contentType);
  }

  mimeIcon(): IconDefinition {
    return this.contentType === undefined ? faFile : getFontAwesomeIconFromMIME(this.contentType);
  }
}


export interface AdministratifData {
  echeance?: Date,
  cloture?: Date,
}

interface JSONAdministratifData {
  echeance?: string,
  cloture?: string,
}

export type DocumentActions = {
  onSign: (doc: ABDocument) => void,
  onEdit?: (doc: ABDocument) => void,
  onDelete?: (doc: ABDocument) => void,
  onSketch?: (doc: ABDocument) => void;
  onArchive?: (doc: ABDocument) => void;
}

