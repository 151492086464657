import {EventEmitter} from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
import {sidebarNavItems} from "../sidebar-nav-items";
import {Projet, ProjetStats} from "../components/projets/Projet";
import {Credentials} from "../utils/auth";

const _store = {
  menuVisible: false,
  historyVisible: false,
};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({actionType}: { actionType: string }) {
    if (actionType === Constants.TOGGLE_SIDEBAR) {
      this.toggleSidebar();
    }
    if (actionType === Constants.TOGGLE_HISTORY) {
      this.toggleHistory();
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  toggleHistory() {
    _store.historyVisible = !_store.historyVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getHistoryState() {
    return _store.historyVisible;
  }

  getSidebarItems(credentials: Credentials, projet?: Projet, stats?: ProjetStats) {
    return sidebarNavItems(credentials, projet, stats);
  }

  addChangeListener(callback: { (...args: any[]): void; }) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback: { (...args: any[]): void; }) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
