import {RGBColor} from "../../utils/colors";
import {Badge} from "react-bootstrap";
import immutable from "immutable";
import {filterStatus} from "../../utils/FetchError";
import {FunctionComponent} from "react";

export const CorpsBadge: FunctionComponent<{ children: Corps | undefined }> =
  (props) => {
    if (props.children) {
      return <Badge className="me-1"
                    pill
                    style={{
                      backgroundColor: props.children.couleur.toCSS(),
                      color: props.children.couleur.textColor().toCSS()
                    }}>
        {props.children.corps}
      </Badge>;
    } else {
      return <Badge className="me-1"
                    pill
                    bg={"danger"}>
        Corps indéfini
      </Badge>;
    }
  }


export class Corps {
  readonly corpsId: number;
  readonly corps: string;
  readonly couleur: RGBColor;

  constructor(corpsId: number, corps: string, couleur: RGBColor) {
    this.corpsId = corpsId;
    this.corps = corps;
    this.couleur = couleur;
  }

  static load(): Promise<immutable.Map<number, Corps>> {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    return fetch(`${backend}/corps`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((corps: { corpsId: number, corps: string, couleur: string }[]) =>
        immutable.Map(corps.map(({corpsId, corps, couleur}) => [corpsId,
          new Corps(corpsId, corps, RGBColor.fromHex(couleur))
        ]))
      )
  }


}
