import React, {ChangeEvent} from "react";
import {Button, ButtonToolbar, Col, Form, FormControl, Image, Modal, Row} from "react-bootstrap";
import {Loadable, LOADING, waitFor} from "../../utils/Loading";
import CreatableSelect from "react-select/creatable";
import immutable from "immutable";
import classNames from "classnames";
import {Projet, projetSelect} from "../projets/Projet";
import {Credentials, GRANTS} from "../../utils/auth";
import {ABDocument} from "./Document";
import Select from "react-select";
import Camera, {FACING_MODES, IMAGE_TYPES} from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import Autosuggest from "react-autosuggest";
import {Suggestions} from "../../utils/Suggestions";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {DAY_PICKER_INPUT_PROPS} from "../../utils/format";
import {Either} from "monet";
import {SubmitError} from "../../utils/FetchError";
import {
  faAlignLeft,
  faCamera,
  faClock,
  faCompress,
  faFolder,
  faSync,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {VisibilitySelect} from "./VisibilitySelect";

type DocEditorProps = {
  credentials: Credentials;
  onSubmit: (submitted: ABDocument, saved: Either<SubmitError, ABDocument[]>) => Promise<Either<SubmitError, ABDocument[]>>;
  edit: ABDocument;
  update: (doc?: ABDocument) => void;
  camera?: string;
  setCameraState: (cameraState?: string) => void;
  administratif?: boolean;
  allProjets?: immutable.Map<number, Projet>;
  cats?: immutable.Set<string>;
  suggestions: Suggestions;
};

type DocEditorState = {
  errors?: { [key: string]: string };
  cameraError?: Error;
  submitStatus: SubmitStatus;
  allCats: Loadable<string[]>;
  width?: number; height?: number;
  downscale: boolean;
  suggestions: string[];
};


export class DocEditor extends React.Component<DocEditorProps, DocEditorState> {
  state: DocEditorState = {
    submitStatus: SubmitStatus.disabled,
    downscale: false,
    suggestions: [],
    allCats: LOADING,
  };

  static defaultProps = {
    disableProject: false,
    suggestions: new Suggestions(),
  }

  allowCamera: boolean;

  constructor(props: DocEditorProps) {
    super(props);
    this.allowCamera = navigator?.mediaDevices?.getSupportedConstraints() !== undefined;
  }


  private refreshCats(projetId?: number): void {
    this.setState({allCats: LOADING});
    ABDocument.allCats(projetId)
      .then(allCats => this.setState({
        allCats: this.props.cats ? allCats.filter(c => this.props.cats!.has(c)) : allCats
      }))
    // .catch((error) => this.setState({allCats: error}));
  }

  componentDidMount(): void {
    this.refreshCats(this.props.edit?.projetId)
    if (this.props.camera) {
      this.setState({downscale: true});
    }
  }


  private handleSubmit(event: React.FormEvent<HTMLFormElement>, doc: ABDocument): void {
    event.preventDefault();
    event.stopPropagation();
    this.setState({submitStatus: SubmitStatus.working});

    doc.prepareFormData(event.currentTarget, this.state.downscale)
      .then(data => ABDocument.submitDoc(data, this.props.credentials))
      .then(r => this.props.onSubmit(doc, r))
      .then(r => r.cata(
        json =>
          this.setState({
            errors: json,
            submitStatus: SubmitStatus.disabled,
          }),
        () => this.hide()
      ))
      .catch((e: Error) => {
        this.setState({
          errors: {downscale: e.message},
          submitStatus: SubmitStatus.disabled
        });
      });
  }

  componentDidUpdate(prevProps: Readonly<DocEditorProps>): void {
    if (prevProps.camera === undefined && this.props.camera !== undefined) {
      this.setState({downscale: true});
    }

    if (this.props.edit && prevProps.edit?.projetId !== this.props.edit.projetId) {
      this.refreshCats(this.props.edit.projetId);
    }
  }


  private hide(): void {
    if (this.formRef.current) this.formRef.current.reset();
    this.setState({
      errors: undefined,
      cameraError: undefined,
      submitStatus: SubmitStatus.disabled,
    });
    this.props.update(undefined);
  }

  private formRef = React.createRef<any>();

  private onTakePhoto(edit: ABDocument, dataUri: string | File[]) {
    this.handleChange(edit, {file: dataUri});
    this.props.setCameraState(undefined);
  }

  private startCamera(edit: ABDocument): void {
    this.setState({downscale: true});
    this.handleChange(edit, {file: undefined});
    let camera: string;
    switch (this.props.camera) {
      case FACING_MODES.ENVIRONMENT:
        camera = FACING_MODES.USER;
        break;
      case FACING_MODES.USER:
      default:
        camera = FACING_MODES.ENVIRONMENT;
    }
    this.props.setCameraState(camera);
  }

  private handleCameraError(error: Error): void {
    this.setState({cameraError: error});
  }

  display(doc: ABDocument): React.ReactNode {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    switch (typeof doc.file) {
      case "object":
        if (Array.isArray(doc.file)) {
          return doc.file.map(f => f.name).join(", ");
        } else {
          return null;
        }
      case "string":
        return <Image style={{minHeight: "1px"}} fluid src={doc.file}/>;
      default:
        return doc.documentId ?
          <Image fluid src={`${backend}/documents/${doc.documentId}/preview`} alt={doc.title}/> :
          undefined;
    }

  }

  render() {
    const {edit} = this.props;
    const {errors} = this.state;

    return (
      <Modal show centered size="lg" className="bg-secondary" onHide={() => null}>
        <Form className="form-horizontal"
              onSubmit={(event: React.FormEvent<HTMLFormElement>) => this.handleSubmit(event, edit)}
              id="docForm"
              ref={this.formRef}>
          <input type="hidden" name="documentId" value={edit.documentId}/>
          <Modal.Header>
            <Row><Col md={12} className={"d-block"}>
              <Autosuggest
                suggestions={this.state.suggestions}
                inputProps={{
                  placeholder: "Titre du document…",
                  name: "title",
                  size: 200,
                  className: classNames(
                    "w-100 form-control-lg fw-bold",
                    {"is-invalid": errors?.title !== undefined}
                  ),
                  onChange: (e, {newValue}) =>
                    this.handleChange(edit, {title: newValue}),
                  value: edit.title,
                }}
                onSuggestionsFetchRequested={({value}) =>
                  this.setState({
                    suggestions: this.props.suggestions.updateSuggestions(value.toString())
                  })
                }
                onSuggestionsClearRequested={() =>
                  this.setState({suggestions: this.props.suggestions.clear()})
                }
                getSuggestionValue={(s) => Suggestions.getSuggestionValue(s.toString())}
                renderSuggestion={(s) => Suggestions.renderSuggestion(s.toString())}
              />
              <FormControl.Feedback
                className={classNames({"d-flex": errors?.title !== undefined})}
                type="invalid">{errors?.title}</FormControl.Feedback>
            </Col></Row>
          </Modal.Header>
          <Modal.Body className="me-2">
            {this.display(edit)}
            {edit.documentId === 0 &&
            <Form.Group className="mb-3">
                <Form.Label>Sélectionnez un document{this.allowCamera && <> ou prenez une photo</>}</Form.Label>
                <Row>
                    <Col md={"auto"}>
                        <Form.Control
                            isInvalid={errors?.doc !== undefined}
                            name={"doc"}
                          // style={{display: "none"}}
                            type="file"
                            multiple
                            onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files &&
                              this.onTakePhoto(edit, [...e.target.files])}
                        />
                    </Col>
                    <Col>
                      {this.allowCamera &&
                      <Button onClick={() => this.startCamera(edit)}
                              className="py-2 px-3">
                          <FontAwesomeIcon icon={this.props.camera ? faSync : faCamera}/>
                      </Button>
                      }
                    </Col>
                </Row>

                <FormControl.Feedback type="invalid">{errors?.doc}</FormControl.Feedback>
                <FormControl.Feedback
                    className={classNames({"d-block": this.state.cameraError})}
                    type="invalid">
                    Impossible d’accéder à l’appareil photo.
                </FormControl.Feedback>

              {this.allowCamera && this.props.camera &&
              <div className="d-flex justify-content-center">
                  <Camera
                      idealFacingMode={this.props.camera}
                      isImageMirror={this.props.camera === FACING_MODES.USER}
                      imageType={IMAGE_TYPES.JPG}
                      imageCompression={0.8}
                      idealResolution={{width: 1280}}
                      onTakePhotoAnimationDone={(dataUri: string) => this.onTakePhoto(edit, dataUri)}
                      onCameraError={(error: any) => this.handleCameraError(error)}
                  />
              </div>
              }


            </Form.Group>
            }


            {this.props.allProjets &&
            <Form.Group className="mb-3">
                <Form.Label>
                    <FontAwesomeIcon icon={faWrench}/> Projet
                </Form.Label>
                <Select
                    name="projet"
                    placeholder="Projet concerné…"
                    isClearable
                    className={classNames({"is-invalid": errors?.projet})}
                    value={this.potentialProject(this.props.allProjets, edit.projetId)}
                    options={this.props.allProjets.valueSeq().map(p => projetSelect(p)).toArray()}
                    onChange={o =>
                      this.handleChange(edit, {projetId: o?.value.projetId})}
                />
                <FormControl.Feedback type="invalid">{errors?.projet}</FormControl.Feedback>
            </Form.Group>
            }

            <Form.Group className="mb-3">
              <Form.Label> <FontAwesomeIcon icon={faFolder}/> Dossier </Form.Label>
              {waitFor(this.state.allCats, allCats => {
                return (
                  <CreatableSelect
                    isValidNewOption={(inputValue) =>
                      inputValue.length > 0 && this.props.cats === undefined
                    }
                    name="categorie"
                    placeholder="Catégorie…"
                    className={classNames({"is-invalid": errors?.categorie})}
                    value={{value: edit.categorie, label: edit.categorie}}
                    options={allCats.map(c => ({value: c, label: c}))}
                    onChange={(o) => this.handleChange(edit, {categorie: o?.value})}
                  />
                );
              })}
              <FormControl.Feedback type="invalid">{errors?.categorie}</FormControl.Feedback>
            </Form.Group>

            {this.props.credentials.in(GRANTS.itv, GRANTS.resp) &&

            <VisibilitySelect
                credentials={this.props.credentials}
                visibility={edit.visibility}
                onChange={(visibility, concerne) => {
                  this.handleChange(edit, {visibility, concerne})
                }}
                concerne={edit.concerne}
                isInvalid={(this.state.errors?.visibility || this.state.errors?.concerne) !== undefined}
                defaultConcerne={edit.posteur}
                projetId={edit.projetId}
                errors={errors}
            />

            }
            <Form.Group className="mb-3">
              <Form.Label>
                <FontAwesomeIcon icon={faAlignLeft}/> Commentaire
              </Form.Label>
              <FormControl
                as="textarea"
                value={edit.commentaire}
                onChange={(e) => this.handleChange(edit, {commentaire: e.currentTarget.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                name="downscale"
                className={classNames({"is-invalid": errors?.downscale !== undefined})}
              >
                <Form.Check.Input
                  type="checkbox" name="downscale"
                  checked={this.state.downscale}
                  onChange={() => this.setState({downscale: !(this.state.downscale)})}
                />{' '}
                <Form.Check.Label>
                  <FontAwesomeIcon icon={faCompress}/> Compresser l’image
                </Form.Check.Label>
              </Form.Check>
              <FormControl.Feedback
                className="offset-1"
                type="invalid">{errors?.downscale}</FormControl.Feedback>
            </Form.Group>
            {this.props.administratif && <>
                <Row>
                    <label className="col-1 text-end">
                        <FontAwesomeIcon icon={faClock}/>
                    </label>
                    <label>Échéance de la facture ou l’attestation :</label>
                </Row>
                <Row>
                    <Col md={{offset: 1}}>
                        <DayPickerInput
                          {...DAY_PICKER_INPUT_PROPS}
                          onDayChange={(day) =>
                            this.handleChange(edit, {
                              administratif: {
                                ...edit.administratif,
                                echeance: day
                              }
                            })
                          }
                          value={edit.administratif?.echeance}
                          inputProps={{className: classNames("form-control text-center", {"is-invalid": errors?.echeance})}}
                        />
                        <FormControl.Feedback
                            className={classNames({"d-flex": errors?.echeance !== undefined})}
                            type="invalid">{errors?.echeance}</FormControl.Feedback>
                    </Col>
                </Row>
            </>}
            {/*{waitFor(this.state.references, references =>*/}
            {/*  references && references.hasReferences() && <>*/}
            {/*        <Row>*/}
            {/*            <label className="col-1 text-end">*/}
            {/*                <FontAwesomeIcon icon={faHandPointRight}/>*/}
            {/*            </label>*/}
            {/*            <label>Éléments référençant ce document :</label>*/}
            {/*        </Row>*/}
            {/*        <Row>*/}
            {/*            <Col md={{offset: 1}}>*/}
            {/*              {references.tasks.map(t => `Tâche ${t.title}`)*/}
            {/*                .concat(references.signatures.map(s => `Signature de ${UserBadge.link(s.signer)}`))*/}
            {/*                .concat(references.checkLists.map(i => `Item checklist ${i.name}`))*/}
            {/*                .concat(references.dossiers.map(d => `Dossier facturation #${d.dossierId}`))*/}
            {/*                .concat(references.questions.map(q => `Question ${q.question}`))*/}
            {/*                .join(", ")*/}
            {/*              }*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </>*/}
            {/*)}*/}

          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <Button variant="secondary"
                      className={"mx-2"}
                      onClick={() => this.hide()}>Annuler</Button>
              <SubmitButton
                id="save"
                type="submit"
                submitstatus={this.state.submitStatus}
              >
                Enregistrer
              </SubmitButton>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      </Modal>
    );


  }

  handleChange(edit: ABDocument, change: Partial<ABDocument>) {
    this.props.update(edit.updated(change));
    this.setState({submitStatus: SubmitStatus.enabled});
  }

  potentialProject(allProjets: immutable.Map<number, Projet>, id?: number) {
    if (id) {
      const projet = allProjets.get(id);
      if (projet) {
        return projetSelect(projet);
      } else {
        return {
          value: {
            ...Projet.new(this.props.credentials.user),
            projetId: id
          },
          label: <>Projet clôturé {id}</>,
          searchable: '',
        };
      }
    } else {
      return undefined;
    }
  }
}

