import {ABDocument, DocumentActions, DocumentVisibility} from "../documents/Document";
import {User} from "../user-profile-lite/User";
import {Attestations} from "./Attestations";
import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import {DocumentCard} from "../documents/DocumentCard";
import {faClipboardCheck, faMehBlank, faSmile, faTimesCircle, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FunctionComponent} from "react";

type AttestationListProps = {
  actions: (doc: ABDocument) => DocumentActions;
  user: User;
  newDocument: (data: Partial<ABDocument>) => ABDocument;
  attestations: Attestations;
};

export const AttestationList: FunctionComponent<AttestationListProps> =
  ({
     user, actions, newDocument,
     attestations: {
       valides,
       invalides,
       manquants
     },
   }) => (
    <Card>
      <Card.Body>
        <ListGroup>
          {valides.length > 0 &&
          <ListGroup.Item>
              <h5 className="success"><FontAwesomeIcon icon={faSmile}/> Mes attestations en cours de validité</h5>
              <Row>
                {valides.map((da, i) =>
                  <Col key={i} lg="4" md="6" sm="12" className="mb-4">
                    <DocumentCard
                      key={da.documentId}
                      doc={da}
                      actions={actions(da)}
                      badges={da.badges()}
                    />
                  </Col>
                )}
              </Row>
          </ListGroup.Item>
          }
          {manquants.length === 0 ?
            <ListGroup.Item className="bg-success">
              <h5 className="text-white"><FontAwesomeIcon icon={faClipboardCheck}/> Dossier
                complet&nbsp;!</h5>
            </ListGroup.Item> :
            <ListGroup.Item>
              <h5 className="text-danger"><FontAwesomeIcon icon={faMehBlank}/> Attestations manquantes</h5>
              <ul className="list-unstyled">
                {manquants.map((m, i) =>
                  <li key={i}>

                    <Button className="text-center btn-success btn-circle m-2"
                            onClick={() => {
                              const doc = newDocument(
                                {
                                  visibility: DocumentVisibility.prestataire,
                                  categorie: "Attestations",
                                  concerne: user,
                                  title: m,
                                }
                              );
                              return actions(doc).onEdit!(doc)
                            }}>
                      <FontAwesomeIcon icon={faUpload}/>
                    </Button>

                    {m}
                  </li>)}
              </ul>
            </ListGroup.Item>
          }
          {invalides.length > 0 &&
          <ListGroup.Item className="bg-secondary">
              <h5 className="text text-white"><FontAwesomeIcon icon={faTimesCircle}/> Attestations invalides ou expirées
              </h5>
              <Row>
                {invalides.map(({document, reasons}, i) =>
                  <Col key={i} lg="4" md="6" sm="12" className="mb-4">
                    <DocumentCard
                      className="bg-light"
                      key={document.documentId}
                      badges={
                        document.badges()
                          .concat(
                            reasons.map(content => ({variant: "danger", content: <>{content}</>}))
                          )
                      }
                      doc={document}
                      actions={actions(document)}
                    />
                  </Col>
                )}
              </Row>
          </ListGroup.Item>
          }
        </ListGroup>
      </Card.Body>
    </Card>);
