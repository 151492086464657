import {GRANTS, useAuth} from "../utils/auth";
import {useProject} from "../utils/SelectedProject";
import * as immutable from "immutable";
import {CLItem} from "../components/checklist/CLItem";
import {WaitForAsync} from "../utils/Loading";
import {useState} from "react";
import {Container, ListGroup} from "react-bootstrap";
import {CheckListItem} from "../components/checklist/CheckListItem";
import {CheckListEditor} from "../components/checklist/CheckListEditor";
import {Either} from "monet";
import {useAsync} from "react-async-hook";
import {SubmitError} from "../utils/FetchError";


type AllCheckList = immutable.OrderedMap<number, CLItem>;

export function CheckListView() {
  const credentials = useAuth();
  const [projet] = useProject();

  const checkList = useAsync<AllCheckList>(() => {
    if (projet) {
      return CLItem.load(projet);
    } else {
      return new Promise(r => r(immutable.OrderedMap()));
    }
  }, [projet]);

  const [edit, setEdit] = useState<CLItem | undefined>();


  const updateItem = (updated: CLItem) => {
    setEdit(edit === undefined ? undefined : updated);
    checkList.execute();
  }

  const handleSubmit: (item: CLItem, submit: boolean) => Promise<Either<SubmitError, CLItem>> =
    (item: CLItem, submit: boolean) =>
      item.submit(credentials, submit)
        .then(u => {
          updateItem(u);
          return Either.right(u);
        });

  const validate = (item: CLItem, validation ?: boolean, justification ?: string) => {
    return item.doValidate(credentials, validation, justification)
      .then(u => updateItem(u));
  }


  if (projet) {
    return <Container fluid className="main-content-container px-4 py-4">
      <WaitForAsync async={checkList}>{checkList =>
        <>
          <ListGroup>
            {checkList.entrySeq().map(([key, cl]) =>
              <CheckListItem
                key={key}
                item={cl}
                onClick={() => setEdit(cl)}
              />
            )}
          </ListGroup>

          {edit &&
              <CheckListEditor
                  projet={projet}
                  credentials={credentials}
                  edit={edit}
                  onHide={() => setEdit(undefined)}
                  onChange={item => updateItem(item)}
                  save={(item, submit) => handleSubmit(item, submit)}
                  validate={(item, validation: boolean, justification?: string) =>
                    validate(item, validation, justification)}
                  unvalidate={
                    credentials.in(GRANTS.resp) ? (item) => validate(item) : undefined
                  }
              />
          }
        </>
      }</WaitForAsync>
    </Container>
  } else {
    return <>Pas de projet sélectionné</>;
  }
}


