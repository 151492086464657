import React, {Fragment, useState} from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  ToggleButton
} from "react-bootstrap";
import {filterStatus, flatMapRight, SubmitError} from "../utils/FetchError";
import Alerts, {AlertData} from "../utils/Alerts";

import {AllUsers} from "../utils/AllUsers";
import {Loadable, LOADING, waitFor, WaitForAsync} from "../utils/Loading";
import {Credentials, GRANTS, useAuth} from "../utils/auth";
import {Projet} from "../components/projets/Projet";
import {JSONUser, User} from "../components/user-profile-lite/User";
import {
  loadProjetCommercial,
  newProjetCommercial,
  ProjetCommercial,
  ProjetTuple,
  saveProjetCommercial
} from "../components/projets/ProjetCommercial";
import {Either} from "monet";
import {
  faComment,
  faExclamation,
  faHandshake,
  faPlus,
  faSearch, faShoppingCart,
  faTable,
  faWrench
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {parseISO} from "date-fns";
import {useAsync} from "react-async-hook";
import {useProject} from "../utils/SelectedProject";
import {UserBadge} from "../components/user-profile-lite/UserBadge";
import {DateFilter, Filter} from "../utils/DateFilter";
import ProjetsList from "../components/projets/ProjetsList";
import {ProjetCard} from "../components/projets/ProjetCard";
import {ProjetForm} from "../components/projets/ProjetForm";
import Notify from "../components/planning/Notify";
import {Recap} from "../components/projets/Recap";
import {AttributionsTable} from "../components/projets/AttributionsTable";
import {FournituresTable} from "../components/projets/FournituresTable";

export type ProjetsFilter = { ouverture: Filter, reception: Filter, cloture: Filter, checklist?: boolean };

enum ViewTypes {
  Edit, Recap, Attrib, Fournitures
}

export function ProjetsView() {
  const [reload, setReload] = useState(0);
  const [projet, setProjet] = useState<Loadable<Projet> | undefined>();
  const [editProjet, setEditProjet] = useState<Projet | undefined>();
  const [editProjetCommercial, setEditProjetCommercial] = useState<ProjetCommercial | undefined>();
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const [errors, setErrors] = useState<{ [key: string]: string } | undefined>();
  const [notify, setNotify] = useState<[User, Date?][] | undefined>();
  const [projetCommercial, setProjetCommercial] = useState<Loadable<ProjetCommercial> | undefined>();

  const [search, setSearch] = useState("");

  const [view, setView] = useState(ViewTypes.Edit);


  const [filters, setFilters] = useState<ProjetsFilter>({
    ouverture: {},
    reception: {},
    cloture: {nulls: false,}
  })

  const projets = useAsync(
    () => Projet.loadAll(filters.cloture.nulls !== false).then(projets => projets.valueSeq()),
    [filters.cloture.nulls]
  );

  const userList = useAsync(AllUsers.loadUsers, []);

  const credentials = useAuth();

  const handleSelectChantier = (i?: number) => {
    if (i) {
      setProjet(LOADING);
      Projet.load(i)
        .then(projet => setProjet(projet))
        .catch(error => setProjet(error));

      if (credentials.in(GRANTS.resp)) {
        setProjetCommercial(LOADING);
        loadProjetCommercial(i)
          .then(projetCommercial => setProjetCommercial(projetCommercial))
          .catch(error => setProjetCommercial(error));
      }
    } else {
      setProjet(undefined);
    }
  }


  const saveProjet = (credentials: Credentials, projet: Projet, pc?: ProjetCommercial) =>
    projet.saveProjet(credentials)
      .then(response => {
        if (pc) {
          return flatMapRight(response, projet =>
            saveProjetCommercial({...pc, projetId: projet.projetId}, credentials)
          )
            .then(savedPcEither => savedPcEither.map(savedPC => ({projet, pc: savedPC})));
        } else {
          return new Promise<Either<SubmitError, ProjetTuple>>(resolve =>
            resolve(response.map(projet => ({projet, pc: undefined})))
          );
        }
      })
      .then(save => {
        save.forEach(({projet, pc}: ProjetTuple) => {
          setProjet(projet);
          setProjetCommercial(pc);
          projets.execute();
          setReload(reload + 1);
          // setAlerts(
          //   Alerts.add(
          //     this,
          //     {
          //       theme: "success",
          //       text: <>Modifications du projet {projet.projetId} enregistrées</>
          //     })
          // );
        });

        save.forEachLeft(errors => {
          setErrors(errors);
          //setAlerts(Alerts.add(this, {theme: "danger", text: <>Vérifiez vos entrées</>}));
        });
        return save;
      });

  const handleNewProjet = (credentials: Credentials) => {
    setProjet(undefined);
    setEditProjet(Projet.new(credentials.user));
    setEditProjetCommercial(credentials.in(GRANTS.resp) ? newProjetCommercial : undefined);
  };


  const notifyAll = () => {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    return fetch(`${backend}/events/updated`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((itv: [JSONUser, string?][]) =>
        setNotify(itv.map(([u, d]) =>
          [User.parse(u), d ? parseISO(d) : undefined]
        ))
      );
  };

  const filterProjet = (p: Projet) =>
    filterSearch(p) &&
    DateFilter.filterDate(filters.ouverture, p.ouverture) &&
    DateFilter.filterDate(filters.reception, p.reception) &&
    DateFilter.filterDate(filters.cloture, p.termine);


  const filterSearch = (p: Projet) => {
    const motif = search.toLocaleLowerCase();

    return motif.trim() === "" ||
      p.title.toLocaleLowerCase().includes(motif) ||
      p.description.toLocaleLowerCase().includes(motif) ||
      p.adresse.toLocaleLowerCase().includes(motif) ||
      (p.client && UserBadge.link(p.client).toLocaleLowerCase().includes(motif)) ||
      UserBadge.link(p.responsable).toLocaleLowerCase().includes(motif);
  }


  const [currentProjet] = useProject();

  return <Fragment>
    <Alerts alerts={alerts}
            update={(newAlerts) => setAlerts(newAlerts)}/>
    <Container fluid className="main-content-container p-4">
      <Row>
        <Col>
          <Card className={"mb-3"}>
            <Card.Body>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch}/>
                </InputGroup.Text>
                <Form.Control
                  id="search"
                  className="search"
                  placeholder="Chercher un projet..."
                  value={search}
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearch(e.currentTarget.value)
                  }
                />
              </InputGroup>

              {credentials.in(GRANTS.resp) &&
                  <ButtonGroup className={"mt-3"}>
                      <ToggleButton
                          id={"radio-edit"}
                          type="radio"
                          name="radio"
                          value={ViewTypes.Edit}
                          checked={view === ViewTypes.Edit}
                          onChange={(e) => setView(ViewTypes.Edit)}
                      >
                          <FontAwesomeIcon icon={faWrench}/> Liste projets
                      </ToggleButton>
                      <ToggleButton
                          id={"radio-recap"}
                          type="radio"
                          name="radio"
                          value={ViewTypes.Recap}
                          checked={view === ViewTypes.Recap}
                          onChange={(e) => setView(ViewTypes.Recap)}
                      >
                          <FontAwesomeIcon icon={faTable}/> Progression projets
                      </ToggleButton>
                      <ToggleButton
                          id={"radio-attrib"}
                          type="radio"
                          name="radio"
                          value={ViewTypes.Attrib}
                          checked={view === ViewTypes.Attrib}
                          onChange={(e) => setView(ViewTypes.Attrib)}
                      >
                          <FontAwesomeIcon icon={faHandshake}/> Attributions
                      </ToggleButton>
                      <ToggleButton
                          id={"radio-fournitures"}
                          type="radio"
                          name="radio"
                          value={ViewTypes.Fournitures}
                          checked={view === ViewTypes.Fournitures}
                          onChange={(e) => setView(ViewTypes.Fournitures)}
                      >
                          <FontAwesomeIcon icon={faShoppingCart}/> Fournitures
                      </ToggleButton>
                  </ButtonGroup>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col><WaitForAsync async={projets}>{projets => {
          switch (view) {
            case ViewTypes.Edit:
              return <ProjetsList
                reload={reload}
                currentProjet={currentProjet}
                onClick={(i: number) => handleSelectChantier(i)}
                onDoubleClick={(projet: Projet) => selectCurrentProjet(projet)}
                credentials={credentials}
                projets={projets.filter(filterProjet)}
                filters={filters}
                setFilters={(f) => setFilters({...filters, ...f})}
              />;
            case ViewTypes.Recap:
              return <Recap
                onClick={(i: number) => handleSelectChantier(i)}
                projets={projets.filter(filterProjet)}
                selectFinished={filters.cloture.nulls !== false}
              />;
            case ViewTypes.Attrib:
              return <AttributionsTable
                projets={projets.filter(filterProjet)}
                selectFinished={filters.cloture.nulls !== false}
              />;
            case ViewTypes.Fournitures:
              return <FournituresTable
                projets={projets.filter(filterProjet)}
                selectFinished={filters.cloture.nulls !== false}/>
          }
        }}</WaitForAsync>
        </Col>
      </Row>


      <Row>
        <Col>
          {waitFor(projet,
            (projet?: Projet) => projet?.projetId &&
                <ProjetCard
                    projet={projet}
                    edit={
                      credentials.in(GRANTS.resp) ?
                        ((projet, projetCommercial) => {
                          setEditProjet(projet);
                          setEditProjetCommercial(projetCommercial);
                          setProjet(undefined);
                        })
                        :
                        undefined
                    }
                    commercial={projetCommercial}
                    onSelect={() => selectCurrentProjet(projet)}
                    onHide={() => handleSelectChantier(undefined)}
                />
          )}
        </Col>
      </Row>

      <div className="button-placeholder"/>

      {credentials.in(GRANTS.resp) &&

          <ButtonToolbar className="button-fixed">
              <Button className="btn-warning text-center btn-circle m-2 ms-auto btn-xl"
                      onClick={() => notifyAll()}
              >
            <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faComment} size="2x" transform={"left-3"}/>
            <FontAwesomeIcon icon={faExclamation} inverse/>
            </span>
              </Button>
              <Button className="btn-success btn-xl text-center btn-circle m-2"
                      onClick={() => handleNewProjet(credentials)}>
                  <FontAwesomeIcon icon={faWrench}/>
                  <FontAwesomeIcon icon={faPlus}/>
              </Button>
          </ButtonToolbar>

      }

      {editProjet && <WaitForAsync async={userList}>{userList =>
        <ProjetForm updateCurrentProjet={(editProjet?: Projet) => setEditProjet(editProjet)}
                    updateCurrentProjetCommercial={(editProjetCommercial?: ProjetCommercial) =>
                      setEditProjetCommercial(editProjetCommercial)}
                    projetCommercial={editProjetCommercial}
                    projet={editProjet}
                    credentials={credentials}
                    save={(projet: Projet, pc?: ProjetCommercial) => saveProjet(credentials, projet, pc)}
                    users={userList}/>
      }</WaitForAsync>}

      {notify &&
          <Notify
              credentials={credentials}
            // sendNotify={(intervenants, includePDF) =>
            //   PlanningView.sendNotify(intervenants, includePDF, props.credentials)}
              onHide={() => setNotify(undefined)}
              removeItv={(user: User) =>
                setNotify(
                  notify?.filter(([u]) => u.userId !== user.userId)
                )}
              intervenants={notify}
          />
      }

    </Container>

  </Fragment>;

}


function selectCurrentProjet(projet: Projet) {
  window.location.href = `?projetId=${projet.projetId}`;
}