import React, {ReactNode} from "react";
import {Badge, Modal} from "react-bootstrap";
import {CLItem, ClStatus} from "./CLItem";
import {GRANTS, WithLoginProps} from "../../utils/auth";
import {ABDocument} from "../documents/Document";
import {nl2br} from "../../utils/Nl2Br";
import SignForm from "../documents/SignForm";
import {Either} from "monet";
import {SubmitError} from "../../utils/FetchError";
import {CheckListPropose} from "./CheckListPropose";
import {Projet} from "../projets/Projet";
import {CheckListValidate} from "./CheckListValidate";
import {CheckListCompleted} from "./CheckListCompleted";

type CheckListEditorProps = WithLoginProps & {
  projet: Projet;
  edit: CLItem;
  onHide: () => void;
  save: (item: CLItem, submit: boolean) => Promise<Either<SubmitError, CLItem>>;
  validate: (item: CLItem, validation: boolean, justification?: string) => Promise<void>;
  unvalidate?: (item: CLItem) => Promise<void>;
  onChange: (item: CLItem) => void;
};


type CheckListEditorState = {

  signDoc?: ABDocument;
};

export class CheckListEditor extends React.Component<CheckListEditorProps, CheckListEditorState> {
  state: CheckListEditorState = {};

  private display(edit: CLItem) {
    if (!this.props.credentials.in(GRANTS.resp) && !this.props.edit.actionRequired) {
      return <CheckListCompleted
        edit={edit}
        onSign={(signDoc) => this.setState({signDoc})}
        onHide={this.props.onHide}
        unvalidate={this.props.unvalidate}
      />;
    } else {
      return null;
    }
  }

  private editor(edit: CLItem) {
    if (this.props.credentials.in(GRANTS.resp) || this.props.edit.actionRequired) {
      switch (edit.status) {
        case ClStatus.PENDING:
          return <CheckListPropose
            projet={this.props.projet}
            edit={edit}
            credentials={this.props.credentials}
            onSign={(signDoc) => this.setState({signDoc})}
            onSubmit={this.props.save}
            onHide={this.props.onHide}
          />;
        case ClStatus.SUBMITTED:
          return <CheckListValidate
            edit={edit}
            onSign={(signDoc) => this.setState({signDoc})}
            onHide={this.props.onHide}
            validate={this.props.validate}
          />
        case ClStatus.VALIDATED:
          return <CheckListCompleted
            edit={edit}
            onSign={(signDoc) => this.setState({signDoc})}
            onHide={this.props.onHide}
            unvalidate={this.props.unvalidate}
          />;
      }
    } else {
      return null;
    }
  }

  render(): ReactNode {
    const {edit} = this.props;

    return (<>
      <Modal show size={"lg"} onHide={this.props.onHide}>
        <Modal.Header>
          <h4>{edit.name}</h4>
        </Modal.Header>
        <Modal.Body>


          <h5><Badge bg={edit.variant()}>{edit.statusString()}</Badge></h5>

          {edit.justification && <>
              <h6>Motif du refus :</h6>
              <p>{nl2br(edit.justification)}</p>
          </>
          }

          <div>
            {this.editor(edit)}
            {this.display(edit)}
          </div>

        </Modal.Body>

      </Modal>

      {this.state.signDoc &&
      <SignForm
          doc={this.state.signDoc}
          credentials={this.props.credentials}
          onUpdate={(document) =>
            this.props.onChange(this.props.edit.updated({document}))
          }
          onHide={() => this.setState({signDoc: undefined})}
      />
      }
    </>);
  }


}
