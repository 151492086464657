import React, {ReactNode} from "react";
import {OptionChantier} from "./OptionChantier";
import {formatDec, formatEur, NUMBER_FORMAT_FR} from "../../utils/format";
import {NumericFormat} from "react-number-format";
import {Button, Form, InputGroup} from "react-bootstrap";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type OptionChantierLIProps = {
  option: OptionChantier;
  onClick: (o: OptionChantier) => void;
  edit: boolean;
  onChange: (e?: OptionChantier) => void;
  disabled: boolean;
};

type OptionChantierLIState = Record<string, never>;

const startsWithNumber = /^\d/;

export class OptionChantierLI extends React.Component<OptionChantierLIProps, OptionChantierLIState> {

  render(): ReactNode {
    const o = this.props.option;
    const estimate = o.estimate();
    return <tr onClick={() => !this.props.disabled && this.props.onClick(o)}>
      <td>
        {o.name}
      </td>
      <td className="text-end me-0 pr-0">
        {formatEur(Number(o.price))}
      </td>
      <td className="ms-0 pl-1">
        {o.unite === 'FT' ? '(forfait)' : `/ ${o.unite.replaceAll(" ", " ")}`}
      </td>

      <td className="text-center">
        {this.props.edit ?
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <InputGroup>
              <NumericFormat
                className="form-control"
                size={3}
                {...NUMBER_FORMAT_FR}
                value={o.eval}
                onValueChange={({floatValue}) =>
                  this.props.onChange(o.updated({eval: floatValue}))}
              />

              <Button
                type="submit"
                className="p-2"
              >
                <FontAwesomeIcon icon={faCheck}/>
              </Button>
            </InputGroup>
          </Form> :
          o.unite === 'FT' ?
            <Form.Check type="checkbox"
                        checked={o.eval > 0}
                        readOnly
            />
            :
            <>{formatDec(o.eval)}&nbsp;{o.eval > 0 && (
              o.unite === 'U' ? '' :
                startsWithNumber[Symbol.match](o.unite) ? `× ${o.unite}` :
                  o.unite
            )}</>
        }
      </td>

      <td className="text-end">{formatEur(estimate)}</td>

      {/*<td className="text-end">*/}
      {/*  <Checkbox*/}
      {/*    disabled={this.props.disabled || o.evalMax === 0}*/}
      {/*    checked={o.selected === true}*/}
      {/*    indeterminate={o.selected === null}*/}
      {/*    onChange={(e) => this.props.onClickCheckBox()}*/}
      {/*  />*/}
      {/*</td>*/}

    </tr>;
  }

  private handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.onChange(undefined);
  }
}