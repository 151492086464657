import {Badge, Button, Card, CloseButton, Container, ProgressBar, Table} from "react-bootstrap";
import {LogoEntreprise, Projet} from "./Projet";
import {useAsync} from "react-async-hook";
import {WaitForAsync} from "../../utils/Loading";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {CLItem, GeneralItem} from "../checklist/CLItem";
import {Fourniture, FournitureStatus, loadAllFournituresFor} from "../fournitures/Fourniture";
import immutable, {Seq} from "immutable";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {GRANTS, useAuth} from "../../utils/auth";
import 'react-day-picker/lib/style.css';
import {EditableDate} from "../planning/EditableDate";
import {loadDonneesRecap, newProjetRecap, ProjetRecap, saveProjetRecap} from "./ProjetRecap";
import {isPast} from "date-fns";
import {loadDonneesCommerciales, ProjetCommercial} from "./ProjetCommercial";
import {Variant} from "react-bootstrap/types";
import {formatDate} from "../../utils/format";
import UsersList from "../user-profile-lite/UsersList";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import filterOptions from "../../utils/SelectSearch";
import Select from "react-select";
import {User} from "../user-profile-lite/User";
import {DureePrevue} from "./ProjetsList";
import {ProjetParResponsable} from "./ProjetParResponsable";


const NumberFormatPERC = new Intl.NumberFormat('fr-FR', {
  style: 'percent',
  maximumFractionDigits: 0,
});


const bt01 = 220
const bt01Pret = immutable.Set.of(50, 160, 10, 195)

const ProgressionContraignants = (props: { p: Projet, allItems: GeneralItem[], checklist: immutable.Map<number, CLItem> }) => {
  const allContraignants = immutable.Set(props.allItems.filter(i => i.contraignant === "contraignant").map(i => i.item))


  if (props.p.ouverture) return <>En chantier</>;
  else {
    const progression = immutable.Seq(props.checklist)
      .filter(cl => cl.validate && cl.contraignant === "contraignant")
      .count(cl => allContraignants.has(cl.item)) / allContraignants.size
    if (progression === 0) {
      return <>Vendu</>;
    } else if (progression === 1) {
      return <>Prêt à démarrer</>;
    } else {
      return <a href={"/projet/checklist?projetId=" + props.p.projetId}>
        <ProgressBar
          now={progression * 100}
          label={NumberFormatPERC.format(progression)}
        />
      </a>;
    }
  }
}

const ProgressionAJour = (props: { allItems: GeneralItem[], checkList: immutable.Map<number, CLItem> }) => {
  const aJour = immutable.Set(props.allItems.filter(i => i.contraignant !== "a-jour").map(i => i.item));
  const progression = props.checkList
    .filter(cl => cl.validate && cl.contraignant !== "a-jour")
    .count(cl => aJour.has(cl.item)) / aJour.size

  return < ProgressBar
    now={progression * 100}
    label={NumberFormatPERC.format(progression)}
  />
}

type RecapProps = {
  onClick: (i: number) => void;
  currentProjet?: Projet;
  projets: Seq.Indexed<Projet>;
  selectFinished: boolean;
};

function BT01(props: { projet: Projet, checkList: immutable.Map<number, CLItem> }) {
  if (props.checkList.get(bt01)?.validate) {
    return <>Signé</>;
  } else if (props.checkList.get(bt01)?.submitted) {
    return <>Envoyé</>;
  } else {
    const progress = bt01Pret.count(i => props.checkList.get(i)?.validate !== undefined) / bt01Pret.size
    if (progress === 1) {
      return <>À faire</>;
    } else {
      return <ProgressBar now={progress * 100} label={NumberFormatPERC.format(progress)}/>;
    }
  }
}

function ContreEtude(props: { projet: Projet, donnees?: ProjetCommercial }) {
  if (props.donnees?.mbContreEtude) {
    let style: Variant;
    if (props.donnees.mbContreEtude >= 25 && props.donnees.mbContreEtude >= (props.donnees.mbPrevisionnelle ?? 0) - 1) {
      style = "success"
    } else {
      style = "danger"
    }
    return <Badge bg={style}>
      {NumberFormatPERC.format(props.donnees.mbContreEtude / 100)}
    </Badge>;
  } else {
    return <Badge>N/D</Badge>;
  }
}


export function Recap(props: RecapProps) {

  const allItems = useAsync(CLItem.allItems, []);
  const allChecklists = useAsync(CLItem.loadAll, [props.selectFinished]);
  // const menuiseries = useAsync(() => loadAllFournituresFor(props.selectFinished, 7), [props.selectFinished]);
  const credentials = useAuth();
  const [display, setDisplay] = useState(immutable.Set.of(credentials.user.userId))
  const donneesCommerciales = useAsync(() =>
      loadDonneesCommerciales({}, props.selectFinished)
        .then(r => immutable.Map(r.map(({projet, projetCommercial}) => [projet.projetId, projetCommercial])))
    ,
    [props.selectFinished]
  );

  const allItvs = useAsync(() => AllUsers.loadItv(credentials), [credentials]);

  const recap = useAsync(
    loadDonneesRecap,
    [props.selectFinished],
    {setLoading: state => ({...state, loading: true})}
  )

  const [editMAPClient, setEditMAPClient] = useState<number | undefined>();
  const [editDemarrage, setEditDemarrage] = useState<number | undefined>();


  const handleRecapUpdate = (update: Partial<ProjetRecap>, projetId: number, old?: ProjetRecap) => {
    const updated = {...(old ?? newProjetRecap(projetId)), ...update};

    return saveProjetRecap(updated, credentials).then(r =>
      r.map(() => recap.execute(props.selectFinished))
        .leftMap((error) => error.toString())
    );
  }


  const MiseAuPointClient = (props: { allItems: GeneralItem[], checkList: immutable.Map<number, CLItem>, projetId: number, recap?: ProjetRecap }) => {
    const done = props.checkList.filter(cl => cl.validate)
    const contraignants = Seq.Indexed(props.allItems).filter(i => i.contraignant === "contraignant").count(i => done.has(i.item))

    if (contraignants === done.size) {
      const miseAuPointClient = props.recap?.miseAuPointClient;
      if (miseAuPointClient && isPast(miseAuPointClient) && editMAPClient !== props.projetId) {
        return <Button size="sm" variant="link"
                       onClick={() => setEditMAPClient(props.projetId)}>Fait</Button>
      } else {
        return <EditableDate
          editable={true}
          date={miseAuPointClient}
          onChange={(miseAuPointClient) =>
            handleRecapUpdate({miseAuPointClient}, props.projetId, props.recap)
              .then(r => r.map(() => setEditMAPClient(undefined)))
          }
          placeholder={"À planifier"}
          disabled={[]}
          dateFormat={() => "d MMM"}
        />
      }
    } else {
      return <>Non prêt</>;
    }
  }

  const DemarragePrevu = (props: { projet: Projet, recap?: ProjetRecap }) => {
    if (editDemarrage === props.projet.projetId) {

      return <>
        <EditableDate
          editable={true}
          date={props.recap?.demarragePrevu}
          onChange={(demarragePrevu) =>
            handleRecapUpdate({demarragePrevu}, props.projet.projetId, props.recap)
          }
          placeholder={"N/D"}
          disabled={[]}
          dateFormat={() => "d MMM"}
        />
        <WaitForAsync async={allItvs}>{(allItvs) => {
          if (allItvs) {
            const promis: User | undefined = props.recap?.promisPar === undefined ? undefined : allItvs.get(props.recap.promisPar);
            return <>Promis par : <Select
              value={userSelect(promis)}
              name="resources"
              isSearchable
              isClearable
              //isDisabled={false}
              filterOption={filterOptions}
              options={allItvs.userSelect(u => AllUsers.atLeast(u, GRANTS.resp))}
              onChange={(promisPar) =>
                handleRecapUpdate({promisPar: promisPar?.value.userId}, props.projet.projetId, props.recap)
              }
              className="basic-multi-select"
              classNamePrefix="select"
            /></>;
          } else {
            return <></>;
          }
        }}</WaitForAsync>
        <CloseButton onClick={() => setEditDemarrage(undefined)}/>
      </>;
    } else {
      return <Button size={"sm"} variant={"link"} onClick={() => setEditDemarrage(props.projet.projetId)}>
        {props.recap?.demarragePrevu ? formatDate(props.recap.demarragePrevu) : 'N/D'}
      </Button>
    }
  }


  return <Card>
    <Card.Body>
      <Table>
        <thead>
        <tr>
          <th className="position-absolute"/>
          <th style={{paddingLeft: "300px"}}/>
          <th>État</th>
          <th>Bati+</th>
          {/*<th>Menuiseries</th>*/}
          <th>MaP client</th>
          <th>BT01</th>
          <th>Contre-étude</th>
          <th>Signature</th>
          <th>Démarrage</th>
          <th>Durée</th>
          <th>Réception</th>
        </tr>
        </thead>

        {/*<WaitForAsync async={menuiseries}>{menuiseries =>*/}
          <WaitForAsync async={allItems}>{allItems =>
            <WaitForAsync async={allChecklists}>{allChecklists =>
              <WaitForAsync async={donneesCommerciales}>{donneesCommerciales =>
                <WaitForAsync async={recap}>{recap =>
                  <tbody className={"overflow-y h-100"}>
                  <ProjetParResponsable projets={props.projets} colSpan={9}>{p =>
                    <>

                      <td>

                        <ProgressionContraignants p={p} allItems={allItems}
                                                  checklist={allChecklists.get(p.projetId, immutable.Map())}/>

                      </td>
                      <td>
                        <a href={"/projet/checklist?projetId=" + p.projetId}>
                          <ProgressionAJour allItems={allItems}
                                            checkList={allChecklists.get(p.projetId, immutable.Map())}/>
                        </a>
                      </td>
                      {/*<td>*/}
                      {/*  <a href={"/projet/fournitures?projetId=" + p.projetId}>*/}
                      {/*    {menuiseries.has(p.projetId) ? menuiseries.get(p.projetId)!.get(7, Seq.Indexed<Fourniture>())*/}
                      {/*      .map(m => FournitureStatus.get(m.statut)).join(", ") : "N/A"}*/}
                      {/*  </a>*/}
                      {/*</td>*/}
                      <td>
                        <MiseAuPointClient
                          allItems={allItems}
                          checkList={allChecklists.get(p.projetId, immutable.Map())}
                          projetId={p.projetId}
                          recap={recap.get(p.projetId)}
                        />
                      </td>
                      <td>
                        <a href={"/projet/checklist?projetId=" + p.projetId}>
                          <BT01 projet={p} checkList={allChecklists.get(p.projetId, immutable.Map())}/>
                        </a>
                      </td>
                      <td onClick={() => props.onClick(p.projetId)}>
                        <ContreEtude projet={p} donnees={donneesCommerciales.get(p.projetId)}/>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          onClick={() => props.onClick(p.projetId)}
                          variant={"link"}>{p.signature ? formatDate(p.signature) : "N/D"}</Button>
                      </td>
                      <td>
                        <DemarragePrevu projet={p} recap={recap.get(p.projetId)}/>
                      </td>
                      <td>
                        <DureePrevue projet={p}/>
                      </td>
                      <td>
                        <Button size="sm" variant="link" onClick={() => props.onClick(p.projetId)}>
                          {p.reception ? formatDate(p.reception) : "N/D"}
                        </Button>
                      </td>
                    </>
                  }</ProjetParResponsable>
                  </tbody>
                }</WaitForAsync>
              }</WaitForAsync>
            }</WaitForAsync>
          }</WaitForAsync>
        {/*}</WaitForAsync>*/}
      </Table>
    </Card.Body>
  </Card>;

}