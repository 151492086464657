import {Entreprise, LogoEntreprise, Projet} from "../projets/Projet";
import {ProjetCommercial} from "../projets/ProjetCommercial";
import {useState} from "react";
import {Button, Card, Collapse, Table, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {formatEur, formatPerc} from "../../utils/format";
import {UserBadge} from "../user-profile-lite/UserBadge";
import Select from "react-select";
import {ProjetForm} from "../projets/ProjetForm";
import {prixPC} from "./Ventes";
import immutable, {Seq} from "immutable";


type PPC = { projet: Projet; projetCommercial: ProjetCommercial; }

type MargesProps = {
  entreprises: Entreprise[],
  onEditProjet: (projet: Projet) => void,
  projets: PPC[],

}

function computeMargesTotales(projs: Seq.Indexed<PPC>, marge: (pc: ProjetCommercial) => number | undefined) {
  const projetsConcernes = projs.map(p => [prixPC(p.projetCommercial), marge(p.projetCommercial)])
    .flatMap<[number, number]>(([prix, marge]) =>
      prix !== undefined && marge !== undefined ?
        Seq.Indexed.of([prix, marge]) : Seq.Indexed.of()
    );

  if (!projetsConcernes.isEmpty()) {
    const prixTotal = projetsConcernes.reduce((a, b) => a + b[0], 0);
    const margeTotale = projetsConcernes.reduce((a, b) => a + b[0] * b[1] / 100, 0);
    return `(${formatEur(margeTotale)}) ${formatPerc(margeTotale / prixTotal)}`;
  }
}

export function Marges({entreprises, projets, onEditProjet}: MargesProps) {
  const [detail, setDetail] = useState(false);
  const [entreprise, setEntreprise] = useState<Entreprise | undefined>();
  const projetsSeq = Seq.Indexed(projets)
    .filter(p => entreprise === undefined || p.projet.entreprise?.entrepriseId === entreprise.entrepriseId);
  const [display, setDisplay] = useState(immutable.Set<number>())

  return <Card className={"my-3"}>
    <Card.Header>
      Marges sur une période donnée
    </Card.Header>
    <Card.Body>
      <Table>
        <thead>
        <tr>
          <th>
            <Button className="py-0" size="lg" variant="link" onClick={() => setDetail(!detail)}><FontAwesomeIcon
              icon={detail ? faAngleUp : faAngleDown}/></Button>
          </th>
          <th className={"text-end"}>Marge prévisionnelle</th>
          <th className={"text-end"}>Marge contre-étude</th>
          <th className={"text-end"}>Marge définitive</th>
        </tr>
        <tr>
          <th>
            <Select name={"entreprise"}
                    value={entreprise && ProjetForm.entrepriseSelect(entreprise)}
                    options={entreprises.map(ProjetForm.entrepriseSelect)}
                    onChange={(selected) => setEntreprise(selected?.value)}
                    isClearable
            />
          </th>
          <th className={"text-end"}>
            {computeMargesTotales(projetsSeq, pc => pc.mbPrevisionnelle)}
          </th>
          <th className={"text-end"}>
            {computeMargesTotales(projetsSeq, pc => pc.mbContreEtude)}
          </th>
          <th className={"text-end"}>
            {computeMargesTotales(projetsSeq, pc => pc.mbDefinitive)}
          </th>
        </tr>

        </thead>
        <Collapse in={detail}>
          <tbody>
          {projetsSeq
            .groupBy(p => p.projetCommercial.commercial?.userId)
            .toArray()
            .map(([commercial, projs]) =>
              <>
                <tr>
                  <td>
                    {display.has(commercial ?? -1) ?
                      <Button className="py-0" size="lg" variant="link"
                              onClick={() => setDisplay(display.remove(commercial ?? -1))}>
                        <FontAwesomeIcon icon={faAngleUp}/>
                      </Button> :
                      <Button className="py-0" size="lg" variant="link"
                              onClick={() => setDisplay(display.add(commercial ?? -1))}>
                        <FontAwesomeIcon icon={faAngleDown}/>
                      </Button>
                    }
                    {commercial ? <UserBadge>{projs.first()!.projetCommercial.commercial!}</UserBadge> : "Vendeur non renseigné"}
                  </td>
                  <td className={"text-end"}>
                    {computeMargesTotales(projs.valueSeq(), pc => pc.mbPrevisionnelle)}
                  </td>
                  <td className={"text-end"}>
                    {computeMargesTotales(projs.valueSeq(), pc => pc.mbContreEtude)}
                  </td>
                  <td className={"text-end"}>
                    {computeMargesTotales(projs.valueSeq(), pc => pc.mbDefinitive)}
                  </td>
                </tr>
                {(display.has(commercial ?? -1)) && projs.valueSeq().map(p => {
                  const pc = p.projetCommercial;
                  const prix = prixPC(pc);
                  return <tr key={p.projet.projetId}>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => onEditProjet(p.projet)}
                      >
                        {p.projet.title}
                      </Button>− <UserBadge>{p.projet.client!}</UserBadge> (
                      {prix ? formatEur(prix) : "prix non renseigné"})
                    </td>
                    <td className={"text-end"}>
                      {prix && pc.mbPrevisionnelle && `(${formatEur(prix * pc.mbPrevisionnelle / 100)})`} {
                      pc.mbPrevisionnelle ? formatPerc(pc.mbPrevisionnelle / 100) : "non renseignée"}
                    </td>
                    <td className={"text-end"}>
                      {prix && pc.mbContreEtude && `(${formatEur(prix * pc.mbContreEtude / 100)})`} {
                      pc.mbContreEtude ? formatPerc(pc.mbContreEtude / 100) : "non renseignée"}
                    </td>
                    <td className={"text-end"}>
                      {prix && pc.mbDefinitive && `(${formatEur(prix * pc.mbDefinitive / 100)})`} {
                      pc.mbDefinitive ? formatPerc(pc.mbDefinitive / 100) : "non renseignée"}
                    </td>
                  </tr>
                })}
              </>
            )
          }
          </tbody>
        </Collapse>
      </Table>
    </Card.Body>
  </Card>
    ;
}