import {Button, Card, Container, FormCheck, FormGroup, FormLabel, Modal, Table} from "react-bootstrap";
import {Loadable, LOADING, WaitFor, waitFor, WaitForAsync} from "../utils/Loading";
import {filterStatus} from "../utils/FetchError";
import immutable from "immutable";
import {
  FacturationProjet,
  fetchFacturations, fetchStades,
} from "../components/facturation/Facturation";
import {Entreprise, fetchEntreprises, Projet} from "../components/projets/Projet";
import Select from "react-select";
import {ProjetForm} from "../components/projets/ProjetForm";
import {nl2br} from "../utils/Nl2Br";
import {User} from "../components/user-profile-lite/User";
import {AllUsers, userSelect} from "../utils/AllUsers";
import filterOptions from "../utils/SelectSearch";
import {formatDate} from "../utils/format";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAsync} from "react-async-hook";
import {useEffect, useState} from "react";
import {FacturationTable} from "../components/facturation/FacturationTable";


const backend = process.env.REACT_APP_BACKEND_SERVER;


export function FacturationView() {
  const entreprises = useAsync(fetchEntreprises, []);
  const [entreprise, setEntreprise] = useState<Entreprise | undefined>();

  const [preview, setPreview] = useState<Loadable<{ comptable?: User, mail: string }> | undefined>();

  const [showCloture, setShowCloture] = useState(false);

  useEffect(() => {
      setEntreprise(entreprises.result?.find(s => s.entrepriseId === 1))
    }, [entreprises.result]
  );

  const [refresh, setRefresh] = useState(0);

  const prepareMail = ({entrepriseId}: Entreprise) => {
    setPreview(LOADING);
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(`${backend}/facturation/${entrepriseId}/preview`, {credentials: "include"})
      .then(filterStatus)
      .then((r) => r.json())
      .then((preview) => setPreview(preview));
  }

  const sendAndValidate = (entrepriseId: number, comptable: number) => {
    fetch(`${backend}/facturation/${entrepriseId}/send/${comptable}`, {credentials: "include"})
      .then(filterStatus)
      .then(() => {
        setPreview(undefined);
        setRefresh(refresh + 1);
      });
  }

  return <Container fluid className="main-content-container px-4 py-4">
    <Card>
      <Card.Header>
        <FormGroup className="mb-3">
          <FormLabel>Entreprise :</FormLabel>
          <WaitForAsync async={entreprises}>{(entreprises: Entreprise[]) =>
            <Select name={"entreprise"}
                    value={entreprise && ProjetForm.entrepriseSelect(entreprise)}
                    options={entreprises.map(ProjetForm.entrepriseSelect)}
                    onChange={(selected) => setEntreprise(selected?.value)}
            />
          }</WaitForAsync>
        </FormGroup>
        <FormGroup>
          <FormCheck name="showCloture"
                     checked={showCloture}
                     label={"Voir projets clôturés"}
                     onChange={(e) => setShowCloture(e.currentTarget.checked)}
          />
        </FormGroup>
      </Card.Header>
      <Card.Body>
        <FacturationTable showCloture={showCloture} entreprise={entreprise} refresh={refresh}/>
      </Card.Body>
      <Card.Footer className="text-end">
        <Button
          disabled={entreprise === undefined}
          onClick={() => entreprise && prepareMail(entreprise)}>
          <FontAwesomeIcon icon={faEnvelope}/> Envoyer
        </Button>
      </Card.Footer>
    </Card>

    <Modal show={preview !== undefined} onHide={() => setPreview(undefined)}>
      <Modal.Header>
        Validation des facturations
      </Modal.Header>
      {waitFor(preview, (preview) =>
          preview && <WaitFor asyncFunction={AllUsers.loadUsers} params={[]}>{(users) =>
            <>
              <Modal.Body>
                <FormGroup className="mb-3">
                  <FormLabel>Destinataire</FormLabel>
                  <Select
                    name="comptable"
                    value={userSelect(preview?.comptable)}
                    options={users.userSelect()}
                    isSearchable={true}
                    filterOption={filterOptions}
                    onChange={selected =>
                      setPreview({
                        ...preview,
                        comptable: selected?.value// && users.get(selected.value)
                      })
                    }
                  />
                </FormGroup>

                <div className="text-monospace border-light">
                  {preview && nl2br(preview.mail)}
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setPreview(undefined)}
                        variant="secondary">
                  Annuler
                </Button>
                <Button
                  disabled={preview?.comptable === undefined}
                  onClick={() =>
                    entreprise && preview?.comptable &&
                    sendAndValidate(entreprise.entrepriseId, preview.comptable.userId)}>
                  Envoyer et valider
                </Button>
              </Modal.Footer>
            </>
          }</WaitFor>
      )}
    </Modal>
  </Container>;


}