import {Projet} from "../projets/Projet";
import {filterStatus} from "../../utils/FetchError";
import {Credentials} from "../../utils/auth";
import * as immutable from "immutable";
import {parseISO} from "date-fns";

export interface Critere {
  critereId: number
  nomCritere: string
}

export type Note = {
  critere: Critere,
  note?: number,
  date?: string
}

export interface NotationHistory {
  date: Date,
  notes: immutable.Map<number, number>,
  moyenne: number,
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export class Notation {
  static loadCurrent(projet: Projet): Promise<{ notes: Note[], moyenne: number }> {
    return fetch(`${backend}/projet/${projet.projetId}/currentNotation`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
  }

  static noter(credentials: Credentials, projet: Projet, critere: Critere, note: number): Promise<any> {
    return fetch(
      `${backend}/projet/${projet.projetId}/noter/${critere.critereId}/${note}`,
      {
        credentials: "include",
        method: 'PUT',
        headers: credentials.headers()
      }
    )
      .then(filterStatus)
  }

  static loadHistory(projet: Projet): Promise<NotationHistory[]> {
    return fetch(`${backend}/projet/${projet.projetId}/notationHistory`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((data: { date: string, notes: [Critere, number][], moyenne: number }[]) =>
        data.map(({date, notes, moyenne}) => (
            {
              date: parseISO(date),
              moyenne,
              notes: immutable.Map(notes.map(([critere, note]) => [critere.critereId, note]))
            }
          )
        )
      )
  }
}