import {Button, Card, Collapse, Table} from "react-bootstrap";
import {Entreprise, LogoEntreprise, Projet} from "../projets/Projet";
import {FacturationProjet} from "../facturation/Facturation";
import immutable, {Seq} from "immutable";
import {formatEur, formatPerc} from "../../utils/format";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

type PlanDeChargeProps = {
  entreprises: Entreprise[],
  facturations: FacturationProjet[],
  onEditProjet: (projet: Projet) => void,
}

export function PlanDeCharge({entreprises, facturations, onEditProjet}: PlanDeChargeProps) {

  const [detail, setDetail] = useState(false);

  const taux = immutable.Map<number, number>(Seq.Indexed(facturations).flatMap(f => {
    if (f.projet.termine) {
      return Seq.Indexed.of([f.projet.projetId, 1]);
    } else {
      const taux = f.facturations.filter(v => v.validDate !== undefined).valueSeq().map(s => s.taux).max();
      if (taux) {
        return Seq.Indexed.of([f.projet.projetId, taux]);
      } else {
        return Seq.Indexed.of();
      }
    }
  }));

  return <Card className={"my-3"}>
    <Card.Header>Plan de charge</Card.Header>
    <Card.Body>
      <Table>
        <thead>
        <tr>
          <th>
            <Button className="py-0" size="lg" variant="link" onClick={() => setDetail(!detail)}><FontAwesomeIcon
              icon={detail ? faAngleUp : faAngleDown}/></Button>
          </th>
          {entreprises.map(e => <th className="text-end" key={e.entrepriseId}>{e.nom}&nbsp;
            <LogoEntreprise>{e}</LogoEntreprise></th>)}
        </tr>
        <tr>
          <th>Total = {formatEur(
            facturations
              .map(p => (p.prix ?? 0) * (1 - taux.get(p.projet.projetId, 0)))
              .reduce((a, v) => a + v, 0)
          )}</th>
          {entreprises.map(e =>
            <th className="text-end"
                key={e.entrepriseId}>
              {formatEur(
                facturations
                  .filter(p => p.projet.entreprise?.entrepriseId === e.entrepriseId)
                  .map(p => (p.prix ?? 0) * (1 - taux.get(p.projet.projetId, 0)))
                  .reduce((a, v) => a + v, 0)
              )}
            </th>
          )}
        </tr>
        </thead>
        <Collapse in={detail}>
          <tbody>
          {facturations.map(f =>
            <tr key={f.projet.projetId}>
              <td>
                <Button
                  variant="link"
                  onClick={() => onEditProjet(f.projet)}
                >
                  {f.projet.title}
                </Button>− <UserBadge>{f.projet.client!}</UserBadge>&nbsp;
                ({formatPerc(taux.get(f.projet.projetId, 0))})
              </td>
              {entreprises.map(e => {
                if (e.entrepriseId === f.projet.entreprise?.entrepriseId) {
                  if (f.prix !== undefined) {
                    return <td className="text-end"
                               key={e.entrepriseId}>{formatEur(f.prix * (1 - taux.get(f.projet.projetId, 0)))}</td>;
                  } else {
                    return <td className="text-end" key={e.entrepriseId}>prix non renseigné</td>;
                  }
                } else {
                  return <td key={e.entrepriseId}/>;
                }
              })}
            </tr>
          )}
          </tbody>
        </Collapse>
      </Table>

    </Card.Body>
  </Card>
}