import {JSONUser, User} from "../user-profile-lite/User";
import {ABDocument, JSONDocument} from "../documents/Document";
import {parse} from "query-string";
import * as LZString from "lz-string";
import {AllUsers} from "../../utils/AllUsers";
import queryString from "query-string";
import FetchError, {filterStatus} from "../../utils/FetchError";
import {parseISO} from "date-fns";
import {Projet} from "../projets/Projet";
import {undefinedToArray} from "../../utils/miscellaneous";
import {Credentials} from "../../utils/auth";
import {Either} from "monet";
import immutable from "immutable";

interface IMessage {
  readonly  timestamp: Date;
  readonly  messageId: number;
  readonly   content: string;
  readonly   subject: string;
  readonly sender: User;
  readonly  recipient: User[];
  readonly   attachedDocs: ABDocument[];
  readonly   attachedBlobs: string[];
  readonly unread: boolean;
}

interface JSONMessage {
  readonly  timestamp: string;
  readonly  messageId: number;
  readonly   content: string;
  readonly   subject: string;
  readonly sender: JSONUser;
  readonly  recipient: JSONUser;
  readonly   attachedDocs: JSONDocument[];
  readonly   attachedBlobs: string[];
  readonly unread: boolean;
}

type PreparedMail = {
  recipient?: number[],
  subject?: string,
  content?: string,
  attachments: number[],
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export class Message implements IMessage {
  readonly content: string;
  readonly subject: string;
  readonly recipient: User[];
  readonly sender: User;
  readonly attachedDocs: ABDocument[];
  readonly attachedBlobs: string[];
  readonly timestamp: Date;
  readonly messageId: number;
  readonly unread: boolean;

  constructor(msg: IMessage) {
    this.content = msg.content;
    this.subject = msg.subject;
    this.recipient = msg.recipient;
    this.attachedDocs = msg.attachedDocs;
    this.attachedBlobs = msg.attachedBlobs;
    this.timestamp = msg.timestamp;
    this.messageId = msg.messageId;
    this.sender = msg.sender;
    this.unread = msg.unread;
  }

  static load(projet: Projet, showNotifications = false): Promise<Message[]> {
    return fetch(`${backend}/projet/${projet.projetId}/messages?${queryString.stringify({showNotifications})}`,
      {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((messages: JSONMessage[]) => messages.map(Message.parse));
  }

  static parse(msg: JSONMessage): Message {
    return new Message({
      ...msg,
      sender: User.parse(msg.sender),
      attachedDocs: msg.attachedDocs.map(ABDocument.parse),
      timestamp: parseISO(msg.timestamp),
      recipient: [User.parse(msg.recipient)],
    });
  }

  addDefaultGreetings(): Message {
    let greetings;
    switch (this.recipient.length) {
      case 0:
        greetings = '';
        break;
      case 1:
        greetings = `Cher ${this.recipient[0].cn()},\n\n`;
        break;
      default:
        greetings = "Bonjour,\n\n";
    }

    const lines = immutable.Seq.Indexed(this.content.split("\n"));
    const remaining = lines.skipWhile(l => l === "Bonjour," || l.startsWith("Cher M")).join("\n");

    return this.updated({content: `${greetings}${remaining}`});
  }

  static new(from: User): Message {
    return new Message({
      content: 'Cordialement,',
      subject: '',
      recipient: [],
      attachedDocs: [],
      attachedBlobs: [],
      timestamp: new Date(),
      sender: from,
      messageId: 0,
      unread: false,
    });
  }

  static prepare(mail: PreparedMail, projet?: Projet): string {
    return "/projet/messages?" + queryString.stringify({
      projetId: projet?.projetId,
      m: LZString.compressToEncodedURIComponent(JSON.stringify(mail))
    });
  }

  send(credentials: Credentials, projet: Projet): Promise<Either<{ [key: string]: string }, Message>> {

    const data = new FormData();

    data.append("projet", projet.projetId.toString());
    for (const u of this.recipient) {
      data.append("recipient[]", u.userId.toString());
    }

    data.append("subject", this.subject);
    data.append("content", this.content);
    for (const a of this.attachedDocs) {
      data.append("attachments[]", a.documentId.toString());
    }

    return fetch(`${backend}/newMessage`, {
      credentials: 'include',
      method: 'POST',
      headers: credentials.headers(),
      body: data
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return new Promise(r => r(Either.right(this)));

          case 400:
            return response.json().then(json =>
              Either.left(json)
            )

          default:
            throw new FetchError(response)
        }
      })

  }

  updated(changes: Partial<Message>): Message {
    return new Message({...this, ...changes});
  }


  static fromGet(from: User, recipients: AllUsers, queryString: string): Promise<Message> | undefined {
    const {m} = parse(queryString);
    if (m) {
      if (Array.isArray(m)) {
        throw new Error("Only one message is allowed");
      }

      const decompress = LZString.decompressFromEncodedURIComponent(m);

      if (decompress === null) {
        throw new Error("Could not read prepared message");
      }

      const json: PreparedMail = JSON.parse(decompress);

      const {recipient: proposedRecipient, subject, content, attachments} = json;

      let recipient: User[];
      if (proposedRecipient) {
        recipient = recipients.getAll(proposedRecipient);
      } else {
        recipient = undefinedToArray(recipients.one());
      }

      if (recipient || subject || content || attachments.length > 0) {
        return Promise.all(attachments.map(ABDocument.load))
          .then(attachedDocs => {
            let m = Message.new(from)
              .updated({ recipient, attachedDocs,});
            if (subject !== undefined) {
              m = m.updated({subject});
            }
            if (content !== undefined) {
              m = m.updated({content});
            }
            return m;
          });
      }


    }

  }

  static predefinedMessages(): Promise<Immutable.OrderedMap<string, string>> {
    return fetch(process.env.REACT_APP_MESSAGES!)
      .then(filterStatus)
      .then(result => result.json())
      .then((predefinedMessages: PredefinedMessage[]) =>
        immutable.OrderedMap(predefinedMessages.map(({sujet, text}) => [sujet, text]))
      );
  }

  signature(): JSX.Element {
    const sender = this.sender;
    return <>&mdash; {sender.pn()} {
      sender.titre && <>&mdash; {sender.titre}</>
    } {
      sender.telephone && <>&mdash; {sender.telephone.format("IDD", {fromCountry: 'FR'})}</>
    }</>
  }

  markAs(credentials: Credentials, read: boolean): Promise<Message> {
    return fetch(`${backend}/message/${this.messageId}/markAs?${queryString.stringify({read})}`,
      {
        credentials: 'include',
        method: 'PUT',
        headers: credentials.headers()
      })
      .then(filterStatus)
      .then(() => this.updated({unread: !read}));
  }

}

type PredefinedMessage = {
  sujet: string;
  text: string;
}
