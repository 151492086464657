import React from "react";
import {Button, Collapse, Form, FormControl, Image} from "react-bootstrap";


import {Loadable, waitFor} from "../../utils/Loading";

import {ReservePoint} from "./ReservePlans";

import {Projet} from "../projets/Projet";
import {Task} from "./Task";
import {ABDocument} from "../documents/Document";
import {Credentials} from "../../utils/auth";

import 'react-day-picker/lib/style.css';

import {Either} from "monet";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import {ImagePicker} from "../../utils/ImagePicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarked} from "@fortawesome/free-solid-svg-icons";

let editorId = 0;

export type ErrorData = { [key: string]: string };

type EventPlanEditorProps = {
  projet?: Projet;
  saveEvent: (e: Partial<Task>) => Promise<Either<ErrorData, Task>>;
  event: Task;
  plans: Loadable<ABDocument[]>;
  credentials: Credentials;
  onHide: () => void;
};

type EventPlanEditorState = {
  submitStatus: SubmitStatus;
  errors?: ErrorData;
  selectPlan: boolean;
  // planPdf?: DOMRect;
};

export class EventPlanEditor extends React.Component<EventPlanEditorProps, EventPlanEditorState> {

  state: EventPlanEditorState = {
    submitStatus: SubmitStatus.enabled,
    selectPlan: this.props.event.plan === undefined,
  };

  id: number = editorId++;


  private saveChange(changes: Partial<Task>) {
    this.setState({submitStatus: SubmitStatus.working});
    return this.props.saveEvent(changes)
      .then(r => {
        this.setState(r.cata(
          errors => ({errors, submitStatus: SubmitStatus.enabled}),
          () => ({submitStatus: SubmitStatus.enabled})
        ));
        return r;
      });
  }

  private handlePlanClick(event: React.MouseEvent) {
    const pdf = document.getElementById("planEditor");
    if (pdf) {
      this.saveChange({
        position: EventPlanEditor.computeCoordinates(event, pdf)
      });
    }
  }

  render() {
    const {event} = this.props;
    const {errors} = this.state;

    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const planFile = event.plan && `${backend}/documents/${event.plan}/preview`;

    const planPdf = document.getElementById("planEditor")?.getBoundingClientRect();

    return (<>
        <Form.Label><FontAwesomeIcon icon={faMapMarked}/> Plans

          <Button className={"py-1 m-1"} onClick={() => this.setState({selectPlan: !this.state.selectPlan})}
                  aria-expanded={this.state.selectPlan} aria-controls="selectPlan">
            Changer de plan
          </Button>
          {planFile &&
          <Button className={"py-1 m-1"} onClick={() => this.saveChange({plan: undefined})}>
              Supprimer le plan
          </Button>
          }
          <SubmitButton
            className={"py-1 m-1"}
            id="save"
            onClick={() => this.handleHide()} submitstatus={this.state.submitStatus}>
            Ok
          </SubmitButton>

        </Form.Label>

        <Collapse in={this.state.selectPlan}>
          <div id="selectPlan">
            {waitFor(this.props.plans, (plans: ABDocument[]) =>
              <ImagePicker
                images={plans.map((i) => ({
                  src: `${backend}/documents/${i.documentId}/preview`,
                  value: i.documentId,
                  title: i.title,
                }))}
                onPick={(image) =>
                  this.saveChange({plan: image?.value, position: undefined})
                    .then((r) =>
                      r.forEach(() => this.setState({selectPlan: false}))
                    )
                }
              />)
            }
          </div>
        </Collapse>


        {planFile &&
        <div className="position-relative m-0 p-0">
            <Image id="planEditor" fluid src={planFile}
                   onClick={(e) => this.handlePlanClick(e)}
                   onLoad={() => this.forceUpdate()}
                   className={errors?.plan ? "form-control is-invalid" : ""}/>
          {planPdf && event.position &&
          <ReservePoint
              pdfRect={planPdf}
              reserve={event}
              position={event.position}
              onMouseEnter={() => null}
              onMouseLeave={() => null}
              onClick={() => null}
              hovered={false}
          />}
            <FormControl.Feedback>{errors?.plan}</FormControl.Feedback>
        </div>
        }

      </>
    );

  }

  private handleHide() {
    this.setState({submitStatus: SubmitStatus.enabled, errors: undefined});
    this.props.onHide();
  }

  static computeCoordinates(event: React.MouseEvent, pdf: HTMLElement) {
    const offset = EventPlanEditor.getPosition(pdf);
    return {
      x: (event.clientX - offset.x) / pdf.clientWidth,
      y: (event.clientY - offset.y) / pdf.clientHeight
    }
  }

  private static getPosition(el: HTMLElement): { x: number, y: number } {
    const rect = el.getBoundingClientRect();

    return {
      x: rect.left,
      y: rect.top
    };

  }
}

