import {Button, Form, Modal} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import * as React from "react";
import {ChangeEvent, Fragment} from "react";
import {Attribution, Offre} from "./Attribution";
import {filterStatus} from "../../utils/FetchError";
import {JSONUser, User} from "../user-profile-lite/User";
import immutable from "immutable";
import Select from "react-select";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import filterOptions from "../../utils/SelectSearch";
import {WithLoginProps} from "../../utils/auth";
import {Entreprise} from "../projets/Projet";
import {WithProjectProps} from "../../utils/SelectedProject";
import {formatEur} from "../../utils/format";
import {Corps} from "../user-profile-lite/Corps";

type GenerateContractProps = WithLoginProps & WithProjectProps & {
  attribution: Attribution,
  offre: Offre,
  corps: immutable.Map<number, Corps>,
  itvOptions: AllUsers,
  onHide: () => void,
  entreprise?: Entreprise,
}

type GenerateContractState = {
  signataires: immutable.List<Signataire>,
  partenaires: string,
  conditionPaiement: { value: number, label: string } | null,
}

interface Signataire {
  user?: User,
  societe: string,
  denomination: string,
  qualite: string,
  siege: string,
  rcs: string,
  favourite: boolean,
}

export class GenerateContract extends React.Component<GenerateContractProps, GenerateContractState> {

  state: GenerateContractState = {
    signataires: immutable.List(),
    partenaires: "ND",
    conditionPaiement: GenerateContract.ConditionsPaiement[3],
  };

  private static ConditionsPaiement = [
    {value: 1, label: "À 10 jours 3% d’escompte"},
    {value: 2, label: "À 10 jours 2% d’escompte"},
    {value: 3, label: "À 30 jours fin de mois"},
    {value: 4, label: "À 45 jours fin de mois"}
  ]


  private static EmptySignataire: Signataire = {
    societe: "",
    qualite: "",
    denomination: "",
    siege: "",
    rcs: "",
    favourite: true,
  }

  private generateContract(): void {
    const data = JSON.stringify({
      attribution: this.props.attribution.attributionId,
      partenaires: this.state.partenaires,
      conditionPaiement: this.state.conditionPaiement?.value,
      signataires: this.state.signataires
        .filter(s => s.user)
        .map(s => ({
          ...s,
          userId: s.user!.userId,
          user: undefined,
        }))
        .toArray()
    });
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const headers = this.props.credentials.headers();
    headers.append("Content-Type", "application/json");

    fetch(
      `${backend}/attributions/genContrat`,
      {
        credentials: 'include',
        method: 'POST',
        headers,
        body: data
      }
    )
      .then(filterStatus)
      .then(() => window.location.href = "/projet/documents");
  }

  private defaultSignataireEP(user: User): Signataire {
    return {
      user,
      societe: this.props.entreprise?.nom || user.societe || user.nom,
      denomination: "Entreprise principale",
      qualite: "Conducteur de travaux",
      siege: this.props.entreprise?.adresse || user.oneLineAddress(),
      rcs: this.props.entreprise?.rcs || "",
      favourite: false,
    };
  }

  private static defaultSignataireSST(user: User): Signataire {
    return {
      user,
      societe: user.societe || user.nom,
      denomination: "Sous-traitant",
      qualite: "Gérant",
      siege: user.oneLineAddress(),
      rcs: "",
      favourite: true,
    };
  }

  componentDidMount() {
    this.setState({
      signataires: immutable.List(),
      partenaires: "ND",
    });
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(`${backend}/attributions/favouriteSigners/${this.props.attribution.selected}`,
      {credentials: "include"})
      .then(filterStatus)
      .then(r => r.json())
      .then((favorites: JSONUser[]) =>
        this.setState({
          signataires:
            immutable.List(favorites)
              .map(User.parse)
              .map(u => GenerateContract.defaultSignataireSST(u))
              .unshift(this.defaultSignataireEP(this.props.credentials.user))
        })
      );
  }

  render(): JSX.Element {
    const {attribution, offre, corps, itvOptions, onHide} = this.props;

    return <Modal size={"lg"} show={true} onHide={onHide}>
      <Modal.Header>
        <dl>
          <dt>Lot</dt>
          <dd>{corps.get(attribution.lot)?.corps}</dd>
        </dl>
        <dl>
          <dt>Sous-traitant</dt>
          <dd><UserBadge>{itvOptions.get(offre.intervenantId)!}</UserBadge>
          </dd>
        </dl>
        <dl>
          <dt>Prix</dt>
          <dd>
            {offre.offre ?
              formatEur(Number(offre.offre)) :
              "?"}
          </dd>
        </dl>
      </Modal.Header>
      <Form>
        <Modal.Body>

          {this.state.signataires.map((s, i) =>
            <Fragment key={i}>
              <h5>Signataire {i + 1}</h5>
              <Form.Group className="mb-1">
                <Select
                  options={this.props.itvOptions.userSelect(
                    u => !this.state.signataires.find((v, j) =>
                      v.user?.userId === u.userId && i !== j)
                  )}
                  filterOption={filterOptions}
                  value={userSelect(s.user)}
                  isClearable
                  onChange={c => {
                    let signataire: Signataire;
                    if (c) {
                      signataire = GenerateContract.defaultSignataireSST(c.value);
                    } else {
                      signataire = GenerateContract.EmptySignataire;
                    }

                    this.setState({
                      signataires: this.state.signataires.set(i, signataire)
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Check checked={Boolean(s.favourite)}
                            label={"Signataire par défaut pour " +
                            UserBadge.link(this.props.itvOptions.get(this.props.offre.intervenantId)!)}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({
                              signataires: this.state.signataires.set(i, {
                                ...s,
                                favourite: e.currentTarget.checked
                              })
                            })}
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Société</Form.Label>
                <Form.Control
                  value={s.societe}
                  onChange={(e) =>
                    this.setState({
                      signataires: this.state.signataires.set(i, {
                        ...s,
                        societe: e.currentTarget.value,
                      })
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Qualité</Form.Label>
                <Form.Control
                  value={s.qualite}
                  onChange={(e) =>
                    this.setState({
                      signataires: this.state.signataires.set(i, {
                        ...s,
                        qualite: e.currentTarget.value,
                      })
                    })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Dénomination</Form.Label>
                <Form.Control
                  value={s.denomination}
                  onChange={(e) =>
                    this.setState({
                      signataires: this.state.signataires.set(i, {
                        ...s,
                        denomination: e.currentTarget.value
                      })
                    })
                  }/>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Siège</Form.Label>
                <Form.Control
                  value={s.siege}
                  onChange={(e) =>
                    this.setState({
                      signataires: this.state.signataires.set(i, {
                        ...s,
                        siege: e.currentTarget.value
                      })
                    })
                  }/>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>RCS</Form.Label>
                <Form.Control
                  value={s.rcs}
                  onChange={(e) =>
                    this.setState({
                      signataires: this.state.signataires.set(i, {
                        ...s,
                        rcs: e.currentTarget.value
                      })
                    })
                  }/>
              </Form.Group>
            </Fragment>
          )}
          <Form.Group className="mb-3">
            <Button
              onClick={() => this.setState({
                signataires: this.state.signataires.push(GenerateContract.EmptySignataire)
              })}>
              Ajouter un signataire
            </Button>
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              Choix du/des fournisseur(s) partenaire(s) :
            </Form.Label>
            <Form.Control
              name={"partenaires"}
              value={this.state.partenaires}
              onChange={(e) =>
                this.setState({partenaires: e.currentTarget.value})
              }
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              Conditions de paiement
            </Form.Label>
            <Select options={GenerateContract.ConditionsPaiement}
                    value={this.state.conditionPaiement}
                    onChange={(v) => this.setState({conditionPaiement: v})}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type={"reset"}
                  onClick={onHide}
                  variant={"secondary"}>Annuler</Button>
          <Button onClick={() =>
            this.generateContract()}>
            Générer le contrat
          </Button>
          {/*<Button type={"submit"}*/}
          {/*        onClick={() =>*/}
          {/*          this.generateContract(true)*/}
          {/*        }>*/}
          {/*  Prévisualiser*/}
          {/*</Button>*/}
        </Modal.Footer>
      </Form>
    </Modal>;
  }
}