import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {ProjetStats} from "../projets/Projet";
import {threeWayBoolRotate} from "../../utils/miscellaneous";
import Checkbox from "react-three-state-checkbox";

type CheckListFilterProps = {
  filter?: boolean, onChange: (checklist?: boolean) => void
}

export class CheckListFilter extends React.Component<CheckListFilterProps> {
  render(): JSX.Element {
    return <>
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning"/><Checkbox
      checked={this.props.filter === true}
      indeterminate={this.props.filter === undefined}
      onChange={() => this.props.onChange(threeWayBoolRotate(this.props.filter))}
    />
    </>;
  }

  static filterPS(ps: ProjetStats): boolean {
    return !ps.checklist || ps.checklist.isWaiting();
  }

  static filter(checklist: boolean | undefined, ps?: ProjetStats): boolean {
    return ps === undefined || checklist === undefined || (
      checklist ? CheckListFilter.filterPS(ps) : !CheckListFilter.filterPS(ps)
    );
  }
}
