import {ABDocument, DocumentActions} from "./Document";
import React from "react";
import {Card} from "react-bootstrap";
import DocumentCardBody, {DocBadge} from "./DocumentCardBody";
import classNames from "classnames";
import {DocumentToolBar} from "./DocumentToolBar";

type DocumentCardProps = {
  doc: ABDocument,
  actions: DocumentActions,
  badges: DocBadge[],
  className?: string,
  refreshAnnotation?: number,
  defaultOpen: boolean,
  showVisibility: boolean,
};

type DocumentCardState = {
  open: boolean;
  hover: boolean;
}

export class DocumentCard extends React.Component<DocumentCardProps, DocumentCardState> {
  static defaultProps = {
    badges: [],
    defaultOpen: false,
    showVisibility: false,
  }

  state: DocumentCardState = {
    open: this.props.defaultOpen,
    hover: false,
  }

  render(): JSX.Element {
    return (
      <Card
        className={classNames("card-small card-post card-post--1", this.props.className)}
        onMouseEnter={()=>this.setState({hover: true})}
        onMouseLeave={()=>this.setState({hover: false})}
      >
        <DocumentCardBody
          doc={this.props.doc}
          badges={this.props.badges}
          refreshAnnotation={this.props.refreshAnnotation}
          open={this.state.open}
          onOpen={() => this.setState({open: true})}
          showVisibility={this.props.showVisibility}
        />
        <DocumentToolBar
          hover={this.state.hover}
          toggleOpen={() => this.setState({open: !this.state.open})}
          actions={this.props.actions}
          doc={this.props.doc}
          open={this.state.open}
        />
      </Card>
    );
  }
}



