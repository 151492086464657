import React from "react";
import classNames from "classnames";
import {Col} from "react-bootstrap";

import SidebarMainNavbar from "./SidebarMainNavbar";
import {SidebarNavItems} from "./SidebarNavItems";

import {Store} from "../../../flux";
import {UserActions} from "../MainNavbar/UserActions";
import {WithProjectProps} from "../../../utils/SelectedProject";
import {Projet, ProjetStats} from "../../projets/Projet";

type MainSidebarProps = { version: string } & WithProjectProps;

type MainSidebarState = {
  menuVisible: boolean;
  stats?: ProjetStats;
};

class MainSidebar extends React.Component<MainSidebarProps, MainSidebarState> {
  state: MainSidebarState = {
    menuVisible: false,
  };

  static defaultProps = {
    hideLogoText: false
  };

  constructor(props: MainSidebarProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<MainSidebarProps>, prevState: Readonly<MainSidebarState>, snapshot?: any) {
    if (this.props.projet !== prevProps.projet) {
      this.loadStats(this.props.projet);
    }
  }

  private loadStats(projet?: Projet) {
    if (projet) {
      Projet.loadStats(true, projet.projetId)
        .then(stats => this.setState({stats: stats.get(projet.projetId)}));
    }
  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
    this.loadStats(this.props.projet);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      menuVisible: Store.getMenuState()
    });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "z-index: 0",
      "d-print-none",
      // "overflow-auto",
      this.state.menuVisible && "open"
    );

    return (
      <Col
        className={classes}
        xs={{span: 12}}
        xl={{span: 2}}
        md={{span: 3}}
      >
        <SidebarMainNavbar/>
        <UserActions />
        {/*<SidebarSearch />*/}
        <SidebarNavItems
          projet={this.props.projet}
          stats={this.state.stats}
          version={this.props.version}
        />
      </Col>
    );
  }
}


export default MainSidebar;
