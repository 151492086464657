import {Either} from "monet";
import React from "react";
import {formatDate, DAY_PICKER_PROPS, DefaultDateFormatDayName} from "../../utils/format";
import {CloseButton, FormControl} from "react-bootstrap";
import classNames from "classnames";
import DayPicker from "react-day-picker";
import {isSameDay} from "date-fns";
import {Modifier, Modifiers} from "react-day-picker/types/Modifiers";
import {SubmitStatus} from "../../utils/LoadingTS";
import LoadingOverlay from 'react-loading-overlay-ts';

type EditableDateProps = {
  date?: Date;
  editable: boolean;
  onChange(d?: Date): Promise<Either<string, unknown>>;
  modifiers?: Partial<Modifiers>;
  selectedDays?: Modifier | Modifier[];
  placeholder: string;
  disabled: Modifier[];
  onWeekClick?: (days: Date[]) => Promise<Either<string, unknown>>;
  dateFormat: (date: Date) => string;
};

type EditableDateState = {
  openCalendar: SubmitStatus;
  error?: string;
};

export class EditableDate extends React.Component<EditableDateProps, EditableDateState> {
  static defaultProps = {
    dateFormat: DefaultDateFormatDayName
  }
  state: EditableDateState = {
    openCalendar: SubmitStatus.disabled,
  };

  private handleDayClick(day: Date) {
    this.setState({openCalendar: SubmitStatus.working});
    const deselect = this.props.date && isSameDay(this.props.date, day) ? undefined : day
    return this.props.onChange(deselect)
      .then(r => r.cata(
        error => this.setState({error, openCalendar: SubmitStatus.enabled}),
        success => this.setState({error: undefined, openCalendar: SubmitStatus.disabled})
      ));
  }

  render(): JSX.Element {
    const disabled = this.props.disabled.concat({daysOfWeek: [0, 6]});
    console.log(this.props.dateFormat);
    return <>
      <div>
        <span className={classNames({"btn-link": this.props.editable})}
              onClick={() => this.props.editable && this.setState({openCalendar: SubmitStatus.enabled})}>
          {this.props.date ?
            formatDate(this.props.date, this.props.dateFormat) :
            this.props.placeholder
          }
        </span>
        {/*{this.props.date &&*/}
        {/*<Button variant={"link"}*/}
        {/*        disabled={!this.props.editable}*/}
        {/*        onClick={() => this.handleDayClick(undefined)}>*/}
        {/*    <i className="fas fa-times-circle"/>*/}
        {/*</Button>*/}
        {/*}*/}
      </div>

      <div className={classNames("Selectable", {"d-none": this.state.openCalendar === SubmitStatus.disabled})}>
        <LoadingOverlay
          active={this.state.openCalendar === SubmitStatus.working}
          spinner
        >
          <DayPicker
            {...DAY_PICKER_PROPS}
            onDayClick={(day) =>
              this.handleDayClick(day)
            }
            showWeekNumbers={this.props.onWeekClick !== undefined}
            initialMonth={this.props.date}
            selectedDays={this.props.selectedDays ?? this.props.date}
            modifiers={this.props.modifiers}
            disabledDays={disabled}
          />

          <CloseButton onClick={() => this.setState({openCalendar: SubmitStatus.disabled})}/>
        </LoadingOverlay>
        <FormControl.Feedback
          type="invalid"
          className={classNames({"d-block": this.state.error !== undefined})}
        >
          {this.state.error?.toString()}
        </FormControl.Feedback>
      </div>
    </>;

  }
}