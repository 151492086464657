import {Badge, Button, ButtonToolbar, Modal} from "react-bootstrap";
import immutable, {Seq} from "immutable";
import * as React from "react";
import {TemplateMateriaux} from "./ChoixMateriaux";
import {faMinus, faPlus, faPlusCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ChoixMateriauxAddProps = {
  materiaux: immutable.Seq.Indexed<TemplateMateriaux>;
  addChoixMateriaux: (materiaux: immutable.Seq.Indexed<TemplateMateriaux>) => Promise<unknown>;
  removeChoix: (att: TemplateMateriaux) => void;
  onHide: () => void;
}

type ChoixMateriauxAddState = {
  working: boolean;
}


export default class ChoixMateriauxAdd extends React.Component<ChoixMateriauxAddProps, ChoixMateriauxAddState> {
  state: ChoixMateriauxAddState = {
    working: false
  }

  render() {
    return (
      <Modal show onHide={() => this.props.onHide()}>
        <Modal.Body>
          {this.state.working ?
            <div className="text-center"><FontAwesomeIcon icon={faSpinner} spin/></div> :
            this.props.materiaux
              .map((a, i) =>
                <Badge
                  key={i}
                  bg={"primary"}
                  className="m-1"
                  style={{fontSize: '110%'}}>
                  {a.defaultTitre}
                  <Button size="sm" className="border-0 btn-circle ms-1 text-center p-1"
                          onClick={() => this.handleAddChoixMateriaux(Seq([a]))}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                  <Button size="sm" className="border-0 btn-circle ms-1 text-center p-1"
                          onClick={() => this.props.removeChoix(a)}>
                    <FontAwesomeIcon icon={faMinus}/>
                  </Button>
                </Badge>
              )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button onClick={() => this.handleAddChoixMateriaux(this.props.materiaux)}>
              <FontAwesomeIcon icon={faPlusCircle}/>&nbsp;
              Ajouter tout
            </Button>
            <Button className="ms-1" variant="secondary"
                    onClick={() => this.props.onHide()}>Fermer</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }


  private handleAddChoixMateriaux(materiaux: Seq.Indexed<TemplateMateriaux>) {
    this.setState({working: true});
    this.props.addChoixMateriaux(materiaux)
      .then(() => this.setState({working: false}));
  }


}