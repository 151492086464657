import React, {Fragment} from "react";
import {ABDocument} from "./Document";
import {Button, Form, ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import {Credentials, GRANTS} from "../../utils/auth";
import FetchError, {filterStatus} from "../../utils/FetchError";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {CertifTree, findPrivateKey, PublicKey, Signature} from "../../utils/Signature";
import {ABLoading, Loadable, LOADING, waitFor} from "../../utils/Loading";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import classNames from "classnames";
import {formatDate, DefaultDateTimeFormat} from "../../utils/format";
import Select from "react-select";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import {User} from "../user-profile-lite/User";
import filterOptions from "../../utils/SelectSearch";
import * as immutable from "immutable";
import {faSignature, faTimesCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ImportSignature} from "./ImportSignature";
import {PatternFormat} from "react-number-format";
import Errors from "../../views/Errors";

type SignFormState = {
  password: string;
  keyid?: number;
  submitStatus: SubmitStatus;
  errors?: any;
  keys: Loadable<PublicKey[]>;
  users?: Loadable<AllUsers>;
  askSigner?: User;
  code?: string;
};

type SignFormProps = {
  doc: ABDocument;
  onUpdate: (doc: ABDocument) => void;
  onHide: () => void;
  credentials: Credentials;
};


enum SignStep {
  LOADING, ERROR, NO_KEY, TO_SIGN, CODE, SIGNED
}


const backend = process.env.REACT_APP_BACKEND_SERVER;


export default class SignForm extends React.Component<SignFormProps, SignFormState> {
  static defaultProps = {
    onChangeMustSign: () => null,
  }

  state: SignFormState = {
    password: '',
    submitStatus: SubmitStatus.disabled,
    keys: LOADING,
  };

  componentDidMount(): void {
    this.props.credentials.user.keys()
      .then(keys => this.setState({keys}))
      .catch((e) => this.setState({keys: e}));
  }

  private handleUpdate() {
    ABDocument.load(this.props.doc.documentId)
      .then(this.props.onUpdate);
  }

  private handleSign(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({submitStatus: SubmitStatus.working})
    fetch(`${backend}/document/${this.props.doc.documentId}/prepareSignature`,
      {
        credentials: "include",
        method: 'PUT',
        headers: this.props.credentials.headers({"Content-Type": "application/json"}),
        body: JSON.stringify({password: this.state.password}),
      })
      .then(filterStatus)
      .then(() => {
        this.setState({
          code: '',
          errors: undefined,
          submitStatus: SubmitStatus.disabled
        });
      })
      .catch(error => {
        if (error instanceof FetchError) {
          error.text().then(m => {
            this.setState({
              errors: m,
              submitStatus: SubmitStatus.disabled
            })
          });
        } else {
          throw error;
        }
      });
  }

  private handleSignCode(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.setState({submitStatus: SubmitStatus.working});
    fetch(`${backend}/document/${this.props.doc.documentId}/signManaged`,
      {
        credentials: "include",
        method: 'PUT',
        headers: this.props.credentials.headers({"Content-Type": "application/json"}),
        body: JSON.stringify({password: this.state.password, code: this.state.code}),
      })
      .then(filterStatus)
      .then(() => {
        this.handleUpdate();
        this.setState({
          code: undefined,
          password: '',
          errors: undefined,
          submitStatus: SubmitStatus.disabled
        });
      })
      .catch(error => {
        if (error instanceof FetchError) {
          error.text().then(m => {
            this.setState({
              errors: m,
              submitStatus: SubmitStatus.disabled
            })
          });
        } else {
          throw error;
        }
      });
  }

  handleImport(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    this.setState({submitStatus: SubmitStatus.working})
    return fetch(`${backend}/document/${this.props.doc.documentId}/sign`,
      {
        method: 'POST',
        credentials: "include",
        headers: this.props.credentials.headers(),
        body: data
      })
      .then(filterStatus)
      .then(() => this.handleUpdate());
  }

  private handleDelete(s: Signature) {
    fetch(`${backend}/document/${this.props.doc.documentId}/signature/${s.keyId}`,
      {
        method: 'DELETE',
        credentials: 'include',
        headers: this.props.credentials.headers()
      }
    )
      .then(filterStatus)
      .then(() => this.handleUpdate());
  }

  render(): JSX.Element {
    const signPDF = `${backend}/document/${this.props.doc.documentId}/signaturesPDF`;

    const signers = immutable.Set(this.props.doc.mustSign.map(u => u.userId))
      .merge(this.props.doc.signatures.map(s => s.signer.userId));

    let key: PublicKey | undefined;
    let signStep: SignStep;
    if (this.state.keys === LOADING) {
      signStep = SignStep.LOADING
    } else if (this.state.keys instanceof Error) {
      signStep = SignStep.ERROR
    } else {
      key = findPrivateKey(this.state.keys);
      if (key) {
        if (this.props.doc.signatures.find(s => s.keyId === key?.keyId) === undefined) {
          signStep = this.state.code === undefined ? SignStep.TO_SIGN : SignStep.CODE;
        } else {
          signStep = SignStep.SIGNED;
        }
      } else {
        signStep = SignStep.NO_KEY
      }
    }

    let form;
    switch (signStep) {
      case SignStep.LOADING:
        form = <ABLoading/>;
        break;
      case SignStep.ERROR:
        form = <Errors>{this.state.keys as Error}</Errors>;
        break;
      case SignStep.CODE:
        form = <>


          <Form
            className={classNames("form-inline")}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.handleSignCode(e)}>
            <Form.Group className="my-3">
              <Form.Label>
                Nous avons envoyé un code au {this.props.credentials.user.telephone?.format("IDD", {fromCountry: 'FR'})}.
              </Form.Label>
              <PatternFormat
                format={"### ###"} mask={"_"}
                className={classNames("form-control me-2", this.state.errors !== undefined && "is-invalid")}
                name="code"
                placeholder="Code de sécurité"
                value={this.state.code}
                onValueChange={({value}) => this.setState({
                  submitStatus: SubmitStatus.enabled,
                  code: value
                })}
              />
              <Form.Control.Feedback
                type="invalid">{this.state.errors}</Form.Control.Feedback>
            </Form.Group>
            <SubmitButton className="me-2"
                          submitstatus={this.state.submitStatus}>
              <FontAwesomeIcon icon={faSignature}/> Signer
            </SubmitButton>

          </Form>
        </>;
        break;
      case SignStep.TO_SIGN:
        form = <>
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.handleSign(e)}>
            <Form.Group className={"my-3"}>
              <Form.Label>Le document sera signé avec la clé #{key!.keyId}{' '}</Form.Label>
              <Form.Control
                className="form-control me-2"
                type="password"
                name="password"
                placeholder="Mot de passe de signature"
                value={this.state.password}
                isInvalid={this.state.errors !== undefined}
                onChange={(e) => this.setState({
                  submitStatus: SubmitStatus.enabled,
                  password: e.currentTarget.value
                })}
              />
              <Form.Control.Feedback
                type="invalid">{this.state.errors}</Form.Control.Feedback>

            </Form.Group>
            <SubmitButton className="me-2"
                          submitstatus={this.state.submitStatus}>
              <FontAwesomeIcon icon={faSignature}/> Signer
            </SubmitButton>
          </Form>
        </>;
        break;
      case SignStep.NO_KEY:
        form = <>Vous devez avoir une clé privée gérée par Bati+ pour générer une signature à ce document.
          <a href="/keys">Vous pouvez en créer une ici.</a></>;
        break;
      case SignStep.SIGNED:
        form = null;
    }


    return (<>
      <Modal size={"lg"} show onHide={this.props.onHide}>
        <Modal.Body>
          <h5>Somme de contrôle SHA256 :</h5>
          <div className="text-monospace small border-light mb-3"
               style={{width: "45em"}}>
            {this.props.doc.digest}
          </div>

          {
            this.props.doc.signatures.length === 0 ?
              <h5>Ce document n&apos;a pas été signé</h5> :
              <>
                <h5>Ce document a été signé :</h5>
                <ListGroup>

                  {this.props.doc.signatures.map((s, i) =>
                    <ListGroupItem key={i}>
                      <h6>
                        <UserBadge>{s.signer}</UserBadge>, clé #{s.keyId},
                        le {formatDate(s.timestamp, DefaultDateTimeFormat)}
                        {this.props.credentials.isAdmin() &&
                        <Button variant="danger"
                                className="py-1 ms-2"
                                onClick={() => this.handleDelete(s)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                        }
                      </h6>
                      <div className="text-monospace small"
                           style={{width: "45em"}}>
                        {s.signature.split("\n").map((l, j) =>
                          <Fragment key={j}>{l}<br/></Fragment>)}
                      </div>
                      {s.certificates.map((c, i) =>
                        <CertifTree key={i} certificate={c}/>
                      )}

                    </ListGroupItem>
                  )}
                </ListGroup>
                <p>
                  <a href={signPDF}>Télécharger le récapitulatif des signatures</a>
                </p>
              </>
          }


          {this.props.doc.mustSign.length > 0 ?
            <>
              <h5>Signatures en attente :</h5>
              <ListGroup>
                {this.props.doc.mustSign.map((ms, i) =>
                  <ListGroupItem key={i}><UserBadge>{ms}</UserBadge> <Button
                    variant={"link"}
                    onClick={() => this.handleCancelSign(ms)}>
                    <FontAwesomeIcon icon={faTimesCircle} size="lg"/>
                  </Button>
                  </ListGroupItem>
                )}
              </ListGroup>
            </>
            :
            <h5>Pas de signatures en attente</h5>
          }

          {form}

          {this.props.credentials.in(GRANTS.resp) && <>
              <Button className="w-100 text-end p-1"
                      variant="link" onClick={() => this.handleAskSign()}>Demander une signature</Button>
            {this.state.users && waitFor(this.state.users, users =>
              <>
                <Select
                  isSearchable
                  options={users.userSelect(u => !signers.has(u.userId))}
                  value={userSelect(this.state.askSigner)}
                  onChange={selected =>
                    this.setState({askSigner: selected?.value})}
                  filterOption={filterOptions}
                />
                <Button
                  onClick={() => this.state.askSigner && this.handleAskSignSubmit(this.state.askSigner)}
                  disabled={!this.state.askSigner}
                >
                  Demander
                </Button>
              </>
            )}
          </>}


          <ImportSignature onImport={this.handleImport}/>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>Fermer</Button>
        </Modal.Footer>

      </Modal>

    </>);
  }

  private handleAskSign() {
    if (this.state.users) {
      this.setState({users: undefined});
    } else {
      this.setState({users: LOADING});
      AllUsers.loadUsers()
        .then(users => this.setState({users}))
        .catch((error) => this.setState({users: error}));
    }
  }

  private handleAskSignSubmit(askSigner: User) {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(
      `${backend}/document/${this.props.doc.documentId}/askSign/${askSigner.userId}`,
      {credentials: "include", method: 'PUT', headers: this.props.credentials.headers()}
    )
      .then(filterStatus)
      .then(() => this.handleUpdate());
  }

  private handleCancelSign(user: User) {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(
      `${backend}/document/${this.props.doc.documentId}/cancelSign/${user.userId}`,
      {credentials: "include", method: 'DELETE', headers: this.props.credentials.headers()}
    )
      .then(filterStatus)
      .then(() => this.handleUpdate());
  }


}