import {Button, ButtonToolbar, Card, Container, Row} from "react-bootstrap";
import {WaitForAsync} from "../utils/Loading";
import {filterStatus} from "../utils/FetchError";
import immutable, {Seq} from "immutable";
import {
  Attribution,
  attributionMapToArray,
  AttributionOffreArray,
  attributionOffreArrayToMap,
  deleteAttribution
} from "../components/attributions/Attribution";
import {useProject} from "../utils/SelectedProject";
import {User} from "../components/user-profile-lite/User";
import {useAuth} from "../utils/auth";
import {AttributionsList} from "../components/attributions/AttributionsList";
import {AttributionsAdd} from "../components/attributions/AttributionsAdd";
import {Task} from "../components/planning/Task";
import {AllUsers, userSelectCorps} from "../utils/AllUsers";
import {Corps} from "../components/user-profile-lite/Corps";
import {useAsync, UseAsyncReturn} from "react-async-hook";
import {Projet} from "../components/projets/Projet";
import {useState} from "react";

const backend = process.env.REACT_APP_BACKEND_SERVER;

export function AttributionsView() {
  const [projet] = useProject()


  const credentials = useAuth();
  const corps = useAsync(Corps.load, [])

  const [lots, setLots] = useState<immutable.Map<number, Corps> | undefined>();

  const contacted = useAsync<immutable.Set<number>>(() => {
    if (projet) {
      return fetch(`${backend}/projet/${projet.projetId}/contacts`, {credentials: 'include'})
        .then(filterStatus)
        .then(response => response.json())
        .then((contacts: number[]) => immutable.Set(contacts))
    } else {
      return new Promise(r => r(immutable.Set()))
    }
  }, [projet]);

  const fetchAttributions = (projet: Projet, contacted: immutable.Set<number>) =>
    fetch(`${backend}/attributions/${projet.projetId}`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((attributions: AttributionOffreArray[]) =>
        immutable.Map<number, Attribution>(attributions.map(a =>
          [
            a.attributionId,
            attributionOffreArrayToMap(a, contacted)
          ]))
      );


  const attributions = useAsync<immutable.Map<number, Attribution>>(
    () => {
      if (projet && contacted.result) {
        return fetchAttributions(projet, contacted.result);
      } else {
        return new Promise(r => r(immutable.Map()));
      }
    },
    [projet, contacted.result],
    {setLoading: state => ({...state, loading: true})}
  )


  const intervenants = useAsync<AllUsers>(() => {
      if (corps.result) {
        return AllUsers.loadItv(credentials)
          .then(itv =>
            itv?.withOptions(userSelectCorps(corps.result!)).withOrdering(User.sortByCorps(corps.result!)) ||
            AllUsers.empty()
          )
      } else {
        return new Promise(r => r(AllUsers.empty()));
      }
    },
    [corps.result, credentials]
  )

  const addAttributions = (added: immutable.Seq.Indexed<Corps>) => {
    const data = new FormData();
    if (projet)
      data.append("projetId", projet.projetId.toString());

    for (const a of added) {
      data.append("corps[]", a.corpsId.toString());
    }

    fetch(`${backend}/attributions/add`, {
      credentials: "include",
      method: 'POST',
      headers: credentials.headers(),
      body: data
    })
      .then(filterStatus)
      .then(() =>
        attributions.execute()
      )
  }

  const update = (updated: Attribution) => {
    const data = JSON.stringify(attributionMapToArray(updated));
    const headers = credentials.headers();
    headers.append("Content-Type", "application/json");

    return fetch(`${backend}/attributions/update`, {
      credentials: "include",
      method: 'POST',
      headers: headers,
      body: data
    })
      .then(filterStatus)
      .then(() => attributions.execute());
  }

  const createEvent = (intervenants: AllUsers, a: Attribution, ac: Corps) => {
    return Task.new(projet!)
      .updated({
        title: ac.corps,
        description: a.detail,
        color: ac.couleur,
        affectation: a.selected === undefined ? undefined : intervenants.get(a.selected),
      })
      .save(credentials)
      .then((event: Task) =>
        update({...a, eventId: event.eventId})
      )
  }

  const handleDelete = (attribution: Attribution) => {
    return deleteAttribution(credentials, attribution)
      .then(() =>
        attributions.execute()
      );
  }

  return (
    <Container fluid className="main-content-container px-4 py-4">
      {lots &&
          <AttributionsAdd
              lots={lots.valueSeq()}
              addLots={(lots) => addAttributions(lots)}
              removeLot={(corps) =>
                setLots(lots!.remove(corps.corpsId))
              }
              onHide={() => setLots(undefined)}
          />
      }

      <Card>
        <Card.Body>
          <Row>
            <WaitForAsync async={attributions}>{(attributions: immutable.Map<number, Attribution>) =>
              <WaitForAsync async={corps}>{(corps: immutable.Map<number, Corps>) =>
                <WaitForAsync async={intervenants}>{(intervenants: AllUsers) =>
                  <AttributionsList
                    credentials={credentials}
                    attributions={attributions}
                    corps={corps}
                    itvOptions={intervenants}
                    update={(a: Attribution) => update(a)}
                    addLot={(c: Corps) => addAttributions(Seq([c]))}
                    delete={(a: Attribution) => handleDelete(a)}
                    createEvent={(a: Attribution, ac: Corps) => createEvent(intervenants, a, ac)}
                    projet={projet!}
                  />
                }</WaitForAsync>
              }</WaitForAsync>
            }</WaitForAsync>
          </Row>
        </Card.Body>
      </Card>
      <div className="button-placeholder"/>
      <ButtonToolbar className="button-fixed">
        <WaitForAsync async={corps}>{(corps: immutable.Map<number, Corps>) =>
          <Button className="text-center btn-success btn-circle ms-auto m-2 btn-xl"
                  onClick={() => setLots(corps)}>
            <i className="fas fa-plus"/>
          </Button>
        }</WaitForAsync>
      </ButtonToolbar>
    </Container>);

}