import * as React from "react";
import {Button, ButtonToolbar, Col, Dropdown, Form, FormControl, Row} from "react-bootstrap";
import {RGBColor} from "../../utils/colors";
import Select from "react-select";
import immutable from "immutable";
import {Fourniture, FournitureStatus, Materiau} from "./Fourniture";
import Autosuggest from 'react-autosuggest';
import {filterStatus} from "../../utils/FetchError";
import {faBarcode, faClone, faPen, faTrash, faTruckPickup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { formatDate } from "../../utils/format";

type FournituresListProps = {
  fournitures: immutable.Map<number, Fourniture>;
  materiaux: immutable.Map<number, Materiau>;
  update: (fourniture: Fourniture) => Promise<void>;
  addMateriau: (lot: Materiau) => void;
  delete: (fourniture: Fourniture) => Promise<void>;
}

type FournituresListState = {
  edit?: number;
  editRef: string;
  editFourn: string;
  editDetail: string;
  references: immutable.Map<number, string[]>;
  fournisseurs?: string[];
  refsSuggestions: string[];
  fournsSuggestions: string[];
  dropdown?: number;
  hover?: number;
}

export default class FournituresList extends React.Component<FournituresListProps, FournituresListState> {
  state: FournituresListState = {
    references: immutable.Map(),
    refsSuggestions: [],
    fournsSuggestions: [],
    editRef: '',
    editFourn: '',
    editDetail: '',
  };


  static StatusOptionValues =
    FournitureStatus.entrySeq()
      .map(([value, label]) => ({value, label}))
      .toArray();


  couleur(a: Fourniture): RGBColor {
    return this.props.materiaux.get(a.materiauId)!.couleur;
  }

  loadSuggestions(materiau: number) {
    const backend = process.env.REACT_APP_BACKEND_SERVER;

    if (!this.state.references.has(materiau)) {
      fetch(`${backend}/fournitures/references/${materiau}`, {credentials: "include"})
        .then(filterStatus)
        .then(response => response.json())
        .then(refs => this.setState({references: this.state.references.set(materiau, refs)}));
    }
    if (!this.state.fournisseurs) {
      fetch(`${backend}/fournitures/fournisseurs`, {credentials: "include"})
        .then(filterStatus)
        .then(response => response.json())
        .then(fournisseurs => this.setState({fournisseurs}));
    }
  }

  render() {
    const {fournitures, materiaux} = this.props;

    if (fournitures.isEmpty()) {
      return "Aucune fourniture à acheter"
    } else {


      return fournitures.valueSeq()
        .sortBy(f => [f.materiauId, f.fournitureId])
        .map(a => {
          const fm = materiaux.get(a.materiauId)!;
          const tc = fm.couleur.textColor().toCSS();
          return (

            <Col sm={12} xl={6} key={a.fournitureId}>

              <Row className="mx-0 my-1 p-1 rounded"
                   style={{
                     backgroundColor: fm.couleur.toCSS()
                   }}>


                <Col
                  sm={6}
                  lg={3}
                  className="align-middle my-auto"
                  style={{color: tc, fontSize: '1.2em'}}
                >

                  <Dropdown className="d-inline-block float-end">
                    <Dropdown.Toggle
                      className="btn-circle p-0"
                      size={"sm"}
                      variant="light"
                      id={`dropdown-${a.fournitureId}`}/>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={() => {
                          this.loadSuggestions(a.materiauId);
                          this.setState({
                            edit: a.fournitureId,
                            editRef: a.reference || '',
                            editFourn: a.fournisseur,
                            editDetail: a.detail,
                          })
                        }}
                      >
                        <FontAwesomeIcon icon={faPen}/> Modifier détails
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => this.props.addMateriau(this.props.materiaux.get(a.materiauId)!)}>
                        <FontAwesomeIcon icon={faClone}/> Autre
                        fourniture {this.props.materiaux.get(a.materiauId)?.materiau}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => this.props.delete(a)}>
                        <FontAwesomeIcon icon={faTrash}/> Supprimer
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {fm.materiau}
                </Col>
                <Col sm={6} className={"px-0 my-auto"}
                     lg={2}
                     onClick={(e: React.MouseEvent) => e.stopPropagation()}
                >
                  <Select
                    components={{
                      DropdownIndicator: () => <><i className="m-1 fas fa-caret-down"/></>
                    }}
                    value={FournituresList.StatusOptionValues[a.statut]}
                    options={FournituresList.StatusOptionValues}
                    onChange={(event) => this.props.update(
                      {
                        ...a,
                        statut: event?.value || 0,
                        commande: event?.value === 2 ? new Date() : undefined
                      }
                    )}
                  />
                </Col>

                <Col sm={5} lg={2} className={"my-auto pl-1 pr-0 py-1"}>
                  {this.state.edit === a.fournitureId ?
                    <Autosuggest
                      suggestions={this.state.refsSuggestions}
                      getSuggestionValue={(s) => s}
                      inputProps={{
                        value: this.state.editRef,
                        onChange: (event, {newValue}) =>
                          this.setState({editRef: newValue}),
                        className: "mt-1 w-100",
                        placeholder: "Référence",
                      }}
                      onSuggestionsClearRequested={() => this.handleRSuggestionsClearRequested()}
                      onSuggestionsFetchRequested={(value) =>
                        this.handleRSuggestionsFetchRequested(a.materiauId, value)
                      }
                      renderSuggestion={(s) => s}
                    /> :
                    <span style={{color: tc}}>
                      <FontAwesomeIcon icon={faBarcode}/> {a.reference || '?'}
                    </span>
                  }
                </Col>
                <Col sm={5} lg={3} className="my-auto py-1 pl-1 pr-0">
                  {this.state.edit === a.fournitureId ?
                    <Autosuggest
                      suggestions={this.state.fournsSuggestions}
                      getSuggestionValue={(s) => s}
                      inputProps={{
                        value: this.state.editFourn,
                        onChange: (event, {newValue}) =>
                          this.setState({editFourn: newValue}),
                        className: "mt-1 w-100",
                        placeholder: "Fournisseur",
                      }}
                      onSuggestionsClearRequested={() => this.handleFSuggestionsClearRequested()}
                      onSuggestionsFetchRequested={(value) =>
                        this.handleFSuggestionsFetchRequested(value)
                      }
                      renderSuggestion={(s) => s}
                    /> :
                    <span style={{color: tc}}>
                      <FontAwesomeIcon icon={faTruckPickup}/> {a.fournisseur || '?'}
                    </span>
                  }
                </Col>
                <Col sm={2} lg={2}  className="my-auto py-1 pl-1 pr-0" style={{color: tc}}>
                  {a.commande && formatDate(a.commande)}
                </Col>

                {/*</Col>*/}


                <Col sm={12}
                     style={{color: fm.couleur.textCSS()}}
                     className="small">
                  {this.state.edit === a.fournitureId ?
                    <Form className="mt-1"
                          onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.updateDetails(e, a)}>
                      <FormControl name="detail" as="textarea"
                                   value={this.state.editDetail}
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                     this.setState({editDetail: e.currentTarget.value})}
                      />
                      <ButtonToolbar className="mt-1">
                        <Button className="ms-auto" type="submit">OK</Button>
                        <Button className="ms-1" variant="secondary"
                                onClick={() => this.setState({edit: undefined})}
                        >
                          Annuler
                        </Button>
                      </ButtonToolbar>
                    </Form> :
                    <span style={{color: tc}}>
                                            {a.detail?.split('\n').map((line: string, i) =>
                                              <p key={i} className="m-0 p-0">{line}</p>)}
                                        </span>
                  }
                </Col>
              </Row>
            </Col>

          );
        })
    }
  }

  private updateDetails(e: React.FormEvent<HTMLFormElement>, a: Fourniture) {
    e.preventDefault();
    this.props.update({
      ...a,
      detail: this.state.editDetail,
      reference: this.state.editRef,
      fournisseur: this.state.editFourn
    })
      .then(() =>
        this.setState({
          references: this.state.references.remove(a.materiauId),
          fournisseurs: this.state.fournisseurs &&
            [...immutable.OrderedSet(this.state.fournisseurs).add(a.fournisseur)],
          edit: undefined
        })
      );
  }

  private handleRSuggestionsFetchRequested(materiau: number, {value}: Autosuggest.SuggestionsFetchRequestedParams) {
    const inputValue = value.trim().toLocaleLowerCase();
    if (inputValue.length > 0 && this.state.references.has(materiau)) {
      const refs = this.state.references.get(materiau)!;
      this.setState({refsSuggestions: refs.filter(r => r.toLocaleLowerCase().includes(inputValue))});
    } else {
      this.handleRSuggestionsClearRequested();
    }
  }

  private handleRSuggestionsClearRequested() {
    this.setState({refsSuggestions: []});
  }

  private handleFSuggestionsFetchRequested({value}: Autosuggest.SuggestionsFetchRequestedParams) {
    const inputValue = value.trim().toLocaleLowerCase();
    if (inputValue.length > 0 && this.state.fournisseurs) {
      const fourns = this.state.fournisseurs;
      this.setState({fournsSuggestions: fourns.filter(r => r.toLocaleLowerCase().includes(inputValue))});
    } else {
      this.handleRSuggestionsClearRequested();
    }
  }

  private handleFSuggestionsClearRequested() {
    this.setState({fournsSuggestions: []});
  }
}