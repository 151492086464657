import {RGBColor} from "../../utils/colors";
import {filterStatus} from "../../utils/FetchError";
import {Credentials} from "../../utils/auth";
import {Projet} from "../projets/Projet";
import * as immutable from "immutable";
import querystring from "query-string";
import {Seq} from "immutable";
import { parseISO } from "date-fns";

export interface Fourniture {
  fournitureId: number;
  projetId: number;
  materiauId: number;
  detail: string;
  statut: number;
  reference?: string;
  commande?: Date;
  fournisseur: string;
}

export interface Materiau {
  materiauId: number;
  materiau: string;
  couleur: RGBColor;
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export function deleteFourniture(credentials: Credentials, fourniture: Fourniture) {
  return fetch(`${backend}/fournitures/${fourniture.fournitureId}`, {
    credentials: "include",
    method: 'DELETE',
    headers: credentials.headers(),
  })
    .then(filterStatus)
}

function parseFourniture(f: any) {
  return ({...f, commande: f.commande && parseISO(f.commande)})
}

export function loadFournitures(projet: Projet): Promise<Fourniture[]> {
  return fetch(`${backend}/fournitures/${projet.projetId}`, {credentials: 'include'})
    .then(filterStatus)
    .then(response => response.json())
    .then(f => f.map(parseFourniture));
}

export function loadAllFournituresFor(selectFinished: boolean, materiauId?: number): Promise<immutable.Map<number, immutable.Map<number, Seq.Indexed<Fourniture>>>> {
  return fetch(`${backend}/projets/fournitures?${querystring.stringify({
    selectFinished,
    materiauId
  })}`, {credentials: 'include'})
    .then(filterStatus)
    .then(response => response.json())
    .then(f => f.map(parseFourniture))
    .then((fournitures: Fourniture[]) =>
      immutable.Seq(fournitures).groupBy(f => f.projetId).map(f => f.groupBy(m => m.materiauId).map(m => m.valueSeq()))
    );
}

export function loadMateriaux(): Promise<Immutable.Map<number, Materiau>> {
  return fetch(`${backend}/fournitures/materiaux`, {credentials: 'include'})
    .then(filterStatus)
    .then(r => r.json())
    .then((materiaux: { couleur: string, materiauId: number, materiau: string }[]) =>
      materiaux.map(m => ({...m, couleur: RGBColor.fromHex(m.couleur)}))
    )
    .then(materiaux => immutable.Map(materiaux.map((m) =>
      [m.materiauId, m]
    )));
}


export const FournitureStatus = immutable.Map([
  [0, "À acheter"],
  [1, "Consultation"],
  [2, "Acheté"],
  [3, "Sans objet"]
]);

export const FournitureNotif = immutable.Map([
  [0, {theme: "danger", text: "white"}],
  [1, {theme: "warning", text: "black"}],
  [2, {theme: "success", text: "white"}]
]);