import React from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Popover,
  Row
} from "react-bootstrap";
import Gravatar from "react-gravatar";
import {Loadable, waitFor} from "../../utils/Loading";
import {GrantsIcon} from "./UserBadge";
import Select from "react-select";
import immutable from "immutable";
import {User} from "./User";
import {GRANTS, WithLoginProps} from "../../utils/auth";
import {CirclePicker} from "react-color";
import {COLORS, RGBColor} from "../../utils/colors";
import filterOptions from "../../utils/SelectSearch";
import {PublicKey, RSAKeys} from "../../utils/Signature";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import {formatDate, DefaultDateTimeFormat} from "../../utils/format";
import PhoneInput from 'react-phone-number-input/input';
import classNames from "classnames";
import 'react-phone-number-input/style.css'
import {parsePhoneNumber} from "libphonenumber-js";
import {faCaretDown, faCircle, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Corps, CorpsBadge} from "./Corps";

type UserFormProps = {
  user: User;
  errors?: { [key: string]: string };
  onChange: (change: Partial<User>) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>, user: User) => Promise<void>;
  connexions?: { time: Date, ip: string }[];
  signatures?: PublicKey[];
  corps: Loadable<immutable.Map<number, Corps>>;
  onDeleteKey?: (keyId: number) => void;
  onDelCertificate?: (keyId: number) => void;
} & WithLoginProps;

interface GrantsOptionValue {
  value: GRANTS,
  label: JSX.Element
}

type UserFormState = {
  openColor: boolean;
  showImport: boolean;
  phone?: string;
  submitStatus: number;
  changedEmail: boolean;
  hasRecoveryCode: boolean;
}

export default class UserForm extends React.Component<UserFormProps, UserFormState> {

  state: UserFormState = {
    openColor: false,
    showImport: false,
    submitStatus: SubmitStatus.disabled,
    changedEmail: false,
    hasRecoveryCode: true,
  };

  handleChange(user: Partial<User>) {
    this.setState({
      submitStatus: SubmitStatus.enabled,
      changedEmail: this.state.changedEmail || user.email !== undefined
    });
    this.props.onChange(user);
  }

  componentDidMount() {
    this.setState({phone: this.props.user.telephone?.formatInternational()});
    this.props.user.hasRecoveryCode()
      .then(hasRecoveryCode => this.setState({hasRecoveryCode}));
  }

  componentDidUpdate(prevProps: Readonly<UserFormProps>, prevState: Readonly<UserFormState>, snapshot?: any) {
    if (prevProps.user.telephone !== this.props.user.telephone) {
      this.setState({phone: this.props.user.telephone?.formatInternational()});
    }
  }

  private static corpsOptions(options: immutable.Map<number, Corps>) {
    return options.map((corps, key) =>
      ({value: key, label: <CorpsBadge key={key}>{corps}</CorpsBadge>, searchable: corps.corps.toLocaleLowerCase()}));
  }

  render(): JSX.Element {

    const {
      user, errors, credentials, connexions, signatures,
      onDeleteKey, onDelCertificate
    } = this.props;

    let disabled = true;

    let admin;

    if (credentials.isAdmin()) {
      const options = [
        {value: GRANTS.std, label: <><GrantsIcon grants={GRANTS.std}/> Client</>},
        {value: GRANTS.itv, label: <><GrantsIcon grants={GRANTS.itv}/> Prestataire</>},
        {value: GRANTS.resp, label: <><GrantsIcon grants={GRANTS.resp}/> Commercial ou comptable</>},
        {
          value: GRANTS.admin,
          label: <span className="text-danger"><GrantsIcon grants={GRANTS.admin}/> Administrateur</span>
        },
      ];
      const optionMap = immutable.Map<GRANTS, GrantsOptionValue>(
        options.map(o => [o.value, o])
      );

      admin = (
        <Row>
          <Col className="form-group">
            <Form.Label className="bg-danger text-white rounded p-1">Niveau de droits</Form.Label>
            <Select name="grants"
                    value={optionMap.get(user.grants)}
                    onChange={selected =>
                      //if (instanceOfGrantsOption(selected)) {
                      this.handleChange({grants: selected?.value})
                      //}
                    }
                    options={options}/>
          </Col>
        </Row>);

      disabled = false;

    } else {
      admin = '';
      disabled = user.grants !== GRANTS.std;
    }

    let header;
    if (user.userId) {
      header = (
        <div>
          <div className="mb-3 mx-auto">
            {user.logo ?
              <Image
                src={`${process.env.REACT_APP_BACKEND_SERVER}/logo/${user.userId}?size=200&time=${user.logo}`}/> :
              <Gravatar className="rounded-circle" email={user.email} size={110}/>
            }
          </div>
          <h6 className="m-0">Détails du compte {user.userId}. {user.prenom} {user.nom}</h6>
        </div>);

    } else {
      header = <h6 className="m-0">Nouvel utilisateur</h6>;
    }

    const colorPicker = (
      <Popover id="ColorPicker">
        <Popover.Body>
          <CirclePicker circleSize={20}
                        color={user.favourite}
                        colors={COLORS}
                        onChangeComplete={(color) =>
                          this.handleChange({favourite: RGBColor.fromColorResult(color)})
                        }/>
        </Popover.Body>
      </Popover>
    );


    return (
      <Card className="mb-4">
        <Card.Header className="border-bottom text-center">
          {header}
        </Card.Header>
        <Card.Body>
          <Form onSubmit={(event: React.FormEvent<HTMLFormElement>) => this.handleSubmit(event, user)}>
            <Row>
              <Col md="2" className="form-group">
                <Form.Label>Civilité</Form.Label>
                <Select
                  isDisabled={disabled}
                  name={"civilite"}
                  options={User.civilites()}
                  value={user.civiliteOption()}
                  onChange={(c) => this.handleChange({civilite: c!.value})}
                />
              </Col>
              {/* First Name */}
              <Col md="5" className="form-group">
                <Form.Label htmlFor="feFirstName">Prénom</Form.Label>
                <Form.Control
                  name="prenom"
                  placeholder="Prénom"
                  disabled={disabled}

                  value={user.prenom || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({prenom: e.currentTarget.value})}
                  isInvalid={errors?.prenom !== undefined}
                />
                <Form.Control.Feedback>{errors?.prenom}</Form.Control.Feedback>
              </Col>
              {/* Last Name */}
              <Col md="5" className="form-group">
                <Form.Label htmlFor="feLastName">Nom</Form.Label>
                <Form.Control
                  name="nom"
                  placeholder="Nom"
                  disabled={disabled}

                  value={user.nom || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({nom: e.currentTarget.value})}
                  isInvalid={errors?.nom !== undefined}
                />
                <Form.Control.Feedback>{errors?.nom}</Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              {/* First Name */}
              <Col md="6" className="form-group">
                <Form.Label htmlFor="feCompany">Couleur préférée − Société</Form.Label>
                <InputGroup>
                  <OverlayTrigger trigger={["click", "focus"]} placement="right"
                                  overlay={colorPicker}
                                  delay={{show: 200, hide: 400}}>
                    <Button
                      className="btn-light px-1"
                      style={{zIndex: 0}}
                      disabled={disabled}
                    >
                      <FontAwesomeIcon icon={faCircle} color={user.favourite.toCSS()} className={"mx-1"}/>
                      <FontAwesomeIcon icon={faCaretDown}/>
                    </Button>
                  </OverlayTrigger>
                  <Form.Control
                    name="societe"
                    disabled={disabled}

                    placeholder="Société"
                    value={user.societe || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({societe: e.currentTarget.value})}
                    isInvalid={errors?.societe !== undefined}
                  />
                </InputGroup>
                <Form.Control.Feedback>{errors?.societe}</Form.Control.Feedback>
              </Col>
              {/* Last Name */}
              <Col md="6" className="form-group">
                <Form.Label htmlFor="feLogo">Logo <span
                  className="text-muted">(format .jpg ou .png, découpe carrée)</span></Form.Label>

                <Form.Control
                  className="form-inline"
                  type="file"
                  name="logo"
                  disabled={disabled}

                  placeholder="Logo"
                  isInvalid={errors?.logo !== undefined}
                  onChange={() => this.handleChange({})}
                />

                <Form.Control.Feedback>{errors?.logo}</Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="form-group">
                <Form.Label>Titre</Form.Label>
                <Form.Control
                  name="titre"
                  disabled={disabled}

                  placeholder="Par ex., « Conseiller commercial », « Maître d'œuvre », etc."
                  value={user.titre || ''}
                  onChange={(e) => this.handleChange({titre: e.currentTarget.value})}
                  isInvalid={errors?.titre !== undefined}
                />
              </Col>
            </Row>
            <Row>
              {/* Email */}
              <Col md="6" className="form-group">
                <Form.Label htmlFor="feEmail">Email</Form.Label>
                <Form.Control
                  name="email"
                  disabled={disabled}
                  placeholder="Adresse email"
                  value={user.email || ''}
                  autoComplete="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({email: e.currentTarget.value})}
                  isInvalid={errors?.email !== undefined}
                />
                {this.state.changedEmail &&
                    <Badge bg="danger">En cas de changement d’adresse email, le mot de passe sera réinitialisé et
                        la clé privée supprimée.</Badge>}
                <Form.Control.Feedback>{errors?.email}</Form.Control.Feedback>
              </Col>
              <Col md="6" className="form-group">
                <Form.Label htmlFor="feTel">Téléphone</Form.Label>
                <PhoneInput
                  defaultCountry="FR"
                  name="telephone"
                  placeholder="Téléphone"
                  value={this.state.phone}
                  disabled={disabled}
                  autoComplete="tel"
                  onChange={(phone: string) => this.setState({
                    submitStatus: SubmitStatus.enabled,
                    phone
                  })}
                  className={classNames("form-control", {"is-invalid": errors?.telephone !== undefined})}
                />
                <Form.Control.Feedback>{errors?.telephone}</Form.Control.Feedback>
              </Col>
            </Row>
            {admin}
            <Row>
              <Col className="form-group">
                <Form.Label
                  htmlFor="feAddress">Adresse</Form.Label>
                <Form.Control
                  name="adresse"
                  placeholder="Adresse"
                  value={user.adresse}
                  disabled={disabled}

                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({adresse: e.currentTarget.value})}
                  isInvalid={errors?.adresse !== undefined}
                />
                <Form.Control.Feedback>{errors?.adresse}</Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              {/* City */}
              <Col md="9" className="form-group">
                <Form.Label htmlFor="feCity">Ville</Form.Label>
                <Form.Control
                  name="ville"
                  placeholder="Ville"
                  disabled={disabled}

                  value={user.ville}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({ville: e.currentTarget.value})}
                />
                <Form.Control.Feedback>{errors && errors.ville}</Form.Control.Feedback>
              </Col>
              {/* Zip Code */}
              <Col md="3" className="form-group">
                <Form.Label htmlFor="feZipCode">Code postal</Form.Label>
                <Form.Control
                  name="codePostal"
                  placeholder="Code postal"
                  value={user.codePostal}
                  disabled={disabled}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange({codePostal: e.currentTarget.value})}
                  isInvalid={errors && errors.codePostal !== undefined}
                />
                <Form.Control.Feedback>{errors && errors.codePostal}</Form.Control.Feedback>
              </Col>
            </Row>
            <Row>
              <Col className="form-group">
                <Form.Label>Corps de métier</Form.Label>
                {waitFor(this.props.corps, (corps: immutable.Map<number, Corps>) => {
                  const options = UserForm.corpsOptions(corps);
                  return (
                    <Select
                      name="corps[]"
                      isMulti
                      isSearchable
                      isClearable
                      isDisabled={disabled}
                      filterOption={filterOptions}
                      value={user.corps.map((i) => options.get(i)!)}
                      options={
                        [...options.valueSeq()
                          .sort((a, b) =>
                            a.searchable.localeCompare(b.searchable)
                          )
                        ]
                      }
                      onChange={selected =>
                        this.handleChange({
                          corps: (selected || []).map(({value}) => value)
                        })
                      }
                    />
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="form-group">
                <Accordion>
                  <Accordion.Item as="h5" eventKey="1">
                    <Accordion.Header>
                      Clés publiques
                    </Accordion.Header>
                    <Accordion.Body>
                      <RSAKeys
                        onDelCertificate={onDelCertificate}
                        onDelete={onDeleteKey}>
                        {signatures ?? []}
                      </RSAKeys>
                    </Accordion.Body>
                  </Accordion.Item>

                  {connexions &&
                      <Accordion.Item as="h5" eventKey="2">
                          <Accordion.Header>
                              Dernières connexions
                          </Accordion.Header>
                          <Accordion.Body>
                              <ListGroup>
                                {connexions.map((t, i) =>
                                  <ListGroupItem key={i} className="p-1">
                                    {formatDate(t.time, DefaultDateTimeFormat)} ({t.ip})
                                  </ListGroupItem>
                                )}
                              </ListGroup>
                          </Accordion.Body>
                      </Accordion.Item>
                  }
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col className={"form-group"}>
                <Button className="m-1"
                        disabled={this.state.hasRecoveryCode || user.userId === 0}
                        variant="secondary"
                        onClick={() => this.sendRecoveryCode()}
                >
                  <FontAwesomeIcon icon={faPaperPlane}/> Initialisation du mot de passe
                </Button>
                <SubmitButton id="save" submitstatus={this.state.submitStatus} className={"ms-1"}>
                  Enregistrer
                </SubmitButton>
              </Col>
            </Row>
          </Form>

        </Card.Body>
      </Card>
    );

  }

  private sendRecoveryCode() {
    this.setState({hasRecoveryCode: true});
    return User.sendRecoveryCode(this.props.user.email);
  }

  private handleSubmit(event: React.FormEvent<HTMLFormElement>, user: User) {
    this.setState({submitStatus: SubmitStatus.working});
    this.props.onSubmit(event, user.updated({
      telephone: this.state.phone ? parsePhoneNumber(this.state.phone) : undefined
    }))
      .then(() => this.setState({submitStatus: SubmitStatus.disabled}));
  }
}