// import ReactDOM from 'react-dom';
// // import * as serviceWorker from './serviceWorker';
// import {App} from './App';
//
// ReactDOM.render(
//     <App version={process.env.REACT_APP_VERSION}/>,
//     document.getElementById('root')
// );
//
// // If you want your app to work offline and parse faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// // serviceWorker.unregister();

import React, {useContext} from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Navigate, Route,
  Routes,
  useRouteError,
} from "react-router-dom";
import {DefaultLayout} from "./layouts/Default";
import Errors from "./views/Errors";
import {ProjetsView} from "./views/ProjetsView";
import Login from "./views/Login";
import EmptyLayout from "./layouts/EmptyLayout";
import "./shards-dashboard/styles/scss/shards-dashboards.scss";
import "./App.scss";
import Logout from "./views/Logout";
import {Recovery} from "./views/Recovery";
import {Intervenants} from "./views/Intervenants";
import {PlanningView} from "./views/PlanningView";
import {DocumentsView} from "./views/DocumentsView";
import {MessagesView} from "./views/MessagesView";
import {AttributionsView} from "./views/AttributionsView";
import {CheckListView} from "./views/CheckListView";
import {OptionsView} from "./views/OptionsView";
import {ChoixMateriauxView} from "./views/ChoixMateriauxView";
import {NotationView} from "./views/NotationView";
import {Users} from "./views/Users";
import {FournituresView} from "./views/FournituresView";
import {ShowImage} from "./views/ShowImage";
import {KeysView} from "./views/KeysView";
import {FacturationView} from "./views/FacturationView";
import {MesAttestations} from "./views/MesAttestations";
import {ContratsFactures} from "./views/ContratsFactures";
import {authContext, isAuth, ProvideAuth} from "./utils/auth";
import {ProvideProject} from "./utils/SelectedProject";
import {StatsView} from "./views/StatsView";
import querystring from "query-string";

function ABRouter() {
  const logged = isAuth(useContext(authContext));
  return <BrowserRouter>
    <Routes>
      <Route index element={logged ?
        <Navigate replace to="/projets"/> :
        <EmptyLayout><Login/></EmptyLayout>
      }
             errorElement={<RouteErrorPage/>}
      />

      {!logged ? <>
          <Route path="/lost">
            <Route path=":userId/:key" element={<EmptyLayout><Recovery/></EmptyLayout>}/>
            <Route path="" element={<EmptyLayout><Recovery/></EmptyLayout>}/>
          </Route>
          <Route path="/first">
            <Route path=":userId/:key" element={<EmptyLayout><Recovery/></EmptyLayout>}/>
            <Route path="" element={<EmptyLayout><Recovery/></EmptyLayout>}/>
          </Route>
          <Route path="/*" element={
            <Navigate replace to={`/?${querystring.stringify({
              fromPath: window.location.pathname,
              fromSearch: window.location.search
            })}`}/>
          }/>
        </>
        : <>
          <Route path="/logout" element={<EmptyLayout><Logout/></EmptyLayout>}/>


          <Route path="/"
                 element={<DefaultLayout version={process.env.REACT_APP_VERSION!}/>}
                 errorElement={<RouteErrorPage/>}>
            <Route path="projets" element={<ProjetsView/>}/>
            <Route path="user-profile-lite" element={<Users/>}/>

            <Route path="show/:src" element={<ShowImage/>}/>
            <Route path="keys" element={<KeysView/>}/>
            <Route path="facturation" element={<FacturationView/>}/>
            <Route path="mon-administratif" element={<MesAttestations/>}/>
            <Route path="contrats-factures" element={<ContratsFactures/>}/>
            <Route path="intervenants">
              <Route path=":eventId" element={<Intervenants/>}/>
              <Route path="" element={<Intervenants/>}/>
            </Route>
            <Route path="stats" element={<StatsView/>}/>
          </Route>
          <Route path="/projet"
                 element={<DefaultLayout version={process.env.REACT_APP_VERSION!}/>}
                 errorElement={<RouteErrorPage/>}
          >
            <Route path="fournitures" element={<FournituresView/>}/>
            <Route path="planning">
              <Route path=":event" element={<PlanningView/>}/>
              <Route path="" element={<PlanningView/>}/>
            </Route>
            <Route path="documents" element={<DocumentsView/>}/>
            <Route path="messages" element={<MessagesView/>}/>
            <Route path="attributions" element={<AttributionsView/>}/>
            <Route path="checklist" element={<CheckListView/>}/>
            <Route path="options" element={<OptionsView/>}/>
            <Route path="choix-materiaux" element={<ChoixMateriauxView/>}/>
            <Route path="notation" element={<NotationView/>}/>
          </Route>
        </>}
    </Routes>
  </BrowserRouter>;
}

type RouteError =
  {
    status: number,
    statusText
      :
      string,
  }

function isRouteError(obj: unknown): obj is RouteError {
  return typeof obj === 'object' && obj !== null && 'status' in obj && 'statusText' in obj
}

function RouteErrorPage() {
  const e = useRouteError();
  if (isRouteError(e)) {
    return <Errors status={e.status}>{e.statusText}</Errors>;
  } else {
    return <Errors>Unknown Error</Errors>
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth><ProvideProject>
      <ABRouter/>
    </ProvideProject></ProvideAuth>
  </React.StrictMode>
  ,
  document.getElementById('root')
);