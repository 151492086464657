import {Fragment, useState} from "react";
import {Button, ButtonToolbar, Container, Form, Row} from "react-bootstrap";
import Alerts, {AlertData, IAlert} from "../utils/Alerts";
import {Planning} from "../components/planning/Planning";
import {WaitFor, WaitForAsync} from "../utils/Loading";
import {GRANTS, useAuth} from "../utils/auth";
import {useProject} from "../utils/SelectedProject";
import immutable from "immutable";
import {Task} from "../components/planning/Task";
import * as queryString from "query-string";
import {Projet} from "../components/projets/Projet";
import {User} from "../components/user-profile-lite/User";
import {UserBadge} from "../components/user-profile-lite/UserBadge";
import Notify from "../components/planning/Notify";
import {AllUsers} from "../utils/AllUsers";
import {faCalendarPlus, faComment, faExclamation, faPrint, faStopwatch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EventPlanEditor} from "../components/planning/EventPlanEditor";
import {EventEdit} from "../components/planning/EventCard";
import {useAsync} from "react-async-hook";
import {Postponer} from "../components/planning/Postponer";
// import {useParams} from "react-router-dom";
//
// type MatchParams = {
//   event?: string;
// }

export function PlanningView() {
  const [projet] = useProject();
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const [showCloture, setShowCloture] = useState<boolean>(false);
  //const [openEvent, setOpenEvent] = useState<number | undefined>();
  const [editedEvent, setEditedEvent] = useState<Task | undefined>();
  const [notify, setNotify] = useState<[User, Date?][] | undefined>();
  // const [allItvs, setAllItvs] = useState<Loadable<AllUsers> | undefined>();
  const [editMode, setEditMode] = useState<EventEdit>(EventEdit.none);
  // const {event} = useParams();
  const [postpone, setPostpone] = useState(false);

  const credentials = useAuth();

  const asyncEvents = useAsync<immutable.Map<number, Task>>(
    () => {
      if (!projet) {
        throw new Error("Projet non défini")
      }
      return Task.loadAllProjet(projet, showCloture)
        .then(events => Planning.eventMap(events))
    },
    [projet, showCloture],
    {setLoading: state => ({...state, loading: true})}
  );

  const backend = process.env.REACT_APP_BACKEND_SERVER;

  const handleClickPlan = (event: React.MouseEvent, plan: number, dom: string) => {
    const pdf = document.getElementById(dom);
    const position = pdf ? EventPlanEditor.computeCoordinates(event, pdf) : undefined;
    handleNewEvent(plan, position);
  }


  const handleNewEvent = (plan?: number, position?: { x: number, y: number }) => {
    if (projet) {
      setEditMode(EventEdit.title);
      setEditedEvent(Task.new(projet, plan, position));
    }
  }

  const notifyAll = (projet: Projet) => {
    projet.recentlyUpdated()
      .then((itv: [User, Date?][]) =>
        setNotify(itv.sort(
          (a, b) =>
            UserBadge.link(a[0]).localeCompare(UserBadge.link(b[0]))
        ))
      );
  }


  return (
    <Fragment>
      <Alerts alerts={alerts}
              update={(newAlerts) => setAlerts(newAlerts)}/>
      <Container className="main-content-container px-4">
        {/* Page Header */}
        <Row className="page-header py-3 clear">
          <Form>
            <Form.Check
              checked={showCloture}
              onChange={(e) => setShowCloture(e.currentTarget.checked)}
              label="Afficher tâches clôturées"/>
          </Form>
        </Row>

        <WaitForAsync async={asyncEvents}>{(events: immutable.Map<number, Task>) => <>
          <WaitFor asyncFunction={AllUsers.loadItv} params={[credentials]}>
            {allItvs =>
              <Planning
                allItvs={allItvs === null ? undefined : allItvs}
                editable={credentials.isAdmin()}
                projet={projet}
                events={events}
                //editedEvent={state.editedEvent}
                credentials={credentials}
                addAlert={(alert: IAlert) => Alerts.add(alert, setAlerts, alerts)}
                updateEvents={() => asyncEvents.execute(projet, showCloture)}
                showProjet={false}
                editEvent={(editedEvent) => setEditedEvent(editedEvent)}
                editedEvent={editedEvent}
                handleClickPlan={(event: React.MouseEvent, plan: number, dom: string) =>
                  handleClickPlan(event, plan, dom)}
                editMode={editMode}
                setEditMode={(editMode) => setEditMode(editMode)}
              />
            }
          </WaitFor>

          <div className="button-placeholder"/>

          <ButtonToolbar className="button-fixed">

            <Button className="btn-success text-center btn-circle ms-auto mb-2 btn-xl"
                    onClick={() => window.location.href =
                      `${backend}/events/printablePlanning?${queryString.stringify({projet: projet?.projetId})}`
                    }>
              <FontAwesomeIcon icon={faPrint}/>
            </Button>
            {credentials.in(GRANTS.resp) && projet && <>
                <Button className="btn-warning text-center btn-circle ms-2 mb-2 btn-xl"
                        onClick={() => notifyAll(projet!)}
                >
                          <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={faComment} transform="left-3" size={"2x"}/>
                          <FontAwesomeIcon icon={faExclamation} inverse/>
                          </span>
                </Button>
                <Button className="btn-warning text-center btn-circle ms-2 mb-2 btn-xl"
                        onClick={() => setPostpone(true)}>
                    <FontAwesomeIcon icon={faStopwatch}/>
                </Button>
                <Button className="btn-success text-center btn-circle ms-2 mb-2 btn-xl"
                        onClick={() => handleNewEvent()}>
                    <FontAwesomeIcon icon={faCalendarPlus}/>
                </Button>
            </>
            }
          </ButtonToolbar>

          <Postponer
            show={postpone}
            onHide={(change) => {
              if (change) {
                asyncEvents.execute()
              }
              setPostpone(false)
            }}
            tasks={events.valueSeq()}
          />

          {notify && projet &&
              <Notify
                  credentials={credentials}
                  projet={projet}
                // sendNotify={(intervenants, includePDF) =>
                //   sendNotify(intervenants, includePDF, props.credentials, props.projet)}
                  onHide={() => setNotify(notify)}
                  removeItv={(user: User) =>
                    setNotify(
                      notify?.filter(([u]) => u.userId !== user.userId)
                    )}
                  intervenants={notify}
              />
          }
        </>}</WaitForAsync>
      </Container>
    </Fragment>
  );


}

