import {User} from "../user-profile-lite/User";
import {ABDocument} from "../documents/Document";
import queryString from "query-string";
import {filterStatus} from "../../utils/FetchError";
import * as immutable from "immutable";

export type AttestationsJson = {
  user: User, valides: any[], invalides: any[], manquants: string[], mustSign: { [key: number]: User[] },
}

export class Attestations {
  user?: User;
  valides: ABDocument[];
  invalides: AttestationInvalide[];
  manquants: string[];
  mustSign: { [key: number]: User[] };

  allAttestations: immutable.Set<number>;



  constructor(valides: ABDocument[], invalides: AttestationInvalide[], manquants: string[], mustSign: { [p: number]: User[] }, user?: User) {
    this.valides = valides;
    this.invalides = invalides;
    this.manquants = manquants;
    this.mustSign = mustSign;
    this.user = user;
    this.allAttestations = immutable.Set(this.allDocs().map(d => d.documentId));
  }

  static parse({user, valides, invalides, manquants, mustSign}: AttestationsJson): Attestations {
    return new Attestations(
      valides.map(ABDocument.parse),
      invalides.map(id => ({document: ABDocument.parse(id), reasons: id.reasons})),
      manquants,
      mustSign,
      user,
    );
  }

  static load(filter: { userId?: number, projetId?: number }): Promise<Attestations[]> {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    return fetch(`${backend}/dossiers/attestations?${queryString.stringify(filter)}`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((atts: AttestationsJson[]) => atts.map(Attestations.parse));
  }

  static types(): Promise<string[]> {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    return fetch(`${backend}/attestationTypes`, {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json());
  }

  allDocs(): ABDocument[] {
    return this.valides.concat(this.invalides.map(i => i.document));
  }

}

type AttestationInvalide = {
  document: ABDocument,
  reasons: string[]
}