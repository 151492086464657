import React from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import querystring from "query-string";
import FetchError from "../utils/FetchError";
import {Loadable, LOADING, waitFor} from "../utils/Loading";
import {withCookies} from "react-cookie";


type LoginState = {
  badLogin?: Loadable<boolean>;
}

class Login extends React.Component<Record<string, never>, LoginState> {

  state: LoginState = {};

  connect(form: React.FormEvent<HTMLFormElement>) {
    form.preventDefault();
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    const credentials = {
      email: form.currentTarget['email'].value,
      password: form.currentTarget['password'].value,
      rememberMe: form.currentTarget['rememberMe'].checked,
    };
    this.setState({badLogin: LOADING});
    fetch(`${backend}/login?${querystring.stringify(credentials)}`, {credentials: 'include'})
      .then(response => {
        const {fromPath, fromSearch} = querystring.parse(window.location.search);
        switch (response.status) {
          case 202:
            window.location.href = `${(fromPath || '/projets')}${fromSearch || ''}`;
            break;
          case 401:
            this.setState({badLogin: true});
            break;
          default:
            throw new FetchError(response);
        }
      })
      .catch(error => this.setState({badLogin: error}));
  }

  render() {
    return (
      <Col sm={6} className="mx-auto my-5">
        <Card>
          <Card.Body>
            {waitFor(this.state.badLogin, (l?: boolean) =>
              <Form onSubmit={(form: React.FormEvent<HTMLFormElement>) => this.connect(form)}>
                <Row className="my-1">
                  <Col sm={12}>
                    Bati+ évolue&nbsp;!<br/>
                    Si vous rencontrez un comportement incohérent de l&lsquo;application,
                    videz le cache de votre navigateur en appuyant simultanément sur les touches Ctrl+F5 de votre clavier.
                  </Col>
                </Row>
                <Row className="my-1">
                  <label className="col-form-label col-sm-3 text-end">E-mail</label>
                  <Form.Control className="col" name="email" type="email"
                                isInvalid={l}/>
                </Row>
                <Row className="my-1">
                  <label className="col-form-label col-sm-3 text-end">Mot de passe</label>
                  <Form.Control className="col" name="password" type="password"
                                isInvalid={l}/>
                  <Form.Control.Feedback type="invalid" className="text-center">
                    Login incorrect. Si vous avez perdu votre mot de passe, ou si vous n’avez jamais utilisé Bati+,
                    cliquez sur un des liens ci-dessous.
                  </Form.Control.Feedback>
                </Row>
                <Row className="my-1 text-center">
                  <Col sm={12}>
                    <Form.Check inline name="rememberMe" label="Rester connecté"/>
                    <div className="text-muted fw-light">[Ne cocher que sur une machine personnelle protégée]</div>
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col className="text-center">
                    <Button type="submit">Connexion</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Card.Body>
          <Card.Footer className="text-center">
            <a href="/first">Première connexion</a> | <a href="/lost">Mot de passe perdu</a>
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}

export default withCookies(Login);