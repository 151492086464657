import React from "react";
import FetchError from "../utils/FetchError";

type ErrorsState = {
  details?: string
};

type ErrorsProps = {
  children: Error | React.ReactNode,
  status?: number,
  statusText?: string,
}

export default class Errors extends React.Component<ErrorsProps, ErrorsState> {

  state: ErrorsState = {};

  componentDidMount(): void {
    this.updateDetails(this.props.children);
  }

  componentDidUpdate(prevProps: ErrorsProps, prevState: Readonly<ErrorsState>): void {
    const error = this.props.children;
    if (prevProps.children !== error) {
      this.updateDetails(error);
    }
  }

  updateDetails(error: Error | React.ReactNode): void {
    if (error instanceof FetchError) {
      error.text().then(result => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(result, "text/html");
        this.setState({details: htmlDoc.getElementsByTagName("body")[0].innerHTML});
      });
    } else if (error instanceof Error) {
      this.setState({details: error.message})
    }
  }

  render(): JSX.Element {
    const error = this.props.children;
    let status;
    let statusText;
    const details: string[] = [];
    if (this.state.details) {
      details.push(this.state.details);
    }

    if (error instanceof FetchError) {
      status = error.status;
      statusText = error.statusText;
    } else if (error instanceof Error) {
      statusText = error.name;
      if (error.stack) {
        details.push(error.stack.replaceAll('\n', '<br/>'));
      }
      status = 500;
    } else {
      status = this.props.status || 500;
      console.log(error);
      statusText = this.props.statusText || `Unknown error: ${error}`
    }

    return (
      <div className="error__content">
        <h3>{status}</h3>

        <h4>{statusText}</h4>
        <p>C’est une erreur de notre part.</p>
        <p>Merci de noter les circonstances dans lesquelles l’erreur est survenue et de nous la
          signaler sur la <a href="https://ab-concept.myjetbrains.com/youtrack/newIssue">plate-forme de gestion des
            tickets</a>.</p>

        {details.map((d, i) =>
          <div key={i} className="text-start" dangerouslySetInnerHTML={{__html: d}}/>
        )}
      </div>
    );
  }
}
