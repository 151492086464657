import {useProject} from "../utils/SelectedProject";
import {GRANTS, useAuth} from "../utils/auth";
import {Button, ButtonToolbar, Card, Container} from "react-bootstrap";
import {useState} from "react";
import {
  ChoixMateriaux,
  deleteChoixMateriaux,
  loadChoixMateriaux,
  loadTemplatesMateriaux,
  saveChoixMateriaux,
  TemplateMateriaux
} from "../components/choix-materiaux/ChoixMateriaux";
import {Loadable, LOADING, waitFor, WaitForAsync} from "../utils/Loading";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faCircle, faDotCircle} from "@fortawesome/free-regular-svg-icons";
import * as immutable from "immutable";
import ChoixMateriauxAdd from "../components/choix-materiaux/ChoixMateriauxAdd";
import {ChoixMateriauxCard} from "../components/choix-materiaux/ChoixMateriauxCard";
import {faCartPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAsync} from "react-async-hook";

library.add(faCircle, faDotCircle);

export function ChoixMateriauxView() {
  const credentials = useAuth();
  const [projet] = useProject();

  const choixMateriaux = useAsync<immutable.Map<number, ChoixMateriaux>>(async () => {
      if (projet) {
        return await loadChoixMateriaux(projet)
          .then(choixMateriaux => immutable.Map(choixMateriaux.map(cm => [cm.idChoixProjet, cm])))
      } else {
        return immutable.Map();
      }
    },
    [projet],
    {setLoading: state => ({...state, loading: true})}
  )

  const [addedMateriaux, setAddedMateriaux] = useState<Loadable<immutable.Seq.Indexed<TemplateMateriaux>> | undefined>();

  const handleDelete = (cm: ChoixMateriaux) =>
    deleteChoixMateriaux(credentials, cm)
      .then(() => choixMateriaux.execute());


  const handleAddChoixMateriaux = (tms: immutable.Seq.Indexed<TemplateMateriaux>) => {
    const newCM = tms.map(tm => (
      {
        ...tm,
        idProjet: projet!.projetId,
        titre: tm.defaultTitre,
        reponseLibre: '',
        idChoixProjet: 0,
        valide: false,
      }
    ));

    return saveChoixMateriaux(credentials, newCM)
      .then(updatedCms => choixMateriaux.execute());
  }

  const handleSave = (toSave: ChoixMateriaux) => {
    return saveChoixMateriaux(credentials,
      immutable.Seq([toSave])
    )
      .then(updatedCms =>
        choixMateriaux.execute()
      );
  }


  return <Container fluid className="main-content-container px-4 py-4">
    <WaitForAsync async={choixMateriaux}>{choixMateriaux => <>
      {choixMateriaux.isEmpty() && <Card><Card.Body>Aucun choix en cours. Ajoutez des matériaux au projet.</Card.Body></Card>}
      {choixMateriaux.valueSeq()
        .sortBy((v) => v.valide ? Infinity : v.idChoix)
        .map((cm, i) =>
          <ChoixMateriauxCard
            key={i}
            cm={cm}
            editable={credentials.in(GRANTS.resp) && !cm.valide}
            onSave={
              (credentials.in(GRANTS.resp) || !cm.valide) ?
                (cm) => handleSave(cm) :
                undefined
            }
            onDelete={
              (credentials.in(GRANTS.resp) && !cm.valide) ?
                (cm) => handleDelete(cm) :
                undefined
            }
          />
        )}
      {addedMateriaux && waitFor(addedMateriaux, addedMateriaux =>
        <ChoixMateriauxAdd
          materiaux={addedMateriaux}
          addChoixMateriaux={(tm) => handleAddChoixMateriaux(tm)}
          removeChoix={(tm) =>
            setAddedMateriaux(addedMateriaux.filter(am => am.idChoix !== tm.idChoix))
          }
          onHide={() => setAddedMateriaux(undefined)}
        />
      )}
    </>}</WaitForAsync>

    {credentials.in(GRANTS.resp) && <>
        <div className="button-placeholder"/>
        <ButtonToolbar className="button-fixed">
            <Button className="text-center btn-success btn-circle ms-auto m-2 btn-xl"
                    style={{lineHeight: ".6em"}}
                    onClick={() => {
                      setAddedMateriaux(LOADING);
                      loadTemplatesMateriaux().then(addedMateriaux =>
                        setAddedMateriaux(addedMateriaux)
                      )
                    }}>
                <FontAwesomeIcon icon={faCartPlus}/>
            </Button>
        </ButtonToolbar>
    </>
    }

  </Container>;


}
