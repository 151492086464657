import {ColorResult} from "react-color";

export const COLORS: string[] = [
  '#007bff',
  '#8445f7',
  '#00b8d8',
  '#17c671',
  '#ffb400',
  '#fb7906',
  '#c4183c',
  '#868e96',
  '#212529',
];

export class RGBColor {
  r: number;
  g: number;
  b: number;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  static fromHex(hex: string): RGBColor {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      return new RGBColor(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16));
    } else {
      throw new Error("Could not parse RGB");
    }
  }

  static fromHash(hash: string): RGBColor {
    let result = 0;
    const sat = 80 / 100;
    const lum = 80 * 255 / 100;
    for (let i = 0; i < hash.length; i++) {
      result = (result + hash.charCodeAt(i)) % 40;
    }
    result = result * 360 / 40;
    return RGBColor.fromHSV(result, sat, lum);
  }

  toHSV(): { h: number, s: number, v: number } {
    const min = Math.min(this.r, this.g, this.b);
    const max = Math.max(this.r, this.g, this.b);

    const v = max;
    const delta = max - min;
    if (max === 0) {
      return {h: -1, s: 0, v: NaN};
    } else {
      const s = delta / max;
      let h;
      if (this.r === max)
        h = (this.g - this.b) / delta;      // between yellow & magenta
      else if (this.g === max)
        h = 2 + (this.b - this.r) / delta;  // between cyan & yellow
      else
        h = 4 + (this.r - this.g) / delta;  // between magenta & cyan
      h *= 60;                // degrees
      if (h < 0)
        h += 360;
      if (isNaN(h))
        h = 0;
      return {h, s, v};
    }
  }

  toCSS(): string {
    return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`;
  }

  toCSSAlpha(alpha: number): string {
    return `rgba(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)}, ${alpha})`;
  }

  hoverable(hovered: boolean): RGBColor {
    if (hovered) {
      const {h, s, v} = this.toHSV();
      return RGBColor.fromHSV(h, s * .75, 255 - .75 * (255 - v));
    } else {
      return this;
    }
  }

  textCSS(): string {
    return this.textColor().toCSS();
  }

  textColor(): RGBColor {
    const luminance = (0.299 * this.r + 0.587 * this.g + 0.114 * this.b) / 255;

    if (luminance > 0.6)
      return RGBColor.BLACK; // bright colors - black font
    else
      return RGBColor.WHITE; // dark colors - white font
  }


  mean({r: r2, g: g2, b: b2}: RGBColor, prop: number): RGBColor {
    return new RGBColor(
      this.r + prop * (r2 - this.r),
      this.g + prop * (g2 - this.g),
      this.b + prop * (b2 - this.b)
    );
  }

  static fromColorResult({rgb: {r, g, b}}: ColorResult): RGBColor {
    return new RGBColor(r, g, b);
  }

  static fromHSV(th: number, s: number, v: number): RGBColor {
    let r, g, b;
    if (s === 0) {
      // achromatic (grey)
      r = g = b = v;
      new RGBColor(r, g, b);
    }
    const h = th / 60;            // sector 0 to 5
    const i = Math.floor(h);
    const f = h - i;          // factorial part of h
    const p = v * (1 - s);
    const q = v * (1 - s * f);
    const t = v * (1 - s * (1 - f));
    switch (i) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      default:        // case 5:
        r = v;
        g = p;
        b = q;
        break;
    }
    return new RGBColor(r, g, b);
  }

  private static componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  toHex() {
    return "#" + RGBColor.componentToHex(this.r) + RGBColor.componentToHex(this.g) + RGBColor.componentToHex(this.b);
  }

  static readonly WHITE: RGBColor = new RGBColor(255, 255, 255);
  static readonly BLACK = new RGBColor(0x21, 0x25, 0x29)
}


export const RGB_COLORS: RGBColor[] = COLORS.map(RGBColor.fromHex);


//
// export function textColor(bgColor: string | undefined): string | undefined {
//   return bgColor && RGBColor.fromHex(bgColor).textColor().toCSS();
// }