import {ABDocument} from "../documents/Document";
import {CLItem} from "./CLItem";
import {Button, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {DocumentCard} from "../documents/DocumentCard";
import {Component} from "react";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";

type CheckListCompletedProps = {
  onSign: (signDoc: ABDocument) => void,
  edit: CLItem, onHide: () => void,
  unvalidate?: (clItem: CLItem) => Promise<void>
};
type CheckListCompletedState = {
  unvalidateButtonStatus: SubmitStatus
};

export class CheckListCompleted extends Component<CheckListCompletedProps, CheckListCompletedState> {
  state: CheckListCompletedState = {
    unvalidateButtonStatus: SubmitStatus.enabled
  }

  private handleUnvalidate(unvalidate: (clItem: CLItem) => Promise<void>) {
    this.setState({unvalidateButtonStatus: SubmitStatus.working});
    unvalidate(this.props.edit)
      .then(() => this.setState({unvalidateButtonStatus: SubmitStatus.enabled}));
  }

  render(): JSX.Element {
    const edit = this.props.edit;
    return <>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label>
            <FontAwesomeIcon icon={faAlignLeft}/> Commentaire
          </Form.Label>
          <Form.Control
            as="textarea"
            value={edit.proposition}
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label><FontAwesomeIcon icon={faPaperclip}/> Document joint</Form.Label>
          {edit.document ? (typeof edit.document === 'number' ? `Document ${edit.document} non visible` :
                <DocumentCard
                  className="mb-3"
                  doc={edit.document}
                  actions={{
                    onSign: () => typeof edit.document === 'object' && this.props.onSign(edit.document),
                  }}
                />
            ) :
            <div>Pas de document joint</div>
          }
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {this.props.unvalidate &&
        <SubmitButton
            variant="warning"
            submitstatus={this.state.unvalidateButtonStatus}
            onClick={() => this.props.unvalidate && this.handleUnvalidate(this.props.unvalidate)}
        >
            Annuler la validation
        </SubmitButton>
        }

        <Button
          className="ms-1"
          type="reset"
          variant="secondary"
          onClick={() => this.props.onHide()}>
          Fermer
        </Button>
      </Modal.Footer>
    </>
      ;
  }
}