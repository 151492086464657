import {Button, ButtonToolbar, ListGroup, Modal} from "react-bootstrap";
import immutable, {Seq} from "immutable";
import {Task} from "./Task";
import {formatDate, NUMBER_FORMAT_FR} from "../../utils/format";
import {DateFilter, Filter} from "../../utils/DateFilter";
import * as React from "react";
import {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faBell, faCalendarCheck, faCalendarTimes} from "@fortawesome/free-solid-svg-icons";
import {addBusinessDays} from "date-fns";
import {useAuth} from "../../utils/auth";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";

type PostponerProps = {
  show: boolean,
  onHide: (change: boolean) => void,
  tasks: Seq.Indexed<Task>,
}

function format(date?: Date) {
  return date && formatDate(date);
}

export function postponeTask(t: Task, filter: Filter, postpone: number): Partial<Task> {
  if (DateFilter.filterDate(filter, t.startDay)) {
    return {
      startDay: addBusinessDays(t.startDay!, postpone),
      echeance: t.echeance && addBusinessDays(t.echeance, postpone),
    };
  } else if (DateFilter.filterDate(filter, t.echeance)) {
    return {echeance: addBusinessDays(t.echeance!, postpone)};
  } else {
    return {};
  }
}

export function Postponer(props: PostponerProps) {
  const [filter, setFilter] = useState<Filter>({from: new Date(), nulls: true});
  const [postpone, setPostpone] = useState<number | undefined>();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.disabled);
  const credentials = useAuth();

  const updated: immutable.Map<number, Partial<Task>> =
    immutable.Map(
      props.tasks
        .filter(t => t.effectue === undefined && t.cloture === undefined)
        .map(t => [t.eventId, postponeTask(t, filter, postpone ?? 0)])
    )
      .filter((u) => u.startDay !== undefined || u.echeance !== undefined)

  useEffect(() => {
      if (updated.isEmpty()) {
        setSubmitStatus(SubmitStatus.disabled)
      } else {
        setSubmitStatus(SubmitStatus.enabled)
      }
    },
    [updated]
  )

  const handleOK = () => {
    setSubmitStatus(SubmitStatus.working);
    Promise.all(
      props.tasks.filter(t => updated.has(t.eventId))
        .map(t => t.updated(updated.get(t.eventId)!).save(credentials))
    ).then(() => {
      setSubmitStatus(SubmitStatus.disabled);
      props.onHide(true);
    });
  };

  return <Modal show={props.show} onHide={()=>props.onHide(false)} size={"lg"}>
    <Modal.Body>
      <div className={"mb-2"}>
        Sélectionner les tâches :
        <DateFilter
          filter={filter}
          onChange={(filter) => setFilter(filter)}
        />
        Les tâches effectuées <FontAwesomeIcon icon={faCalendarCheck}/> ou clôturées <FontAwesomeIcon
        icon={faCalendarTimes}/>
        ne sont pas affectées.
      </div>

      <div className={"my-2"}>
        Retarder de&nbsp;: <NumericFormat
        {...NUMBER_FORMAT_FR}
        decimalScale={0}
        suffix={" jours"}
        value={postpone}
        onValueChange={({floatValue}) => setPostpone(floatValue)}
      />
      </div>

      <ListGroup className={"mt-2"}>
        {props.tasks.map(e =>
          <ListGroup.Item
            style={{
              background: updated.has(e.eventId) ? e.color.toCSS() : undefined,
              color: updated.has(e.eventId) ? e.color.textColor().toCSS() : "#999999",
            }}
            key={e.eventId}>
            {e.title} : {format(e.startDay) ?? "?"} − &nbsp;
            {e.cloture ? <FontAwesomeIcon icon={faCalendarTimes}/> :
              e.effectue ? <FontAwesomeIcon icon={faCalendarCheck}/> :
                e.echeance && <FontAwesomeIcon icon={faBell}/>
            }&nbsp;
            {format(e.cloture ?? e.effectue ?? e.echeance) ?? "?"}

            {postpone && postpone !== 0 && updated.has(e.eventId) && <>
                &nbsp;<FontAwesomeIcon className="mx-2" icon={faArrowRight}/>
              {format(updated.get(e.eventId)?.startDay ?? e.startDay) ?? "?"} −
              {format(updated.get(e.eventId)?.echeance ?? e.echeance) ?? "?"}
            </>}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar className="mt-1">
        <SubmitButton className="ms-auto" submitstatus={submitStatus} onClick={() => handleOK()}>OK</SubmitButton>
        <Button className="ms-1" variant="secondary"
                onClick={() => props.onHide(false)}
        >
          Annuler
        </Button>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>;
}