import {useEffect, useState} from "react";
import {ABDocument,} from "../components/documents/Document";
import {useAuth} from "../utils/auth";
import {Container} from "react-bootstrap";
import {WaitForAsync} from "../utils/Loading";
import Alerts, {AlertData} from "../utils/Alerts";
import SignForm from "../components/documents/SignForm";
import {DocEditor} from "../components/documents/DocEditor";
import * as immutable from "immutable";
import {Attestations} from "../components/attestations/Attestations";
import {AttestationList} from "../components/attestations/AttestationList";
import {Suggestions} from "../utils/Suggestions";
import {Either} from "monet";
import {flatMapRight, mergeEithers, SubmitError} from "../utils/FetchError";
import {useAsync} from "react-async-hook";
import {User} from "../components/user-profile-lite/User";

export function MesAttestations() {

  const credentials = useAuth();
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const [edit, setEdit] = useState<ABDocument | undefined>();
  const [signDoc, setSignDoc] = useState<ABDocument | undefined>();

  const asyncAttestations = useAsync(
    (user: User) => Attestations.load({userId: user.userId}),
    [credentials.user]
  );
  const [suggestions, setSuggestions] = useState<Suggestions>(new Suggestions());

  useEffect(() => {
    Attestations.types().then(types => setSuggestions(new Suggestions(types)))
  }, []);


  const handleUpdate = (doc: ABDocument | undefined) => {
    setEdit(doc);
  }

  const handleSubmit = (submitted: ABDocument, saved: Either<SubmitError, ABDocument[]>) => {
    return flatMapRight(saved, docs => Promise.all(docs.map(d =>
      d.saveAdministratif(credentials, submitted.administratif)
    ))
      .then((r) =>
        mergeEithers(r)
          .map(r => {
            asyncAttestations.execute(credentials.user)
            return r;
          })
      ));
  }


  const handleDelete = (document: ABDocument) => {
    document.delete(credentials)
      .then(() => asyncAttestations.execute(credentials.user));
  }


  const actions = (doc: ABDocument) => {
    return ({
      onSign: (signDoc: ABDocument) => setSignDoc(signDoc),
      onEdit: doc.editable(credentials) ? ((edit: ABDocument) => setEdit(edit)) : undefined,
      onDelete: credentials.isAdmin() ? ((del: ABDocument) => handleDelete(del)) : undefined,
    });
  }


  return <>
    <Alerts
      alerts={alerts}
      update={(newAlerts) => setAlerts(newAlerts)}/>
    <Container fluid className="main-content-container px-4 py-4">
      <WaitForAsync async={asyncAttestations}>{([attestations]) =>
        <AttestationList
          user={credentials.user}
          actions={(doc) => actions(doc)}
          newDocument={(data) => ABDocument.new(credentials, data)}
          attestations={attestations}
        />
      }</WaitForAsync>

    </Container>

    {signDoc &&
        <SignForm
            doc={signDoc}
            credentials={credentials}
            onUpdate={(signDoc) => {
              setSignDoc(signDoc);
              asyncAttestations.execute(credentials.user);
            }}
            onHide={() => setSignDoc(undefined)}
        />
    }

    {edit &&
        <DocEditor
            credentials={credentials}
            onSubmit={(submitted, saved) => handleSubmit(submitted, saved)}
            update={(doc) => handleUpdate(doc)}
            edit={edit}
            cats={immutable.Set.of("Attestations")}
            administratif
            suggestions={suggestions}
            setCameraState={() => null}/>
    }
  </>;


}

