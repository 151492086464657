import {Button, ButtonToolbar, Col, Modal, Row} from "react-bootstrap";
import immutable, {Seq} from "immutable";
import * as React from "react";
import {Materiau} from "./Fourniture";
import {faMinus, faPlus, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type FournituresAddProps = {
  materiaux: immutable.Seq.Indexed<Materiau>;
  addFournitures: (materiaux: immutable.Seq.Indexed<Materiau>) => void;
  removeMateriau: (att: Materiau) => void;
  onHide: () => void;
}

export default function FournituresAdd(props: FournituresAddProps) {
  return (
    <Modal show onHide={() => props.onHide()} size="lg">
      <Modal.Body>
        <Row>
          {props.materiaux
            .sortBy((m) => m.materiauId)
            .map((a, i) =>
              <Col sm={12} xl={6} key={i}>
                <div
                  className="mx-0 my-1 p-1 px-3 rounded"
                  key={i}
                  style={{
                    backgroundColor: a.couleur.toCSS(),
                    color: a.couleur.textColor().toCSS()
                  }}>
                  {a.materiau}
                  <Button size="sm" className="border-0 btn-circle ms-1 text-center p-1"
                          style={{backgroundColor: a.couleur.textCSS(), color: a.couleur.toCSS()}}
                          onClick={() => props.addFournitures(Seq([a]))}>
                    <FontAwesomeIcon icon={faPlus}/>
                  </Button>
                  <Button size="sm" className="border-0 btn-circle ms-1 text-center p-1"
                          style={{backgroundColor: a.couleur.textCSS(), color: a.couleur.toCSS()}}
                          onClick={() => props.removeMateriau(a)}>
                    <FontAwesomeIcon icon={faMinus}/>
                  </Button>
                </div>
              </Col>
            )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button onClick={() => props.addFournitures(props.materiaux.valueSeq())}>
            <FontAwesomeIcon icon={faPlusCircle}/>&nbsp;
            Ajouter tout
          </Button>
          <Button className="ms-1" variant="secondary"
                  onClick={() => props.onHide()}>Fermer</Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}