import Alerts, {AlertData} from "../utils/Alerts";
import {Button, Card, Container} from "react-bootstrap";
import {WaitForAsync} from "../utils/Loading";
import Select from "react-select";
import {Projet, projetSelect} from "../components/projets/Projet";
import filterOptions from "../utils/SelectSearch";
import Contrats from "../components/attestations/Contrats";
import {useState} from "react";
import {DossierFacturation} from "../components/attestations/DossierFacturation";
import * as immutable from "immutable";
import {Seq} from "immutable";
import {ABDocument,  DocumentVisibility} from "../components/documents/Document";
import {Credentials, GRANTS, useAuth,} from "../utils/auth";
import {User} from "../components/user-profile-lite/User";
import {AllUsers, userSelect} from "../utils/AllUsers";
import {DossiersFacturationUI} from "../components/attestations/DossiersFacturationUI";
import SignForm from "../components/documents/SignForm";
import {DocEditor} from "../components/documents/DocEditor";
import Checkbox from "react-three-state-checkbox";
import {threeWayBoolRotate} from "../utils/miscellaneous";
import classNames from "classnames";
import {Either} from "monet";
import {flatMapRight, mergeEithers, SubmitError} from "../utils/FetchError";
import {useAsync, UseAsyncReturn} from "react-async-hook";

type Filter = { projet?: Projet, user?: User, showCloture?: boolean };

export function ContratsFactures() {

  const credentials = useAuth();
  const projets = useAsync(Projet.loadAll, []);
  const [filter, setFilter] = useState<Filter>({showCloture: false});
  const dossiers = useAsync(async () =>
      DossierFacturation.load(filter)
        .then(allDossiers => {
          const byUser = allDossiers.mapEntries(([u, dfs]) =>
            [u.userId, immutable.Set(dfs.map(df => df.dossierId))]
          )
          const byId = immutable.Map(
            allDossiers.valueSeq().flatMap((d) => Seq.Indexed(d)).map(d => [d.dossierId, d])
          );
          return {byUser, byId};
        })
    , [filter])

  const allUsers = useAsync(AllUsers.loadItv, [credentials]);
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const [edit, setEdit] = useState<ABDocument | undefined>();
  const [signDoc, setSignDoc] = useState<ABDocument | undefined>();
  const [editToDossier, setEditToDossier] = useState<DossierFacturation | undefined>();

  const contrats = useAsync(async () => {
    return await ABDocument.searchDocumentAdministratif({
      ...filter,
      categorie: 'Contrats'
    })
  }, [filter])

  const factures = useAsync(async () => {
    return await ABDocument.searchDocumentAdministratif({
      ...filter,
      categorie: 'Factures'
    })
  }, [filter])

  const handleSubmit = (submitted: ABDocument, saved: Either<SubmitError, ABDocument[]>) => {
    return flatMapRight(saved, docs => Promise.all(docs.map(d =>
      d.categorie === 'Administratif' ?
        d.saveAdministratif(credentials, submitted.administratif) :
        new Promise<Either<{ [key: string]: string }, ABDocument>>(resolve => resolve(Either.right(d)))
    ))
      .then((r) =>
        mergeEithers(r)
          .map(docs => {
            docs.forEach(d => editToDossier?.addDocument(credentials, d));
            dossiers.execute();
            contrats.execute();
            factures.execute();
            return docs;
          })
      ));
  }


  return <>
    <Alerts
      alerts={alerts}
      update={(newAlerts) => setAlerts(newAlerts)}/>
    <Container fluid className="main-content-container px-4 py-4">
      <WaitForAsync async={projets}>{projets =>
        <WaitForAsync async={allUsers}>{allusers =>
          <>
            <Card>
              <Card.Body>
                <label>Projet :</label>
                <Select
                  className="m-2"
                  isSearchable
                  isClearable
                  options={projets.valueSeq().map(p => projetSelect(p)).toArray()}
                  value={projetSelect(filter.projet)}
                  onChange={c => setFilter({...filter, projet: c?.value})}
                  filterOption={filterOptions}
                />

                {allusers && <>
                    <label>Prestataire :</label>
                    <Select
                        className="m-2"
                        isSearchable
                        isClearable
                        options={allusers?.userSelect() || []}
                        value={userSelect(filter.user)}
                        onChange={c => setFilter(
                          {...filter, user: c?.value}
                        )}
                        filterOption={filterOptions}
                    />
                </>
                }

                <label>Voir dossiers clôturés : </label> <Checkbox
                className="p-1"
                checked={filter.showCloture === true}
                indeterminate={filter.showCloture === undefined}
                onChange={() => setFilter({
                  ...filter,
                  showCloture: threeWayBoolRotate(filter.showCloture)
                })}
              />

              </Card.Body>
            </Card>
            <WaitForAsync async={factures}>{allFactures =>
              <Dossier
                asyncDossiers={dossiers}
                contrats={contrats}
                allFactures={allFactures}
                filter={filter}
                projets={projets}
                setEdit={setEdit}
                setSignDoc={setSignDoc}
                allusers={allusers === null ? undefined : allusers}
                setEditToDossier={setEditToDossier}
              />
            }</WaitForAsync>

            {edit &&
                <DocEditor
                    credentials={credentials}
                    onSubmit={(submitted, saved) => handleSubmit(submitted, saved)}
                    update={(doc) => setEdit(doc)}
                    edit={edit}
                    cats={immutable.Set.of("Contrats", "Factures", "Attestations")}
                    setCameraState={() => null}
                    allProjets={projets}
                    administratif={edit.categorie === 'Attestations'}
                />
            }
          </>
        }</WaitForAsync>
      }</WaitForAsync>


    </Container>

    {signDoc &&
        <SignForm
            doc={signDoc}
            credentials={credentials}
            onUpdate={(signDoc) => {
              setSignDoc(signDoc);
              contrats.execute();
              factures.execute();
              dossiers.execute();
            }}
            onHide={() => setSignDoc(undefined)}
        />
    }

  </>;

}

type BaseDossiers = {
  byUser: immutable.Map<number, immutable.Set<number>>, byId: immutable.Map<number, DossierFacturation>
}

type DossierProps = {
  asyncDossiers: UseAsyncReturn<BaseDossiers>,
  contrats: UseAsyncReturn<immutable.Map<number, ABDocument>>,
  allFactures: immutable.Map<number, ABDocument>,
  filter: Filter,
  projets: immutable.Map<number, Projet>,
  setEdit: (doc: ABDocument) => void,
  setSignDoc: (doc: ABDocument) => void,
  allusers?: AllUsers,
  projet?: Projet,
  setEditToDossier: (df: DossierFacturation) => void,
}

function Dossier({
                   asyncDossiers,
                   contrats,
                   allFactures,
                   filter,
                   projets,
                   setEdit,
                   setSignDoc,
                   allusers,
                   projet,
                   setEditToDossier
                 }: DossierProps) {
  const credentials = useAuth();

  const dossiersByUser = (
    dossiers: BaseDossiers,
    allUsers?: AllUsers
  ) => {
    let dbu = immutable.Map<User, Seq.Indexed<DossierFacturation>>();
    for (const [userId, dfIds] of dossiers.byUser) {
      let user;
      if (allUsers) {
        user = allUsers.get(userId)
      } else if (userId === credentials.user.userId) {
        user = credentials.user
      }

      if (user) {
        dbu = dbu.set(user, dfIds.toIndexedSeq().map(id => dossiers.byId.get(id)!));
      }
    }
    return dbu;
  }

  const actions = (doc: ABDocument, grants: Credentials) => {
    return ({
      onSign: (signDoc: ABDocument) => setSignDoc(signDoc),
      onEdit: doc.editable(grants) ? ((edit: ABDocument) => setEdit(edit)) : undefined,
    });
  }

  const handleNewDossier = (contrat: ABDocument) => {
    DossierFacturation.create(credentials, contrat)
      .then(() => {
        asyncDossiers.execute();
      })
  }

  const handleValidate = (dossier: DossierFacturation) => {
    dossier.validate(credentials)
      .then(() => asyncDossiers.execute());
  }

  const cloture = (contrat: ABDocument) => {
    contrat.cloture()
      .then(() => contrats.execute());
  }


  return <WaitForAsync async={asyncDossiers}>{dossiers => {
    const facturesEnDossier = dossiers.byId.valueSeq()
      .map(d => d.attestations.allAttestations)
      .reduce((a, b) => a.union(b), immutable.Set());
    const factures = allFactures.filter(f =>
      !facturesEnDossier.has(f.documentId))
      .valueSeq();
    const disabledFactDownload = dossiers.byId.valueSeq()
      .flatMap((d) =>
        d.undownloaded(credentials, (c) => c === 'Factures')
      )
      .isEmpty();

    return <WaitForAsync async={contrats}>{contrats =>
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <h5>Dossiers facturation</h5>
            <Button variant="success" size="lg"
                    className={classNames("btn-pill",
                      credentials.in(GRANTS.resp) ? "visible" : "invisible")}
                    disabled={disabledFactDownload}
                    onClick={() =>
                      window.location.href =
                        DossierFacturation.downloadAllFact(filter, ["Factures"], false, true)
                    }
            >
              <i className="fas fa-download"/> Factures
            </Button>
          </Card.Header>
          <Card.Body>
            <DossiersFacturationUI
              credentials={credentials}
              dossiers={dossiersByUser(dossiers, allusers)}
              projets={projets}
              actions={(doc) => actions(doc, credentials)}
              updateDossier={(dossier) => asyncDossiers.execute()}
              docAdmin={contrats.valueSeq().concat(factures.valueSeq())}
              onDelete={() => asyncDossiers.execute()}
              onValidate={credentials.in(GRANTS.resp) ?
                (dossier: DossierFacturation) => handleValidate(dossier) :
                undefined}
              upload={(m) => m === 'Factures' ?
                (dos, concerne) => {
                  setEdit(
                    ABDocument.new(credentials, {
                      categorie: 'Factures',
                      visibility: DocumentVisibility.prestataire,
                      concerne,
                      projetId: dos.projetId
                    }));
                  setEditToDossier(dos);
                } :
                undefined}
            />
          </Card.Body>
        </Card>

        <Card>
          <Card.Header className="d-flex justify-content-between">
            <h5>Contrats</h5>
            <Button size="lg" variant="success" className="text-center btn-circle"
                    onClick={() => setEdit(
                      ABDocument.new(credentials, {
                        categorie: 'Contrats',
                        visibility: DocumentVisibility.prestataire,
                        concerne: filter.user || credentials.user,
                        projetId: (filter.projet || projet)?.projetId
                      })
                    )}>
              <i className="fas fa-upload"/>
            </Button>
          </Card.Header>
          <Card.Body>
            {contrats.isEmpty() ? "Rien à signaler" :
              <Contrats
                docAdmin={contrats.valueSeq()}
                dossiers={dossiers.byId.valueSeq()}
                actions={(doc) => actions(doc, credentials)}
                projets={projetId => projets.get(projetId)}
                newDossier={contrat => handleNewDossier(contrat)}
                cloture={contrat => cloture(contrat)}
              />
            }
          </Card.Body>
        </Card>
      </>
    }</WaitForAsync>;
  }}</WaitForAsync>;
}