import React from "react";

import {Card, Form, InputGroup, ListGroup, ListGroupItem,} from "react-bootstrap";

import {UserBadge, UserRights} from "./UserBadge";
import {User} from "./User";
import immutable from "immutable";
import {AllUsers} from "../../utils/AllUsers";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Corps, CorpsBadge} from "./Corps";

type UsersListProps = {
  users: AllUsers;
  currentUserId?: number;
  onClick: (userId: number) => void;
  title: string;
  corps: immutable.Map<number, Corps>;
}

type UsersListState = {
  search: string
}

export default class UsersList extends React.Component<UsersListProps, UsersListState> {
  static defaultProps = {
    corps: immutable.Map()
  }
  state: UsersListState = {
    search: "",
  };

  render(): JSX.Element {

    let list: Iterable<JSX.Element>;

    if (!this.props.users.isEmpty()) {

      const users = this.props.users.toSeq()
        .filter(u =>
          UserBadge.link(u).toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()) ||
          u.myCorps(this.props.corps).find(c => c.corps.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase()))
        )
        .sort(User.sortByCorps(this.props.corps));


      list = users.map((u, i) => (
        <ListGroupItem action
                       className="nav-item p-2"
                       key={i}
                       active={u.userId === this.props.currentUserId}
                       onClick={() => this.props.onClick(u.userId)}>
          <UserBadge>{u}</UserBadge>

          <div className="float-end ms-auto me-1">
            <UserRights
              color={u.favourite}
              selected={u.userId === this.props.currentUserId}>{u}</UserRights>
          </div>

          <br/>
          <span className="small">
            {u.myCorps(this.props.corps).map((c, j) => <CorpsBadge key={j}>{c}</CorpsBadge>)}
          </span>


        </ListGroupItem>
      ));

    } else {
      list = [<ListGroupItem key={0}>Aucun résultat</ListGroupItem>];
    }


    return (
      <Card className="card-small users-list mb-4">
        <Card.Header className="text-center">
          <h6 className="m-0">{this.props.title}</h6>
          <Form className="w-100">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch}/>
              </InputGroup.Text>
              <Form.Control
                id="search"
                className="search"
                placeholder="Rechercher..."
                value={this.state.search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({search: event.currentTarget.value})
                }
              />
            </InputGroup>
          </Form>
        </Card.Header>

        <ListGroup style={{maxHeight: "400px"}}
                   className="border-top overflow-auto">
          {list}
        </ListGroup>

      </Card>
    );
  }

}
