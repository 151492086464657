import React from "react";
import {Collapse} from "react-bootstrap";


import {Loadable, LOADING, waitFor} from "../../utils/Loading";
import immutable from "immutable";

import {ABDocument} from "./Document";

import 'react-day-picker/lib/style.css';
import {ImagePicker} from "../../utils/ImagePicker";
import {Projet} from "../projets/Projet";


export type ErrorData = { [key: string]: string };

type EventEditorProps = {
  projet: Projet;
  exclude: ABDocument[];
  annotations: immutable.Map<number, number>;
  open: boolean;
  onPick: (value: ABDocument) => void;
  categories: string[];
  className?: string;
};

type EventEditorState = {
  photos: Loadable<ABDocument[]>;
  errors?: ErrorData;
};

export class Gallery extends React.Component<EventEditorProps, EventEditorState> {

  state: EventEditorState = {
    photos: LOADING,
  };

  static defaultProps = {
    annotations: immutable.Map()
  }

  private refreshImages(projetId: number) {
    this.setState({photos: LOADING});
    ABDocument.loadProjet(projetId, false, this.props.categories)
      .then(photos => this.setState({photos}))
      .catch((error) => this.setState({photos: error}));
  }

  componentDidMount(): void {
    this.refreshImages(this.props.projet.projetId);
  }


  render(): JSX.Element {
    const {photos} = this.state;
    const backend = process.env.REACT_APP_BACKEND_SERVER;

    return (
      <Collapse in={this.props.open}>
        <div className={this.props.className}>
          {waitFor(photos, (images) =>
            <ImagePicker
              images={images.filter(i => !this.props.exclude.find(p => p.documentId === i.documentId))
                .map(i => ({
                  src: `${backend}/documents/${i.documentId}/preview?${this.props.annotations.get(i.documentId)}`,
                  value: i,
                  title: i.title,
                }))}
              onPick={(i) => i && this.props.onPick(i.value)}
            />
          )}
        </div>
      </Collapse>
    );


  }


}

