import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import {Button, ButtonToolbar, Col, Container, Row} from "react-bootstrap";

import UserForm from "../components/user-profile-lite/UserForm";
import UsersList from "../components/user-profile-lite/UsersList";
import {Loadable, LOADING, waitFor} from "../utils/Loading";
import Alerts, {AlertData} from "../utils/Alerts";
import FetchError from "../utils/FetchError";
import {grantsToString, useAuth} from "../utils/auth";
import {User} from "../components/user-profile-lite/User";
import immutable from "immutable";
import {PublicKey} from "../utils/Signature";
import {delCertificate, deleteKey} from "./KeysView";
import {AllUsers} from "../utils/AllUsers";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Corps} from "../components/user-profile-lite/Corps";

export function Users() {

  const credentials = useAuth();

  const [userList, setUserList] = useState<Loadable<AllUsers>>(LOADING);
  const [corps, setCorps] = useState<Loadable<immutable.Map<number, Corps>>>(LOADING);
  const [loadedUser, setLoadedUser] = useState<Loadable<User>>(credentials.user);
  const [connexions, setConnexions] = useState<{ time: Date, ip: string }[]>([]);
  const [publicKeys, setPublicKeys] = useState<PublicKey[]>([]);
  const [formError, setFormError] = useState<{ [key: string]: string }>({});
  const [currentUserId, setCurrentUserId] = useState<number>(credentials.user.userId);
  const [alerts, setAlerts] = useState<AlertData | undefined>();

  const refreshUserList = () => {
    setUserList(LOADING);
    AllUsers.loadUsers()
      .then(userList => setUserList(userList))
      .catch(error => setUserList(error))
  }


  const loadUser = (currentUserId: number) => {
    setLoadedUser(LOADING);
    User.load(currentUserId)
      .then(loadedUser => {
        setLoadedUser(loadedUser);
        setFormError({});
        if (credentials.isAdmin()) {
          loadedUser.connexions()
            .then(connexions => setConnexions(connexions));
        }
        loadedUser.keys()
          .then(publicKeys => setPublicKeys(publicKeys));
      })
      .catch(error => setLoadedUser(error));
  }


  const handleClick = (i: number) => {
    setCurrentUserId(i);
    loadUser(i);
  }

  const handleDeleteKey = (keyId: number, currentUser: User) => {
    return deleteKey(credentials, keyId)
      .then(() => currentUser.keys())
      .then((publicKeys) => setPublicKeys(publicKeys))
  }

  const handleDelCertificate = (certificateId: number, currentUser: User) => {
    return delCertificate(credentials, certificateId)
      .then(() => currentUser.keys())
      .then((publicKeys) => setPublicKeys(publicKeys))
  }

  const handleChange = (user: User, change: Partial<User>) => {
    setLoadedUser(user.updated(change));
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, user: User) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    data.append("userId", user.userId?.toString() || '0');
    data.append("favourite", user.favourite.toHex());
    data.delete("telephone");
    data.append("telephone", user.telephone?.formatInternational() ?? '');
    data.delete("grants");
    data.append("grants", grantsToString(user.grants))
    data.delete("email")
    data.append("email", user.email);


    const backend = process.env.REACT_APP_BACKEND_SERVER;
    return fetch(`${backend}/saveUser`, {
      credentials: 'include',
      method: 'POST',
      headers: credentials.headers(),
      body: data
    })
      .then(response => {
          switch (response.status) {
            case 200:
              if (currentUserId === credentials.user.userId) {
                window.location.reload();
              } else {
                response.json().then(result => {
                  const updated = user.updated({userId: result})
                  updated.keys().then(publicKeys => {
                    setFormError({});
                    setLoadedUser(updated);
                    setCurrentUserId(result.userId);
                    setPublicKeys(publicKeys);
                    setAlerts(Alerts.add(
                      {
                        theme: "success",
                        text: <>Modifications de {result.prenom} {result.nom} enregistrées</>
                      },
                      (alert) => setAlerts(alert),
                      alerts
                    ));
                  });
                });
                refreshUserList();
              }
              break;

            case            400            :
              response.json().then(json => {
                setAlerts(Alerts.add(
                  {theme: "danger", text: <>Vérifiez vos entrées</>},
                  (alerts) => setAlerts(alerts),
                  alerts,
                ));
                setFormError(json);
              });
              break;

            default:
              throw new FetchError(response)
          }
        }
      )
      .catch(error => setLoadedUser(error));
  }

  const handleNewUser = () => {
    setLoadedUser(User.new());
    setPublicKeys([]);
    setConnexions([]);
    setCurrentUserId(-1);
  }

//
  useEffect(() => {
    if (credentials.isAdmin()) {
      credentials.user.connexions()
        .then(connexions => setConnexions(connexions));
    }
    credentials.user.keys()
      .then(publicKeys => setPublicKeys(publicKeys));

    Corps.load()
      .then(corps => setCorps(corps))
      .catch(e => setCorps(e));

    refreshUserList();
  }, [credentials]);


  return (
    <Fragment>
      <Alerts alerts={alerts}
              update={(newAlerts) => setAlerts(newAlerts)}/>
      <Container fluid className="main-content-container p-4">
        <Row>
          <Col lg="4" md="6">
            {waitFor(userList, (userList) =>
              waitFor(corps, (corps: immutable.Map<number, Corps>) =>
                <UsersList
                  title="Comptes utilisateur"
                  currentUserId={currentUserId}
                  users={userList}
                  onClick={(i) => handleClick(i)}
                  corps={corps}
                />)
            )}
          </Col>
          <Col lg="8">

            {waitFor(loadedUser, (loadedUser: User) =>
              <UserForm user={loadedUser}
                        credentials={credentials}
                        onChange={(change) => handleChange(loadedUser, change)}
                        onSubmit={(event: React.FormEvent<HTMLFormElement>, user: User) =>
                          handleSubmit(event, user)}
                        errors={formError}
                        connexions={connexions}
                        corps={corps}
                        signatures={publicKeys}
                        onDelCertificate={
                          credentials.isAdmin() ?
                            (keyId) => handleDelCertificate(keyId, loadedUser) :
                            undefined}
                        onDeleteKey={credentials.isAdmin() ?
                          ((keyId) => handleDeleteKey(keyId, loadedUser)) :
                          undefined}
              />
            )}


          </Col>
        </Row>
        <div className="button-placeholder"/>
        <ButtonToolbar className="button-fixed">
          <Button className="btn-success btn-xl text-center btn-circle m-3"
                  onClick={() => handleNewUser()}>
            <FontAwesomeIcon icon={faUserPlus}/>
          </Button>
        </ButtonToolbar>
      </Container>
    </Fragment>
  );


}