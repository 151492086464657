import {Button} from "react-bootstrap";
import React, {HTMLAttributes, ReactNode} from "react";
import {ButtonProps} from "react-bootstrap/esm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

type SubmitButtonProps = {
  submitstatus: SubmitStatus,
  icon?: ReactNode,
} & ButtonProps & HTMLAttributes<HTMLButtonElement>

export enum SubmitStatus {disabled, working, enabled}

export const SubmitButton: React.FunctionComponent<SubmitButtonProps> = (props) =>
  <Button type="submit"
          disabled={props.submitstatus !== SubmitStatus.enabled}
          {...props}>
    {props.submitstatus === SubmitStatus.working ?
      <><FontAwesomeIcon icon={faSpinner} spin/>&nbsp;</> :
      props.icon && <>{props.icon}&nbsp;</>}
    {props.children}
  </Button>;

