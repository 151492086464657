import {GRANTS, WithLoginProps} from "../../utils/auth";
import * as Immutable from "immutable";
import {Task} from "./Task";
import React, {Fragment} from "react";
import {
  addDays,
  addHours,
  addWeeks,
  differenceInHours,
  getISOWeek,
  getISOWeekYear,
  isAfter,
  isBefore,
  isPast,
  max,
  min,
  startOfISOWeek
} from "date-fns";
import {lexico, onDefined} from "../../utils/miscellaneous";
import classNames from "classnames";
import {Desc, Week} from "./Week";
import {formatDate, DefaultDateFormatDayName} from "../../utils/format";
import {Planning} from "./Planning";
import {RGBColor} from "../../utils/colors";

type TimetableProps = WithLoginProps & {
  events: Immutable.Map<number, Task>;
  onClick: (e: Task) => void;
  showProjet: boolean;
  startDate?: Date;
  endDate?: Date;
}

type TimetableState = {
  hovered?: number;
}

export class Timetable extends React.Component<TimetableProps, TimetableState> {
  state: TimetableState = {};

  render() {
    if (this.props.events.isEmpty()) {
      return "Pas de tâches sur ce projet";
    } else {
      const planning = new Map();
      const unplanned = [];

      for (const [, e] of this.props.events) {

        const start = e.startDay;
        const echeance = e.echeance;
        const alert = echeance && start && addHours(start, differenceInHours(echeance, start) * .8);
        const cloture = e.cloture;
        const effectue = e.effectue;
        const now = new Date();

        let lb, ub, openLb, openUb, over, isAlert;

        if (effectue || cloture) {
          // Task is finished, then it must have an earlier start.
          lb = onDefined([start, effectue ?? cloture], min);
          ub = onDefined([start, effectue ?? cloture], max);
          openLb = false;
          openUb = false;
        } else if (start) {
          if (echeance) {
            lb = onDefined([start, echeance], min);
            openLb = isBefore(echeance, start);
            openUb = !isPast(start) && isPast(echeance);
            ub = onDefined([start, echeance], max);
            over = isPast(echeance);
            isAlert = alert && isPast(alert);
          } else if (isPast(start)) {
            over = false;
            lb = start;
            ub = now;
            openLb = false;
            openUb = true;
          }
        } else if (echeance) {
          openLb = true;
          if (isBefore(echeance, now)) {
            lb = echeance;
            ub = now;
            openUb = true;
            over = true;
          } else {
            lb = now;
            ub = echeance;
            openUb = false;
          }
        }

        if (lb && ub) {
          // console.log(e, {lb,ub,openUb,openLb,over,isAlert});
          console.assert(!isAfter(lb, ub), `${e.title} : ${lb} > ${ub}`);
          let sow = startOfISOWeek(lb); //Planning.getDateOfISOWeek(getISOWeek(lb), getISOWeekYear(lb));
          let eow = addWeeks(sow, 1);

          while (Timetable.intersects(lb, ub, sow, eow)) {
            // Must use toISOString because current will not hash correctly
            Planning.group(planning, [getISOWeekYear(sow), getISOWeek(sow)]).set(
              {
                task: e,
                lb,
                ub,
                openUb,
                openLb,
                over,
                isAlert,
                weekStart: max([lb, sow]),
                weekEnd: min([ub, addDays(eow, -2)])
              },
              null);
            sow = eow;
            eow = addWeeks(sow, 1);
          }

        } else {
          unplanned.push(e);
        }
      }

      const content = [];
      if (unplanned.length > 0) {
        content.push(
          <Fragment key={-1}>
            <h5 className="my-0 py-1 text-warning">Non planifié</h5>
            <table className="w-100 mb-3">
              <tbody>
              {unplanned.map(e => {
                const isHovered = this.state.hovered === e.eventId;
                const background = `linear-gradient(90deg, #FFF, ${e.color.hoverable(isHovered).toCSS()}, #FFF)`;
                const meanBgColor = e.color.hoverable(isHovered).mean(RGBColor.WHITE, 0.5);
                return (
                  <tr key={e.eventId} id={`e${e.eventId}`}>
                    <td
                      onMouseEnter={() => this.setState({hovered: e.eventId})}
                      onMouseLeave={() => this.setState({hovered: undefined})}
                      onClick={() => this.props.onClick(e)}
                      className={classNames("border border-light p-2",
                        {"plan-rounded-right": (e.cloture || e.echeance) !== undefined})}
                      style={{
                        background,
                        color: meanBgColor.textColor().toCSS()
                      }}>

                      <Desc event={e} meanBgColor={meanBgColor}
                            showProjet={this.props.showProjet}
                            showEyes={this.props.credentials.in(GRANTS.resp)}
                            showItv={!this.props.credentials.is(e.affectation)}/>

                      {e.cloture ?
                        <> | Clôturé le {formatDate(e.cloture, DefaultDateFormatDayName)} </> :
                        e.echeance && <> |
                              Échéance {formatDate(e.echeance, DefaultDateFormatDayName)}</>}
                    </td>
                  </tr>);
              })}
              </tbody>

            </table>

          </Fragment>);
      }

      const currentWeek = [getISOWeekYear(new Date()), getISOWeek(new Date())];
      const startWeek = this.props.startDate && [getISOWeekYear(this.props.startDate), getISOWeek(this.props.startDate)];
      const endWeek = this.props.endDate && [getISOWeekYear(addDays(this.props.endDate, 7)),
        getISOWeek(addDays(this.props.endDate, 7))];

      for (const [year, yearPlan] of [...planning.entries()].sort()) {
        for (const [week, weekPlan] of [...yearPlan.entries()].sort(
          ([w1]: any, [w2]: any) => w1 - w2
        )) {
          if (startWeek && lexico([Number(year), Number(week)], startWeek) < 0) {
            continue;
          }
          if (endWeek && lexico([Number(year), Number(week)], endWeek) >= 0) {
            continue;
          }
          const compareWeek = lexico([Number(year), Number(week)], currentWeek);
          const isCurrent = compareWeek === 0;

          // console.log([year, week, [...weekPlan.keys()]])

          content.push(
            <div key={60 * year + week}
                 className={classNames(
                   {'hatched-light': compareWeek < 0}
                 )}>
              <h5 className={classNames("my-0 py-2", {"fw-bold": isCurrent})}>
                {isCurrent && <i className="fas fa-caret-right fa-lg"/>} Semaine {week} {year}
              </h5>

              <Week
                hoverEvent={(event) => this.setState({hovered: event})}
                hoveredEvent={this.state.hovered}
                plannedYear={year} plannedWeek={week}
                plan={[...weekPlan.keys()]}
                selectEvent={(event) => this.props.onClick(event)}
                credentials={this.props.credentials}
                showProjet={this.props.showProjet}
              />

            </div>
          );
        }
      }
      return content;
    }

  }

  private static intersects(startA: Date, endA: Date, startB: Date, endB: Date): boolean {
    return isBefore(startA, endB) && isBefore(startB, endA);
  }

}
