import React, {Component} from "react";
import {Button, Col, Form, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import {UserBadge} from "../user-profile-lite/UserBadge";
import Select from "react-select";
import {Loadable, LOADING, waitFor} from "../../utils/Loading";
import Alerts, {AlertData, IAlert} from "../../utils/Alerts";
import {mapRight, SubmitError} from "../../utils/FetchError";
import immutable from "immutable";
import {Message} from "./Message";
import {ABDocument} from "../documents/Document";
import {GRANTS, WithLoginProps} from "../../utils/auth";
import {Projet} from "../projets/Projet";
import {FACING_MODES} from "react-html5-camera-photo";
import {DocEditor} from "../documents/DocEditor";
import filterOptions from "../../utils/SelectSearch";
import Errors from "../../views/Errors";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import {Either} from "monet";
import {faCamera, faPaperPlane, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {User} from "../user-profile-lite/User";
import Autosuggest from "react-autosuggest";
import {Suggestions} from "../../utils/Suggestions";
import classNames from "classnames";

type MessageFormProps = {
  message: Message;
  recipients: AllUsers;
  projet: Projet;
  addAlert: (alert: IAlert) => void;
  onHide: () => void;
  onChange: (message: Message) => void;
  onSend: (message: Message) => void;
} & WithLoginProps;


type MessageFormState = {
  submitStatus: SubmitStatus;
  alerts?: AlertData;
  predefinedMessages: Loadable<immutable.OrderedMap<string, string>>;
  camera?: string;
  editDoc?: ABDocument;
  fatal?: Error;
  errors?: { [key: string]: string };
  suggestions: string[];
  documents: Loadable<immutable.OrderedMap<number, ABDocument>>;
}

export class MessageForm extends Component<MessageFormProps, MessageFormState> {
  state: MessageFormState = {
    submitStatus: SubmitStatus.enabled,
    predefinedMessages: LOADING,
    suggestions: [],
    documents: LOADING,
  };

  componentDidMount() {
    if (this.props.credentials.in(GRANTS.resp)) {
      Message.predefinedMessages()
        .then(predefinedMessages =>
          this.setState({
            predefinedMessages,
            suggestions: predefinedMessages.keySeq().toArray(),
          })
        )
        .catch(error => this.setState({predefinedMessages: error}));
    } else {
      this.setState({
        predefinedMessages: immutable.Map(),
      })
    }
    this.refreshDocuments(this.props.projet);
  }

  refreshDocuments(projet: Projet): void {
    this.setState({documents: LOADING});
    ABDocument.loadProjet(projet.projetId, false, ["Plans PDF", "Notice par lot"])
      .then(documents =>
        this.setState({
          documents: immutable.OrderedMap(
            documents.map((r: ABDocument) => [r.documentId, r])
          )
        })
      )
      .catch((error) => this.setState({documents: error}));
  }

  sendMessage(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.setState({submitStatus: SubmitStatus.working});

    this.props.message.send(this.props.credentials, this.props.projet)
      .then(r => r.fold(
        errors =>
          this.setState({
            alerts: Alerts.add(
              {theme: "danger", text: <>Vérifiez vos entrées</>},
              (alerts) => this.setState({alerts}), this.state.alerts
            ),
            errors,
            submitStatus: SubmitStatus.enabled,
          }),
        ok => {
          this.props.onHide();
          this.props.onSend(this.props.message);
        }
      ))
      .catch(errors => this.setState({fatal: errors}));
  }

  handleSubjectChange(subject: string, pm: immutable.Map<string, string>) {
    const predefined = pm.get(subject);
    let changes: Partial<Message> = {subject};
    if (predefined) {
      const lines = immutable.Seq.Indexed(this.props.message.content.split("\n"));
      let greetings = lines.takeWhile(l => l === "Bonjour," || l.startsWith("Cher M")).join("\n");
      const remaining = lines.skipWhile(l => l === "Bonjour," || l.startsWith("Cher M")).join("\n");

      if (greetings.length > 0) {
        greetings = `${greetings}\n\n`
      }

      changes = {
        ...changes,
        content: `${greetings}${predefined}\n\n${remaining}`,
      };
    }
    this.props.onChange(this.props.message.updated(changes));

  }


  handleRecptChange(users: User[]): void {
    this.props.onChange(
      this.props.message
        .updated({
          recipient: users,
        })
        .addDefaultGreetings()
    );

  }

  render() {
    const {credentials, message, onChange} = this.props;
    const {errors} = this.state;

    if (this.state.fatal) {
      return <Errors>{this.state.fatal}</Errors>;
    } else {

      return (
        <Modal show size={"lg"} onHide={this.props.onHide}>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.sendMessage(e)}>
            <Modal.Body>
              <Row>
                <Col>
                  <label>De</label>{' '}
                  <UserBadge>{credentials.user}</UserBadge>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>À</label>{' '}

                  <Select
                    name="recipient[]"
                    isSearchable
                    isMulti
                    value={message.recipient.map(u => userSelect(u))}
                    className={errors?.recipient ? "form-control is-invalid" : ""}
                    options={this.props.recipients.userSelect()}
                    filterOption={filterOptions}
                    onChange={(change) => {
                      this.handleRecptChange(
                        change.map(v => v?.value).filter((user): user is User => !!user)
                      )
                    }
                    }
                  />

                  <FormControl.Feedback type="invalid">
                    {errors?.recipient && "Sélectionnez un destinataire"}
                  </FormControl.Feedback>
                </Col>
              </Row>

              <Row>
                <Col>
                  <label>Sujet</label>
                  {waitFor(this.state.predefinedMessages, (pm: immutable.Map<string, string>) =>
                    <Autosuggest
                      suggestions={this.state.suggestions}
                      inputProps={{
                        className: classNames("form-control", errors?.subject && "is-invalid"),
                        onChange: (e, {newValue}) =>
                          this.handleSubjectChange(newValue, pm),
                        value: message.subject,
                      }}
                      onSuggestionsFetchRequested={({value}) =>
                        this.setState({
                          suggestions: Suggestions.updateSuggestions(
                            pm.keySeq(), value
                          )
                        })
                      }
                      onSuggestionsClearRequested={() =>
                        this.setState({suggestions: pm.keySeq().toArray()})
                      }
                      renderSuggestion={(s) => s}
                      getSuggestionValue={(s) => Suggestions.getSuggestionValue(s)}

                    />
                  )}

                  <FormControl.Feedback type="invalid">{errors?.subject}</FormControl.Feedback>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Message</label>
                  <FormControl as="textarea"
                               id="content"
                               rows={20}
                               name={"content"}
                               className="col-sm-11"
                               value={message.content}
                               onChange={(e) =>
                                 this.props.onChange(message.updated({content: e.currentTarget.value}))}
                  />
                  <div className="text-muted fw-light">
                    [La signature sera ajoutée automatiquement lors de l’envoi du message]
                  </div>
                  <div className="fw-normal">
                    {message.signature()}
                  </div>
                </Col>
              </Row>


              <Row>
                <Col>
                  {waitFor(this.state.documents, (documents: immutable.Map<number, ABDocument>) =>
                    <InputGroup className="mt-3 w-auto">
                      <Button
                        className="btn-pill"
                        onClick={(evt: React.MouseEvent) => {
                          evt.preventDefault();
                          this.setState({
                            editDoc: ABDocument.new(this.props.credentials,
                              {
                                projetId: this.props.projet.projetId,
                                title: message.subject,
                                categorie: this.props.credentials.user.grants === GRANTS.std ? 'Photos client' : 'Photos'
                              }),
                            camera: FACING_MODES.ENVIRONMENT,
                          });
                        }}>
                        <FontAwesomeIcon icon={faCamera}/>
                      </Button>

                      <Button
                        className="btn-pill"
                        onClick={(evt: React.MouseEvent) => {
                          evt.preventDefault();
                          this.setState({
                            editDoc: ABDocument.new(this.props.credentials, {
                              projetId: this.props.projet.projetId,
                              title: message.subject,
                            })
                          });
                        }}>
                        <FontAwesomeIcon icon={faPaperclip}/>
                      </Button>

                      <Select
                        id="attachments"
                        isMulti
                        className="w-75"
                        name="attachments[]"
                        value={message.attachedDocs.map(MessageForm.docOptions)}
                        options={documents.valueSeq().map(MessageForm.docOptions).toArray()}
                        onChange={(elt) =>
                          Array.isArray(elt) &&
                          onChange(message.updated({attachedDocs: elt.map(d => d.value)}))
                        }
                      />
                    </InputGroup>
                  )}
                  {this.state.editDoc &&
                      <DocEditor
                          credentials={this.props.credentials}
                          edit={this.state.editDoc}
                          update={(doc) => this.setState({editDoc: doc})}
                          onSubmit={(submitted, saved) =>
                            this.handleNewPhoto(submitted, saved)}
                          camera={this.state.camera}
                          setCameraState={(camera) => this.setState({camera})}
                      />
                  }
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col>
                  <Button
                    variant={"secondary"}
                    className={"mx-1"}
                    onClick={() => this.props.onHide()}>
                    Annuler
                  </Button>
                  <SubmitButton
                    type="submit"
                    submitstatus={this.state.submitStatus}
                    icon={<FontAwesomeIcon icon={faPaperPlane}/>}
                  >
                    Envoyer
                  </SubmitButton>
                </Col>
              </Row>
            </Modal.Footer>
          </Form>

        </Modal>
      );
    }

  }

  static docOptions(doc: ABDocument) {
    return ({value: doc, label: doc.title});
  }

  handleNewPhoto(submitted: ABDocument, docs: Either<SubmitError, ABDocument[]>): Promise<Either<{ [key: string]: string }, ABDocument[]>> {
    return mapRight(docs, docs => {
      this.refreshDocuments(this.props.projet);
      this.props.onChange(this.props.message.updated({
        attachedDocs: this.props.message.attachedDocs.concat(docs)
      }));
      return new Promise(r => r(docs));
    });
  }
}