import {ReactNode} from "react";

export class Suggestions {
  private readonly base: string[];

  constructor(base: string[] = []) {
    this.base = base;
  }

  /** When suggestion is clicked, Autosuggest needs to populate the input
   *  based on the clicked suggestion. Teach Autosuggest how to calculate the
   *  input value for every given suggestion.
   */
  static getSuggestionValue(suggestion: string): string {
    return suggestion;
  }

  static renderSuggestion(suggestion: string): ReactNode {
    return suggestion;
  }

  updateSuggestions(value: string): string[] {
    return Suggestions.updateSuggestions(this.base, value);
  }

  static updateSuggestions(base: Iterable<string>, value: string): string[] {
    const inputValue = value.trim().toLowerCase();
    return [...base].filter(titre =>
      titre.toLowerCase().indexOf(inputValue) >= 0
    );
  }

  clear(): string[] {
    return [];
  }
}