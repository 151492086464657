import Gravatar from "react-gravatar";
import React, {Fragment, FunctionComponent} from "react";
import md5 from "md5";
import * as queryString from "query-string";
import {Image} from "react-bootstrap";
import {User} from "./User";
import {GRANTS} from "../../utils/auth";
import {RGBColor} from "../../utils/colors";
import {faUserCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {faHardHat} from "@fortawesome/free-solid-svg-icons/faHardHat";
import {faUserTie} from "@fortawesome/free-solid-svg-icons/faUserTie";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import * as immutable from "immutable";


type UserBadgeProps = {
  mailto?: {
    body?: string;
    subject?: string;
  };
  children: User;
  size: number;
  civilite: boolean;
  prenom: boolean;
}



export class UserBadge extends React.Component<UserBadgeProps> {

  static defaultProps = {
    size: 25,
    civilite: false,
    prenom: true,
  };

  static gravatar(user: User, size = 80) {

    // MD5 (Message-Digest Algorithm) by WebToolkit
    //
    const hash = md5(user.email.trim().toLowerCase());

    return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size}&d=retro`;
  }

  static link(user: User, prenom = true, civilite = false): string {
    const name = immutable.Seq.Indexed.of(
      civilite ? immutable.Seq.Indexed.of(user.civilite) : immutable.Seq(),
      prenom ? immutable.Seq.Indexed.of(user.prenom) : immutable.Seq(),
      immutable.Seq.Indexed.of(user.nom)
    ).flatten()
      .join(" ");

    return user.societe ? `${user.societe} (${name})` : name;
  }

  static image(user: User, size = 25) {
    return user.logo ?
      <Image
        className="rounded-circle me-1"
        src={`${process.env.REACT_APP_BACKEND_SERVER}/logo/${user.userId}?size=${size}&time=${user.logo}`}/>
      :
      <Gravatar className="rounded-circle me-1" email={user.email}
                size={size} protocol="https://" alt=""/>;
  }

  render() {
    const {children, mailto, size, civilite, prenom} = this.props;

    const link = UserBadge.link(children, prenom, civilite);

    return (
      <Fragment>
        {UserBadge.image(children, size)}
        {mailto ?
          <a href={`mailto:${children.email}?${queryString.stringify(mailto)}`}>{link}</a> : link}
      </Fragment>
    );
  }
}

export const UserRights: FunctionComponent<{ children: User, selected: boolean, color: RGBColor }> =
  ({
     children,
     selected,
     color: baseColor
   }) => {
    const color = baseColor.hoverable(selected);
    if (children.grants >= GRANTS.itv) {
      return (
        <span className="fa-stack">
          <FontAwesomeIcon icon={faCircle} className="fa-stack-1x" size={"2x"} color={color.toCSS()}/>
          <GrantsIcon className="fa-stack-1x" grants={children.grants} color={color.textColor().toCSS()}/>
        </span>
      );
    } else {
      return null;
    }

  };

type GrantsIconProps = Partial<FontAwesomeIconProps> & { grants: GRANTS };

export const GrantsIcon: FunctionComponent<GrantsIconProps> = ({grants, ...props}) => {
  switch (grants) {
    case GRANTS.admin:
      return <FontAwesomeIcon {...props} icon={faUserCog}/>;
    case GRANTS.resp:
      return <FontAwesomeIcon {...props} icon={faUserTie}/>;
    case GRANTS.itv:
      return <FontAwesomeIcon {...props} icon={faHardHat}/>;
    // case GRANTS.std:
    //   return <FontAwesomeIcon {...props} icon={faUser}/>
    default:
      return null;
  }
}
