import {parseISO} from "date-fns";
import * as immutable from "immutable";
import {User} from "../user-profile-lite/User";
import * as queryString from "query-string";
import {filterStatus} from "../../utils/FetchError";
import {Attestations, AttestationsJson} from "./Attestations";
import {Projet} from "../projets/Projet";
import {ABDocument} from "../documents/Document";
import {Credentials} from "../../utils/auth";

const backend = process.env.REACT_APP_BACKEND_SERVER;

interface DossierFacturationJSON {
    dossierId: number,
    projetId: number,
    attestations: AttestationsJson,
    cloture?: string,
}

export class DossierFacturation {
  dossierId: number;
  projetId: number;
  attestations: Attestations;
  cloture?: Date;

  constructor(dossierId: number, projetId: number, attestations: Attestations, cloture?: Date) {
    this.dossierId = dossierId;
    this.attestations = attestations;
    this.cloture = cloture;
    this.projetId = projetId;
  }

  static parseDossierFacturation({dossierId, projetId, attestations, cloture}: DossierFacturationJSON) {
    return new DossierFacturation(
      dossierId,
      projetId,
      Attestations.parse(attestations),
      cloture === undefined ? undefined : parseISO(cloture),
    );
  }

  static parseDFPerUser(ds: { user: User, dossiers: any[] }): { user: User, dossiers: DossierFacturation[] } {
    return {user: ds.user, dossiers: ds.dossiers.map(d => DossierFacturation.parseDossierFacturation(d))};
  }

  static load(filter: { user?: User, projet?: Projet, dossierId?: number, showCloture?: boolean }): Promise<immutable.Map<User, DossierFacturation[]>> {
    return fetch(`${backend}/dossiers?${queryString.stringify({
        userId: filter.user?.userId,
        projetId: filter.projet?.projetId,
        dossierId: filter.dossierId,
        showCloture: filter.showCloture,
      })}`,
      {credentials: 'include'})
      .then(filterStatus)
      .then(r => r.json())
      .then((dfs: { user: User, dossiers: DossierFacturationJSON[] }[]) =>
        immutable.Map(
          dfs.map(DossierFacturation.parseDFPerUser)
            .map(({user, dossiers}) => ([user, dossiers]))
        )
      );
  }

  static create(credentials: Credentials, contrat: ABDocument): Promise<DossierFacturation> {
    return fetch(`${backend}/dossier/new?${queryString.stringify({
      contrat: contrat.documentId
    })}`, {
      credentials: 'include',
      method: 'POST',
      headers: credentials.headers()
    })
      .then(filterStatus)
      .then(r => r.json())
      .then(d => DossierFacturation.parseDossierFacturation(d));
  }

  delete(credentials: Credentials): Promise<void> {
    return fetch(`${backend}/dossier/${this.dossierId}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: credentials.headers(),
    })
      .then(filterStatus)
      .then(() => {
        return;
      });
  }

  addAllAttestations(credentials: Credentials): Promise<DossierFacturation> {
    return fetch(`${backend}/dossier/${this.dossierId}/addAll`, {
      credentials: 'include',
      method: 'PUT',
      headers: credentials.headers(),
    })
      .then(filterStatus)
      .then(r => r.json())
      .then(DossierFacturation.parseDFPerUser)
      .then(({dossiers}) => dossiers[0]);
  }

  addDocument(credentials: Credentials, doc: ABDocument): Promise<DossierFacturation> {
    return fetch(`${backend}/dossier/${this.dossierId}/${doc.documentId}`, {
      credentials: 'include',
      method: 'PUT',
      headers: credentials.headers(),
    })
      .then(filterStatus)
      .then(r => r.json())
      .then(DossierFacturation.parseDFPerUser)
      .then(({dossiers}) => dossiers[0]);
  }

  removeDocument(credentials: Credentials, doc: ABDocument): Promise<DossierFacturation> {
    return fetch(`${backend}/dossier/${this.dossierId}/${doc.documentId}`, {
      credentials: 'include',
      method: 'DELETE',
      headers: credentials.headers()
    })
      .then(filterStatus)
      .then(r => r.json())
      .then(DossierFacturation.parseDFPerUser)
      .then(({dossiers}) => dossiers[0]);
  }

  validate(credentials: Credentials): Promise<DossierFacturation> {
    return fetch(`${backend}/dossier/${this.dossierId}/validate`, {
      credentials: 'include',
      method: 'PUT',
      headers: credentials.headers()

    })
      .then(filterStatus)
      .then(r => r.json())
      .then(DossierFacturation.parseDFPerUser)
      .then(({dossiers}) => dossiers[0]);
  }

  hasCategorie(c: string): boolean {
    return this.attestations.allDocs().map(d => d.categorie).includes(c);
  }

  undownloaded(by: Credentials, cats: (cat: string) => boolean = () => true): ABDocument[] {
    return this.attestations.allDocs().filter(d => cats(d.categorie) &&
      !d.telecharge.find(u => by.is(u.user)));
  }

  static downloadAllFact(filter: { dossier?: DossierFacturation, user?: User, projet?: Projet, showCloture?: boolean }, categorie: string[], redownload: boolean, complet?: boolean): string {
    return `${backend}/dossiers/downloadZip?${queryString.stringify({
      dossierId: filter.dossier?.dossierId,
      userId: filter.user?.userId,
      projetId: filter.projet?.projetId,
      showCloture: filter.showCloture,
      categorie,
      redownload,
      complet
    })}`;
  }

  download(redownload = true): string {
    return DossierFacturation.downloadAllFact({dossier: this}, ['Attestations', 'Factures', 'Contrats'], redownload);
  }
}