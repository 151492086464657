import {Component} from "react";
import classNames from "classnames";
import {Navbar} from "react-bootstrap";


import {UserBadge} from "../../user-profile-lite/UserBadge";
import {Projet} from "../../projets/Projet";
import {Constants, Dispatcher} from "../../../flux";

type MainNavbarProps = {
    projet?: Projet;
}

class MainNavbar extends Component<MainNavbarProps> {

    constructor(props: MainNavbarProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        Dispatcher.dispatch({
            actionType: Constants.TOGGLE_SIDEBAR
        });
    }

    render() {
        const {projet} = this.props;
        const classes = classNames(
            "main-navbar",
            "bg-white",
            "px-3 m-0",
            "sticky-top"
        );

        return (
            <Navbar variant="light" className={classes} expand="md">
                {projet && <Navbar.Brand href="#" className="p-0 m-0">
                    <h5>
                        {projet.title}<span className="d-none d-md-inline"> −&nbsp;
                        <UserBadge civilite prenom={false}>{projet.client!}</UserBadge></span>
                    </h5>
                </Navbar.Brand>
                }
                <Navbar.Toggle
                    aria-controls="main-sidebar"
                    className="toggle-sidebar m-0 d-sm-inline d-md-none"
                    onClick={this.handleClick}
                />
            </Navbar>
        );
    }
}


export default MainNavbar;
