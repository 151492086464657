import {Fragment, useEffect, useState} from "react";
import {Button, ButtonToolbar, Container} from "react-bootstrap";
import Alerts, {AlertData} from "../utils/Alerts";
import {WaitForAsync} from "../utils/Loading";
import {useAuth,} from "../utils/auth";
import {Message} from "../components/messages/Message";
import {MessageForm} from "../components/messages/MessageForm";
import {MessageList} from "../components/messages/MessageList";
import {AllUsers} from "../utils/AllUsers";
import {useProject,} from "../utils/SelectedProject";
import {User} from "../components/user-profile-lite/User";
import {undefinedToArray} from "../utils/miscellaneous";
import {faFeather} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Task} from "../components/planning/Task";
import * as immutable from "immutable";
import {MessageCard} from "../components/messages/MessageCard";
import queryString from "query-string";
import {useAsync} from "react-async-hook";

export function MessagesView() {
  const [alerts, setAlerts] = useState<AlertData | undefined>();
  const credentials = useAuth();
  const [projet] = useProject();
  const recipients = useAsync(
    () => AllUsers.loadUsers({}, (u) => u.userId !== credentials.user.userId),
    [credentials.user]
  )

  const [selectedMessage, setSelectedMessage] = useState<Message | undefined>();

  const asyncMessages = useAsync<immutable.OrderedMap<number, Message>>(
    () => {
      if (projet) {
        return Message.load(projet)
          .then(messages => immutable.OrderedMap(messages.map(m => [m.messageId, m])))
      } else {
        return new Promise(r => r(immutable.OrderedMap()))
      }
    },
    [projet],
    {
      setLoading: state => ({...state, loading: true}),
    }
  );

  const [init, setInit] = useState(false);
  const [message, setMessage] = useState<Message | undefined>();

  useEffect(() => {
    if (!init && recipients.result) {
      const fromGet = Message.fromGet(credentials.user, recipients.result, window.location.search);
      if (fromGet) {
        fromGet.then((message) => setMessage(message));
        setInit(true);
      }
    }
  }, [recipients, credentials.user])

  useEffect(() => {
    if (asyncMessages.result && !init) {
      const {o} = queryString.parse(window.location.search);
      if (o) {
        setSelectedMessage(asyncMessages.result.get(Number(o)))
      }
    }
  }, [asyncMessages])

  const createEvent = (message: Message) => {
    if (projet) {
      return Task.new(projet)
        .updated({
          title: message.subject,
          description: message.content,
          photos: message.attachedDocs,
        })
        .save(credentials)
        .then((t) => window.location.href = "/projet/planning/" + t.eventId)
    }
  }


  const handleSelectMessage = (m: Message) => {
    setSelectedMessage(m);
    if (m.unread) {
      m.markAs(credentials, true)
        .then(() => asyncMessages.execute());
    }
  }

  const markAsUnread = (m: Message) => {
    if (!m.unread) {
      m.markAs(credentials, false)
        .then(() => asyncMessages.execute());
    }
  }

  return (
    <Fragment>
      <Alerts
        alerts={alerts}
        update={(newAlerts) => setAlerts(newAlerts)}/>
      <Container fluid className="main-content-container p-4">
        <WaitForAsync async={asyncMessages}>{messages => <>
          <MessageList messages={messages.valueSeq()}
                       showNotif={false}
                       onShowNotifChange={(sn) =>
                         asyncMessages.execute(projet, false)
                       }
                       onSelect={(m) => handleSelectMessage(m)}
          />


          {selectedMessage &&
              <MessageCard
                  message={selectedMessage}
                  onCreateEvent={(m) => createEvent(m)}
                  onHide={() => setSelectedMessage(undefined)}
                  onMarkAsUnread={(m) => markAsUnread(m)}
              />
          }
        </>
        }</WaitForAsync>

        <WaitForAsync async={recipients}>{(recipients: AllUsers) => <>
          <div className="button-placeholder"/>
          <ButtonToolbar className="button-fixed">
            <Button className="btn-success text-center btn-circle m-2 ms-auto btn-xl"
                    onClick={() => setMessage(newMessage(credentials.user, recipients))}
            >
              <FontAwesomeIcon icon={faFeather}/>
            </Button>
          </ButtonToolbar>
          {projet && message &&
              <MessageForm
                  onHide={() => setMessage(undefined)}
                  message={message}
                  credentials={credentials}
                  recipients={recipients}
                  projet={projet}
                  addAlert={(alert) => setAlerts(
                    Alerts.add(alert, (alerts) => setAlerts(alerts), alerts)
                  )}
                  onSend={message => asyncMessages.execute()}
                  onChange={message => setMessage(message)}
              />
          }
        </>}</WaitForAsync>

      </Container>
    </Fragment>
  );

}

function newMessage(from: User, recipients: AllUsers): Message {
  return Message.new(from)
    .updated({
      recipient: undefinedToArray(recipients.one())
    })
    .addDefaultGreetings();
}
