import {Badge, Button, ButtonToolbar, Col, Modal, Row} from "react-bootstrap";
import immutable, {Seq} from "immutable";
import * as React from "react";
import {Corps} from "../user-profile-lite/Corps";
import {faMinus, faPlus, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type AttributionsAddProps = {
  lots: immutable.Seq.Indexed<Corps>;
  addLots: (lots: immutable.Seq.Indexed<Corps>) => void;
  removeLot: (att: Corps) => void;
  onHide: () => void;
}

export class AttributionsAdd extends React.Component<AttributionsAddProps> {

  render(): JSX.Element {
    return (
      <Modal show onHide={() => this.props.onHide()} size="lg">
        <Modal.Body>
          <Row>
            {this.props.lots
              .sortBy((c) => c.corpsId)
              .map((a, i) =>
                <Col sm={12} xl={6} key={i}>
                  <div
                    className="mx-0 my-1 p-1 px-3 rounded"
                    style={{
                      backgroundColor: a.couleur.toCSS(),
                      color: a.couleur.textColor().toCSS()
                    }}>
                    {a.corps}
                    <Button size="sm" className="border-0 btn-circle ms-1 text-center p-1"
                            style={{backgroundColor: a.couleur.textColor().toCSS(), color: a.couleur.toCSS()}}
                            onClick={() => this.props.addLots(Seq([a]))}>
                      <FontAwesomeIcon icon={faPlus}/>
                    </Button>
                    <Button size="sm" className="border-0 btn-circle ms-1 text-center p-1"
                            style={{backgroundColor: a.couleur.textColor().toCSS(), color: a.couleur.toCSS()}}
                            onClick={() => this.props.removeLot(a)}>
                      <FontAwesomeIcon icon={faMinus}/>
                    </Button>
                  </div>
                </Col>
              )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button onClick={() => this.props.addLots(this.props.lots.valueSeq())}>
              <FontAwesomeIcon icon={faPlusCircle}/>&nbsp;
              Ajouter tout
            </Button>
            <Button className="ms-1" variant="secondary"
                    onClick={() => this.props.onHide()}>Fermer</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
      ;
  }
}