import {Button, Card, Collapse, Table} from "react-bootstrap";
import {Entreprise, LogoEntreprise, Projet} from "../projets/Projet";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {formatEur} from "../../utils/format";
import {ProjetCommercial} from "../projets/ProjetCommercial";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";

type VentesProps = {
  entreprises: Entreprise[],
  onEditProjet: (projet: Projet) => void,
  projets: { projet: Projet; projetCommercial: ProjetCommercial; }[],
}

export function prixPC(projetCommercial: ProjetCommercial) {
  return projetCommercial.prixDefinitif ?? projetCommercial.prixMaison;
}

function prixProjets(projets: { projet: Projet, projetCommercial: ProjetCommercial }[]): (number | undefined)[] {
  return projets.map(p => prixPC(p.projetCommercial))
}

export function Ventes({entreprises, projets, onEditProjet}: VentesProps) {
  const [detail, setDetail] = useState(false);

  return <Card className={"my-3"}>
    <Card.Header>Volume de ventes sur une période donnée</Card.Header>
    <Card.Body>
      <Table>
        <thead>
        <tr>
          <th>
            <Button className="py-0" size="lg" variant="link" onClick={() => setDetail(!detail)}><FontAwesomeIcon
              icon={detail ? faAngleUp : faAngleDown}/></Button>
          </th>
          {entreprises.map(e => <th className="text-end" key={e.entrepriseId}>{e.nom}&nbsp;
            <LogoEntreprise>{e}</LogoEntreprise></th>)}
        </tr>
        <tr>
          <th>
            Total = {
            formatEur(
              prixProjets(projets)
                .reduce<number>((a, v) => a + (v ?? 0), 0)
            )} (
            {projets.length} ventes, moy. {
            formatEur(
              prixProjets(projets).reduce<number>((a, v) => a + (v ?? 0), 0) /
              prixProjets(projets).filter(p => p !== undefined).length
            )
          })

          </th>
          {entreprises.map(e => {
            const proj = projets.filter(p => p.projet.entreprise?.entrepriseId === e.entrepriseId);
            const total = prixProjets(proj).reduce<number>((a, v) => a + (v ?? 0), 0);
            const count = prixProjets(proj).filter(p => p !== undefined).length;
            return <th className="text-end" key={e.entrepriseId}>
              {formatEur(total)} ({proj.length} ventes{count > 0 && `, moy. ${formatEur(total / count)}`})
            </th>
          })}
        </tr>
        </thead>
        <Collapse in={detail}>
          <tbody>
          {projets.map(p =>
            <tr key={p.projet.projetId}>
              <td>
                <Button
                  variant="link"
                  onClick={() => onEditProjet(p.projet)}
                >
                  {p.projet.title}
                </Button>− <UserBadge>{p.projet.client!}</UserBadge></td>
              {entreprises.map(e => {
                if (e.entrepriseId === p.projet.entreprise?.entrepriseId) {
                  const prix = p.projetCommercial.prixDefinitif ?? p.projetCommercial.prixMaison;
                  if (prix !== undefined) {
                    return <td className="text-end" key={e.entrepriseId}>{formatEur(prix)}</td>;
                  } else {
                    return <td className="text-end" key={e.entrepriseId}>non renseigné</td>;
                  }
                } else {
                  return <td key={e.entrepriseId}/>;
                }
              })}
            </tr>
          )}
          </tbody>
        </Collapse>
      </Table>
    </Card.Body>
  </Card>;
}