import './imagePicker.scss'

import React from 'react'
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Img<V> = { src: string, value: V, title: string };

type ImagePickerProps<V> = {
  images: Img<V>[],
  onPick: (i: Img<V> | undefined) => void,
}

type ImagePickerState<V> = {
  picked?: Img<V>,
}

export class ImagePicker<V> extends React.Component<ImagePickerProps<V>, ImagePickerState<V>> {
  state: ImagePickerState<V> = {};

  handleImageClick(image: Img<V>) {
    const {onPick} = this.props;

    let newerPickedImage;
    if (image.value !== this.state.picked?.value) {
      newerPickedImage = image;
    }

    onPick(newerPickedImage);

  }

  renderImage(image: Img<V>, i: number) {
    return (
      <Image
        src={image.src}
        isSelected={this.state.picked?.value === image.value}
        onImageClick={() => this.handleImageClick(image)}
        title={image.title}
        key={i}
      />
    )
  }

  render() {
    const {images} = this.props
    return (
      <div className="image_picker">
        {images.map((img, i) => this.renderImage(img, i))}
        <div className="clear"/>
      </div>
    )
  }
}

const ImageStyle: (width: number, height: number) => React.CSSProperties = (width, height) => {
  return {
    width,
    height,
    objectFit: "cover"
  };
}

type ImageProps = {
  src: string,
  title: string,
  isSelected: boolean,
  onImageClick: () => void,
}

function Image({src, isSelected, onImageClick, title}: ImageProps) {
  return (
    <div className={`responsive${isSelected ? " selected" : ""}`}
         onClick={onImageClick}>
      <img src={src}
           className={`thumbnail${isSelected ? " selected" : ""}`}
           style={ImageStyle(150, 150)}
           alt=""
      />
      <div className="checked">
        <FontAwesomeIcon icon={faCheckCircle} color={"green"}/>
      </div>
      <div className="title">{title}</div>
    </div>
  )
}
