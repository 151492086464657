import {Nav} from "react-bootstrap";

import SidebarNavItem from "./SidebarNavItem";
import {Store} from "../../../flux";
import {useAuth} from "../../../utils/auth";
import {Projet, ProjetStats} from "../../projets/Projet";

type SidebarNavItemsProps = {
  projet?: Projet;
  stats?: ProjetStats;
  version: string;
}

export function SidebarNavItems(props: SidebarNavItemsProps) {
  const credentials = useAuth();
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {
          Store.getSidebarItems(credentials, props.projet, props.stats)
            .map((item, idx) => (
              <SidebarNavItem key={idx} item={item}/>
            ))
        }
      </Nav>
      <div className="small text-light text-center">Version {props.version}</div>
    </div>
  );
}
