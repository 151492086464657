import {filterStatus} from "../../utils/FetchError";
import {Projet} from "../projets/Projet";
import {Credentials} from "../../utils/auth";
import * as immutable from "immutable";

export type ChoixMateriauxOption = {
  idChoix: number,
  noOption: number,
  description: {
    md: string,
    html: string,
  },
}

export interface TemplateMateriaux {
  idChoix: number,
  defaultTitre: string,
  description: {
    md: string,
    html: string,
  },
  options: ChoixMateriauxOption[],
}

export interface ChoixMateriaux extends TemplateMateriaux {
  idChoixProjet: number,
  idProjet: number,
  titre: string,
  reponseLibre: string,
  valide: boolean,
  noOption?: number,
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export function loadChoixMateriaux(projet: Projet): Promise<ChoixMateriaux[]> {
  return fetch(`${backend}/projet/${projet.projetId}/choixMateriaux`, {credentials: "include"})
    .then(filterStatus)
    .then(r => r.json());
}

export function saveChoixMateriaux(credentials: Credentials, cm: immutable.Seq.Indexed<ChoixMateriaux>): Promise<immutable.Seq.Indexed<ChoixMateriaux>> {
  return fetch(`${backend}/choixMateriaux`,
    {
      credentials: "include",
      method: "PUT",
      headers: credentials.headers({"Content-Type": "application/json"}),
      body: JSON.stringify(cm.toArray())
    },
  )
    .then(filterStatus)
    .then(r => r.json())
    .then(cms => immutable.Seq(cms));
}

export function loadTemplatesMateriaux(): Promise<immutable.Seq.Indexed<TemplateMateriaux>> {
  return fetch(`${backend}/choixMateriaux`)
    .then(filterStatus)
    .then(r => r.json())
    .then((tm: TemplateMateriaux[]) => immutable.Seq(tm));
}

export function deleteChoixMateriaux(credentials: Credentials, cm: ChoixMateriaux): Promise<Response> {
  return fetch(`${backend}/choixMateriaux/${cm.idChoixProjet}`,
    {
      credentials: "include",
      method: "DELETE",
      headers: credentials.headers(),
    }
  )
    .then(filterStatus)
}