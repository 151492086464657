import {Entreprise, JSONProjet, Projet} from "../projets/Projet";
import immutable, {Seq} from "immutable";
import {parseISO} from "date-fns";
import {filterStatus} from "../../utils/FetchError";
import queryString from "query-string";

export interface StadeFacturation {
  stadeId: number,
  projetId: number,
  nom: string,
  taux: number,
  validDate?: Date,
}

export type FacturationProjet = {
  projet: Projet,
  prix?: number,
  facturations: immutable.Map<number, StadeFacturation>;
};

export function validFacturations(fp: FacturationProjet): Seq.Indexed<StadeFacturation> {
  return fp.facturations.valueSeq().filter(f => f.validDate);
}

type StadeJSON = { projetId: number, stadeId: number, nom: string, taux: number, validDate?: string };

export function parseStadeJson(stade: StadeJSON): StadeFacturation {
  return {
    projetId: stade.projetId,
    stadeId: stade.stadeId,
    nom: stade.nom,
    taux: stade.taux,
    validDate: stade.validDate === undefined ? undefined : parseISO(stade.validDate),

  };
}

export type FacturationJSON = JSONProjet & { facturations: StadeJSON[], prix?: string };

export function parseFacturationJson(projetFact: FacturationJSON): FacturationProjet {
  return (
    {
      projet: Projet.parse(projetFact),
      prix: projetFact.prix ? Number(projetFact.prix) : undefined,
      facturations: immutable.Map(projetFact.facturations
        .map(parseStadeJson)
        .map((f: StadeFacturation) => [f.stadeId, f]))
    }
  );
}

const backend = process.env.REACT_APP_BACKEND_SERVER;

export async function fetchFacturations(includeTermine = false, entreprise?: Entreprise): Promise<FacturationProjet[]> {
  const response1 = await fetch(`${backend}/facturation?${queryString.stringify({
      entreprise: entreprise?.entrepriseId,
      includeTermine,
    })}`,
    {credentials: 'include'});

  const facturations: FacturationJSON[] = await filterStatus(response1).json();
  return facturations.map(parseFacturationJson);


}

export function fetchStades(): Promise<StadeFacturation[]> {
  return fetch(`${backend}/facturation/stades`, {credentials: 'include'})
    .then(filterStatus)
    .then(response => response.json())
}