import {useProject} from "../utils/SelectedProject";
import {useAuth} from "../utils/auth";
import {useState} from "react";
import * as immutable from "immutable";
import {OptionChantier} from "../components/options/OptionChantier";
import {WaitForAsync} from "../utils/Loading";
import {Alert, Button, Card, Container, Modal, Table} from "react-bootstrap";
import {OptionChantierLI} from "../components/options/OptionChantierLI";
import {SubmitButton, SubmitStatus} from "../utils/LoadingTS";
import {formatDate, formatEur} from "../utils/format";
import {CLItem} from "../components/checklist/CLItem";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAsync} from "react-async-hook";


type OptionsMap = immutable.OrderedMap<string, immutable.Map<number, OptionChantier>>;

export function OptionsView() {
  const credentials = useAuth();
  const [projet] = useProject();

  const options = useAsync<OptionsMap>(async () => {
      if (projet) {
        return await OptionChantier.list(projet);
      } else {
        return immutable.OrderedMap()
      }
    },
    [projet],
    {setLoading: state => ({...state, loading: true})}
  );

  const checkListItem = useAsync<CLItem | undefined>(async () => {
    if (projet) {
      return await OptionChantier.checkListItem(projet);
    }
  }, [projet]);

  const [validate, setValidate] = useState(false);
  const [edit, setEditHook] = useState<OptionChantier | undefined>();

  const setEdit = (e?: OptionChantier) => {
    console.log(edit, e);
    if (edit?.optionId !== e?.optionId) {
      if (edit !== undefined) {
        handleSave(edit);
      }
    }
    setEditHook(e);

  }


  const handleSave = (o: OptionChantier) => {
    return o.save(credentials)
      .then(saved => options.execute());
  }

  const handleValidate = async () => {
    if (projet) {
      return await OptionChantier.validate(credentials, projet)
        .then(validated => {
          setValidate(false);
          checkListItem.execute();
        })
    }
  }

  return <Container fluid className="main-content-container px-4 py-4">
    <Card className="card-small">
      <WaitForAsync async={options}>{options =>
        <WaitForAsync async={checkListItem}>{clItem => {
          const ungrouped = options.valueSeq().flatMap(o => o.valueSeq());

          const est = ungrouped.map(o => o.estimate())
            .reduce((a, b) => a + b, 0);

          return <>
            <Card.Body>

              <Table className="table-hover">
                <thead>
                <tr>
                  <th>Option</th>
                  <th className="text-center" colSpan={2}>Prix unitaire HT</th>
                  <th className="text-center">Quantité souhaitée</th>
                  <th className="text-center">Prix estimé HT</th>
                  <th/>
                </tr>
                </thead>
                {options.entrySeq().map(([category, group], i) =>
                  <tbody key={i}>
                  <tr className="bg-light">
                    <th colSpan={5}><h5>{category}</h5></th>
                  </tr>
                  {group.valueSeq().map((o, j) =>
                    <OptionChantierLI option={edit?.optionId === o.optionId ? edit : o}
                                      key={j}
                                      edit={edit?.optionId === o.optionId}
                                      onClick={(o) =>
                                        o.unite === 'FT' ?
                                          handleSave(o.updated({eval: o.eval === 0 ? 1 : 0})) :
                                          setEdit(o)
                                      }
                                      onChange={(e) => setEdit(e)}
                                      disabled={clItem?.submitted !== undefined}
                    />
                  )}
                  </tbody>
                )}
              </Table>
            </Card.Body>
            <Card.Footer className="text-end">
              <Alert variant={"info"}>Total indicatif
                : {formatEur(est)}&nbsp;HT soit {formatEur(est * 1.2)}&nbsp;TTC{' '}</Alert>
              {clItem?.submitted ?
                <Alert variant={"success"}>
                  <FontAwesomeIcon icon={faCheck}/> Sélection validée le {formatDate(clItem.submitted)}
                </Alert>
                :
                <Button
                  size="lg"
                  onClick={() => setValidate(true)}
                >
                  Valider
                </Button>}
            </Card.Footer>
          </>;
        }
        }</WaitForAsync>
      }</WaitForAsync>
    </Card>
    <Modal show={validate}>
      <Modal.Header>
        Validez votre liste d’options
      </Modal.Header>
      <Modal.Body>
        <p className="text-danger">
          Après avoir cliqué sur le bouton « Valider » ci-dessous, vous ne pourrez plus effectuer de modifications.
        </p>

        <p>Vérifiez attentivement les options choisies et contrôlez le total TTC indicatif correspondant.</p>
        <p>Contactez votre conducteur de travaux pour tout renseignement complémentaire.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button type="reset" variant="secondary" onClick={() => setValidate(false)}>Annuler</Button>
        <SubmitButton variant="danger"
                      submitstatus={SubmitStatus.enabled}
                      onClick={() => handleValidate()}
        >
          Valider
        </SubmitButton>
      </Modal.Footer>
    </Modal>

  </Container>;


}