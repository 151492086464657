import {parsePhoneNumber, PhoneNumber} from "libphonenumber-js";
import * as immutable from "immutable";
import {Seq} from "immutable";
import {FunctionComponent, ReactElement} from "react";
import {Maybe} from "monet";

export function threeWayBoolRotate(old: boolean | undefined): boolean | undefined {
  if (old === true) {
    return false;
  } else if (old === false) {
    return undefined;
  } else {
    return true;
  }
}

export function threeWayBoolRotateNull(old: boolean | null): boolean | null {
  if (old === true) {
    return false;
  } else if (old === false) {
    return null;
  } else {
    return true;
  }
}

export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}

export function parsePhoneNumberFR(number: string): PhoneNumber | undefined {
  try {
    return parsePhoneNumber(number, 'FR');
  } catch (Error) {
    // Nope
  }
}


export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function onDefined<T>(x: (T | undefined)[], f: (array: T[]) => T | undefined): T | undefined {
  const dates = x.filter(notUndefined);
  if (dates.length === 0) {
    return undefined;
  } else {
    return f(dates);
  }
}


export function reduceOption<T extends NonNullable<Record<string, never>>>(x: Seq.Indexed<T>, reducer: (acc: T, v: T) => T): Maybe<T> {
  if (x.isEmpty()) {
    return Maybe.none();
  } else {
    return Maybe.of(x.reduce(reducer));
  }
}

export function lexico(s1: number[], s2: number[]) {
  for (let i = 0; i < Math.min(s1.length, s2.length); i++) {
    if (s1[i] < s2[i]) {
      return -1;
    } else if (s1[1] > s2[i]) {
      return 1;
    }
  }
  return s1.length - s2.length;
}

export function undefinedToArray<C>(i?: C): C[] {
  return i ? [i] : [];
}

export function undefinedToSeq<C>(i?: C): immutable.Seq.Indexed<C> {
  return immutable.Seq.Indexed(undefinedToArray(i));
}

type ConditionalWrapperProps = {
  condition: boolean,
  wrapper: (children: ReactElement) => ReactElement,
  children: ReactElement,
}

export const ConditionalWrapper: FunctionComponent<ConditionalWrapperProps> = ({
                                                                                 condition,
                                                                                 wrapper,
                                                                                 children,
                                                                               }) => (condition ? wrapper(children) : children);
