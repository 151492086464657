import {ABDocument} from "./Document";
import React from "react";
import {SketchField} from 'react-sketch2';
import {Button, ButtonToolbar, Modal} from "react-bootstrap";
import {CirclePicker} from "react-color";
import {COLORS} from "../../utils/colors";
import {WithLoginProps} from "../../utils/auth";
import {filterStatus} from "../../utils/FetchError";
import {faEraser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type SketchProps = {
    doc: ABDocument;
    onHide: (id: number) => void;
} & WithLoginProps

type SketchState = {
    color: string;
    width?: number;
    height?: number;
    refreshBG: number;
}


export class Sketch extends React.Component<SketchProps, SketchState> {

    state: SketchState = {
        color: '#007bff',
        refreshBG: new Date().getTime()
    };

    ref = React.createRef<any>();

    componentDidMount(): void {

        const bb = document.getElementById("sketchfield")?.getBoundingClientRect();
        const width = bb?.width;
        const height = bb?.height;

        this.setState({width, height});
    }

    render() {
        const backend = process.env.REACT_APP_BACKEND_SERVER;
        const file = `${backend}/documents/${this.props.doc.documentId}/image?${this.state.refreshBG}`;

        // const bb = document.getElementById("sketchfield")?.getBoundingClientRect();
        // const width = bb?.width;
        // const height = bb?.height;


        return (
            <Modal size={"lg"} show onHide={() => this.handleHide()}>
                <Modal.Body>

                    <div id="sketchfield">
                        <SketchField
                            className="mx-auto"
                            style={{
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url('${file}')`,
                                backgroundPosition: 'center',
                            }}
                            width={this.state.width}
                            height={this.state.height}
                            tool={"pencil"}
                            lineColor={this.state.color}
                            lineWidth={3}
                            ref={this.ref}
                        />
                    </div>
                    <ButtonToolbar className="w-100 justify-content-center position-absolute"
                                   style={{top: "25px"}}>
                        <div className="my-auto d-inline-block">
                            <CirclePicker
                                circleSize={20}
                                color={this.state.color}
                                colors={COLORS}
                                width={(COLORS.length).toString()}
                                onChangeComplete={(color) => this.setState({color: color.hex})}/>
                        </div>
                        <Button className="mx-3" onClick={() => this.erase()}>
                            <FontAwesomeIcon icon={faEraser}/>
                        </Button>
                        <Button onClick={() => this.handleHide()}>Fermer</Button>
                    </ButtonToolbar>
                </Modal.Body>
            </Modal>
        );
    }

    handleHide() {
        const sketch = this.ref.current;
        // console.save(this._sketch.toDataURL(), 'toDataURL.txt');
        // console.save(JSON.stringify(this._sketch.toJSON()), 'toDataJSON.txt');

        /*eslint-enable no-console*/

        const backend = process.env.REACT_APP_BACKEND_SERVER;
        fetch(`${backend}/documents/${this.props.doc.documentId}/annotate`, {
            credentials: "include",
            method: 'POST',
            headers: this.props.credentials.headers(),
            body: sketch.toDataURL()
        })
            .then(filterStatus)
            .then(() => this.props.onHide(this.props.doc.documentId));

        // imgDown.download = 'toPNG.png';
        // imgDown.dispatchEvent(event);
    }

    erase() {
        const backend = process.env.REACT_APP_BACKEND_SERVER;
        fetch(`${backend}/documents/${this.props.doc.documentId}/eraseAnnotation`, {
            credentials: "include",
        })
            .then(filterStatus)
            .then(() => {
                this.setState({refreshBG: new Date().getTime()});
                this.ref.current.clear();
            });
    }

}