import React, {ReactNode} from "react";

import {Badge, Card, OverlayTrigger, Table, Tooltip} from "react-bootstrap";

import {UserBadge} from "../user-profile-lite/UserBadge";
import {LogoEntreprise, Projet, ProjetStats, totalStats} from "./Projet";
import immutable, {Seq} from "immutable";
import {Credentials, GRANTS} from "../../utils/auth";
import {Notif} from "../../utils/Notif";
import {formatDate} from "../../utils/format";
import formatDistance from "../../utils/formatDistance";
import {differenceInDays, isPast} from "date-fns";
import {DateFilter, Filter} from "../../utils/DateFilter";
import {Variant} from "react-bootstrap/types";
import {OverlayInjectedProps} from "react-bootstrap/esm/Overlay";
import {faSearch, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatMoyenne} from "../../views/NotationView";
import classNames from "classnames";
import {CheckListFilter} from "../checklist/CheckListFilter";
import {ProjetsFilter} from "../../views/ProjetsView";

type ProjetsListProps = {
  onClick: (i: number) => void;
  reload: number;
  currentProjet?: Projet;
  onDoubleClick: (p: Projet) => void;
  credentials: Credentials;
  projets: Seq.Indexed<Projet>;
  setFilters: (filters: Partial<ProjetsFilter>) => void;
  filters: ProjetsFilter;
  //search: (p: Projet) => boolean;
};
type ProjetsListState = {
  //projets: Loadable<Seq.Indexed<Projet>>;
  stats: immutable.Map<number, ProjetStats>;
};

export function DureePrevue({projet}: { projet: Projet }) {
  return <>{projet.ouverture &&
    formatDistance(projet.termine ?? new Date(), projet.ouverture)
  } <br/> {projet.dureePrevue &&
      <Badge bg={projet.end().exists(isPast) ? "danger" : "primary"}>
          prévu {projet.dureePrevue} mois
      </Badge>
  }</>;
}

export default class ProjetsList extends React.Component<ProjetsListProps, ProjetsListState> {
  state: ProjetsListState = {
    //projets: LOADING,
    stats: immutable.Map(),
  };


  static AttTheme(key: number): Variant {
    switch (key) {
      case 1:
        return "warning";
      case 2 :
        return "success";
      default:
        return "danger";
    }
  }

  private refreshProjetsList(selectFinished: boolean) {
    Projet.loadStats(selectFinished)
      .then(stats => this.setState({stats}))
      .catch(error => this.setState({stats: error}));
  }


  private handleProjetClick(i: number) {
    this.props.onClick(i)
  }

  componentDidMount(): void {
    this.refreshProjetsList(this.props.filters.cloture.nulls !== false);
  }

  componentDidUpdate(prevProps: Readonly<ProjetsListProps>, prevState: ProjetsListState): void {
    if (prevProps.reload < this.props.reload || this.props.filters.cloture.nulls !== prevProps.filters.cloture.nulls) {
      this.refreshProjetsList(this.props.filters.cloture.nulls !== false);
    }
  }


  render(): ReactNode {

    let nbColumns: number;
    switch (this.props.credentials.grants()) {
      case GRANTS.std:
        nbColumns = 8;
        break;
      case GRANTS.itv:
        nbColumns = 9;
        break;
      case GRANTS.resp:
        nbColumns = 9;
        break;
      case GRANTS.admin:
        nbColumns = 13;
        break;
    }

    const projets = this.props.projets
      .filter(p =>
        CheckListFilter.filter(this.props.filters.checklist, this.state.stats.get(p.projetId))
      )
      .sortBy((p) =>
        [
          p.responsable.userId === this.props.credentials.user.userId ? 'AAA' :
            UserBadge.link(p.responsable), p.ouverture, p.title
        ]
      );


    return (
      <Card className="card-small users-list mb-3">
        <Card.Body className="p-0">
          <Table className="table-hover mb-0">
            <thead className="bg-light">
            <tr className="rotated-header">
              <th scope="col" colSpan={2} className="border-0 p-2">
                <div><span>Projet</span></div>
              </th>
              <th scope="col" className="border-0 p-2">
                <div><span>Client</span></div>
              </th>
              <th scope="col" className="border-0 p-2">
                <div>
                  <span className="d-inline d-lg-none">MŒ</span>
                  <span className="d-none d-lg-inline">Maitre d’œuvre</span>
                </div>
              </th>
              <th scope="col"
                  className="border-0 small">
                <div className="rotated-header-container">
                  <div className="rotated-header-content">Ouverture</div>
                </div>
              </th>
              <th scope="col"
                  className="border-0 small">
                <div className="rotated-header-container">
                  <div className="rotated-header-content">Réception</div>
                </div>
              </th>
              <th scope="col"
                  className="border-0 small">
                <div className="rotated-header-container">
                  <div className="rotated-header-content">Clôture</div>
                </div>
              </th>
              <th scope="col"
                  className="border-0 small d-none d-lg-table-cell">
                <div className="rotated-header-container">
                  <div className="rotated-header-content">Durée</div>
                </div>
              </th>

              <th scope="col"
                  className="border-0 small d-none d-lg-table-cell">
                <div className="rotated-header-container">
                  <div className="rotated-header-content">Msgs non lus</div>
                </div>
              </th>

              {this.props.credentials.in(GRANTS.itv) && !this.props.credentials.in(GRANTS.resp) &&
                  <th scope="col"
                      className="border-0 small d-none d-lg-table-cell">
                      <div className="rotated-header-container">
                          <div className="rotated-header-content">Tâches</div>
                      </div>
                  </th>
              }

              {this.props.credentials.in(GRANTS.resp) &&
                  <th scope="col"
                      className="border-0 small d-none d-lg-table-cell">
                      <div className="rotated-header-container">
                          <div className="rotated-header-content">Satisfaction</div>
                      </div>
                  </th>
              }


              {this.props.credentials.in(GRANTS.resp) &&
                  <th scope="col"
                      className="border-0 small d-none d-lg-table-cell ">
                      <div className="rotated-header-container">
                          <div className="rotated-header-content">Checklist</div>
                      </div>
                  </th>
              }
              {this.props.credentials.isAdmin() && <>
                  <th scope="col"
                      className="border-0 small d-none d-lg-table-cell">
                      <div className="rotated-header-container">
                          <div className="rotated-header-content">Retards contact</div>
                      </div>
                  </th>

                {/*<th scope="col"*/}
                {/*    className="border-0 small d-none d-lg-table-cell ">*/}
                {/*    <div className="rotated-header-container">*/}
                {/*        <div className="rotated-header-content">Attributions</div>*/}
                {/*    </div>*/}
                {/*</th>*/}
                {/*<th scope="col"*/}
                {/*    className="border-0 small d-none d-lg-table-cell ">*/}
                {/*    <div className="rotated-header-container">*/}
                {/*        <div className="rotated-header-content">Fournitures</div>*/}
                {/*    </div>*/}
                {/*</th>*/}
              </>
              }
            </tr>
            <tr>
              <th colSpan={4}>

              </th>
              <th>
                <DateFilter
                  filter={this.props.filters.ouverture}
                  onChange={(ouverture) => this.props.setFilters({ouverture})}
                  handleNulls
                />
              </th>
              <th>
                <DateFilter
                  filter={this.props.filters.reception}
                  onChange={(reception) => this.props.setFilters({reception})}
                  handleNulls
                />
              </th>
              <th>
                <DateFilter
                  filter={this.props.filters.cloture}
                  onChange={(cloture) => this.props.setFilters({cloture})}
                  handleNulls
                />
              </th>
              {this.props.credentials.in(GRANTS.resp) ? <>
                  <th colSpan={3} className="d-none d-lg-table-cell"/>
                  <th className="d-none d-lg-table-cell">
                    <CheckListFilter
                      filter={this.props.filters.checklist}
                      onChange={(checklist) => this.props.setFilters({checklist})}
                    />
                  </th>
                  <th colSpan={nbColumns - 10} className="d-none d-lg-table-cell"/>
                </> :
                <th colSpan={nbColumns - 6} className="d-none d-lg-table-cell"/>

              }
            </tr>
            </thead>
            <tbody>
            {projets.map((p) => {
              const s = this.state.stats.get(p.projetId);
              const renderTooltip: React.FunctionComponent<OverlayInjectedProps> =
                (props) => (
                  <Tooltip id={`tooltip-adresse-${p.projetId}`} {...props}>
                    {p.adresse.replace(/\n/gi, ' \u2013 ')}
                  </Tooltip>
                );
              return (
                <tr key={p.projetId}
                    onDoubleClick={() => this.props.onDoubleClick(p)}
                    onClick={() => this.handleProjetClick(p.projetId)}
                    className={classNames({"table-primary": p.projetId === this.props.currentProjet?.projetId})}>
                  <td className="p-2">
                    <OverlayTrigger
                      key={`adresse-${p.projetId}`}
                      overlay={renderTooltip}
                      transition={false}
                    >
                      {({ref, ...triggerHandler}) =>
                        <div {...triggerHandler}>
                          <span ref={ref}>{p.title}</span>
                        </div>
                      }
                    </OverlayTrigger>
                  </td>
                  <td className="p-2">
                    {p.entreprise &&
                        <LogoEntreprise>{p.entreprise}</LogoEntreprise>
                    }
                  </td>
                  <td className="p-2"><UserBadge civilite prenom={false}>{p.client!}</UserBadge></td>
                  <td className="p-2">
                    <span>{UserBadge.image(p.responsable)}</span>
                    <span
                      className={"d-none d-lg-inline"}>{UserBadge.link(p.responsable)}</span>
                  </td>

                  <td className="p-2 small">
                    {p.ouverture && formatDate(p.ouverture)}
                  </td>
                  <td className="p-2 small">
                    {p.reception && formatDate(p.reception)}
                  </td>
                  <td className="p-2 small">
                    {p.termine && formatDate(p.termine)}
                  </td>
                  <td className="p-2 small d-none d-lg-table-cell">
                    <DureePrevue projet={p}/>
                  </td>
                  {s && <>
                      <td className="text-center small d-none d-lg-table-cell">
                        {s.unreadStats === 0 && "-"}
                          <Notif theme="danger">{s.unreadStats}</Notif>
                      </td>

                    {this.props.credentials.in(GRANTS.itv) && !this.props.credentials.in(GRANTS.resp) &&
                        <td className="text-center  small d-none d-lg-table-cell">
                          {totalStats(s.eventsStats.delete("closed")) === 0 && "-"}
                            <Notif theme="danger" tooltip="En retard">
                              {s.eventsStats.get("late")}
                            </Notif>
                            <Notif theme="warning" tooltip="En cours">
                              {s.eventsStats.get("open")}
                            </Notif>
                            <Notif theme="info" tooltip="Effectué">
                              {s.eventsStats.get("finished")}
                            </Notif>
                        </td>
                    }

                    {this.props.credentials.in(GRANTS.resp) &&
                        <td className="text-center  small d-none d-lg-table-cell">
                          {s.satisfaction === undefined ? "-" :
                            <div className="fa-layers fa-fw mx-2">
                              <FontAwesomeIcon icon={faStar} size="2x" className={`text-warning`}
                                               transform="left-3"/>
                              <strong className="fa-layers-text text-black">
                                {formatMoyenne.format(s.satisfaction)}
                              </strong>
                            </div>
                          }
                        </td>
                    }

                    {this.props.credentials.in(GRANTS.resp) &&
                        <td className="text-center  small d-none d-lg-table-cell">
                          {s.checklist && s.checklist.notif()}
                        </td>
                    }

                    {this.props.credentials.isAdmin() && <>
                        <td className="text-center  small d-none d-lg-table-cell">
                          {s.delaysMsgClient === 0 && "- "}
                            <Notif theme="warning">{s.delaysMsgClient}</Notif>
                          {s.lastMsgClient &&
                              <Badge bg={
                                differenceInDays(new Date(), s.lastMsgClient) >= 14 ?
                                  "danger" : "info"
                              }
                              >{formatDistance(s.lastMsgClient, new Date())}</Badge>
                          }
                        </td>

                      {/*<td className="text-center  small d-none d-lg-table-cell">*/}
                      {/*  {s.attStats && <>*/}
                      {/*    {totalStats(s.attStats) === 0 && "-"}*/}
                      {/*    {s.attStats.entrySeq().map(([key, value]) =>*/}
                      {/*      <Notif*/}
                      {/*        key={key}*/}
                      {/*        theme={ProjetsList.AttTheme(key)}*/}
                      {/*        tooltip={AttributionStatus.get(key)}>*/}
                      {/*        {value}*/}
                      {/*      </Notif>*/}
                      {/*    )}*/}
                      {/*  </>}*/}
                      {/*</td>*/}

                      {/*<td className="text-center  small d-none d-lg-table-cell">*/}
                      {/*  {s.fournStats && <>*/}
                      {/*    {totalStats(s.fournStats) === 0 && "-"}*/}
                      {/*    {s.fournStats.entrySeq().map(([key, value]) =>*/}
                      {/*      <Notif*/}
                      {/*        key={key}*/}
                      {/*        {...FournitureNotif.get(key)}*/}
                      {/*        tooltip={FournitureStatus.get(key)}>*/}
                      {/*        {value}*/}
                      {/*      </Notif>*/}
                      {/*    )}*/}
                      {/*  </>}*/}
                      {/*</td>*/}
                    </>}
                  </>}
                </tr>
              );
            })}
            </tbody>
            <tfoot>
            <tr>
              <td colSpan={5}>{projets.size} projets affichés</td>
            </tr>
            </tfoot>
          </Table>
        </Card.Body>
      </Card>
    );
  }

}

