import React from "react";
import {Accordion, Button, Form, FormControl, Modal} from "react-bootstrap";
import {Loadable, LOADING, waitFor} from "../../utils/Loading";

import Select from "react-select";
import classNames from "classnames";
import {BadgeEntreprise, Entreprise, Projet} from "./Projet";
import {GRANTS, WithLoginProps} from "../../utils/auth";
import {AllUsers, userSelect} from "../../utils/AllUsers";
import filterOptions from "../../utils/SelectSearch";
import {DAY_PICKER_INPUT_PROPS, NUMBER_FORMAT_EUR, NUMBER_FORMAT_PERC} from "../../utils/format";
import {filterStatus, SubmitError} from "../../utils/FetchError";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {Either} from "monet";
import {ProjetCommercial, ProjetTuple} from "./ProjetCommercial";
import {NumericFormat} from 'react-number-format';
import md5 from "md5";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faCalendar,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarTimes,
  faExclamationTriangle, faFileContract,
  faHome,
  faIndustry,
  faLock,
  faSmile, faStopwatch,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";

type ProjetFormProps = WithLoginProps & {
  projet: Projet;
  projetCommercial?: ProjetCommercial;
  updateCurrentProjet: (projet?: Projet) => void;
  updateCurrentProjetCommercial: (projetCommercial?: ProjetCommercial) => void;
  save: (projet: Projet, pc?: ProjetCommercial) => Promise<Either<SubmitError, ProjetTuple>>;
  users: AllUsers;
}

type ProjetFormState = {
  submitStatus: SubmitStatus;
  errors?: { [key: string]: string };
  showDelete: boolean;
  entreprises: Loadable<Entreprise[]>;
}

type DonneesCommercialesProps = WithLoginProps & {
  users: AllUsers;
  projetCommercial: ProjetCommercial;
  errors?: { [key: string]: string };
  updateCurrentProjetCommercial: (pc: ProjetCommercial) => void;
}

class DonneesCommerciales extends React.Component<DonneesCommercialesProps> {

  handlePCChange(changes: Partial<ProjetCommercial>, projetCommercial: ProjetCommercial) {
    const updated = {...projetCommercial, ...changes};
    this.props.updateCurrentProjetCommercial(updated);
  }

  render() {
    const {users, projetCommercial, errors, credentials} = this.props;
    const locked = !credentials.isAdmin() && projetCommercial.locked;
    return <>
      <Accordion className="border">
        <Accordion.Header>Données commerciales</Accordion.Header>
        <Accordion.Body>
          <Form.Group className="mb-3">
            <Form.Label>
              <FontAwesomeIcon icon={faCalendar}/> Date de vente
            </Form.Label>
            <DayPickerInput
              style={{display: "block"}}
              {...DAY_PICKER_INPUT_PROPS}
              value={projetCommercial.dateVente}
              onDayChange={(dateVente) =>
                this.handlePCChange({dateVente}, projetCommercial)
              }
              inputProps={{
                className: classNames("form-control", {"is-invalid": errors?.dateVente !== undefined})
              }}
            />
            <FormControl.Feedback type="invalid">{errors?.dateVente}</FormControl.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Vendeur</Form.Label>
            <Select
              name="commercial"
              className={classNames({"is-invalid": errors?.commercial !== undefined})}
              styles={{
                container: () => ({border: errors?.commercial && '1px solid red'})
              }}
              value={projetCommercial?.commercial && userSelect(projetCommercial.commercial)}
              options={users.userSelect()}
              isSearchable={true}
              isDisabled={locked}
              filterOption={filterOptions}
              onChange={selected =>
                this.handlePCChange({commercial: selected?.value}, projetCommercial)
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Prix maison</Form.Label>
            <NumericFormat
              {...NUMBER_FORMAT_EUR}
              className={classNames("form-control", {"is-invalid": errors?.prixMaison})}
              value={projetCommercial?.prixMaison}
              disabled={locked}
              onValueChange={({floatValue}) =>
                this.handlePCChange({prixMaison: floatValue}, projetCommercial)}
            />
            <Form.Control.Feedback type="invalid">{errors?.prixMaison}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Marge brute prévisionnelle</Form.Label>
            <NumericFormat
              {...NUMBER_FORMAT_PERC}
              className={classNames("form-control", {"is-invalid": errors?.mbPrevisionnelle})}
              value={projetCommercial?.mbPrevisionnelle}
              disabled={locked}
              onValueChange={({floatValue}) =>
                this.handlePCChange({mbPrevisionnelle: floatValue}, projetCommercial)}
            />
            <Form.Control.Feedback type="invalid">{errors?.mbPrevisionnelle}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Prix définitif</Form.Label>
            <NumericFormat
              {...NUMBER_FORMAT_EUR}
              className={classNames("form-control", {"is-invalid": errors?.prixDefinitif})}
              value={projetCommercial?.prixDefinitif}
              disabled={locked}
              onValueChange={({floatValue}) =>
                this.handlePCChange({prixDefinitif: floatValue}, projetCommercial)}
            />
            <Form.Control.Feedback type="invalid">{errors?.prixDefinitif}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Marge brute contre-étude</Form.Label>
            <NumericFormat
              {...NUMBER_FORMAT_PERC}
              className={classNames("form-control", {"is-invalid": errors?.mbContreEtude})}
              value={projetCommercial?.mbContreEtude}
              disabled={locked}
              onValueChange={({floatValue}) =>
                this.handlePCChange({mbContreEtude: floatValue}, projetCommercial)}
            />
            <Form.Control.Feedback type="invalid">{errors?.mbContreEtude}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Marge définitive</Form.Label>
            <NumericFormat
              {...NUMBER_FORMAT_PERC}
              className={classNames("form-control", {"is-invalid": errors?.mbDefinitive})}
              value={projetCommercial?.mbDefinitive}
              disabled={locked}
              onValueChange={({floatValue}) =>
                this.handlePCChange({mbDefinitive: floatValue}, projetCommercial)}
            />
            <Form.Control.Feedback type="invalid">{errors?.mbDefinitive}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><FontAwesomeIcon icon={faAlignLeft}/> Notes</Form.Label>
            <Form.Control
              as={"textarea"}
              value={projetCommercial.notes}
              disabled={locked}
              onChange={c => this.handlePCChange({notes: c.currentTarget.value}, projetCommercial)}
            />
            <Form.Control.Feedback type="invalid">{errors?.notes}</Form.Control.Feedback>
          </Form.Group>
          {this.props.credentials.isAdmin() &&
              <Form.Group className="mb-3">
                  <Form.Check>
                      <Form.Check.Input
                          className="me-1"
                          onChange={(e) =>
                            this.handlePCChange({locked: e.currentTarget.checked}, projetCommercial)}
                          type="checkbox" checked={projetCommercial.locked}/>
                      <Form.Check.Label><FontAwesomeIcon icon={faLock}/> Verrouiller</Form.Check.Label>
                  </Form.Check>
              </Form.Group>
          }
        </Accordion.Body>
      </Accordion>
    </>;
  }
}


export class ProjetForm extends React.Component<ProjetFormProps, ProjetFormState> {

  state: ProjetFormState = {
    submitStatus: SubmitStatus.disabled,
    showDelete: false,
    entreprises: LOADING,
  };

  static entrepriseSelect(entreprise: Entreprise) {
    return {
      value: entreprise,
      label: <BadgeEntreprise>{entreprise}</BadgeEntreprise>
    };
  }

  componentDidMount() {
    const backend = process.env.REACT_APP_BACKEND_SERVER;
    fetch(`${backend}/entreprises`)
      .then(filterStatus)
      .then(result => result.json())
      .then((entreprises: Entreprise[]) => this.setState({entreprises}));
  }

  handleFormChange(changes: Partial<Projet>) {
    const updated = this.props.projet.updated(changes);
    this.props.updateCurrentProjet(updated);
    this.setState({submitStatus: SubmitStatus.enabled});
  }


  save(event: React.FormEvent) {
    event.preventDefault();
    this.props.save(this.props.projet, this.props.projetCommercial)
      .then(result => {
        if (result.isRight()) {
          this.hide();
        } else {
          this.setState({errors: result.left(), submitStatus: SubmitStatus.disabled})
        }
      });
  }

  hide() {
    this.setState({submitStatus: SubmitStatus.disabled, errors: undefined});
    this.props.updateCurrentProjet(undefined);
    this.props.updateCurrentProjetCommercial(undefined);
  }

  render() {

    const {projet, projetCommercial} = this.props;

    const {errors} = this.state;

    return (
      <Modal show onHide={() => this.hide()} centered size="lg">
        {waitFor(this.state.entreprises, (entreprises: Entreprise[]) =>
          <Form onSubmit={(event: React.FormEvent) => this.save(event)}>
            <Modal.Header className="border-bottom">
              <Form.Group className="w-100">
                <Form.Label>Titre du projet</Form.Label>
                <Form.Control
                  name="title"
                  size="lg"
                  value={projet.title}
                  placeholder="Nouveau projet"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.handleFormChange({title: event.currentTarget.value})}
                  isInvalid={errors?.title !== undefined}
                />
                <Form.Control.Feedback
                  className="col-12"
                  type="invalid">{errors?.title}</Form.Control.Feedback>
              </Form.Group>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Label> <FontAwesomeIcon icon={faIndustry}/> Entreprise</Form.Label>
                <Select name={"entreprise"}
                        className={classNames("basing-single col", {"is-invalid": errors?.entreprise !== undefined})}
                        styles={{
                          container: () => ({border: errors?.entreprise && '1px solid red'})
                        }}
                        value={projet.entreprise && ProjetForm.entrepriseSelect(projet.entreprise)}
                        options={entreprises.map(ProjetForm.entrepriseSelect)}
                        onChange={(selected) =>
                          this.handleFormChange({entreprise: selected?.value})
                        }
                />
                <FormControl.Feedback className="offset-sm-2" type="invalid">
                  {errors?.entreprise}
                </FormControl.Feedback>
              </Form.Group>

              {waitFor(this.props.users, (users: AllUsers) => <>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <FontAwesomeIcon icon={faSmile}/> Client
                  </Form.Label>

                  <Select
                    name="client"
                    className={classNames("basing-single col", {"is-invalid": errors?.client !== undefined})}
                    styles={{
                      container: () => ({border: errors?.client && '1px solid red'})
                    }}
                    value={userSelect(projet.client)}
                    options={users.userSelect(u => AllUsers.atMost(u, GRANTS.std))}
                    isSearchable={true}
                    filterOption={filterOptions}
                    onChange={selected =>
                      this.handleFormChange({client: selected?.value})
                    }
                  />

                  <FormControl.Feedback className="offset-sm-2" type="invalid">
                    {errors?.client}
                  </FormControl.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <FontAwesomeIcon icon={faUserTie}/> Maitre d’œuvre
                  </Form.Label>

                  <Select
                    name="responsable"
                    className="basic-single col"
                    styles={{
                      container: () => ({
                        border: errors?.responsable && '1px solid red'
                      })
                    }}
                    value={userSelect(projet.responsable)}
                    options={users.userSelect(u => AllUsers.atLeast(u, GRANTS.resp))}
                    isSearchable={true}
                    filterOption={filterOptions}
                    onChange={selected =>
                      this.handleFormChange({responsable: selected?.value})
                    }/>
                </Form.Group>
              </>)}
              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faAlignLeft}/> Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="col"
                  name="description"
                  placeholder="Description"
                  value={projet.description}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    this.handleFormChange({description: event.currentTarget.value})}
                  isInvalid={errors?.description !== undefined}
                />
                <FormControl.Feedback
                  className="offset-2"
                  type="invalid">{errors?.description}</FormControl.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faHome}/> Adresse
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="col"
                  name="adresse"
                  placeholder="Adresse"
                  value={projet.adresse}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                    this.handleFormChange({adresse: event.currentTarget.value})}
                  isInvalid={errors?.adresse !== undefined}
                />
                <FormControl.Feedback
                  className="offset-2"
                  type="invalid">{errors?.adresse}</FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faFileContract}/> Signature
                </Form.Label>
                <DayPickerInput
                  style={{display: "block"}}
                  {...DAY_PICKER_INPUT_PROPS}
                  value={projet.signature}
                  onDayChange={(signature) =>
                    this.handleFormChange({signature})
                  }
                  inputProps={{
                    className: classNames("form-control", {"is-invalid": errors?.signature !== undefined})
                  }}
                />
                <FormControl.Feedback
                  className={classNames({"d-flex": errors?.signature !== undefined})}
                  type="invalid"
                >{errors?.signature}</FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faCalendarPlus}/> Ouverture
                </Form.Label>
                <DayPickerInput
                  style={{display: "block"}}
                  {...DAY_PICKER_INPUT_PROPS}
                  value={projet.ouverture}
                  onDayChange={(ouverture) =>
                    this.handleFormChange({ouverture})
                  }
                  inputProps={{
                    className: classNames("form-control", {"is-invalid": errors?.ouverture !== undefined})
                  }}
                  dayPickerProps={{
                    disabledDays: [
                      projet.termine && {after: projet.termine},
                      projet.reception && {after: projet.reception}
                    ]
                  }}
                />
                <FormControl.Feedback
                  className={classNames({"d-flex": errors?.ouverture !== undefined})}
                  type="invalid"
                >{errors?.ouverture}</FormControl.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faStopwatch}/> Durée prévue
                </Form.Label>
                <NumericFormat
                  name="dureePrevue"
                  placeholder="Durée prévue"
                  value={projet.dureePrevue}
                  className={classNames("form-control", {"is-invalid": errors?.dureePrevue})}
                  onValueChange={(value) =>
                    this.handleFormChange({dureePrevue: value.floatValue})}

                />
                <FormControl.Feedback
                  className={classNames({"d-flex": errors?.dureePrevue !== undefined})}
                  type="invalid"
                >{errors?.dureePrevue}</FormControl.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faCalendarCheck}/> Réception
                </Form.Label>
                <DayPickerInput
                  style={{display: "block"}}
                  {...DAY_PICKER_INPUT_PROPS}
                  value={projet.reception}
                  onDayChange={(reception) =>
                    this.handleFormChange({reception})
                  }
                  inputProps={{
                    className: classNames("form-control", {"is-invalid": errors?.reception !== undefined})
                  }}
                  dayPickerProps={{
                    disabledDays: [
                      projet.ouverture && {before: projet.ouverture},
                      projet.termine && {after: projet.termine},
                    ]
                  }}
                />
                <FormControl.Feedback type="invalid">{errors?.reception}</FormControl.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <FontAwesomeIcon icon={faCalendarTimes}/> Clôture
                </Form.Label>
                <DayPickerInput
                  style={{display: "block"}}
                  {...DAY_PICKER_INPUT_PROPS}
                  value={projet.termine}
                  onDayChange={(termine) =>
                    this.handleFormChange({termine})
                  }
                  inputProps={{
                    className: classNames("form-control", {"is-invalid": errors?.termine !== undefined})
                  }}
                  dayPickerProps={{
                    disabledDays: [
                      projet.ouverture && {before: projet.ouverture},
                      projet.reception && {before: projet.reception}
                    ]
                  }}
                />
                <FormControl.Feedback type="invalid">{errors?.termine}</FormControl.Feedback>
              </Form.Group>

              {projetCommercial && waitFor(this.props.users, users =>
                <DonneesCommerciales
                  credentials={this.props.credentials}
                  users={users}
                  projetCommercial={projetCommercial}
                  errors={errors}
                  updateCurrentProjetCommercial={(pc) => {
                    this.setState({submitStatus: SubmitStatus.enabled});
                    this.props.updateCurrentProjetCommercial(pc);
                  }}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              {this.state.showDelete &&
                  <div className="text-danger">
                      <p>
                          <FontAwesomeIcon icon={faExclamationTriangle}/> Cette opération supprimera toutes les
                          questions, les messages, les documents et les
                          tâches associées au projet
                          et ne peut pas être annulée. <FontAwesomeIcon icon={faExclamationTriangle}/>
                      </p>
                      <p className="text-muted fw-light">
                          Pour supprimer le projet, recopiez l’adresse suivante dans la barre d’adresse de
                          votre navigateur&nbsp;:
                        {process.env.REACT_APP_BACKEND_SERVER}/projet/delete/{projet.projetId}/{md5(projet.projetId.toString())}
                      </p>
                  </div>
              }
              <Button
                onClick={() => this.setState({showDelete: true})}
                variant="danger"
              >
                Supprimer le projet
              </Button>
              <Button id="cancel"
                      variant="secondary"
                      onClick={() => this.hide()}>
                Annuler
              </Button>
              <SubmitButton
                id="save"
                submitstatus={this.state.submitStatus}
              >
                Enregistrer
              </SubmitButton>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
    );
  }


}
