import * as immutable from "immutable";
import {Seq} from "immutable";
import {JSONUser, User} from "../components/user-profile-lite/User";
import {Credentials, GRANTS} from "./auth";
import {UserBadge} from "../components/user-profile-lite/UserBadge";
import {filterStatus} from "./FetchError";
import queryString from "query-string";
import {Corps, CorpsBadge} from "../components/user-profile-lite/Corps";

const backend = process.env.REACT_APP_BACKEND_SERVER;

type OptionType = { value: User, label: JSX.Element, searchable: string };

export class AllUsers {
  readonly userMap: immutable.Map<number, User>;


  private readonly options: (user: User) => OptionType;

  private readonly ordering: (user1: User, user2: User) => number;

  private constructor(users: immutable.Map<number, User>,
                      options: (user: User) => OptionType,
                      ordering: (user1: User, user2: User) => number,
  ) {
    this.userMap = users;
    this.options = options;
    this.ordering = ordering;
  }

  static fromArray(
    users: User[],
    options: (user: User) => OptionType = userSelect,
    ordering: (user1: User, user2: User) => number = userOrdering): AllUsers {
    return new AllUsers(
      immutable.Map(users.map(u => [u.userId, u])),
      options,
      ordering,
    )
  }

  static empty(
    options: (user: User) => OptionType = userSelect,
    ordering: (user1: User, user2: User) => number = userOrdering) {
    return new AllUsers(immutable.Map(), options, ordering)
  }

  withOptions(options: (user: User) => OptionType): AllUsers {
    return new AllUsers(this.userMap, options, this.ordering);
  }

  withOrdering(ordering: (user1: User, user2: User) => number): AllUsers {
    return new AllUsers(this.userMap, this.options, ordering);
  }

  atMost(grant: GRANTS): Seq.Indexed<User> {
    return this.toSeq().filter((u) => AllUsers.atMost(u, grant));
  }

  atLeast(grant: GRANTS): Seq.Indexed<User> {
    return this.toSeq().filter((u) => AllUsers.atLeast(u, grant));
  }

  toSeq(): Seq.Indexed<User> {
    return this.userMap.valueSeq();
  }

  get(key: number): User | undefined {
    return this.userMap.get(key);
  }

  getArray(key: number): User[] {
    const u = this.userMap.get(key);
    return u ? [u] : [];
  }

  isEmpty(): boolean {
    return this.userMap.isEmpty();
  }

  static atLeast(user: User, grant: GRANTS): boolean {
    return grant <= user.grants;
  }

  static atMost(user: User, grant: GRANTS): boolean {
    return grant >= user.grants;
  }

  userSelect(
    filter: (u: User) => boolean = () => true,
    ordering: (user1: User, user2: User) => number = this.ordering): OptionType[] {
    return this.toSeq()
      .filter(filter)
      .sort(ordering)
      .map(this.options)
      .toArray();
  }

  has(r: number): boolean {
    return this.userMap.has(r);
  }

  getAll(ids: number[]): User[] {
    return ids.flatMap(u => this.getArray(u));
  }

  one(): User | undefined {
    if (this.userMap.size === 1) {
      return this.userMap.first(undefined)
    } else {
      return undefined;
    }
  }

  static loadItv(credentials: Credentials): Promise<AllUsers | null> {
    if (credentials.in(GRANTS.resp)) {
      return fetch(`${backend}/listItv`, {credentials: 'include'})
        .then(filterStatus)
        .then(response => response.json())
        .then((allItvs: JSONUser[]) =>
          AllUsers.fromArray(allItvs.map(User.parse))
        );
    }
    return new Promise((r) => r(null))
  }

  static loadUsers(backendFilter: { projet?: number } = {}, frontEndFilter: (u: User) => boolean = () => true): Promise<AllUsers> {
    return fetch(`${backend}/listUsers?${queryString.stringify(backendFilter)}`, {credentials: 'include'})
      .then(filterStatus)
      .then(response => response.json())
      .then((userList: JSONUser[]) =>
        AllUsers.fromArray(userList.map(User.parse).filter(frontEndFilter))
      );
  }
}

export function userOrdering(user1: User, user2: User): number {
  return UserBadge.link(user1).localeCompare(UserBadge.link(user2));
}

export function userSelect(user: User): OptionType;
export function userSelect(user: User | undefined): OptionType | undefined;
export function userSelect(user: User | undefined): OptionType | undefined {
  return user && {
    value: user,
    label: <UserBadge>{user}</UserBadge>,
    searchable: UserBadge.link(user).toLocaleLowerCase(),
  };
}

export function userSelectCorps(corps: immutable.Map<number, Corps>): (user: User) => OptionType {
  const usc = (user: User): OptionType => {
    const listCorps = user.corps.map((c, i) =>
      <CorpsBadge key={i}>{corps.get(c)}</CorpsBadge>
    );
    return {
      value: user,
      label:
        <>
          <UserBadge>{user}</UserBadge>&nbsp;
          {listCorps}
        </>,
      searchable: UserBadge.link(user).toLocaleLowerCase() + user.corps.map(c => corps.get(c)?.corps).join(" ").toLocaleLowerCase()
    }
  };
  return usc;
}

