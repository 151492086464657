import React from "react";
import {Row} from "react-bootstrap";
import {ABDocument, DocumentActions} from "../documents/Document";
import * as immutable from "immutable";
import {Seq} from "immutable";
import {Projet} from "../projets/Projet";
import {DossierFacturation} from "./DossierFacturation";
import {ContratCard} from "./ContratCard";

type ContratsProps = {
  docAdmin: Seq.Indexed<ABDocument>,
  actions: (doc: ABDocument) => DocumentActions,
  projets: (projetId: number) => Projet | undefined,
  otherDocs: Seq.Indexed<ABDocument>,
  dossiers: Seq.Indexed<DossierFacturation>;
  newDossier?: (contrat: ABDocument) => void,
  cloture?: (contrat: ABDocument) => void,
};

export default class Contrats extends React.Component<ContratsProps> {
  static defaultProps = {
    otherDocs: Seq(),
  };

  render() {
    const enCours = this.props.dossiers.filter(d => !d.cloture);

    const enCoursValide = immutable.Set(
      enCours
        .flatMap(d => d.attestations.valides)
        .map(a => a.documentId)
    );

    const enCoursInvalide = immutable.Map(
      enCours
        .flatMap(d => d.attestations.invalides)
        .map(({document, reasons}) => [document.documentId, reasons])
    );

    const cloture = this.props.dossiers.filter(d => d.cloture)
      .map(d => d.attestations.allAttestations)
      .reduce((a1, a2) => a1.union(a2), immutable.Set());

    return !this.props.docAdmin.isEmpty() && (
      <Row>
        {this.props.docAdmin.map((da, i) =>
          <ContratCard
            key={i}
            da={da}
            reasons={enCoursInvalide.get(da.documentId) || []}
            enCoursValide={enCoursValide.has(da.documentId)}
            valide={cloture.has(da.documentId)}
            projet={da.projetId === undefined ? undefined : this.props.projets(da.projetId)}
            cloture={this.props.cloture}
            actions={this.props.actions(da)}
            newDossier={this.props.newDossier}
          />
        )}

      </Row>

    );
  }
}