import {Col, Container, NavItem, Row} from "react-bootstrap";
import {useAuth} from "../../../utils/auth";
import Notifications from "./Notifications";
import {UserBadge} from "../../user-profile-lite/UserBadge";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



export function UserActions() {

  const credentials = useAuth();


  const user = credentials.user;
  const content = <Container>
    <Row>
      <Col xs="auto" className={"ms-auto me-0 p-0"}>
        <Notifications credentials={credentials}/>
      </Col>
      <Col xs="1" className="my-auto mx-1">
        <a className="text-light" href="/logout" title="Déconnexion">
          <FontAwesomeIcon icon={faSignOutAlt}/>
        </a>
      </Col>
    </Row>
    <Row>
      <Col xs={12} className="text-center">
        {UserBadge.image(user, 50)}
      </Col>
    </Row>
    <Row className={"justify-content-center"}>
      <Col xs={12} className="px-2">
        <div className="text-truncate mt-1 p-0">
          {user.prenom} {user.nom}
        </div>
      </Col>
    </Row>
    <Row className={"justify-content-center"}>
      <Col xs={12} className="px-2">
        <div className="text-truncate p-0">
          {user.societe}
        </div>
      </Col>
    </Row>

  </Container>;


  return (
    <NavItem className="text-nowrap p-2 text-center border-bottom">
      {content}
    </NavItem>
  );

}
