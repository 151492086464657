import React, {FormEvent} from "react";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import FetchError from "../../utils/FetchError";
import {Button, Form} from "react-bootstrap";
import classNames from "classnames";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ImportSignatureProps = {
  onImport: (e: React.FormEvent<HTMLFormElement>) => Promise<void>,
}
type ImportSignatureState = {
  showImport: boolean,
  publicKey: string,
  signature: string,
  submitStatus: SubmitStatus;
  errors?: string,
}

export class ImportSignature extends React.Component<ImportSignatureProps, ImportSignatureState> {
  state: ImportSignatureState = {
    showImport: false,
    signature: '',
    publicKey: '',
    submitStatus: SubmitStatus.disabled,
  }

  handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.onImport(e)
      .then(() => this.setState({submitStatus: SubmitStatus.disabled, showImport: false}))
      .catch((err: FetchError) => err.text()
        .then(m => this.setState({
          errors: m,
          submitStatus: SubmitStatus.disabled
        })));
  }

  render() {
    return (<>

        <Button
          className="w-100 text-end p-1"
          variant={"link"}
          onClick={() => this.setState({showImport: !this.state.showImport})}>
          Importer une signature
        </Button>

        <Form
          className={classNames("form-inline", {"d-none": !this.state.showImport})}
          onSubmit={(e) => this.handleSubmit(e)}>

          <Form.Control
            className="form-control me-2"
            as="textarea"
            name="publicKey"
            placeholder="Clé publique"
            value={this.state.publicKey}
            isInvalid={this.state.errors !== undefined}
            onChange={(e) => this.setState({
              submitStatus: SubmitStatus.enabled,
              publicKey: e.currentTarget.value
            })}
          />

          <Form.Control
            className="form-control me-2"
            as="textarea"
            name="signature"
            placeholder="Signature"
            value={this.state.signature}
            isInvalid={this.state.errors !== undefined}
            onChange={(e) => this.setState({
              submitStatus: SubmitStatus.enabled,
              signature: e.currentTarget.value
            })}
          />

          <SubmitButton className="me-2"
                        submitstatus={this.state.submitStatus}>
            <FontAwesomeIcon icon={faSignature}/> Importer
          </SubmitButton>
          <Form.Control.Feedback
            type="invalid">{this.state.errors}</Form.Control.Feedback>
        </Form>

      </>
    );
  }
}