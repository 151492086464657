import {Button, Container, Image} from "react-bootstrap";
import {faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useParams} from "react-router-dom";
import querystring from "query-string";

export function encodeURIForShow(uri: string) {
  return encodeURIComponent(uri.replaceAll("/", "\\"));
}

function decodeURIForShow(uri: string) {
  return uri.replaceAll("\\", "/");
}

export function ShowImage() {
  const {src} = useParams();

  // render(): JSX.Element {
  const {back} = querystring.parse(window.location.search.slice(1));
  const backend = process.env.REACT_APP_BACKEND_SERVER;
  const srcUrl = src && `${backend}/${decodeURIForShow(src)}`;
  const backUrl = back && `${back}`;
  return (
    <Container className="text-center">
      <Image className="m-2" fluid src={srcUrl}/>
      <div>
        <Button onClick={() => {
          if (backUrl) window.location.href = backUrl;
          else window.history.back();
        }}>
          <FontAwesomeIcon icon={faArrowCircleLeft}/> Retour
        </Button>
      </div>
    </Container>
  );
  // }
}