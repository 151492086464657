import React, {ChangeEvent} from "react";
import {Button, ButtonToolbar, Form, FormControl, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {SubmitButton, SubmitStatus} from "../../utils/LoadingTS";
import {Projet} from "../projets/Projet";
import {Credentials, GRANTS} from "../../utils/auth";
import {ABDocument, DocumentVisibility} from "./Document";
import 'react-html5-camera-photo/build/css/index.css';
import Errors from "../../views/Errors";
import {Either} from "monet";
import {SubmitError} from "../../utils/FetchError";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faEye} from "@fortawesome/free-solid-svg-icons";
import {VisibilitySelect} from "./VisibilitySelect";
import {User} from "../user-profile-lite/User";

type ZipUploadProps = {
  projet: Projet;
  credentials: Credentials;
  onSubmit: (saved: Either<SubmitError, ABDocument[]>) => Promise<Either<SubmitError, ABDocument[]>>;
  onHide: () => void;

};

type ZipUploadState = {
  errors?: any;
  submitStatus: SubmitStatus;
  file: File[];
  visibility: DocumentVisibility,
  concerne?: User,
};

export class ZipUpload extends React.Component<ZipUploadProps, ZipUploadState> {
  state: ZipUploadState = {
    submitStatus: SubmitStatus.disabled,
    visibility: DocumentVisibility.public,
    file: [],
  };

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    data.append("projetId", this.props.projet.projetId.toString());
    if (this.state.concerne && this.state.visibility === DocumentVisibility.prestataire) {
      data.append("concerne", this.state.concerne.userId.toString());
    }
    data.append("visibility", DocumentVisibility[this.state.visibility]);

    for (const file of this.state.file) {
      data.append("doc[]", file, file.name);
    }

    this.setState({submitStatus: SubmitStatus.working});

    for (const [key, value] of data.entries()) {
      console.log(key, value);
    }


    ABDocument.submitZip(data, this.props.credentials)
      .then(r => this.props.onSubmit(r))
      .then(response => response.cata(
        json => {
          this.setState({
            errors: json,
            submitStatus: SubmitStatus.disabled,
          })
        },
        () => this.hide()
      ))
      .catch(e => this.setState({errors: e}));
  }

  hide() {
    this.formRef.current.reset();
    this.setState({
      errors: null,
      submitStatus: SubmitStatus.disabled,
    });
    this.props.onHide()
  }

  handleChange(file: File[]) {
    this.setState({file, submitStatus: SubmitStatus.enabled});
  }

  formRef = React.createRef<any>();

  render() {
    const {errors} = this.state;

    return (
      <Modal show onHide={() => this.hide()} centered size="lg">
        {this.state.errors ? <Errors>{this.state.errors}</Errors> :
          <Form className="form-horizontal"
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => this.handleSubmit(event)}
                id="docForm"
                ref={this.formRef}>

            <Modal.Body>
              <Form.Group>
                <Form.Control
                  isInvalid={errors?.doc !== undefined}
                  name={"doc"}
                  // style={{display: "none"}}
                  type="file"
                  multiple
                  onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files &&
                    this.handleChange([...e.target.files])}
                />
                <FormControl.Feedback type="invalid">{errors?.doc}</FormControl.Feedback>

              </Form.Group>


              {this.state.file.map(f => f.name).join(" ")}

              <Row>
                <label className="col-1 text-end">
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-description">Commentaire</Tooltip>}>
                    <FontAwesomeIcon icon={faAlignLeft}/>
                  </OverlayTrigger>
                </label>
                <Form.Control
                  as="textarea"
                  className={"col-11"}
                  name="commentaire"
                  // value={edit.commentaire}
                  // onChange={(e) => this.handleChange(edit, {commentaire: e.currentTarget.value})}
                />
              </Row>

              {this.props.credentials.in(GRANTS.itv, GRANTS.resp) &&
              <Row>
                  <label className="col-1 text-end">
                      <OverlayTrigger
                          overlay={<Tooltip id="tooltip-description">Visibilité</Tooltip>}>
                          <FontAwesomeIcon icon={faEye}/>
                      </OverlayTrigger>
                  </label>
                  <VisibilitySelect
                      credentials={this.props.credentials}
                      visibility={this.state.visibility}
                      onChange={(visibility, concerne) => {
                        this.setState({visibility, concerne})
                      }}
                      concerne={this.state.concerne}
                      isInvalid={(this.state.errors?.visibility || this.state.errors?.concerne) !== undefined}
                      defaultConcerne={this.props.credentials.user}
                      projetId={this.props.projet.projetId}
                  />
                  <FormControl.Feedback
                      className="offset-1"
                      type="invalid">{errors?.visibility}{errors?.concerne}</FormControl.Feedback>
              </Row>}
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar>
                <Button className="btn-secondary mx-2"
                        onClick={() => this.hide()}>Annuler</Button>
                <SubmitButton id="save"
                              submitstatus={this.state.submitStatus}>
                  Enregistrer
                </SubmitButton>
              </ButtonToolbar>
            </Modal.Footer>
          </Form>
        }
      </Modal>
    );

  }


}
