import React, {Fragment, ReactNode} from "react";
import {
  Accordion, Badge,
  Button,
  ButtonToolbar,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";

import {UserBadge} from "../user-profile-lite/UserBadge";
import {LogoEntreprise, Projet} from "./Projet";
import {formatDate, formatEur, formatPerc} from "../../utils/format";
import formatDistance from "../../utils/formatDistance";
import {ProjetCommercial} from "./ProjetCommercial";
import {Loadable, waitFor} from "../../utils/Loading";
import {
  faAlignLeft, faCalendar,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarTimes,
  faEdit, faFileContract,
  faHome,
  faLock,
  faSmile, faStopwatch,
  faUserTie
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {undefinedToArray} from "../../utils/miscellaneous";
import {Message} from "../messages/Message";
import {add, isFuture, isPast} from "date-fns";

type ProjetCardProps = {
  projet: Projet;
  edit?: (projet: Projet, commercial?: ProjetCommercial) => void;
  onSelect: (projet: Projet) => void;
  onHide: () => void;
  commercial?: Loadable<ProjetCommercial>;
}


export class ProjetCard extends React.Component<ProjetCardProps> {

  render() {

    const {projet, edit, onSelect, onHide, commercial} = this.props;

    return (
      <Modal size={"lg"} show onHide={onHide}>
        <Modal.Header className="border-bottom text-center">
          <h5 className="m-3">
            <small>#{projet.projetId}.</small> {projet.title}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            {projet.entreprise &&
                <ListGroupItem className="p-3 d-flex">
                    <Col xs={1} className="text-center">
                        <LogoEntreprise>{projet.entreprise}</LogoEntreprise>
                    </Col>
                    <Col xs={11}>
                      {projet.entreprise.nom}
                    </Col>
                </ListGroupItem>
            }

            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-client">Client</Tooltip>}>
                  <FontAwesomeIcon icon={faSmile}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                <a href={Message.prepare({
                  recipient: undefinedToArray(projet.client?.userId),
                  subject: projet.title,
                  content: projet.description,
                  attachments: [],
                }, projet)}><UserBadge civilite prenom={false}>{projet.client!}</UserBadge></a>
              </Col>
            </ListGroupItem>
            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id={"tooltip-responsable"}>Responsable</Tooltip>}>
                  <FontAwesomeIcon icon={faUserTie}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                <UserBadge>{projet.responsable}</UserBadge>
              </Col>
            </ListGroupItem>
            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-description">Description</Tooltip>}>
                  <FontAwesomeIcon icon={faAlignLeft}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.description.split("\n").map((item, key) => <Fragment key={key}>{item}
                  <br/></Fragment>)}
              </Col>
            </ListGroupItem>
            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-adresse">Adresse</Tooltip>}>
                  <FontAwesomeIcon icon={faHome}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.adresse.split("\n").map((item, key) => <Fragment key={key}>{item}
                  <br/></Fragment>)}
              </Col>
            </ListGroupItem>

            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-ouverture">Démarrage prévu</Tooltip>}>
                  <FontAwesomeIcon icon={faFileContract}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.signature ?
                  `Signature le ${formatDate(projet.signature)}` :
                  "Pas de date de signature"}
              </Col>
            </ListGroupItem>

            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-ouverture">Ouverture</Tooltip>}>
                  <FontAwesomeIcon icon={faCalendarPlus}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.ouverture ?
                  `Projet ouvert le ${formatDate(projet.ouverture)} (il y a ${
                    formatDistance(new Date(), projet.ouverture)})` :
                  "Date d'ouverture non définie"}
              </Col>
            </ListGroupItem>
            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-ouverture">Durée prévue</Tooltip>}>
                  <FontAwesomeIcon icon={faStopwatch}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.dureePrevue ? `${projet.dureePrevue} mois` : "non définie"}
                {projet.end().map(end => <>
                  &nbsp;(fin prévue le {formatDate(end)}, {
                  isFuture(end) ? `${formatDistance(new Date(), end)} restant)` :
                    <Badge bg="danger">dépassement {formatDistance(new Date(), end)}</Badge>
                })
                </>)}
              </Col>
            </ListGroupItem>

            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-ouverture">Réception</Tooltip>}>
                  <FontAwesomeIcon icon={faCalendarCheck}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.reception ?
                  `Projet receptionné le ${formatDate(projet.reception)} (il y a ${
                    formatDistance(new Date(), projet.reception)})` :
                  "Date de réception non définie"}
              </Col>
            </ListGroupItem>
            <ListGroupItem className="p-3 d-flex">
              <Col xs={1} className="text-center">
                <OverlayTrigger overlay={<Tooltip id="tooltip-cloture">Clôture</Tooltip>}>
                  <FontAwesomeIcon icon={faCalendarTimes}/>
                </OverlayTrigger>
              </Col>
              <Col xs={11}>
                {projet.termine ?
                  `Projet clôturé le ${formatDate(projet.termine)}${projet.ouverture ? ` (durée ${
                      formatDistance(projet.termine, projet.ouverture)
                    })` :
                    ''}` :
                  "Projet non clôturé"}
              </Col>
            </ListGroupItem>
          </ListGroup>
          {waitFor(commercial, commercial => {
            const commercialArray: [string, string][] = [];
            const adminArray = [];
            if (commercial) {
              if (commercial.dateVente) {
                commercialArray.push(["Date vente", formatDate(commercial.dateVente)])
              }
              if (commercial.prixMaison) {
                commercialArray.push(["Prix maison", formatEur(commercial.prixMaison)]);
              }
              if (commercial.depotPermis) {
                adminArray.push(["Dépot permis", formatDate(commercial.depotPermis)]);
              }
              if (commercial.acceptationPermis) {
                adminArray.push(["Acceptation permis", formatDate(commercial.acceptationPermis)]);
              }
              if (commercial.mbPrevisionnelle) {
                commercialArray.push(["MB prévisionnelle", formatPerc(commercial.mbPrevisionnelle / 100.0)]);
              }
              if (commercial.mbContreEtude) {
                commercialArray.push(["MB contre-étude", formatPerc(commercial.mbContreEtude / 100.0)]);
              }
              if (commercial.prixDefinitif) {
                commercialArray.push(["Prix définitif", formatEur(commercial.prixDefinitif)])
              }
              if (commercial.mbDefinitive) {
                commercialArray.push(["Marge définitive", formatPerc(commercial.mbDefinitive / 100.0)]);
              }
              if (commercial.notes) {
                commercialArray.push(["Notes", commercial.notes]);
              }
            }

            return <Accordion className="border-top">
              {(commercial?.commercial || commercialArray.length > 0) && <Accordion.Item eventKey="0">
                  <Accordion.Header>Données commerciales {commercial?.locked &&
                      <FontAwesomeIcon icon={faLock}/>}</Accordion.Header>
                  <Accordion.Body>
                      <ListGroup variant="flush">
                        {commercial?.commercial &&
                            <ListGroupItem className="p-2 d-flex">
                                <Col xs={3} className="text-end pe-2"><label>Vendeur</label></Col>
                                <Col xs={9} className="mx-1"><UserBadge>{commercial.commercial}</UserBadge></Col>
                            </ListGroupItem>
                        }
                        {commercialArray.map(([k, v], i) =>
                          <ListGroupItem key={i} className="p-2 d-flex">
                            <Col xs={3} className="text-end pe-2"><label>{k}</label></Col>
                            <Col xs={2} className="text-end">{v}</Col>
                          </ListGroupItem>
                        )}
                      </ListGroup>
                  </Accordion.Body>
              </Accordion.Item>}
              {adminArray.length > 0 && <Accordion.Item eventKey="1">
                  <Accordion.Header>Données administratives</Accordion.Header>
                  <Accordion.Body>
                      <ListGroup variant="flush">
                        {adminArray.map(([k, v], i) =>
                          <ListGroupItem key={i} className="p-2 d-flex">
                            <Col xs={3} className="text-end pe-2"><label>{k}</label></Col>
                            <Col xs={9}>{v}</Col>
                          </ListGroupItem>
                        )}
                      </ListGroup>
                  </Accordion.Body>
              </Accordion.Item>}
            </Accordion>;

          })}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="ms-auto">
            {
              edit && waitFor(this.props.commercial, commercial =>
                <>
                  <Button className="mx-1" onClick={() => edit(projet, commercial)}>
                    <FontAwesomeIcon icon={faEdit} size="lg"/>
                  </Button>
                </>
              )
            }
            <Button className="mx-1" onClick={() => onSelect(projet)}>Sélectionner</Button>
            <Button className="mx-1" onClick={onHide}>Fermer</Button>
          </ButtonToolbar>

        </Modal.Footer>
      </Modal>
    );

  }

}