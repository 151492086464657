import {WithLoginProps} from "../../utils/auth";
import {Loadable, LOADING, waitFor} from "../../utils/Loading";
import immutable from "immutable";
import {ABDocument} from "./Document";
import {AlertData} from "../../utils/Alerts";
import React, {Fragment} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {DocumentCard} from "./DocumentCard";
import {Projet} from "../projets/Projet";
import {UserBadge} from "../user-profile-lite/UserBadge";
import {DocEditor} from "./DocEditor";
import SignForm from "./SignForm";
import {Either} from "monet";
import {SubmitError} from "../../utils/FetchError";

type ParapheurProps = WithLoginProps;

type ParapheurState = {
  documents: Loadable<immutable.Map<number, ABDocument>>;
  projets: Loadable<immutable.Map<number, Projet>>;
  alerts?: AlertData;
  editDoc?: ABDocument;
  signDoc?: ABDocument;
  camera?: string;
};

export class Parapheur extends React.Component<ParapheurProps, ParapheurState> {
  state: ParapheurState = {
    documents: LOADING,
    projets: LOADING,
  };

  componentDidMount() {
    this.refreshDocs();
    this.refreshProjects();
  }

  private refreshProjects() {
    this.setState({projets: LOADING});
    Projet.loadAll(false)
      .then(projets => this.setState({projets}));
  }

  private refreshDocs() {
    this.setState({documents: LOADING});
    ABDocument.mustSign()
      .then((documents) => {
        const map = immutable.Map(documents.map(d => [d.documentId, d]));
        this.setState({documents: map})
      })
      .catch((error) => this.setState({documents: error}));
  }

  private updateDocs(document: ABDocument[], documents: immutable.Map<number, ABDocument>) {
    const upd = document.reduce(
      (docs, d) => {
        if (d.mustSign.findIndex(this.props.credentials.is) > 0) {
          return docs.set(d.documentId, d);
        } else {
          return docs.remove(d.documentId);
        }
      },
      documents
    )
    this.setState({documents: upd});
  }

  private handleDelete(document: ABDocument, documents: immutable.Map<number, ABDocument>): Promise<void> {
    return document.delete(this.props.credentials)
      .then(() => documents.remove(document.documentId))
      .then((r) => {
        this.setState({documents: r});
      })
      .catch((r: Error) =>
        this.setState({documents: r})
      );

  }

  render() {
    return waitFor(this.state.documents, (docs: immutable.Map<number, ABDocument>) =>
      <Fragment>
        <Card className="mb-3">
          <Card.Header><h2>Documents à signer</h2></Card.Header>
          <Card.Body>
            {docs.isEmpty() && "Rien pour l'instant"}
            {waitFor(this.state.projets, projets =>
              docs.groupBy(d => d.projetId)
                .entrySeq()
                .map(([projetId, docSeq]) => {
                    const projet = projetId && projets.get(projetId)
                    return <Fragment key={projetId}>
                      <h4>
                        {projet ? <>{projet.title} {projet.client &&
                            <UserBadge civilite={true} prenom={false}>{projet.client}</UserBadge>}</> :
                        "inconnu"}
                      </h4>
                      <Row>
                        {docSeq.valueSeq().map((doc) =>
                          <Col lg="4" md="6" sm="12" className="mb-4" key={doc.documentId}>
                            <DocumentCard
                              actions={{
                                onDelete: this.props.credentials.isAdmin() ? (() => this.handleDelete(doc, docs)) : undefined,
                                onEdit: doc.editable(this.props.credentials) ? (() => this.setState({editDoc: doc})) : undefined,
                                onSign: (d) => this.setState({signDoc: d}),
                              }}
                              doc={doc}
                              badges={doc.mustSignBadges()}
                              defaultOpen={false}
                            />
                          </Col>
                        )}
                      </Row>
                    </Fragment>;
                  }
                )
            )}
          </Card.Body>
        </Card>

        {this.state.editDoc &&
        <DocEditor
            credentials={this.props.credentials}
            edit={this.state.editDoc}
            update={(doc) => this.setState({editDoc: doc})}
            onSubmit={(submitted, saved) => this.handleSubmitted(saved, docs)}
            camera={this.state.camera}
            setCameraState={(camera) => this.setState({camera})}
        />
        }

        {this.state.signDoc &&
        <SignForm
            doc={this.state.signDoc}
            credentials={this.props.credentials}
            onUpdate={(signDoc) => {
              this.updateDocs([signDoc], docs);
              this.setState({signDoc});
            }}
            onHide={() => this.setState({signDoc: undefined})}
        />
        }
      </Fragment>
    );
  }

  private handleSubmitted(res: Either<SubmitError, ABDocument[]>, docs: immutable.Map<number, ABDocument>): Promise<Either<SubmitError, ABDocument[]>> {
    res.forEach(r => this.updateDocs(r, docs));
    return new Promise((resolve) => resolve(res));
  }

}

